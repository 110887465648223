import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { assetsApi } from './Assets'
import { getAuth } from '../Auth'

const API_URL = process.env.REACT_APP_API_URL

export const checkoutApi = createApi({
	reducerPath: 'checkoutApi',
	baseQuery: fetchBaseQuery({
		baseUrl: `${API_URL}/asset-status/`,
		prepareHeaders: (headers) => {
			const auth = getAuth()
			if (auth && auth?.token) {
				headers.set('Authorization', `Bearer ${auth?.token}`);
			}
			return headers;
		},
	}),
	tagTypes: ['Checkout'],
	endpoints: (build) => ({
		addCheckout: build.mutation<any, any>({
			query: (data) => ({
				url: `checkout`,
				method: 'POST',
				body: data,
			}),
			onQueryStarted: (arg, api) => {
				api.queryFulfilled.then(() => {
					api.dispatch(
						assetsApi.util.invalidateTags(["Assets"])
					);
				});
			},
			invalidatesTags: () => [{ type: 'Checkout' }],
		}),
		addCheckin: build.mutation<any, any>({
			query: (data) => ({
				url: `checkin`,
				method: 'POST',
				body: data,
			}),
			onQueryStarted: (arg, api) => {
				api.queryFulfilled.then(() => {
					api.dispatch(
						assetsApi.util.invalidateTags(["Assets"])
					);
				});
			},
			invalidatesTags: () => [{ type: 'Checkout' }],
		}),
	}),
})

export const {
	useAddCheckoutMutation,
	useAddCheckinMutation,
} = checkoutApi
