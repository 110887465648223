import { toAbsoluteUrl } from '_metronic/helpers';
import { Images } from '_metronic/assets/Assets';
import "./Footer.css"
import { Link } from 'react-router-dom';

function Footer() {
	return (
		<footer className="footer-section d-flex justify-content-center align-items-center flex-column position-relative z-2">
			<div className="custom-container container">
				<div className="d-flex justify-content-center align-items-center flex-column">
					<Link to={"/"}>
						<div className="image mb-lg-5 mb-4">
							<img
								src={toAbsoluteUrl(Images.DefaultWhiteLogo)}
								alt='Logo'
								className='h-25px h-xl-30px img-fluid'
							/>
						</div>
					</Link>
					<p className=' mb-lg-5 mb-4'>&copy; 2023 All Rights Reserved Asset Rack</p>
					<ul className='social-icons list-unstyled d-flex'>
						<li>
							<Link to={"/"}><i className='lab la-facebook-f'></i></Link>
						</li>
						<li>
							<Link to={"/"}><i className='lab la-twitter'></i></Link>
						</li>
						<li>
							<Link to={"/"}><i className='lab la-linkedin-in'></i></Link>
						</li>
						<li>
							<Link to={"/"}><i className='lab la-google'></i></Link>
						</li>
						<li>
							<Link to={"/"}><i className="lab la-pinterest-p"></i></Link>
						</li>
					</ul>
					<ul className='internal-links list-unstyled d-flex  justify-content-center align-items-center '>
						<li>
							<Link to={`/privacy-policy`}>Privacy Policy</Link>
						</li>
						<li>
							<Link to={`/terms-of-service`}>Terms Of Service</Link>
						</li>
					</ul>
				</div>
			</div>
		</footer>
	)
}

export default Footer