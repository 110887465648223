import {Navigate, Route, Routes, Outlet} from 'react-router-dom'
import {PageLink, PageTitle} from '../../../../_metronic/layout/core'
import Location from './Locations/Location'
import CompanyInfo from './CompnayInfo/CompanyInfo'
import Sites from './Sites/Sites'
import Departments from './Departments/Departments'
import Assets from './Database/Assets/Assets'
import Options from './Options/Options'
import CustomEmails from './CustomEmails/CustomEmails'
import Events from './Event/Components/Events'
import Categories from './Categories/Categories'
import PersonEmployee from './Database/PersonEmployee/PersonEmployee'
import CustomersTable from './Database/CustomersTable/CustomersTable'
import MaintenanceTable from './Database/Maintenance Table/MaintenanceTable'
import WarrantyTable from './Database/WarrantiesTable/Warranty'
import ContractTable from './Database/ContractTable/ContractTable'
import {TableOption} from './TableOptions/TableOption'
import AddCustomEvents from './Event/Components/AddCustomEvents'
import UserAssets from './Asset/Assets'
import AddAssets from './AddAssets/AddAssetsInfo'
import EditAssets from './EditAssets/EditAssetsInfo'
import SetupWizard from './SetupWizard'

const setupBreadCrumbs: Array<PageLink> = [
  {
    title: 'Setup',
    path: '/apps/setup/wizard',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const SetupPage = () => {
  return (
    <Routes>
      <Route element={<Outlet />}>
        <Route
          path='wizard'
          element={
            <>
              <PageTitle breadcrumbs={setupBreadCrumbs}>Setup Wizard</PageTitle>
              <SetupWizard />
            </>
          }
        />
        <Route
          path='company'
          element={
            <>
              <PageTitle breadcrumbs={setupBreadCrumbs}>Company Information</PageTitle>
              <CompanyInfo />
            </>
          }
        />
        <Route
          path='location'
          element={
            <>
              <PageTitle breadcrumbs={setupBreadCrumbs}>Locations</PageTitle>
              <Location />
            </>
          }
        />
        <Route
          path='sites'
          element={
            <>
              <PageTitle breadcrumbs={setupBreadCrumbs}>Sites</PageTitle>
              <Sites />
            </>
          }
        />
        <Route
          path='assets/add'
          element={
            <>
              <PageTitle breadcrumbs={setupBreadCrumbs}>Add Assets</PageTitle>
              <AddAssets />
            </>
          }
        />
        <Route
          path='assets/edit'
          element={
            <>
              <PageTitle breadcrumbs={setupBreadCrumbs}>Edit Assets</PageTitle>
              <EditAssets />
            </>
          }
        />
        <Route
          path='categories'
          element={
            <>
              <PageTitle breadcrumbs={setupBreadCrumbs}>Categories</PageTitle>
              <Categories />
            </>
          }
        />
        <Route
          path='department'
          element={
            <>
              <PageTitle breadcrumbs={setupBreadCrumbs}>Departments</PageTitle>
              <Departments />
            </>
          }
        />
        <Route
          path='database/asset'
          element={
            <>
              <PageTitle breadcrumbs={setupBreadCrumbs}>Assets Table</PageTitle>
              <Assets />
            </>
          }
        />
        <Route
          path='database/person'
          element={
            <>
              <PageTitle breadcrumbs={setupBreadCrumbs}>Persons/Employees</PageTitle>
              <PersonEmployee />
            </>
          }
        />
        <Route
          path='database/customer'
          element={
            <>
              <PageTitle breadcrumbs={setupBreadCrumbs}>Customers Table</PageTitle>
              <CustomersTable />
            </>
          }
        />
        <Route
          path='database/maintenance'
          element={
            <>
              <PageTitle breadcrumbs={setupBreadCrumbs}>Maintenance Table</PageTitle>
              <MaintenanceTable />
            </>
          }
        />
        <Route
          path='database/warranty'
          element={
            <>
              <PageTitle breadcrumbs={setupBreadCrumbs}>Warranties Table</PageTitle>
              <WarrantyTable />
            </>
          }
        />
        <Route
          path='database/contract'
          element={
            <>
              <PageTitle breadcrumbs={setupBreadCrumbs}>Contract Table</PageTitle>
              <ContractTable />
            </>
          }
        />
        <Route
          path='database/warranty'
          element={
            <>
              <PageTitle breadcrumbs={setupBreadCrumbs}>Warranties Table</PageTitle>
            </>
          }
        />
        <Route
          path='database/contract'
          element={
            <>
              <PageTitle breadcrumbs={setupBreadCrumbs}>Contract Table</PageTitle>
            </>
          }
        />
        <Route
          path='table'
          element={
            <>
              <PageTitle breadcrumbs={setupBreadCrumbs}>Table Option</PageTitle>
              <TableOption />
            </>
          }
        />
        <Route
          path='Options'
          element={
            <>
              <PageTitle breadcrumbs={setupBreadCrumbs}>Options</PageTitle>
              <Options />
            </>
          }
        />
        <Route
          path='CustomEmails'
          element={
            <>
              <PageTitle breadcrumbs={setupBreadCrumbs}>CustomEmails</PageTitle>
              <CustomEmails />
            </>
          }
        />
        <Route
          path='events'
          element={
            <>
              <PageTitle breadcrumbs={setupBreadCrumbs}>Events</PageTitle>
              <Events />
            </>
          }
        />
        <Route
          path='add'
          element={
            <>
              <PageTitle breadcrumbs={setupBreadCrumbs}>Add Custom Event</PageTitle>
              <AddCustomEvents />
            </>
          }
        />
        <Route
          path='events/edit/:id'
          element={
            <>
              <PageTitle breadcrumbs={setupBreadCrumbs}>Edit Custom Event</PageTitle>
              <AddCustomEvents />
            </>
          }
        />
        <Route index element={<Navigate to='/apps/setup/company' />} />
      </Route>
    </Routes>
  )
}

export default SetupPage
