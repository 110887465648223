import { useFormik } from 'formik'
import Modal from 'react-bootstrap/Modal'
import { initialPersons, PersonResponse } from '../Core/_models'
import * as Yup from 'yup'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { useAuth } from '../../../../Auth'
import { useAddPersonMutation, useUpdatePersonMutation } from '../../../../Services/Persons'
import { useGetAllSitesQuery } from 'App/Modules/Services/Sites'
import { useGetAllDepartmentsQuery } from 'App/Modules/Services/Departments'
import { useGetAllLocationsBySiteQuery } from 'App/Modules/Services/Locations'
import { useGetAllGroupsQuery } from 'App/Modules/Services/groups'
import DepartmentsModel from '../../Departments/Components/DepartmentsModel'
import LocationModal from '../../Locations/Components/LocationModal'
import SitesModal from '../../Sites/Components/SitesModal'
import { emailRegex, nameRegex } from 'config'

const PersonsSchema = Yup.object().shape({
  firstName: Yup.string()
    .matches(nameRegex, 'Only letters, numbers and no extra spaces allowed')
    .min(3, 'Minimum 3 letters')
    .max(50, 'Maximum 50 letters')
    .required('First name is required'),
  lastName: Yup.string()
    .matches(nameRegex, 'Only letters, numbers and no extra spaces allowed')
    .min(3, 'Minimum 3 letters')
    .max(50, 'Maximum 50 letters')
    .required('Last name is required'),
  phone: Yup.string()
    .matches(
      /^(\+)?[0-9]*$/,
      'Phone number must contain only numeric characters and may start with a plus sign'
    )
    .test('is-valid-number', 'Phone number must be between 10 and 15 digits', (value) => {
      if (value && value.trim().length > 0) {
        const stringValue = String(value).replace(/\D/g, '')
        return stringValue.length >= 10 && stringValue.length <= 15
      }
      return true
    })
    .nullable(),
  email: Yup.string()
    .matches(emailRegex, 'Invalid email address')
    .max(50, 'Maximum 50 characters')
    .nullable()
    .required('Email is required'),
  notes: Yup.string().max(250, 'Notes must be no more than 250 characters.'),
})
const PersonsModal = (props: any) => {
  const { currentUser } = useAuth()
  const [siteId, setSiteId] = useState<boolean>(false)
  const [skip, setSkip] = useState<boolean>(false)
  const { data: siteData } = useGetAllSitesQuery('')
  const [showSiteModal, setShowSiteModal] = useState<boolean>(false)
  const [showLocationModal, setShowLocationModal] = useState<boolean>(false)
  const [showDepartmentModal, setShowDepartmentModal] = useState<boolean>(false)
  const [addCustomer, { isLoading: isAdding }] = useAddPersonMutation()
  const [updateCustomer, { isLoading: isUpdating }] = useUpdatePersonMutation()
  const { data: locationData, error: locationError } = useGetAllLocationsBySiteQuery(
    { id: siteId },
    { skip: !siteId }
  )
  const { data: departmentData } = useGetAllDepartmentsQuery({ page: 0, limit: 0 })
  const { data: groupsList } = useGetAllGroupsQuery({})

  useEffect(() => {
    formik.resetForm()
    if (props?.personId) {
      formik.setValues({
        firstName: props?.PersonData?.person?.firstName,
        lastName: props?.PersonData?.person?.lastName,
        employeeId: props?.PersonData?.person?.employeeId || '',
        title: props?.PersonData?.person?.title || '',
        phone: props?.PersonData?.person?.phone || '',
        email: props?.PersonData?.person?.email || '',
        siteId: props?.PersonData?.person?.siteId,
        locationID: props?.PersonData?.person?.locationID,
        departmentId: props?.PersonData?.person?.departmentId,
        notes: props?.PersonData?.person?.notes || '',
      })
    }
  }, [props.personId, props.PersonData])

  useEffect(() => {
    setSkip(false)
  }, [])

  const handleSiteClick = (e: any) => {
    const id = e.target.value
    setSiteId(id)
    setSkip(false)
  }
  const handleShowSiteModal = () => {
    setShowSiteModal(true)
  }

  const handleCloseSiteModal = () => {
    setShowSiteModal(false)
  }

  const handleShowLocationModal = () => {
    setShowLocationModal(true)
  }

  const handleCloseLocationModal = () => {
    setShowLocationModal(false)
  }

  const handleShowDepartmentModal = () => {
    setShowDepartmentModal(true)
  }

  const handleCloseDepartmentModal = () => {
    setShowDepartmentModal(false)
  }

  const formik = useFormik({
    initialValues: { ...initialPersons },
    validationSchema: PersonsSchema,
    onSubmit: async (values) => {
      if (currentUser) {
        try {
          const data = props?.personId
            ? await updateCustomer({ id: props?.personId, values }).unwrap()
            : await addCustomer(values).unwrap()
          if (data) {
            toast.dismiss()
            if (data?.success) {
              props.setPersonId('')
              formik.resetForm()
              toast.success(data?.message)
              props.handleClose()
            } else {
              toast.error(data?.message)
            }
          }
        } catch (error: any) {
          if (!error?.data?.success) {
            toast.error(error?.data?.message)
          }
          formik.setSubmitting(false)
        }
      }
    },
  })
  const handleClose = () => {
    formik.resetForm()
    props.setPersonId('')
    props.handleClose()
  }
  return (
    <>
      <Modal
        className='custom-modal-sm'
        show={props.show}
        onHide={props.handleClose}
        backdrop='static'
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>{props?.personId ? 'Update Person' : 'Add Person'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={formik.handleSubmit} noValidate className='form'>
            <div className='inner-site-modal'>
              <div className='row mb-4'>
                <label className='col-lg-3 col-form-label required fw-bold fs-6 mb-2'>
                  First Name
                </label>
                <div className='col-lg-9 fv-row'>
                  <div>
                    <input
                      {...formik.getFieldProps('firstName')}
                      type='text'
                      className='form-control form-control-solid mb-3 mb-lg-0'
                      onChange={formik.handleChange}
                    />
                  </div>
                  {formik.touched.firstName && formik.errors.firstName && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.firstName}</div>
                    </div>
                  )}
                </div>
              </div>
              <div className='row mb-4'>
                <label className='col-lg-3 col-form-label required fw-bold fs-6 mb-2'>
                  Last Name
                </label>
                <div className='col-lg-9 fv-row'>
                  <div>
                    <input
                      {...formik.getFieldProps('lastName')}
                      type='text'
                      className='form-control form-control-solid mb-3 mb-lg-0'
                      onChange={formik.handleChange}
                    />
                  </div>
                  {formik.touched.lastName && formik.errors.lastName && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.lastName}</div>
                    </div>
                  )}
                </div>
              </div>
              <div className='row mb-4'>
                <label className='col-lg-3 col-form-label fw-bold fs-6 mb-2'>Employee ID</label>
                <div className='col-lg-9 fv-row'>
                  <div>
                    <input
                      {...formik.getFieldProps('employeeId')}
                      type='text'
                      className='form-control form-control-solid mb-3 mb-lg-0'
                      onChange={formik.handleChange}
                    />
                  </div>
                  {formik.touched.employeeId ||
                    (formik.errors.employeeId && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.employeeId}</div>
                      </div>
                    ))}
                </div>
              </div>
              <div className='row mb-4'>
                <label className='col-lg-3 col-form-label fw-bold fs-6 mb-2'>Title</label>
                <div className='col-lg-9 fv-row'>
                  <div>
                    <input
                      {...formik.getFieldProps('title')}
                      type='text'
                      className='form-control form-control-solid mb-3 mb-lg-0'
                      onChange={formik.handleChange}
                    />
                  </div>
                </div>
              </div>
              <div className='row mb-4'>
                <label className='col-lg-3 col-form-label  fw-bold fs-6 mb-2'>Phone</label>
                <div className='col-lg-9 fv-row'>
                  <input
                    {...formik.getFieldProps('phone')}
                    type='text'
                    className='form-control form-control-solid mb-3 mb-lg-0'
                    onChange={formik.handleChange}
                  />
                  {formik.touched.phone && formik.errors.phone && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.phone}</div>
                    </div>
                  )}
                </div>
              </div>
              <div className='row mb-4'>
                <label className='col-lg-3 col-form-label required fw-bold fs-6 mb-2'>Email</label>
                <div className='col-lg-9 fv-row'>
                  <div>
                    <input
                      {...formik.getFieldProps('email')}
                      type='text'
                      className='form-control form-control-solid mb-3 mb-lg-0'
                      onChange={formik.handleChange}
                    />
                  </div>
                  {formik.touched.email && formik.errors.email && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.email}</div>
                    </div>
                  )}
                </div>
              </div>
              <div className='row mb-4'>
                <label className='col-lg-3 col-form-label fw-bold fs-6'>
                  <span>Site</span>
                </label>
                <div className='col-lg-9 fv-row'>
                  <select
                    className='form-select form-control form-control-solid form-select-solid  '
                    {...formik.getFieldProps('siteId')}
                    onChange={(e) => {
                      formik.handleChange(e)
                      handleSiteClick(e)
                    }}
                  >
                    <option>Select Site</option>
                    {siteData?.sites.map((item: any, idx: number) => {
                      return (
                        <option key={idx} value={item.id}>
                          {item.name}
                        </option>
                      )
                    })}
                  </select>
                  <SitesModal
                    show={showSiteModal}
                    handleClose={handleCloseSiteModal}
                    SiteData={[]}
                  />
                </div>
              </div>
              <div className='row mb-4'>
                <label className='col-lg-3 col-form-label fw-bold fs-6'>
                  <span>Location</span>
                </label>
                <div className='col-lg-9 fv-row'>
                  <select
                    className='form-select form-control form-control-solid form-select-solid  '
                    {...formik.getFieldProps('locationId')}
                  >
                    <option>Select Location</option>
                    {locationData?.locations?.map((item: any, idx: number) => {
                      return (
                        <option key={idx} value={item.id}>
                          {item.location}
                        </option>
                      )
                    })}
                  </select>
                  <LocationModal
                    show={showLocationModal}
                    handleClose={handleCloseLocationModal}
                    locationData={[]}
                  />
                </div>
              </div>

              <div className='row mb-4'>
                <label className='col-lg-3 col-form-label fw-bold fs-6'>
                  <span>Department</span>
                </label>
                <div className='col-lg-9 fv-row'>
                  <select
                    className='form-select form-control form-control-solid form-select-solid  '
                    {...formik.getFieldProps('departmentId')}
                  >
                    <option>Select Department</option>
                    {departmentData?.department?.map((item: any, idx: number) => {
                      return (
                        <option key={idx} value={item.id}>
                          {item.department}
                        </option>
                      )
                    })}
                  </select>
                  <DepartmentsModel
                    show={showDepartmentModal}
                    handleClose={handleCloseDepartmentModal}
                    data={[]}
                  />
                </div>
              </div>
              <div className='row mb-4'>
                <label className='col-lg-3 col-form-label fw-bold fs-6 mb-2'>Notes</label>
                <div className='col-lg-9 fv-row'>
                  <textarea
                    {...formik.getFieldProps('notes')}
                    rows={3}
                    className='form-control form-control-solid mb-3 mb-lg-0'
                  />
                </div>
                {formik.touched.notes && formik.errors.notes && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.notes}</div>
                  </div>
                )}
              </div>
            </div>
            <div className='text-end pt-2'>
              <button
                type='button'
                className='btn btn-light-primary   main-btn-style me-2'
                onClick={handleClose}
              >
                Close{' '}
              </button>
              <button
                type='submit'
                className='btn btn-primary main-btn-style'
                disabled={formik.isSubmitting || !formik.isValid || !formik.dirty}
              >
                {!isAdding && !isUpdating && (
                  <span className='indicator-label'>
                    {props?.updatePerson ? 'Update Person' : 'Add Person'}
                  </span>
                )}
                {(isAdding || isUpdating) && (
                  <span className='indicator-progress d-block'>
                    Please wait...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default PersonsModal
