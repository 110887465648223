import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { getAuth } from '../Auth'

const API_URL = process.env.REACT_APP_API_URL

export const dashboardApi = createApi({
    reducerPath: 'dashboardApi',
    baseQuery: fetchBaseQuery({
        baseUrl: `${API_URL}/dashboard/`,
        prepareHeaders: (headers) => {
            const auth = getAuth()
            if (auth && auth?.token) {
                headers.set('Authorization', `Bearer ${auth?.token}`);
            }
            return headers;
        },
    }),
    tagTypes: ['Dashboard'],
    endpoints: (build) => ({
        getDashboardStats: build.query<any, void>({
            query: () => `stats`,
            providesTags: ['Dashboard'],
        }),
    }),
})

export const {
    useGetDashboardStatsQuery
} = dashboardApi
