import React, { useState } from 'react';
import SetupEmailModal from './SetupAlerts/SetupEmailModal';

function EmailAlerts({frequencyAllDays, stopEmailPara_1}) {
    const [emailCheckbox, setEmailCheckbox] = useState<boolean>(false);
	const [showCheckoutModal, setShowCheckoutModal] = useState<boolean>(false)

    const handleShowCheckoutModal = () => {
		setShowCheckoutModal(true)
	}

    const handleCloseCheckoutModal = () => {
		setShowCheckoutModal(false)
	}

    const handleCheckboxChange = (index) => {
        setEmailCheckbox(!emailCheckbox);
    };

    const handleCancel = () => {
		setShowCheckoutModal(false)
	}


  return (
    <div className='d-flex'>
        <input className='form-check-input custom-form-check-input' type='checkbox' onChange={handleCheckboxChange} defaultChecked={false}/>
        <div className='ps-4'>
            <strong>Email Alerts</strong>
            <p>Turn on this option to email out an alert if any alert exists.</p>
            {emailCheckbox ? 
                <button type="button" className="btn btn-light-primary main-btn-style" onClick={() => handleShowCheckoutModal()}>Setup Alert Setting</button> 
                : 
                ""
            }
        </div>
        <SetupEmailModal
            show={showCheckoutModal}
            frequencyAllDays={frequencyAllDays}
            stopEmailPara_1={stopEmailPara_1}
            handleClose={handleCloseCheckoutModal}
            handleCancel={handleCancel}
        />
    </div>
  )
}

export default EmailAlerts