export type Asset = {
  assetTagId?: string
  brand?: string | null
  categoryId?: number
  departmentId?: number
  description?: string
  locationId?: number
  model?: string | null
  purchasedDate?: string
  purchasedFrom?: string | null
  serialNo?: string | null
  siteId?: number
  cost?: number | null
  statusType?: string
  photo?: any
  ram?: string | null
  storage?: string | null
  imei?: string | null
  ports?: number | null
  mbps?: string | null
  stands?: number | null
  batteries?: number | null
  bulbs?: number | null
  lens?: number | null
}

export const initialAssets: Asset = {
  assetTagId: '',
  brand: null,
  categoryId: undefined,
  departmentId: undefined,
  description: '',
  locationId: undefined,
  model: null,
  purchasedDate: undefined,
  purchasedFrom: null,
  serialNo: null,
  siteId: undefined,
  cost: null,
  statusType: 'Available',
  photo: '',
  ram: null,
  storage: null,
  imei: null,
  ports: null,
  mbps: null,
  stands: null,
  batteries: null,
  bulbs: null,
  lens: null
}

export type AssetResponse = {
  id: number
  assetTagId?: string
  brand?: string
  categoryId?: number
  createdAt?: string
  departmentId?: number
  description?: string
  locationId?: number
  model?: string
  purchasedDate?: string
  purchasedFrom?: string
  serialNo?: string
  siteId?: number
  userId?: number
  cost?: number | null
  statusType?: string
  assetstatus?: any
  site?: any
  location?: any
  photo?: any
}

export type Warranty = {
  duration?: number
  expirationDate?: string
  description?: string | undefined
  assetId?: number | null
  userId?: number | null
}

export const initialWarranty: Warranty = {
  duration: 1,
  expirationDate: '',
  description: '',
  assetId: null,
  userId: null
}