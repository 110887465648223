import { useEffect, useState } from "react";

function AdminRights({ checked, handleCheckboxChange }) {
    const [editAdminRight, setEditAdminRights] = useState(false)
    const [adminRights, setAdminRights] = useState(false)

    const OnclickEditAdminRights = () => {
        const newEditState = !editAdminRight;
        setEditAdminRights(newEditState);
        handleCheckboxChange('viewCompanyInfo', newEditState);
        handleCheckboxChange('addGroup', newEditState);
        handleCheckboxChange('addUser', newEditState);
    };

    useEffect(() => {
        if (checked.viewCompanyInfo && checked.addGroup && checked.addUser) {
            setEditAdminRights(true);
        } else {
            setEditAdminRights(false);
        }
    }, [checked]);

    const OnclickAdminRights = () => {
        const newAdminState = !adminRights;
        setAdminRights(newAdminState);
        handleCheckboxChange('enableDepartments', newAdminState);
        handleCheckboxChange('viewMaintenance', newAdminState);
        handleCheckboxChange('enableWarranties', newAdminState);
    };
    useEffect(() => {
        if (checked.enableDepartments && checked.viewMaintenance && checked.enableWarranties) {
            setAdminRights(true);
        } else {
            setAdminRights(false);
        }
    }, [checked]);
    return (
        <>
            <div className='mb-5'>
                <h4 className="mb-3">Admin Editing Rights</h4>
                <p className="mb-5">User has right to edit:</p>
                <div className="row">
                    <div className="offset-lg-1 col-lg-10">
                        <div className="table-responsive mb-4">
                            <table className="table table-bordered mb-4">
                                <thead className="thead-light  light-yellow-table">
                                    <tr>
                                        <th className="w-40">
                                            <label className='radio mb-0 d-inline-flex' htmlFor='allEditingRights'>
                                                <div>
                                                    <input
                                                        type='checkbox'
                                                        className='form-check-input custom-form-check-input sig_input me-2'
                                                        id='allEditingRights'
                                                        name='allEditingRights'
                                                        checked={editAdminRight}
                                                        onClick={OnclickEditAdminRights}
                                                        value='True'
                                                    />
                                                </div>
                                                <span className='vertically_align'>All Admin Editing Rights</span>
                                            </label>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className="d-none">
                                        <td>
                                            <label className='radio mb-0 d-inline-flex' htmlFor='alerts'>
                                                <div>
                                                    <input
                                                        type='checkbox'
                                                        className='form-check-input custom-form-check-input sig_input me-2'
                                                        id='alerts'
                                                        name='hasCheckOut'
                                                        value='True'
                                                    />
                                                </div>
                                                <span className='vertically_align'>Alerts</span>
                                            </label>
                                        </td>
                                        <td>
                                            <p className="mb-0">Setup Alerts.</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <label className='radio mb-0 d-inline-flex' htmlFor='companyInfo'>
                                                <div>
                                                    <input
                                                        type='checkbox'
                                                        className='form-check-input custom-form-check-input sig_input me-2'
                                                        checked={checked?.viewCompanyInfo}
                                                        id='companyInfo'
                                                        name='companyInfo'
                                                        onChange={(e) => handleCheckboxChange('viewCompanyInfo', e.target.checked)}
                                                        value='True'
                                                    />
                                                </div>
                                                <span className='vertically_align'>Company Info.</span>
                                            </label>
                                        </td>
                                        <td>
                                            <p className="mb-0">User can change Company information.</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <label className='radio mb-0 d-inline-flex' htmlFor='Groups'>
                                                <div>
                                                    <input
                                                        type='checkbox'
                                                        className='form-check-input custom-form-check-input sig_input me-2'
                                                        checked={checked?.addGroup}
                                                        id='Groups'
                                                        name='Groups'
                                                        onChange={(e) => handleCheckboxChange('addGroup', e.target.checked)}
                                                        value='True'
                                                    />
                                                </div>
                                                <span className='vertically_align'>Groups</span>
                                            </label>
                                        </td>
                                        <td>
                                            <p className="mb-0">Create or edit groups to change users' permissions.</p>
                                        </td>
                                    </tr>
                                    <tr className="d-none">
                                        <td>
                                            <label className='radio mb-0 d-inline-flex' htmlFor='options'>
                                                <div>
                                                    <input
                                                        type='checkbox'
                                                        className='form-check-input custom-form-check-input sig_input me-2'
                                                        id='options'
                                                        name='hasCheckOut'
                                                        value='True'
                                                    />
                                                </div>
                                                <span className='vertically_align'>Options</span>
                                            </label>
                                        </td>
                                        <td>
                                            <p className="mb-0">Setup default options.</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <label className='radio mb-0 d-inline-flex' htmlFor='users'>
                                                <div>
                                                    <input
                                                        type='checkbox'
                                                        className='form-check-input custom-form-check-input sig_input me-2'
                                                        checked={checked?.addUser}
                                                        id='users'
                                                        name='users'
                                                        onChange={(e) => handleCheckboxChange('addUser', e.target.checked)}
                                                        value='True'
                                                    />
                                                </div>
                                                <span className='vertically_align'>Users</span>
                                            </label>
                                        </td>
                                        <td>
                                            <p className="mb-0">Create and manage users.</p>
                                        </td>
                                    </tr>
                                    <tr className="d-none">
                                        <td>
                                            <label className='radio mb-0 d-inline-flex' htmlFor='reminderEmails'>
                                                <div>
                                                    <input
                                                        type='checkbox'
                                                        className='form-check-input custom-form-check-input sig_input me-2'
                                                        id='reminderEmails'
                                                        name='hasCheckOut'
                                                        value='True'
                                                    />
                                                </div>
                                                <span className='vertically_align'>Customize Reminder Emails</span>
                                            </label>
                                        </td>
                                        <td>
                                            <p className="mb-0">User can customize reminder emails.</p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="table-responsive mb-4 d-none">
                            <table className="table table-bordered mb-4">
                                <thead className="thead-light  light-yellow-table">
                                    <tr>
                                        <th className="w-40">
                                            <label className='radio mb-0 d-inline-flex' htmlFor='allDatabaseRights'>
                                                <div>
                                                    <input
                                                        type='checkbox'
                                                        className='form-check-input custom-form-check-input sig_input me-2'
                                                        id='allDatabaseRights'
                                                        name='hasCheckOut'
                                                        value='True'
                                                    />
                                                </div>
                                                <span className='vertically_align'>All Admin Database Field Rights</span>
                                            </label>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <label className='radio mb-0 d-inline-flex' htmlFor='databaseFields'>
                                                <div>
                                                    <input
                                                        type='checkbox'
                                                        className='form-check-input custom-form-check-input sig_input me-2'
                                                        id='databaseFields'
                                                        name='hasCheckOut'
                                                        value='True'
                                                    />
                                                </div>
                                                <span className='vertically_align'>Asset Database fields</span>
                                            </label>
                                        </td>
                                        <td>
                                            <p className="mb-0">Create or remove asset database fields.</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <label className='radio mb-0 d-inline-flex' htmlFor='CustomizeAssetForm'>
                                                <div>
                                                    <input
                                                        type='checkbox'
                                                        className='form-check-input custom-form-check-input sig_input me-2'
                                                        id='CustomizeAssetForm'
                                                        name='hasCheckOut'
                                                        value='True'
                                                    />
                                                </div>
                                                <span className='vertically_align'>Customize Asset Form</span>
                                            </label>
                                        </td>
                                        <td>
                                            <p className="mb-0">Allow user to customize Asset Form</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <label className='radio mb-0 d-inline-flex' htmlFor='CustomerDatabaseFields'>
                                                <div>
                                                    <input
                                                        type='checkbox'
                                                        className='form-check-input custom-form-check-input sig_input me-2'
                                                        id='CustomerDatabaseFields'
                                                        name='hasCheckOut'
                                                        value='True'
                                                    />
                                                </div>
                                                <span className='vertically_align'>Customer Database Fields</span>
                                            </label>
                                        </td>
                                        <td>
                                            <p className="mb-0">Create or remove customer database fields.</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <label className='radio mb-0 d-inline-flex' htmlFor='CustomizeCustomerForm'>
                                                <div>
                                                    <input
                                                        type='checkbox'
                                                        className='form-check-input custom-form-check-input sig_input me-2'
                                                        id='CustomizeCustomerForm'
                                                        name='hasCheckOut'
                                                        value='True'
                                                    />
                                                </div>
                                                <span className='vertically_align'>Customize Customer Form</span>
                                            </label>
                                        </td>
                                        <td>
                                            <p className="mb-0">Allow user to customize Customer Form</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <label className='radio mb-0 d-inline-flex' htmlFor='EmployeeDatabaseFields'>
                                                <div>
                                                    <input
                                                        type='checkbox'
                                                        className='form-check-input custom-form-check-input sig_input me-2'
                                                        id='EmployeeDatabaseFields'
                                                        name='hasCheckOut'
                                                        value='True'
                                                    />
                                                </div>
                                                <span className='vertically_align'>Person/Employee Database Fields</span>
                                            </label>
                                        </td>
                                        <td>
                                            <p className="mb-0">Create or remove person/employee database fields.</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <label className='radio mb-0 d-inline-flex' htmlFor='CustomizeEmployeeForm'>
                                                <div>
                                                    <input
                                                        type='checkbox'
                                                        className='form-check-input custom-form-check-input sig_input me-2'
                                                        id='CustomizeEmployeeForm'
                                                        name='hasCheckOut'
                                                        value='True'
                                                    />
                                                </div>
                                                <span className='vertically_align'>Customize Person/Employee Form</span>
                                            </label>
                                        </td>
                                        <td>
                                            <p className="mb-0">Allow user to customize Person/Employee Form</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <label className='radio mb-0 d-inline-flex' htmlFor='MaintenanceDatabaseFields'>
                                                <div>
                                                    <input
                                                        type='checkbox'
                                                        className='form-check-input custom-form-check-input sig_input me-2'
                                                        id='MaintenanceDatabaseFields'
                                                        name='hasCheckOut'
                                                        value='True'
                                                    />
                                                </div>
                                                <span className='vertically_align'>Maintenance Database Fields</span>
                                            </label>
                                        </td>
                                        <td>
                                            <p className="mb-0">Create or remove maintenance database fields.</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <label className='radio mb-0 d-inline-flex' htmlFor='CustomizeMaintenanceForm'>
                                                <div>
                                                    <input
                                                        type='checkbox'
                                                        className='form-check-input custom-form-check-input sig_input me-2'
                                                        id='CustomizeMaintenanceForm'
                                                        name='hasCheckOut'
                                                        value='True'
                                                    />
                                                </div>
                                                <span className='vertically_align'>Customize Maintenance Form</span>
                                            </label>
                                        </td>
                                        <td>
                                            <p className="mb-0">Allow user to customize Maintenance Form</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <label className='radio mb-0 d-inline-flex' htmlFor='WarrantyDatabaseFields'>
                                                <div>
                                                    <input
                                                        type='checkbox'
                                                        className='form-check-input custom-form-check-input sig_input me-2'
                                                        id='WarrantyDatabaseFields'
                                                        name='hasCheckOut'
                                                        value='True'
                                                    />
                                                </div>
                                                <span className='vertically_align'>Warranty Database Fields</span>
                                            </label>
                                        </td>
                                        <td>
                                            <p className="mb-0">Create or remove warranty database fields.</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <label className='radio mb-0 d-inline-flex' htmlFor='CustomizeWarrantyForm'>
                                                <div>
                                                    <input
                                                        type='checkbox'
                                                        className='form-check-input custom-form-check-input sig_input me-2'
                                                        id='CustomizeWarrantyForm'
                                                        name='hasCheckOut'
                                                        value='True'
                                                    />
                                                </div>
                                                <span className='vertically_align'>Customize Person/Employee Form</span>
                                            </label>
                                        </td>
                                        <td>
                                            <p className="mb-0">Allow user to customize Warranty Form</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <label className='radio mb-0 d-inline-flex' htmlFor='ContractDatabaseFields'>
                                                <div>
                                                    <input
                                                        type='checkbox'
                                                        className='form-check-input custom-form-check-input sig_input me-2'
                                                        id='ContractDatabaseFields'
                                                        name='hasCheckOut'
                                                        value='True'
                                                    />
                                                </div>
                                                <span className='vertically_align'>Contract Database Fields</span>
                                            </label>
                                        </td>
                                        <td>
                                            <p className="mb-0">Create or remove contract database fields.</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <label className='radio mb-0 d-inline-flex' htmlFor='CustomizeContractForm'>
                                                <div>
                                                    <input
                                                        type='checkbox'
                                                        className='form-check-input custom-form-check-input sig_input me-2'
                                                        id='CustomizeContractForm'
                                                        name='hasCheckOut'
                                                        value='True'
                                                    />
                                                </div>
                                                <span className='vertically_align'>Customize Contract Form</span>
                                            </label>
                                        </td>
                                        <td>
                                            <p className="mb-0">Allow user to customize contract Form</p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <div className='mb-5'>
                <h4 className="mb-3">Admin Enable/Disable Rights</h4>
                <p className="mb-5">User can enable or disable following features:</p>
                <div className="row">
                    <div className="offset-lg-1 col-lg-10">
                        <div className="table-responsive mb-4 d-none">
                            <table className="table table-bordered mb-4">
                                <thead className="thead-light  light-yellow-table">
                                    <tr>
                                        <th className="w-40">
                                            <label className='radio mb-0 d-inline-flex' htmlFor='SetupEvents'>
                                                <div>
                                                    <input
                                                        type='checkbox'
                                                        className='form-check-input custom-form-check-input sig_input me-2'
                                                        id='SetupEvents'
                                                        name='hasCheckOut'
                                                        value='True'
                                                    />
                                                </div>
                                                <span className='vertically_align'>Setup Events</span>
                                            </label>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <label className='radio mb-0 d-inline-flex' htmlFor='Check-out-Checkin'>
                                                <div>
                                                    <input
                                                        type='checkbox'
                                                        className='form-check-input custom-form-check-input sig_input me-2'
                                                        checked={checked?.hasCheckout}
                                                        id='Check-out-Checkin'
                                                        name='Check-out-Checkin'
                                                        onChange={(e) => handleCheckboxChange('hasCheckout', e.target.checked)}
                                                        value='True'
                                                    />
                                                </div>
                                                <span className='vertically_align'>Check-out/Check-in</span>
                                            </label>
                                        </td>
                                        <td>
                                            <p className="mb-0">Enable/disable and setup Check-out/Check-in event.</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <label className='radio mb-0 d-inline-flex' htmlFor='CustomizeCheck-out-Check-inForm'>
                                                <div>
                                                    <input
                                                        type='checkbox'
                                                        className='form-check-input custom-form-check-input sig_input me-2'
                                                        checked={checked?.addCheckout}
                                                        id='CustomizeCheck-out-Check-inForm'
                                                        name='CustomizeCheck-out-Check-inForm'
                                                        onChange={(e) => handleCheckboxChange('addCheckout', e.target.checked)}
                                                        value='True'
                                                    />
                                                </div>
                                                <span className='vertically_align'>Customize Check-out/Check-in Form</span>
                                            </label>
                                        </td>
                                        <td>
                                            <p className="mb-0">Enable/disable and customize Check-out/Check-in event.</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <label className='radio mb-0 d-inline-flex' htmlFor='Lease-LeaseReturn'>
                                                <div>
                                                    <input
                                                        type='checkbox'
                                                        className='form-check-input custom-form-check-input sig_input me-2'
                                                        checked={checked?.viewLease}
                                                        id='Lease-LeaseReturn'
                                                        name='Lease-LeaseReturn'
                                                        onChange={(e) => handleCheckboxChange('viewLease', e.target.checked)}
                                                        value='True'
                                                    />
                                                </div>
                                                <span className='vertically_align'>Lease/Lease Return</span>
                                            </label>
                                        </td>
                                        <td>
                                            <p className="mb-0">Enable/disable and setup Lease/Lease Return event.</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <label className='radio mb-0 d-inline-flex' htmlFor='customizeLease'>
                                                <div>
                                                    <input
                                                        type='checkbox'
                                                        className='form-check-input custom-form-check-input sig_input me-2'
                                                        checked={checked?.addLease}
                                                        id='customizeLease'
                                                        name='customizeLease'
                                                        onChange={(e) => handleCheckboxChange('addLease', e.target.checked)}
                                                        value='True'
                                                    />
                                                </div>
                                                <span className='vertically_align'>Customize Lease/Lease Return Form</span>
                                            </label>
                                        </td>
                                        <td>
                                            <p className="mb-0">Enable/disable and customize Lease/Lease Return event.</p>
                                        </td>
                                    </tr>
                                    <tr className="d-none">
                                        <td>
                                            <label className='radio mb-0 d-inline-flex' htmlFor='lostFound'>
                                                <div>
                                                    <input
                                                        type='checkbox'
                                                        className='form-check-input custom-form-check-input sig_input me-2'
                                                        id='lostFound'
                                                        name='hasCheckOut'
                                                        value='True'
                                                    />
                                                </div>
                                                <span className='vertically_align'>Lost/Found</span>
                                            </label>
                                        </td>
                                        <td>
                                            <p className="mb-0">Enable/disable and setup Lost/Found event.</p>
                                        </td>
                                    </tr>
                                    <tr className="d-none">
                                        <td>
                                            <label className='radio mb-0 d-inline-flex' htmlFor='customizeLostFound'>
                                                <div>
                                                    <input
                                                        type='checkbox'
                                                        className='form-check-input custom-form-check-input sig_input me-2'
                                                        id='customizeLostFound'
                                                        name='hasCheckOut'
                                                        value='True'
                                                    />
                                                </div>
                                                <span className='vertically_align'>Customize Lost/Found Form</span>
                                            </label>
                                        </td>
                                        <td>
                                            <p className="mb-0">Enable/disable and customize Lost/Found event.</p>
                                        </td>
                                    </tr>
                                    <tr className="d-none">
                                        <td>
                                            <label className='radio mb-0 d-inline-flex' htmlFor='repair'>
                                                <div>
                                                    <input
                                                        type='checkbox'
                                                        className='form-check-input custom-form-check-input sig_input me-2'
                                                        id='repair'
                                                        name='hasCheckOut'
                                                        value='True'
                                                    />
                                                </div>
                                                <span className='vertically_align'>Repair</span>
                                            </label>
                                        </td>
                                        <td>
                                            <p className="mb-0">Enable/disable and setup Repair event.</p>
                                        </td>
                                    </tr>
                                    <tr className="d-none">
                                        <td>
                                            <label className='radio mb-0 d-inline-flex' htmlFor='customizeRepair'>
                                                <div>
                                                    <input
                                                        type='checkbox'
                                                        className='form-check-input custom-form-check-input sig_input me-2'
                                                        id='customizeRepair'
                                                        name='hasCheckOut'
                                                        value='True'
                                                    />
                                                </div>
                                                <span className='vertically_align'>Customize Repair Form</span>
                                            </label>
                                        </td>
                                        <td>
                                            <p className="mb-0">Enable/disable and customize Repair event.</p>
                                        </td>
                                    </tr>
                                    <tr className="d-none">
                                        <td>
                                            <label className='radio mb-0 d-inline-flex' htmlFor='broken'>
                                                <div>
                                                    <input
                                                        type='checkbox'
                                                        className='form-check-input custom-form-check-input sig_input me-2'
                                                        id='broken'
                                                        name='hasCheckOut'
                                                        value='True'
                                                    />
                                                </div>
                                                <span className='vertically_align'>Broken</span>
                                            </label>
                                        </td>
                                        <td>
                                            <p className="mb-0">Enable/disable and setup Broken event.</p>
                                        </td>
                                    </tr>
                                    <tr className="d-none">
                                        <td>
                                            <label className='radio mb-0 d-inline-flex' htmlFor='CustomizeBrokenForm'>
                                                <div>
                                                    <input
                                                        type='checkbox'
                                                        className='form-check-input custom-form-check-input sig_input me-2'
                                                        id='CustomizeBrokenForm'
                                                        name='hasCheckOut'
                                                        value='True'
                                                    />
                                                </div>
                                                <span className='vertically_align'>Customize Broken Form</span>
                                            </label>
                                        </td>
                                        <td>
                                            <p className="mb-0">Enable/disable and setup Broken event.</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <label className='radio mb-0 d-inline-flex' htmlFor='Dispose'>
                                                <div>
                                                    <input
                                                        type='checkbox'
                                                        className='form-check-input custom-form-check-input sig_input me-2'
                                                        checked={checked?.viewDispose}
                                                        id='Dispose'
                                                        name='Dispose'
                                                        onChange={(e) => handleCheckboxChange('viewDispose', e.target.checked)}
                                                        value='True'
                                                    />
                                                </div>
                                                <span className='vertically_align'>Dispose</span>
                                            </label>
                                        </td>
                                        <td>
                                            <p className="mb-0">Enable/disable and setup Dispose event.</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <label className='radio mb-0 d-inline-flex' htmlFor='CustomizeDisposeForm'>
                                                <div>
                                                    <input
                                                        type='checkbox'
                                                        className='form-check-input custom-form-check-input sig_input me-2'
                                                        checked={checked?.addDispose}
                                                        id='CustomizeDisposeForm'
                                                        name='DispCustomizeDisposeForm'
                                                        onChange={(e) => handleCheckboxChange('addDispose', e.target.checked)}
                                                        value='True'
                                                    />
                                                </div>
                                                <span className='vertically_align'>Customize Dispose Form</span>
                                            </label>
                                        </td>
                                        <td>
                                            <p className="mb-0">Enable/disable and customize Dispose event.</p>
                                        </td>
                                    </tr>
                                    <tr className="d-none">
                                        <td>
                                            <label className='radio mb-0 d-inline-flex' htmlFor='Donate'>
                                                <div>
                                                    <input
                                                        type='checkbox'
                                                        className='form-check-input custom-form-check-input sig_input me-2'
                                                        id='Donate'
                                                        name='hasCheckOut'
                                                        value='True'
                                                    />
                                                </div>
                                                <span className='vertically_align'>Donate</span>
                                            </label>
                                        </td>
                                        <td>
                                            <p className="mb-0">Enable/disable and setup Donate event.</p>
                                        </td>
                                    </tr>
                                    <tr className="d-none">
                                        <td>
                                            <label className='radio mb-0 d-inline-flex' htmlFor='CustomizeDonate'>
                                                <div>
                                                    <input
                                                        type='checkbox'
                                                        className='form-check-input custom-form-check-input sig_input me-2'
                                                        id='CustomizeDonate'
                                                        name='hasCheckOut'
                                                        value='True'
                                                    />
                                                </div>
                                                <span className='vertically_align'>Customize Donate Form</span>
                                            </label>
                                        </td>
                                        <td>
                                            <p className="mb-0">Enable/disable and customize Donate event.</p>
                                        </td>
                                    </tr>
                                    <tr className="d-none">
                                        <td>
                                            <label className='radio mb-0 d-inline-flex' htmlFor='Sell'>
                                                <div>
                                                    <input
                                                        type='checkbox'
                                                        className='form-check-input custom-form-check-input sig_input me-2'
                                                        id='Sell'
                                                        name='hasCheckOut'
                                                        value='True'
                                                    />
                                                </div>
                                                <span className='vertically_align'>Sell</span>
                                            </label>
                                        </td>
                                        <td>
                                            <p className="mb-0">Enable/disable and setup Sell event.</p>
                                        </td>
                                    </tr>
                                    <tr className="d-none">
                                        <td>
                                            <label className='radio mb-0 d-inline-flex' htmlFor='CustomizeSellForm'>
                                                <div>
                                                    <input
                                                        type='checkbox'
                                                        className='form-check-input custom-form-check-input sig_input me-2'
                                                        id='CustomizeSellForm'
                                                        name='hasCheckOut'
                                                        value='True'
                                                    />
                                                </div>
                                                <span className='vertically_align'>Customize Sell Form</span>
                                            </label>
                                        </td>
                                        <td>
                                            <p className="mb-0">Enable/disable and customize Sell event.</p>
                                        </td>
                                    </tr>
                                    <tr className="d-none">
                                        <td>
                                            <label className='radio mb-0 d-inline-flex' htmlFor='CustomEvent'>
                                                <div>
                                                    <input
                                                        type='checkbox'
                                                        className='form-check-input custom-form-check-input sig_input me-2'
                                                        id='CustomEvent'
                                                        name='hasCheckOut'
                                                        value='True'
                                                    />
                                                </div>
                                                <span className='vertically_align'>Custom Event</span>
                                            </label>
                                        </td>
                                        <td>
                                            <p className="mb-0">Enable/disable and setup Custom event.</p>
                                        </td>
                                    </tr>
                                    <tr className="d-none">
                                        <td>
                                            <label className='radio mb-0 d-inline-flex' htmlFor='CustomizeEventForm'>
                                                <div>
                                                    <input
                                                        type='checkbox'
                                                        className='form-check-input custom-form-check-input sig_input me-2'
                                                        id='CustomizeEventForm'
                                                        name='hasCheckOut'
                                                        value='True'
                                                    />
                                                </div>
                                                <span className='vertically_align'>Customize Event Form</span>
                                            </label>
                                        </td>
                                        <td>
                                            <p className="mb-0">Enable/disable and customize Custom event.</p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div className="table-responsive mb-4">
                            <table className="table table-bordered mb-4">
                                <thead className="thead-light  light-yellow-table">
                                    <tr>
                                        <th className="w-40">
                                            <label className='radio mb-0 d-inline-flex' htmlFor='allEnableRights'>
                                                <div>
                                                    <input
                                                        type='checkbox'
                                                        className='form-check-input custom-form-check-input sig_input me-2'
                                                        id='allEnableRights'
                                                        name='allEnableRights'
                                                        checked={adminRights}
                                                        onClick={OnclickAdminRights}
                                                        value='True'
                                                    />
                                                </div>
                                                <span className='vertically_align'>All Enable/ Disable Rights</span>
                                            </label>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr className="d-none">
                                        <td>
                                            <label className='radio mb-0 d-inline-flex' htmlFor='Audit'>
                                                <div>
                                                    <input
                                                        type='checkbox'
                                                        className='form-check-input custom-form-check-input sig_input me-2'
                                                        id='Audit'
                                                        name='hasCheckOut'
                                                        value='True'
                                                    />
                                                </div>
                                                <span className='vertically_align'>Audit</span>
                                            </label>
                                        </td>
                                        <td>
                                            <p className="mb-0">Enable or disable Audit feature.</p>
                                        </td>
                                    </tr>
                                    <tr className="d-none">
                                        <td>
                                            <label className='radio mb-0 d-inline-flex' htmlFor='AssetsLinking'>
                                                <div>
                                                    <input
                                                        type='checkbox'
                                                        className='form-check-input custom-form-check-input sig_input me-2'
                                                        id='AssetsLinking'
                                                        name='hasCheckOut'
                                                        value='True'
                                                    />
                                                </div>
                                                <span className='vertically_align'>Assets Linking</span>
                                            </label>
                                        </td>
                                        <td>
                                            <p className="mb-0">Enable or disable Assets Linking feature.</p>
                                        </td>
                                    </tr>
                                    <tr className="d-none">
                                        <td>
                                            <label className='radio mb-0 d-inline-flex' htmlFor='Contracts'>
                                                <div>
                                                    <input
                                                        type='checkbox'
                                                        className='form-check-input custom-form-check-input sig_input me-2'
                                                        id='Contracts'
                                                        name='hasCheckOut'
                                                        value='True'
                                                    />
                                                </div>
                                                <span className='vertically_align'>Contracts</span>
                                            </label>
                                        </td>
                                        <td>
                                            <p className="mb-0">Enable or disable Contracts feature.</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <label className='radio mb-0 d-inline-flex' htmlFor='Departments'>
                                                <div>
                                                    <input
                                                        type='checkbox'
                                                        className='form-check-input custom-form-check-input sig_input me-2'
                                                        checked={checked?.enableDepartments}
                                                        id='Departments'
                                                        name='Departments'
                                                        onChange={(e) => handleCheckboxChange('enableDepartments', e.target.checked)}
                                                        value='True'
                                                    />
                                                </div>
                                                <span className='vertically_align'>Departments</span>
                                            </label>
                                        </td>
                                        <td>
                                            <p className="mb-0">Enable or disable Departments feature.</p>
                                        </td>
                                    </tr>
                                    <tr className="d-none">
                                        <td>
                                            <label className='radio mb-0 d-inline-flex' htmlFor='Depreciation'>
                                                <div>
                                                    <input
                                                        type='checkbox'
                                                        className='form-check-input custom-form-check-input sig_input me-2'
                                                        id='Depreciation'
                                                        name='hasCheckOut'
                                                        value='True'
                                                    />
                                                </div>
                                                <span className='vertically_align'>Depreciation</span>
                                            </label>
                                        </td>
                                        <td>
                                            <p className="mb-0">Enable or disable Depriciation feature.</p>
                                        </td>
                                    </tr>
                                    <tr className="d-none">
                                        <td>
                                            <label className='radio mb-0 d-inline-flex' htmlFor='Documents'>
                                                <div>
                                                    <input
                                                        type='checkbox'
                                                        className='form-check-input custom-form-check-input sig_input me-2'
                                                        id='Documents'
                                                        name='hasCheckOut'
                                                        value='True'
                                                    />
                                                </div>
                                                <span className='vertically_align'>Documents</span>
                                            </label>
                                        </td>
                                        <td>
                                            <p className="mb-0">Enable or disable Documents feature.</p>
                                        </td>
                                    </tr>
                                    <tr className="d-none">
                                        <td>
                                            <label className='radio mb-0 d-inline-flex' htmlFor='Funding'>
                                                <div>
                                                    <input
                                                        type='checkbox'
                                                        className='form-check-input custom-form-check-input sig_input me-2'
                                                        id='Funding'
                                                        name='hasCheckOut'
                                                        value='True'
                                                    />
                                                </div>
                                                <span className='vertically_align'>Funding</span>
                                            </label>
                                        </td>
                                        <td>
                                            <p className="mb-0">Enable or disable Funding feature.</p>
                                        </td>
                                    </tr>
                                    <tr className="d-none">
                                        <td>
                                            <label className='radio mb-0 d-inline-flex' htmlFor='Insurance'>
                                                <div>
                                                    <input
                                                        type='checkbox'
                                                        className='form-check-input custom-form-check-input sig_input me-2'
                                                        id='Insurance'
                                                        name='hasCheckOut'
                                                        value='True'
                                                    />
                                                </div>
                                                <span className='vertically_align'>Insurance</span>
                                            </label>
                                        </td>
                                        <td>
                                            <p className="mb-0">Enable or disable Insurance feature.</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <label className='radio mb-0 d-inline-flex' htmlFor='maintenances'>
                                                <div>
                                                    <input
                                                        type='checkbox'
                                                        className='form-check-input custom-form-check-input sig_input me-2'
                                                        checked={checked?.viewMaintenance}
                                                        id='maintenances'
                                                        name='maintenances'
                                                        onChange={(e) => handleCheckboxChange('viewMaintenance', e.target.checked)}
                                                        value='True'
                                                    />
                                                </div>
                                                <span className='vertically_align'>Maintenances</span>
                                            </label>
                                        </td>
                                        <td>
                                            <p className="mb-0">Enable or disable Maintenances feature.</p>
                                        </td>
                                    </tr>
                                    <tr className="d-none">
                                        <td>
                                            <label className='radio mb-0 d-inline-flex' htmlFor='MultiplePhotos'>
                                                <div>
                                                    <input
                                                        type='checkbox'
                                                        className='form-check-input custom-form-check-input sig_input me-2'
                                                        id='MultiplePhotos'
                                                        name='hasCheckOut'
                                                        value='True'
                                                    />
                                                </div>
                                                <span className='vertically_align'>MultiplePhotos</span>
                                            </label>
                                        </td>
                                        <td>
                                            <p className="mb-0">Enable or disable Photos feature.</p>
                                        </td>
                                    </tr>
                                    <tr className="d-none">
                                        <td>
                                            <label className='radio mb-0 d-inline-flex' htmlFor='SubCategories'>
                                                <div>
                                                    <input
                                                        type='checkbox'
                                                        className='form-check-input custom-form-check-input sig_input me-2'
                                                        id='SubCategories'
                                                        name='hasCheckOut'
                                                        value='True'
                                                    />
                                                </div>
                                                <span className='vertically_align'>SubCategories</span>
                                            </label>
                                        </td>
                                        <td>
                                            <p className="mb-0">Enable or disable SubCategories feature.</p>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <label className='radio mb-0 d-inline-flex' htmlFor='Warranties'>
                                                <div>
                                                    <input
                                                        type='checkbox'
                                                        className='form-check-input custom-form-check-input sig_input me-2'
                                                        checked={checked?.enableWarranties}
                                                        id='Warranties'
                                                        name='Warranties'
                                                        onChange={(e) => handleCheckboxChange('enableWarranties', e.target.checked)}
                                                        value='True'
                                                    />
                                                </div>
                                                <span className='vertically_align'>Warranties</span>
                                            </label>
                                        </td>
                                        <td>
                                            <p className="mb-0">Enable or disable Warranties feature.</p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AdminRights