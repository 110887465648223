import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { setupAxios, useAuth } from '../../../../Auth'
import { CustomerResponse } from '../Core/_models'
import { CustomersHeader } from './CustomersHeader'
import CustomersModal from './CustomersModal'
import ConfirmationPopup from '../../../../../../_metronic/helpers/components/ConfirmationPopup'
import {
  useDeleteCustomersMutation,
  useGetAllCustomersQuery,
  useGetSingleCustomerQuery,
} from '../../../../Services/Customers'
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes'
import { useIntl } from 'react-intl'
import { PageTitle } from '_metronic/layout/core'
import localeInfo from 'rc-pagination/lib/locale/en_US'
import Pagination from 'rc-pagination'
import 'rc-pagination/assets/index.css'
import '@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css'
import { searchFieldsOptionsCustomer, setupKeys } from 'config'
import SplashScreen from 'App/SplashScreen'
import Loader from 'App/BeatLoader'
import SetupCoulmnListing from './SetupColumnListing'
import Columns from './Columns.json'
import SetupColumns from './SetupColumns'
import { ColumnData } from './RenderColumnData'
import { Tooltip } from 'react-tooltip'

const CustomersListing = (props: any) => {
  const intl = useIntl()
  const { currentUser } = useAuth()
  const [check, setCheck] = useState<number[]>([])
  const [skip, setSkip] = useState<boolean>(false)
  const [show, setShow] = useState<boolean>(false)
  const [data, setData] = useState({})
  const [searchAssets, setSearchAssets] = useState<any>('')
  const [searchKeywords, setSearchKeywords] = useState<any>('')
  const [selectAll, setSelectAll] = useState(false)
  const [updateCustomer, setUpdateCustomer] = useState<boolean>(true)
  const [selected, setSelected] = useState<number[]>([])
  const [deleteDataArr, setDeleteDataArr] = useState<number[]>([])
  const [deleteModal, setDeleteModal] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [deleteCustomers, { isLoading: isLoadingDeleteCustomers }] = useDeleteCustomersMutation()
  const [isCustomersExist, setIsCustomersExist] = useState<any>(false)
  const [searchFields, setSearchFields] = useState<any[]>([])
  const [deleteCascade, setDeleteCascade] = useState(false)
  const [selectAllData, setSelectAllData] = useState<boolean>(false)
  const [selectedData, setSelectedData] = useState<boolean>(false)
  const [pagination, setPagination] = useState<any>({})
  const [page, setPage] = useState<number>(1)
  const [limit, setLimit] = useState<number>(10)
  const [showSetupColumns, setShowSetupColumns] = useState<boolean>(false)
  const [selectedColumns, setSelectedColumns] = useState<{ key: any; value: any }[]>(Columns)
  const selectedColumnsRender = selectedColumns.filter((column) => column.value === true)
  const [selectedFields, setSelectedFields] = useState<string[]>([])
  const {
    data: userCustomersList,
    isLoading: isLoadingGetCustomers,
    refetch,
  } = useGetAllCustomersQuery(
    { search: searchKeywords, searchField: selectedFields, page: page, limit: limit },
    { skip }
  )
  const [customerId, setCustomerId] = useState('')
  const { data: singleCustomer, isLoading: singleCustomerLoading } = useGetSingleCustomerQuery(
    { id: customerId },
    { skip: !customerId }
  )

  const handleClose = () => {
    setShow(false)
  }

  useEffect(() => {
    setSkip(false)
  }, [searchAssets])

  useEffect(() => {
    if (singleCustomer) {
      setLoading(false)
    }
  }, [singleCustomer])

  const handleResetSearch = () => {
    setSearchKeywords('')
    setSearchAssets('')
  }

  const handleSearch = () => {
    if (!searchAssets) {
      setSearchKeywords('')
    }
    if (searchAssets === searchKeywords) {
      setSearchKeywords("")
    }
    if (searchAssets) {
      setLoading(true)
    }
    setSearchKeywords(searchAssets)
    const fields = searchFields
      .filter((option) => option.value !== '*')
      .map((option) => option.value)
    setSelectedFields(fields)
    setSkip(false)
  }

  const handleShow = (item: any) => {
    setCustomerId(item)
    setShow(true)
  }

  useEffect(() => {
    if (currentUser && userCustomersList) {
      checkVistingPage(userCustomersList?.customers)
      setPagination(userCustomersList?.pagination)
      setLoading(false)
      refetch()
    }
  }, [userCustomersList, refetch])

  const checkVistingPage = (dataList: any) => {
    if (isCustomersExist === false) {
      if (dataList && dataList?.length > 0) {
        setIsCustomersExist(dataList?.length)
      } else {
        setIsCustomersExist(0)
      }
    }
  }

  const deleteSelectedCustomers = async (id: number[]) => {
    try {
      const customerIds = { customerIds: id }
      const res = await deleteCustomers({ id: customerIds }).unwrap()
      toast.success(res.message)
      setCheck([])
      setDeleteDataArr([])
      setSelectAll(false)
      setDeleteModal(false)
      setDeleteCascade(false)
    } catch (err: any) {
      if (!err?.data?.success) {
        toast.error(err?.data?.message)
      }
    }
  }

  const cancel = () => {
    setDeleteModal(false)
    setDeleteCascade(false)
  }

  const confirm = () => deleteSelectedCustomers(check)
  function onChangeSearchFields(value, { action, option }) {
    if (action === 'select-option' && option.value === '*') {
      setSearchFields(searchFieldsOptionsCustomer)
      value = searchFieldsOptionsCustomer.slice(1)
    } else if (action === 'deselect-option' && option.value === '*') {
      setSearchFields([])
      value = []
    } else if (action === 'deselect-option') {
      setSearchFields(value.filter((o) => o.value !== '*'))
    } else if (value.length === searchFieldsOptionsCustomer.length - 1) {
      setSearchFields(searchFieldsOptionsCustomer)
    } else {
      setSearchFields(value)
    }
  }

  const handleCheckboxChange = (id: number) => {
    setSelected((prevSelected) => {
      const updatedSelected = prevSelected.includes(id)
        ? prevSelected.filter((itemId) => itemId !== id)
        : [...prevSelected, id]
      const isAllSelected = updatedSelected.length === userCustomersList?.customers?.length
      setSelectAll(isAllSelected)
      return updatedSelected
    })
    setDeleteDataArr((prevSelected) => {
      if (prevSelected.includes(id)) {
        return prevSelected.filter((itemId) => itemId !== id)
      } else {
        return [...prevSelected, id]
      }
    })
  }

  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = userCustomersList?.customers?.map((item: any) => item.id)
      setSelected(newSelecteds)
      setSelectAll(true)
      setDeleteDataArr(newSelecteds)
    } else {
      setSelected([])
      setSelectAll(false)
      setDeleteDataArr([])
    }
  }

  useEffect(() => {
    if (selectAllData) {
      const newSelecteds = userCustomersList?.customers?.map((item: any) => item.id) || []
      setSelected(newSelecteds)
      setDeleteDataArr(newSelecteds)
    }
    setSelectAllData(false)
  }, [selectedData])

  useEffect(() => {
    if (deleteDataArr && deleteDataArr.length === 0) {
      const multicheckSelectCheckboxes = document.querySelectorAll('[name="multicheckSelect"]')
      multicheckSelectCheckboxes.forEach((checkbox) => {
        if (checkbox instanceof HTMLInputElement) {
          checkbox.checked = false
        }
      })
    } else if (selectAll == true) {
      const multicheckSelectCheckboxes = document.querySelectorAll('[name="multicheckSelect"]')
      multicheckSelectCheckboxes.forEach((checkbox) => {
        if (checkbox instanceof HTMLInputElement) {
          checkbox.checked = true
        }
      })
    } else if (selectAll == false) {
      const multicheckSelectCheckbox = document.querySelector(
        '[name="multicheckSelect"]'
      ) as HTMLInputElement

      if (multicheckSelectCheckbox) {
        multicheckSelectCheckbox.checked = false
      }
    }
  }, [deleteDataArr])

  const handlePageChange = (currentPage: any) => {
    setLoading(true)
    setPage(currentPage)
    setSkip(false)
  }
  const currentPage = pagination?.page || 0
  const totalRecords = pagination?.total || 0
  const pageSize = limit || 10
  const startRecord = (currentPage - 1) * pageSize + 1
  const endRecord = Math.min(currentPage * pageSize, totalRecords)

  return (
    <>
      <SplashScreen isLoadingTemplate={isLoadingGetCustomers || isLoadingDeleteCustomers} />
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'Customers' })}</PageTitle>
      <SetupCoulmnListing
        showSetupColumns={showSetupColumns}
        setShowSetupColumns={setShowSetupColumns}
        selectedColumns={selectedColumns}
        setSelectedColumns={setSelectedColumns}
        assetsData={userCustomersList}
      />
      <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
        <div className='post d-flex flex-column-fluid' id='kt_post'>
          <div id='kt_content_container' className={`container ${showSetupColumns && 'd-none'}`}>
            <div className='card'>
              <ConfirmationPopup
                deleteModal={deleteModal}
                cancel={cancel}
                confirm={confirm}
                deleteCascade={deleteCascade}
                setupName={setupKeys.customer}
              />
              <CustomersHeader
                deleteDataArr={deleteDataArr}
                setDeleteModal={setDeleteModal}
                setCheck={setCheck}
                handleShow={handleShow}
                setLoading={setLoading}
                setCustomerId={setCustomerId}
                setUpdateCustomer={setUpdateCustomer}
              />
              <CustomersModal
                show={show}
                handleClose={handleClose}
                CustomerData={singleCustomer}
                customerId={customerId}
                setCustomerId={setCustomerId}
                updateCustomer={updateCustomer}
              />
              <div className='card-body py-4'>
                <p className={'text-dark'}>
                  Manage your customers. After you add customers, you can lease assets to them.
                </p>
                <div className='row'>
                  <div className='col-xxl-4 col-md-6 col-12'>
                    <div className='form-group w-100 w-sm-auto mb-3'>
                      <label
                        className='col-form-label pt-0 pb-1 fw-bolder text-muted'
                        htmlFor='sSearch_1'
                      >
                        {' '}
                        Search Customers
                      </label>
                      <div
                        className='search-input-wrapper position-relative'
                        data-tooltip-id='my-tooltip-1'
                        data-tooltip-content='First Name, Last Name, Email or Company'
                      >
                        <input
                          type='search'
                          className='form-control  form-control-solid'
                          onChange={(e) => {
                            setSearchAssets(e.target.value)
                          }}
                          value={searchAssets}
                          placeholder='First Name, Last Name, Email or Company'
                          id='sSearch_1'
                          name='sSearch_1'
                        />
                        {searchAssets && (
                          <span className='reset-icon' onClick={handleResetSearch}>
                            <i className='fa fa-times fs-6 p-0'></i>
                          </span>
                        )}
                        <button
                          className='btn btn-light-primary main-btn-style'
                          onClick={handleSearch}
                        >
                          <i className='fa fa-search fs-xxl-6 fs-7 p-0'></i>
                        </button>
                      </div>
                      <Tooltip id='my-tooltip-1' />
                    </div>
                  </div>
                  <div className='col-xxl-4 col-md-6 col-12'>
                    <div className='form-group w-100 w-sm-auto assetSearch_field mb-3'>
                      <label className='col-form-label pt-0 pb-1 fw-bolder text-muted'>
                        Search in fields
                      </label>
                      <div className='ReactMultiSelectCheckboxes custom-checkbox-select'>
                        <ReactMultiSelectCheckboxes
                          options={searchFieldsOptionsCustomer}
                          value={searchFields}
                          onChange={onChangeSearchFields}
                          name='searchField'
                          labelledBy='Select'
                          className='form-control'
                          width='100%'
                          placeholderButtonLabel='First Name, Last Name, Email, Phone, Company'
                        />
                      </div>
                      <span
                        className='field-validation-valid'
                        data-valmsg-for='chk_searchFields'
                        data-valmsg-replace='true'
                      ></span>
                    </div>
                  </div>
                </div>
                <div className='d-flex align-items-center justify-content-between flex-wrap mb-4'>
                  <div className='d-flex limit-options align-items-center mb-sm-0 mb-3'>
                    <span className='text-muted'>Showing</span>
                    <select
                      name='iTakeList'
                      id='iTakeList'
                      className='form-select form-select-sm fw-bold mx-3 border-1'
                      value={limit}
                      onChange={(e) => {
                        setLoading(true)
                        setPage(1)
                        setLimit(parseInt(e.target.value))
                        setSkip(false)
                      }}
                    >
                      <option value='10'>10</option>
                      <option value='15'>15</option>
                      <option value='20'>20</option>
                      <option value='25'>25</option>
                      <option value='50'>50</option>
                    </select>
                    <span className='text-muted'>entries</span>
                  </div>
                  <SetupColumns setShowSetupColumns={setShowSetupColumns} />
                </div>
                <div className='react-grid-card'>
                  <div className='table-responsive pb-4'>
                    <table
                      id='kt_table_users'
                      className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
                    >
                      <thead>
                        <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                          <th role='columnheader' className='w-10px pe-2'>
                            <div className='header-checkbox me-3'>
                              <input
                                type='checkbox'
                                className='form-check-input custom-form-check-input'
                                checked={selectAll}
                                onChange={handleSelectAll}
                              />
                            </div>
                          </th>
                          {selectedColumnsRender.map((column, index) => (
                            <th key={index}>{column.key.replace(/_/g, ' ')}</th>
                          ))}
                          <th className='text-center'>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {userCustomersList?.customers?.length > 0 ? (
                          userCustomersList?.customers?.map((asset: any, rowIndex: number) => (
                            <tr key={rowIndex} role='row'>
                              <td role='cell'>
                                <div className='form-check form-check-sm form-check-custom '>
                                  <input
                                    type='checkbox'
                                    className='form-check-input'
                                    checked={selected.includes(asset?.id)}
                                    onChange={() => handleCheckboxChange(asset?.id)}
                                  />{' '}
                                </div>
                              </td>
                              {selectedColumnsRender.map((column, colIndex) => (
                                <td key={colIndex} className='table-elipses listing-table-elipses'>
                                  <ColumnData
                                    column={column.key.replace(/_/g, ' ')}
                                    assetsData={{ allAssets: [asset] }}
                                  />
                                </td>
                              ))}
                              <td className='text-center'>
                                <button
                                  onClick={() => {
                                    handleShow(asset?.id)
                                    setUpdateCustomer(true)
                                  }}
                                  className='menu-link px-4 btn btn-light-secondary py-2 view-btn-style me-2'
                                >
                                  Edit
                                </button>
                                <button
                                  onClick={() => {
                                    setDeleteModal(true)
                                    setCheck([asset?.id])
                                  }}
                                  className='menu-link px-4 btn btn-light-secondary py-2 view-btn-style'
                                >
                                  Delete
                                </button>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={selectedColumnsRender?.length + 2} className='text-center'>
                              <div className='d-flex text-center w-100 align-items-center justify-content-center no-records fw-bold rounded'>
                                No records found
                              </div>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
                {userCustomersList?.customers?.length ? (
                  <div className='d-sm-flex align-items-center justify-content-between flex-wrap mt-3'>
                    <div className='m-1'>
                      Showing {startRecord} to {endRecord} of {totalRecords} records
                    </div>
                    {pagination && (
                      <Pagination
                        className='m-1 custom-pagination'
                        defaultCurrent={1}
                        pageSize={limit}
                        current={pagination?.page}
                        total={pagination?.total}
                        onChange={handlePageChange}
                        locale={localeInfo}
                      />
                    )}
                  </div>
                ) : (
                  ''
                )}
                <Loader loading={loading} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default CustomersListing
