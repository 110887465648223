import { useAuth } from 'App/Modules/Auth'
import { dateFormat, defaultDateFormat } from 'config'
import { Modal } from 'react-bootstrap'
import { Link } from 'react-router-dom'
function AssetMinimalDetailModal(props) {
    const { currentUser } = useAuth()

    return (
        <>
            <Modal
                className='px-2'
                {...props}
                size='md'
                aria-labelledby='contained-modal-title-vcenter'
                backdrop='static'
            >
                <Modal.Header closeButton>
                    <Modal.Title>{props.singleAssetData?.asset?.assetTagId}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='modal-title fw-bolder mb-4'>{props.singleAssetData?.asset?.description}</div>
                    <div className='assetProduct_detail'>
                        <div className='asset_detail_table table-responsive'>
                            <table
                                className='table table-bordered detail-table table-sm mb-0'
                                aria-label='Quick View'
                                role='presentation'
                            >
                                <tbody>
                                    <tr>
                                        <td className='label_col bg-light'>Purchase Date</td>
                                        <td className='fw-bold detail_col table-elipses'>
                                            <span>
                                                {dateFormat(props.singleAssetData?.asset?.purchasedDate, currentUser?.dateFormat || defaultDateFormat)}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='label_col bg-light'>Purchased From</td>
                                        <td className='fw-bold detail_col table-elipses'>
                                            <span>
                                                {props.singleAssetData?.asset?.purchasedFrom}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='label_col bg-light'>Cost</td>
                                        <td className='fw-bold detail_col table-elipses'>
                                            <span>
                                                {props.singleAssetData?.asset?.cost}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='label_col bg-light'>Brand</td>
                                        <td className='fw-bold detail_col table-elipses'>
                                            <span>
                                                {props.singleAssetData?.asset?.brand}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='label_col bg-light'>Model</td>
                                        <td className='fw-bold detail_col table-elipses'>
                                            <span>
                                                {props.singleAssetData?.asset?.model}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='label_col bg-light'>Serial No</td>
                                        <td className='fw-bold detail_col table-elipses'>
                                            <span>{props.singleAssetData?.asset?.serialNo}</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='label_col bg-light'>Site</td>
                                        <td className='fw-bold detail_col table-elipses'>
                                            <span>
                                                {props.singleAssetData?.asset?.site?.name}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='label_col bg-light'>Location</td>
                                        <td className='fw-bold detail_col table-elipses'>
                                            <span>
                                                {props.singleAssetData?.asset?.location?.location}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='label_col bg-light'>Category</td>
                                        <td className='fw-bold detail_col table-elipses'>
                                            <span>
                                                {props.singleAssetData?.asset?.category?.category}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='label_col bg-light'>Department</td>
                                        <td className='fw-bold detail_col table-elipses'>
                                            <span>
                                                {props.singleAssetData?.asset?.department?.department}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='label_col bg-light'>Assigned to</td>
                                        <td className='fw-bold detail_col table-elipses'>
                                            <span>
                                                {props.singleAssetData?.asset?.site?.name}
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td className='label_col bg-light'>Status</td>
                                        <td className='detail_col table-elipses'>
                                            <div className={`d-inline-block align-items-center px-3 py-2 rounded ${props.assetMaintenance?.userasset?.statusType === 'available' || 'Available'
                                                ? 'bg-light-green'
                                                : props.singleAssetData?.asset?.statusType === 'lease'
                                                    ? 'bg-light-blue'
                                                    : props.singleAssetData?.asset?.statusType === 'dispose'
                                                        ? 'bg-light-pink'
                                                        : props.singleAssetData?.asset?.statusType === 'check_out'
                                                            ? 'bg-light-blue'
                                                            : ''
                                                }`}
                                            >
                                                {props.singleAssetData?.asset?.statusType === 'check_out'
                                                    ? 'Checked out'
                                                    : props.singleAssetData?.asset?.statusType === 'lease'
                                                        ? 'Leased'
                                                        : props.singleAssetData?.asset?.statusType === 'dispose'
                                                            ? 'Disposed'
                                                            : props.singleAssetData?.asset?.statusType === 'available'
                                                                ? 'Available'
                                                                : props.singleAssetData?.asset?.statusType}
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <button
                        onClick={props.onHide}
                        type='button'
                        className='btn btn-light-primary   main-btn-style me-2'
                        data-bs-dismiss='modal'
                    >
                        Close
                    </button>
                    <Link
                        to={`/asset/view/${props.singleAssetData?.asset?.id}`}
                        className='btn btn-primary main-btn-style'
                        href='/assets/detail/29962946'
                        id='eventUrl'
                    >
                        More Details
                    </Link>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default AssetMinimalDetailModal
