import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import { useGetCompanyQuery } from 'App/Modules/Services/Company'
import { useState, useEffect } from 'react'
import getSymbolFromCurrency from 'currency-symbol-map'
import { useAuth } from 'App/Modules/Auth'
import Loader from 'App/BeatLoader'
import { useGetWarrantyQuery } from 'App/Modules/Services/Warranty'
import { toast } from 'react-toastify'
import moment from 'moment'

const DeleteConfirmationModal = (props: any) => {
  return (
    <>
      {' '}
      <Modal
        show={props.show}
        onHide={() => {
          props.handleClose()
        }}
        backdrop='static'
        keyboard={false}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Asset Warranty</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='assetProduct_detail mb-5'>
            Are you sure ou want to delete this warranty?
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className='text-end pt-2'>
            <div
              className='btn btn-light-primary   main-btn-style me-2'
              onClick={() => {
                props.handleClose()
              }}
            >
              Close{' '}
            </div>
            <Button
              className='btn btn-danger main-btn-style me-2'
              onClick={() => {
                props.handleDelete()
                props.handleClose()
              }}
              disabled={props.isDeleted}
            >
              {' '}
              {!props.isDeleted ? (
                <span className='indicator-label'>Delete</span>
              ) : (
                <span className='indicator-progress d-block'>
                  Please wait...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default DeleteConfirmationModal
