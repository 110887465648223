import { toAbsoluteUrlImage } from '_metronic/helpers'
import { dateFormat, defaultDateFormat } from 'config'
import { Images } from '_metronic/assets/Assets'
import { useAuth } from 'App/Modules/Auth'
import moment from 'moment'
interface ColumnDataProps {
  column: string
  assetsData?: any
  currencyLogo?: any
  assetMainteneces?: any
}
const date = new Date()
export const ColumnData: React.FC<ColumnDataProps> = ({ column, assetsData, currencyLogo }) => {
  const { currentUser } = useAuth()
  const renderData = () => {
    switch (column) {
      case 'Asset Photo':
        return assetsData?.allAssets?.map((asset: any, index: number) => (
          <div className='d-inline align-items-center asset-img-table'>
            {asset?.userasset?.photo ? (
              <img
                src={toAbsoluteUrlImage(asset?.userasset?.photo)}
                alt='asset'
                className='img-fluid'
                crossOrigin='anonymous'
              />
            ) : (
              <img src={Images?.AssetsPlaceholder} alt='asset' className='img-fluid' />
            )}
          </div>
        ))
      case 'Asset Tag ID':
        return assetsData?.allAssets?.map((asset: any, index: number) => (
          <span key={index}>{asset?.userasset?.assetTagId}</span>
        ))
      case 'Description':
        return assetsData?.allAssets?.map((asset: any, index: number) => (
          <span key={index}>{asset?.userasset?.description}</span>
        ))
      case 'Brand':
        return assetsData?.allAssets?.map((asset: any, index: number) => (
          <span key={index}>{asset?.userasset?.brand}</span>
        ))
      case 'Purchased Date':
        return assetsData?.allAssets?.map((asset: any, index: number) => (
          <span key={index}>{asset?.userasset?.purchasedDate
            ? moment(asset?.userasset?.purchasedDate).format(currentUser?.dateFormat || defaultDateFormat)
            : null}</span>
        ))
      case 'Cost':
        return assetsData?.allAssets?.map((asset: any, index: number) => (
          <span key={index}>{asset?.userasset?.cost ? currencyLogo + ' ' + asset?.userasset?.cost : ''}</span>
        ))
      case 'Status':
        return assetsData?.allAssets?.map((asset: any, index: number) => (
          <span key={index}>{asset?.userasset?.statusType}</span>
        ))
      case 'Created By':
        return assetsData?.allAssets?.map((asset: any, index: number) => (
          <span key={index}>{asset?.user?.firstName} {asset?.user?.lastName}</span>
        ))
      case 'Date Created':
        return assetsData?.allAssets?.map((asset: any, index: number) => (
          <span key={index}>
            {asset?.userasset?.createdAt ? dateFormat(asset?.userasset?.createdAt, currentUser?.dateFormat || defaultDateFormat) : ''}
          </span>
        ))
      case 'Model':
        return assetsData?.allAssets?.map((asset: any, index: number) => (
          <span key={index}>{asset?.userasset?.model}</span>
        ))
      case 'Purchased From':
        return assetsData?.allAssets?.map((asset: any, index: number) => (
          <span key={index}>{asset?.userasset?.purchasedFrom}</span>
        ))
      case 'Serial No':
        return assetsData?.allAssets?.map((asset: any, index: number) => (
          <span key={index}>{asset?.userasset?.serialNo}</span>
        ))
      case 'Reservation':
        return assetsData.allAssets.map((asset: any, index: number) => {
          const currentDate = new Date()
          return (
            <>
              {asset?.userasset?.reserveassets?.map((reserveAsset, index) => {
                const endDate = new Date(reserveAsset.endDate)
                if (endDate >= currentDate) {
                  return <span key={index}>Res. {dateFormat(reserveAsset.endDate, currentUser?.dateFormat || defaultDateFormat)}</span>
                } else {
                  return null
                }
              })}
            </>
          )
        })
      case 'Category':
        return assetsData?.allAssets?.map((asset: any, index: number) => (
          <span key={index}>{asset?.userasset?.category?.category}</span>
        ))
      case 'Department':
        return assetsData?.allAssets?.map((asset: any, index: number) => (
          <span key={index}>{asset?.userasset?.department?.department}</span>
        ))
      case 'Location':
        return assetsData?.allAssets?.map((asset: any, index: number) => (
          <span key={index}>{asset?.userasset?.location?.location}</span>
        ))
      case 'Site':
        return assetsData?.allAssets?.map((asset: any, index: number) => (
          <span key={index}>{asset?.userasset?.site?.name}</span>
        ))
      case 'Assigned To':
        return assetsData?.allAssets?.map((asset: any, index: number) =>
          asset?.userasset?.assetstatus?.checkedTo === "person" ? (
            <span key={index}>
              {`${asset?.userasset?.assetstatus?.user?.firstName || ''} ${asset?.userasset?.assetstatus?.user?.lastName || ''
                }`}
            </span>
          ) : asset?.userasset?.assetstatus !== null ? (
            <span key={index}>
              {`${asset?.userasset?.site?.name || ''} / ${asset?.userasset?.location?.location || ''}`}
            </span>
          ) : (
            <span></span>
          )
        )
      case 'Event Date':
        return assetsData?.allAssets?.map((asset: any, index: number) => (
          <span key={index}>
            {asset?.userasset?.statusType === 'check_out'
              ? asset?.userasset?.assetstatus?.checkoutDate && dateFormat(asset?.userasset?.assetstatus?.checkoutDate, currentUser?.dateFormat || defaultDateFormat)
              : null}
          </span>
        ))
      case 'Event Due Date':
        return assetsData?.allAssets?.map((asset: any, index: number) => (
          <span key={index}>
            {asset?.userasset?.statusType === 'check_out'
              ? asset?.userasset?.assetstatus?.dueDate && dateFormat(asset?.userasset?.assetstatus?.dueDate, currentUser?.dateFormat || defaultDateFormat)
              : null}
          </span>
        ))
      case 'Event Notes':
        return assetsData?.allAssets?.map((asset: any, index: number) => (
          <span key={index}>{asset?.userasset?.statusType === "check_out"
            ? asset?.userasset?.assetstatus?.notes || null
            : null}</span>
        ))
      case 'Leased To':
        return assetsData?.allAssets?.map((asset: any, index: number) => (
          <span key={index}>{`${asset?.userasset?.assetslease?.user?.firstName || ""} ${asset?.userasset?.assetslease?.user?.lastName || ""
            }`}</span>
        ))
      case 'Title':
        return assetsData?.allAssets?.map((asset: any, index: number) => (
          <span key={index}>{asset?.maintenanceTitle}</span>
        ))
      case 'Expires':
        return assetsData?.allAssets?.map((asset: any, index: number) => (
          <span key={index}>
            {asset?.maintenanceDueDate ? dateFormat(asset?.maintenanceDueDate, currentUser?.dateFormat || defaultDateFormat) : ''}
          </span>
        ))
      case 'Frequency':
        return assetsData?.allAssets?.map((asset: any, index: number) => (
          <span key={index}>{asset?.maintenanceFrequency}</span>
        ))
      case 'Is Repeating':
        return assetsData?.allAssets?.map((asset: any, index: number) => (
          <span key={index}>{asset?.maintenanceReapting ? "yes" : "no"}</span>
        ))
      case 'Maintenance By':
        return assetsData?.allAssets?.map((asset: any, index: number) => (
          <span key={index}>{asset?.maintenanceBy}</span>
        ))
      case 'Maintenance Completion Date':
        return assetsData?.allAssets?.map((asset: any, index: number) => (
          <span key={index}>
            {asset?.maintenanceDateCompleted ? dateFormat(asset?.maintenanceDateCompleted, currentUser?.dateFormat || defaultDateFormat) : ''}
          </span>
        ))
      case 'Maintenance Cost':
        return assetsData?.allAssets?.map((asset: any, index: number) => (
          <span key={index}>{asset?.maintenanceCost}</span>
        ))
      case 'Maintenance Detail':
        return assetsData?.allAssets?.map((asset: any, index: number) => (
          <span key={index}>{asset?.maintenanceDetails}</span>
        ))
      case 'Recur on every':
        return assetsData?.allAssets?.map((asset: any, index: number) => (
          <span key={index}>{asset?.recureOnEvery}</span>
        ))
      case 'Maintenance Status':
        return assetsData?.allAssets?.map((asset: any, index: number) => (
          asset?.maintenanceStatus ?
            <span className={`bg-light-${asset?.maintenanceStatus === "completed" ? "green" : asset?.maintenanceStatus === "inProgress" ? "blue" : asset?.maintenanceStatus === "onHold" ? "danger" : asset?.maintenanceStatus === "scheduled" ? "warning" : asset?.maintenanceStatus === "cancelled" ? "secondary" : ''} d-inline align-items-center px-3 py-2 rounded`} key={index}>{asset?.maintenanceStatus === 'scheduled'
              ? 'Scheduled'
              : asset?.maintenanceStatus === 'inProgress'
                ? 'In Progress'
                : asset?.maintenanceStatus === 'onHold'
                  ? 'On Hold'
                  : asset?.maintenanceStatus === 'cancelled'
                    ? 'Cancelled'
                    : asset?.maintenanceStatus === 'completed'
                      ? 'Completed'
                      : asset?.maintenanceStatus}</span>
            : null
        ))
      default:
        return ''
    }
  }

  return <>{renderData()}</>
}

export const SampleColumnData: React.FC<ColumnDataProps> = ({ column }) => {
  const { currentUser } = useAuth()
  const renderData = () => {
    switch (column) {
      case 'Asset Photo':
        return 'Sample Photo'
      case 'Asset Tag ID':
        return 'Sample TagId'
      case 'Description':
        return 'Sample Description'
      case 'Brand':
        return 'Sample Brand'
      case 'Purchased Date':
        return dateFormat(date, currentUser?.dateFormat || defaultDateFormat)
      case 'Cost':
        return 'RS 1000'
      case 'Status':
        return 'Sample Status'
      case 'Created By':
        return 'Sample userName'
      case 'Date_Created':
        return dateFormat(date, currentUser?.dateFormat || defaultDateFormat)
      case 'Model':
        return 'Sample Model'
      case 'Purchased From':
        return 'Sample Purchased Location'
      case 'Serial No':
        return 'Sr-12'
      case 'Date Created':
        return dateFormat(date, currentUser?.dateFormat || defaultDateFormat)
      case 'Reservation':
        return 'Sample Reservation'
      case 'Category':
        return 'Sample Category'
      case 'Department':
        return 'Sample Department'
      case 'Location':
        return 'Sample Location'
      case 'Site':
        return 'Sample Site'
      case 'Assigned To':
        return 'Sample Assigned'
      case 'Event Date':
        return dateFormat(date, currentUser?.dateFormat || defaultDateFormat)
      case 'Event Due Date':
        return dateFormat(date, currentUser?.dateFormat || defaultDateFormat)
      case 'Event Notes':
        return 'Sample Event Notes'
      case 'Leased To':
        return 'Sample Customer Name'
      case 'Title':
        return 'Sample Title'
      case 'Expires':
        return dateFormat(date, currentUser?.dateFormat || defaultDateFormat)
      case 'Frequency':
        return 'Sample Frequency'
      case 'Is Repeating':
        return 'Weekly'
      case 'Maintenance By':
        return 'Sample Company Name'
      case 'Maintenance Completion Date':
        return dateFormat(date, currentUser?.dateFormat || defaultDateFormat)
      case 'Maintenance Cost':
        return '500'
      case 'Maintenance Detail':
        return 'Sample Deatils'
      case 'Recur on every':
        return 'Sample'
      case 'Maintenance Status':
        return 'In-Progress'
      default:
        return ''
    }
  }

  return <>{renderData()}</>
}
