import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react'
import {getAuth} from '../Auth'

const API_URL = process.env.REACT_APP_API_URL

export const groupsApi = createApi({
  reducerPath: 'groupsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${API_URL}/security-group/`,
    prepareHeaders: (headers) => {
      const auth = getAuth()
      if (auth && auth?.token) {
        headers.set('Authorization', `Bearer ${auth?.token}`)
      }
      return headers
    },
  }),
  tagTypes: ['Groups'],
  endpoints: (build) => ({
    getAllGroups: build.query<any, any>({
      query: ({ page = '', limit = '' }) => ({
        url: `get-all`,
        method: 'GET',
        params: { page, limit }
      }),
      providesTags: ['Groups'],
    }),
    getGroup: build.query<any, any>({
      query: (id) => ({
        url: `get/${id}`,
        method: 'GET',
      }),
      providesTags: ['Groups'],
    }),
    addGroups: build.mutation<any, any>({
      query: (body) => ({
        url: `create`,
        method: 'POST',
        body,
      }),
      invalidatesTags: () => [{type: 'Groups'}],
    }),
    deleteGroup: build.mutation<any, any>({
      query: (id) => ({
        url: `destroy/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: () => [{type: 'Groups'}],
    }),
    updateGroup: build.mutation<any, any>({
      
      query: ({tempData , id}) => ({
        url: `update/${id}`,
        method: 'PUT',
        body:tempData,
      }),
      invalidatesTags: (result, error, {id}) => [{type: 'Groups'}],
    }),
    
  }),
})

export const {
  useGetAllGroupsQuery,
  useGetGroupQuery,
  useAddGroupsMutation,
  useDeleteGroupMutation,
  useUpdateGroupMutation,
} = groupsApi
