import { useFormik } from 'formik'
import Modal from 'react-bootstrap/Modal'
import { initialCustomers, CustomerResponse } from '../Core/_models'
import * as Yup from 'yup'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { useAuth } from '../../../../Auth'
import { countriesList } from './Countries'
import {
  useAddCustomerMutation,
  useUpdateCustomerMutation,
  useGetAllCustomersQuery,
} from '../../../../Services/Customers'
import { emailRegex, nameRegex } from 'config'


const CustomersSechma = Yup.object().shape({
  firstName: Yup.string()
    .matches(nameRegex, 'Only letters, numbers and no extra spaces allowed')
    .min(3, 'Minimum 3 letters')
    .max(50, 'Maximum 50 letters')
    .required('First name is required'),
  lastName: Yup.string()
    .matches(nameRegex, 'Only letters, numbers and no extra spaces allowed')
    .min(3, 'Minimum 3 letters')
    .max(50, 'Maximum 50 letters')
    .required('Last name is required'),
  email: Yup.string()
    .matches(emailRegex, 'Invalid email address')
    .max(50, 'Maximum 50 characters')
    .required('Email is required'),
  phone: Yup.string()
    .matches(
      /^(\+)?[0-9]*$/,
      'Phone number must contain only numeric characters and may start with a plus sign'
    )
    .test('is-valid-number', 'Phone number must be between 10 and 15 digits', (value) => {
      if (value && value.trim().length > 0) {
        const stringValue = String(value).replace(/\D/g, '')
        return stringValue.length >= 10 && stringValue.length <= 15
      }
      return true
    })
    .nullable(),
  notes: Yup.string().max(250, 'Notes must be no more than 250 characters.'),
})

const CustomersModal = (props: any) => {
  const { currentUser } = useAuth()
  const [skip, setSkip] = useState<boolean>(false)
  const [addCustomer, { isLoading: isAdding }] = useAddCustomerMutation()
  const [updateCustomer, { isLoading: isUpdating }] = useUpdateCustomerMutation()
  const { data: userCustomersList, refetch: refetchUserCustomersList } = useGetAllCustomersQuery(
    { values: '' },
    { skip }
  )

  useEffect(() => {
    setSkip(false)
  }, [])

  useEffect(() => {
    formik.resetForm()
    if (props.customerId) {
      formik.setValues({
        firstName: props?.CustomerData?.customer?.firstName,
        lastName: props?.CustomerData?.customer?.lastName,
        company: props?.CustomerData?.customer?.company,
        address1: props?.CustomerData?.customer?.address1,
        address2: props?.CustomerData?.customer?.address2,
        city: props?.CustomerData?.customer?.city,
        state: props?.CustomerData?.customer?.state,
        zip: props?.CustomerData?.customer?.zip,
        country: props?.CustomerData?.customer?.country,
        phone: props?.CustomerData?.customer?.phone || '',
        cell: props?.CustomerData?.customer?.cell,
        email: props?.CustomerData?.customer?.email,
        notes: props?.CustomerData?.customer?.notes || '',
      })
    }
  }, [props.customerId, props.CustomerData])

  const formik = useFormik({
    initialValues: { ...initialCustomers },
    validationSchema: CustomersSechma,
    onSubmit: async (values) => {
      if (currentUser) {
        try {
          const data = props?.customerId
            ? await updateCustomer({ id: props.customerId, values }).unwrap()
            : await addCustomer(values).unwrap()
          if (data) {
            toast.dismiss()
            if (data?.success) {
              toast.success(data?.message)
              formik.resetForm()
              props.setCustomerId('')
              props.handleClose()
            } else {
              toast.error(data?.message)
            }
          }
          userCustomersList()
        } catch (error: any) {
          toast.error(error?.data?.message)
          formik.setSubmitting(false)
        }
      }
    },
  })

  const handleClose = () => {
    formik.resetForm()
    props.setCustomerId('')
    props.handleClose()
  }

  return (
    <>
      <Modal
        className='custom-modal-sm'
        show={props.show}
        onHide={props.handleClose}
        backdrop='static'
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>{props?.customerId ? 'Update Customer' : 'Add Customer'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={formik.handleSubmit} noValidate className='form' autoComplete='on'>
            <div className='inner-site-modal'>
              <div className='row mb-4'>
                <label htmlFor='firstName' className='col-lg-3 col-form-label required fw-bold fs-6 mb-2'>
                  First Name
                </label>
                <div className='col-lg-9 fv-row'>
                  <div>
                    <input
                      id='firstName'
                      {...formik.getFieldProps('firstName')}
                      type='text'
                      className='form-control form-control-solid mb-3 mb-lg-0'
                    />
                  </div>
                  {formik.touched.firstName && formik.errors.firstName && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.firstName}</div>
                    </div>
                  )}
                </div>
              </div>
              <div className='row mb-4'>
                <label htmlFor='lastName' className='col-lg-3 col-form-label required fw-bold fs-6 mb-2'>
                  Last Name
                </label>
                <div className='col-lg-9 fv-row'>
                  <div>
                    <input
                      id='lastName'
                      {...formik.getFieldProps('lastName')}
                      type='text'
                      className='form-control form-control-solid mb-3 mb-lg-0'
                    />
                  </div>
                  {formik.touched.lastName && formik.errors.lastName && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.lastName}</div>
                    </div>
                  )}
                </div>
              </div>
              <div className='row mb-4'>
                <label htmlFor='company' className='col-lg-3 col-form-label fw-bold fs-6 mb-2'>
                  Company
                </label>
                <div className='col-lg-9 fv-row'>
                  <div>
                    <input
                      id='company'
                      {...formik.getFieldProps('company')}
                      type='text'
                      className='form-control form-control-solid mb-3 mb-lg-0'
                    />
                  </div>
                  {formik.touched.company && formik.errors.company && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.company}</div>
                    </div>
                  )}
                </div>
              </div>
              <div className='row mb-4'>
                <label htmlFor='address1' className='col-lg-3 col-form-label fw-bold fs-6 mb-2'>
                  Address1
                </label>
                <div className='col-lg-9 fv-row'>
                  <input
                    id='address1'
                    {...formik.getFieldProps('address1')}
                    type='text'
                    className='form-control form-control-solid mb-3 mb-lg-0'
                  />
                </div>
              </div>


              <div className='row mb-4'>
                <label htmlFor='address2' className='col-lg-3 col-form-label fw-bold fs-6 mb-2'>Address2</label>
                <div className='col-lg-9 fv-row'>
                  <input
                    id='address2'
                    {...formik.getFieldProps('address2')}
                    type='text'
                    className='form-control form-control-solid mb-3 mb-lg-0'
                    autoComplete='off'
                  />
                </div>
              </div>
              <div className='row mb-4'>
                <label htmlFor='city' className='col-lg-3 col-form-label fw-bold fs-6 mb-2'>City</label>
                <div className='col-lg-9 fv-row'>
                  <input
                    id='city'
                    {...formik.getFieldProps('city')}
                    type='text'
                    className='form-control form-control-solid mb-3 mb-lg-0'
                  />
                </div>
              </div>
              <div className='row mb-4'>
                <label htmlFor='state' className='col-lg-3 col-form-label fw-bold fs-6 mb-2'>State</label>
                <div className='col-lg-9 fv-row'>
                  <input
                    id='state'
                    {...formik.getFieldProps('state')}
                    type='text'
                    className='form-control form-control-solid mb-3 mb-lg-0'
                  />
                </div>
              </div>
              <div className='row mb-4'>
                <label htmlFor='zip' className='col-lg-3 col-form-label fw-bold fs-6 mb-2'>Zip</label>
                <div className='col-lg-9 fv-row'>
                  <input
                    id='zip'
                    {...formik.getFieldProps('zip')}
                    type='text'
                    className='form-control form-control-solid mb-3 mb-lg-0'
                  />
                </div>
              </div>
              <div className='row mb-4'>
                <label htmlFor='country' className='col-lg-3 col-form-label fw-bold fs-6'>Country</label>
                <div className='col-lg-9 fv-row'>
                  <select
                    id='country'
                    className='form-select form-control form-control-solid form-select-solid'
                    {...formik.getFieldProps('country')}
                  >
                    <option selected> Select country</option>
                    {countriesList?.map((item: any, idx: number) => {
                      return (
                        <option key={idx} value={item.code}>
                          {item.name}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              <div className='row mb-4'>
                <label htmlFor='phone' className='col-lg-3 col-form-label fw-bold fs-6 mb-2'>Phone</label>
                <div className='col-lg-9 fv-row'>
                  <input
                    id='phone'
                    {...formik.getFieldProps('phone')}
                    type='text'
                    className='form-control form-control-solid mb-3 mb-lg-0'
                  />
                  {formik.touched.phone && formik.errors.phone && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.phone}</div>
                    </div>
                  )}
                </div>
              </div>
              <div className='row mb-4'>
                <label htmlFor='email' className='col-lg-3 col-form-label required fw-bold fs-6 mb-2'>Email</label>
                <div className='col-lg-9 fv-row'>
                  <input
                    id='email'
                    {...formik.getFieldProps('email')}
                    type='text'
                    className='form-control form-control-solid mb-3 mb-lg-0'
                  />
                  {formik.touched.email && formik.errors.email && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.email}</div>
                    </div>
                  )}
                </div>
              </div>
              <div className='row mb-4'>
                <label htmlFor='notes' className='col-lg-3 col-form-label fw-bold fs-6 mb-2'>Notes</label>
                <div className='col-lg-9 fv-row'>
                  <textarea
                    id='notes'
                    {...formik.getFieldProps('notes')}
                    rows={3}
                    className='form-control form-control-solid mb-3 mb-lg-0'
                  />
                </div>
                {formik.touched.notes && formik.errors.notes && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>{formik.errors.notes}</div>
                  </div>
                )}
              </div>
            </div>
            <div className='text-end pt-2'>
              <button
                type='button'
                className='btn btn-light-primary   main-btn-style me-2'
                onClick={handleClose}
              >
                Close{' '}
              </button>
              <button
                type='submit'
                className='btn btn-primary main-btn-style'
                disabled={formik.isSubmitting || !formik.isValid || !formik.dirty}
              >
                {!isAdding && !isUpdating && (
                  <span className='indicator-label'>
                    {props?.updateCustomer ? 'Update Customer' : 'Add Customer'}
                  </span>
                )}
                {(isAdding || isUpdating) && (
                  <span className='indicator-progress d-block'>
                    Please wait...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default CustomersModal
