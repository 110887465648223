import { Modal } from 'react-bootstrap'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { toast } from 'react-toastify'
import {
  MaintenanceInterface,
  defaultMaintenanceDetail as initialValues,
} from '../../Core/SetupModel'
import { useUpdateMaintenanceMutation } from 'App/Modules/Services/Maintenance'
import { useEffect, useState } from 'react'
import { dateFormat, defaultDateFormat } from 'config'
import { useAuth } from '../../../Auth'
import moment from 'moment'

const accountDetailsSchema = Yup.object().shape({
  maintenanceTitle: Yup.string()
    .required('Title is required')
    .test('no-empty-spaces', 'Title should not be empty', (value = '') => {
      return !/^\s*$/.test(value)
    }),
})
function MaintenancesEditModal(props) {
  const [updateMaintenance, { isLoading: isUpdating }] = useUpdateMaintenanceMutation()
  const [repeatingMaintenance, setRepeatingMaintenance] = useState(false)
  const [repeatingFrequency, setRepeatingFrequency] = useState('')
  const { currentUser } = useAuth()
  useEffect(() => {
    if (props.assetMaintenance?.assetId) {
      if (props.assetMaintenance?.maintenanceReapting) {
        setRepeatingMaintenance(true)
      }
      if (props.assetMaintenance?.maintenanceFrequency) {
        setRepeatingFrequency(props.assetMaintenance?.maintenanceFrequency)
      }
      formik.setValues({
        maintenanceTitle: props.assetMaintenance?.maintenanceTitle,
        maintenanceDetails: props.assetMaintenance?.maintenanceDetails,
        maintenanceDueDate: props.assetMaintenance?.maintenanceDueDate && moment(
          props.assetMaintenance?.maintenanceDueDate,
        ).format('YYYY-MM-DD'),
        maintenanceBy: props.assetMaintenance?.maintenanceBy,
        maintenanceStatus: props.assetMaintenance?.maintenanceStatus,
        maintenanceDateCompleted: props.assetMaintenance?.maintenanceDateCompleted && moment(
          props.assetMaintenance?.maintenanceDateCompleted,
        ).format('YYYY-MM-DD'),
        maintenanceCost: props.assetMaintenance?.maintenanceCost,
        maintenanceReapting: props.assetMaintenance?.maintenanceReapting,
        maintenanceFrequency: props.assetMaintenance?.maintenanceFrequency,
        recureOnEvery: props.assetMaintenance?.recureOnEvery,
        recureOn: props.assetMaintenance?.recureOn,
      })
    }
  }, [props.assetMaintenance?.assetId])

  const formik = useFormik({
    initialValues: { ...initialValues },
    validationSchema: accountDetailsSchema,
    onSubmit: async (values) => {
      try {
        const data = {
          ...values,
          maintenanceFrequency: repeatingMaintenance ? repeatingFrequency : null,
          maintenanceReapting: repeatingMaintenance,
          recureOn: repeatingMaintenance ? values?.recureOn : null,
          recureOnEvery: repeatingMaintenance ? values?.recureOnEvery : null
        }
        const result = await updateMaintenance({
          body: data,
          id: props.assetMaintenance?.id,
        }).unwrap()
        if (result.success) {
          toast.success(result?.message)
          props.setShowEditModal(false)
          props.setShowModal(true)
          props.setLoading(true)
        }
      } catch (error) {
        toast.error(error?.data?.message)
      }
    },
  })


  useEffect(() => {
    if (repeatingFrequency !== props.assetMaintenance?.maintenanceFrequency) {
      const data = {
        ...formik.values,
        recureOnEvery: null,
        recureOn: null,
      }
      formik.setValues(data)
    }
  }, [repeatingFrequency])

  return (
    <>
      <Modal
        className='px-2'
        size='lg'
        {...props}
        aria-labelledby='contained-modal-title-vcenter'
        backdrop='static'
      >
        <Modal.Header closeButton>
          <Modal.Title>Asset Maintenance</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={formik.handleSubmit} noValidate className='form'>
            <div className='inner-site-modal'>
              <div className='row mb-4'>
                <label className='col-lg-3 col-form-label required fw-bold fs-6 mb-2'>
                  {' '}
                  Title{' '}
                </label>
                <div className='col-lg-9 fv-row'>
                  <div>
                    <input
                      {...formik.getFieldProps('maintenanceTitle')}
                      onChange={(e) => {
                        formik.handleChange(e)
                      }}
                      type='text'
                      className='form-control form-control-solid mb-3 mb-lg-0'
                      autoComplete='off'
                    />
                  </div>
                  {formik.touched.maintenanceTitle && formik.errors.maintenanceTitle && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.maintenanceTitle}</div>
                    </div>
                  )}
                </div>
              </div>
              <div className='row mb-4'>
                <label className='col-lg-3 col-form-label fw-bold fs-6'>Details</label>
                <div className='col-lg-9 fv-row'>
                  <textarea
                    {...formik.getFieldProps('maintenanceDetails')}
                    onChange={(e) => {
                      formik.handleChange(e)
                    }}
                    rows={3}
                    className='form-control form-control-solid mb-3 mb-lg-0'
                    autoComplete='off'
                  />
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-3 col-form-label  fw-bold fs-6'>Due Date</label>
                <div className='col-lg-9 fv-row'>
                  <div className='input-group'>
                    <input
                      type='date'
                      className='form-control  form-control-solid'
                      aria-label='Amount (to the nearest dollar)'
                      {...formik.getFieldProps('maintenanceDueDate')}
                      onChange={(e) => {
                        formik.handleChange(e)
                      }}
                    />
                    <span className='input-group-text border-0'>
                      <i className='fa fa-calendar'></i>
                    </span>
                  </div>
                </div>
              </div>
              <div className='row mb-4'>
                <label className='col-lg-3 col-form-label fw-bold fs-6'> Maintenance By </label>
                <div className='col-lg-9 fv-row'>
                  <div>
                    <input
                      {...formik.getFieldProps('maintenanceBy')}
                      onChange={(e) => {
                        formik.handleChange(e)
                      }}
                      type='text'
                      className='form-control form-control-solid mb-3 mb-lg-0'
                      autoComplete='off'
                    />
                  </div>
                  {formik.touched.maintenanceBy && formik.errors.maintenanceBy && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.maintenanceBy}</div>
                    </div>
                  )}
                </div>
              </div>
              <div className='row mb-4'>
                <label className='col-lg-3 col-form-label fw-bold fs-6'>
                  <span>Maintenance Status</span>
                </label>
                <div className='col-lg-9 fv-row'>
                  <select
                    className='form-select form-control form-control-solid form-select-solid  '
                    {...formik.getFieldProps('maintenanceStatus')}
                    onChange={(e) => {
                      formik.handleChange(e)
                    }}
                  >
                    <option>Select ...</option>
                    <option value='scheduled'>Scheduled</option>
                    <option value='inProgress'>In progress</option>
                    <option value='onHold'>On hold</option>
                    <option value='cancelled'>Cancelled</option>
                    <option value='completed'>Completed</option>
                  </select>
                </div>
              </div>
              <div className='row mb-4'>
                <label className='col-lg-3 col-form-label fw-bold fs-6'>Date Completed</label>
                <div className='col-lg-9 fv-row'>
                  <div className='input-group'>
                    <input
                      type='date'
                      className='form-control  form-control-solid'
                      name='completedDate'
                      {...formik.getFieldProps('maintenanceDateCompleted')}
                      onChange={(e) => {
                        formik.handleChange(e)
                      }}
                    />
                    <span className='input-group-text border-0'>
                      <i className='fa fa-calendar'></i>
                    </span>
                  </div>
                </div>
              </div>
              <div className='row mb-4'>
                <label className='col-lg-3 col-form-label fw-bold fs-6'>
                  Maintenance Cost
                </label>
                <div className='col-lg-9 fv-row'>
                  <div className='input-group currency-field'>
                    <span className='input-group-text'>$</span>
                    <input
                      type='text'
                      className='form-control  form-control-solid'
                      aria-label='Amount (to the nearest dollar)'
                      {...formik.getFieldProps('maintenanceCost')}
                      onChange={(e) => {
                        formik.handleChange(e)
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className='row mb-4 align-items-center'>
                <label className='col-lg-3 col-form-label  fw-bold fs-6'>Repeating</label>
                <div className='col-lg-9 fv-row'>
                  <div className='d-flex'>
                    <div className='m-2 form-check-custom fw-bold'>
                      <div>
                        <input
                          className='form-check-input custom-form-check-input'
                          {...formik.getFieldProps('maintenanceReapting')}
                          onClick={() => {
                            setRepeatingMaintenance(true)
                          }}
                          type='checkbox'
                          value={'yes'}
                          checked={repeatingMaintenance == false ? false : true}
                          name={'repeating'}
                          id='repeatingYes'
                        />
                      </div>
                      <label className='form-check-label' htmlFor='repeatingYes'>
                        Yes
                      </label>
                    </div>
                    <div className='m-2 form-check-custom fw-bold'>
                      <div>
                        <input
                          className='form-check-input custom-form-check-input'
                          {...formik.getFieldProps('maintenanceReapting')}
                          onClick={() => {
                            setRepeatingMaintenance(false)
                          }}
                          value={'no'}
                          checked={repeatingMaintenance === false ? true : false}
                          type='checkbox'
                          name={'repeating'}
                          id='repeatingNo'
                        />
                      </div>
                      <label className='form-check-label' htmlFor='repeatingNo'>
                        No
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className={repeatingMaintenance ? 'row mb-6 align-items-center' : 'd-none'}>
                <label className='col-lg-3 col-form-label  fw-bold fs-6' htmlFor='maintenanceFrequency'>Frequency</label>
                <div className='col-lg-9 fv-row'>
                  <div className='d-flex'>
                    <div className='m-2 form-check-custom fw-bold'>
                      <div>
                        <input
                          className='form-check-input custom-form-check-input'
                          {...formik.getFieldProps('maintenanceFrequency')}
                          onClick={() => {
                            setRepeatingFrequency('daily')
                          }}
                          type='checkbox'
                          value={'daily'}
                          checked={repeatingFrequency == 'daily' ? true : false}
                          name={'frequency'}
                          id='dailyFrequency'
                        />
                      </div>
                      <label className='form-check-label' htmlFor='dailyFrequency'>
                        Daily
                      </label>
                    </div>
                    <div className='m-2 form-check-custom fw-bold'>
                      <div>
                        <input
                          className='form-check-input custom-form-check-input'
                          {...formik.getFieldProps('maintenanceFrequency')}
                          onClick={() => {
                            setRepeatingFrequency('weekly')
                          }}
                          type='checkbox'
                          value={'weekly'}
                          checked={repeatingFrequency == 'weekly' ? true : false}
                          name={'frequency'}
                          id='weeklyFrequency'
                        />
                      </div>
                      <label className='form-check-label' htmlFor='weeklyFrequency'>
                        Weekly
                      </label>
                    </div>
                    <div className='m-2 form-check-custom fw-bold'>
                      <div>
                        <input
                          className='form-check-input custom-form-check-input'
                          {...formik.getFieldProps('maintenanceFrequency')}
                          onClick={() => {
                            setRepeatingFrequency('monthly')
                          }}
                          type='checkbox'
                          value={'monthly'}
                          checked={repeatingFrequency == 'monthly' ? true : false}
                          name={'frequency'}
                          id='monthlyFrequency'
                        />
                      </div>
                      <label className='form-check-label' htmlFor='monthlyFrequency'>
                        Monthly
                      </label>
                    </div>
                    <div className='m-2 form-check-custom fw-bold'>
                      <div>
                        <input
                          className='form-check-input custom-form-check-input'
                          {...formik.getFieldProps('maintenanceFrequency')}
                          onClick={() => {
                            setRepeatingFrequency('yearly')
                          }}
                          type='checkbox'
                          value={'yearly'}
                          checked={repeatingFrequency == 'yearly' ? true : false}
                          name={'frequency'}
                          id='yearlyFrequency'
                        />
                      </div>
                      <label className='form-check-label' htmlFor='yearlyFrequency'>
                        Yearly
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              {repeatingFrequency == 'weekly' ||
                repeatingFrequency == 'monthly' ||
                (repeatingFrequency == 'yearly' && (
                  <label className='col-lg-3 col-form-label  fw-bold fs-6'>Recur on:</label>
                ))}

              <div
                className={
                  repeatingFrequency == 'weekly' || repeatingFrequency == 'monthly'
                    ? 'row mb-6'
                    : 'd-none'
                }
              >
                <label className='col-lg-3 col-form-label  fw-bold fs-6' htmlFor='recureOnEvery'>Every</label>
                <div className='col-lg-9 fv-row'>
                  <div className='input-group'>
                    <input
                      type='number'
                      min="0"
                      className='form-control  form-control-solid'
                      {...formik.getFieldProps('recureOnEvery')}
                      onChange={(e) => {
                        formik.handleChange(e)
                      }}
                      id='recureOnEvery'
                    />
                  </div>
                </div>
              </div>
              <div className={repeatingFrequency == 'yearly' ? 'row mb-6' : 'd-none'}>
                <label className='col-lg-3 col-form-label  fw-bold fs-6' htmlFor='recureOnEvery'>Every</label>
                <div className='col-lg-9 fv-row'>
                  <div className='input-group'>
                    <select
                      {...formik.getFieldProps('recureOnEvery')}
                      onChange={(e) => {
                        formik.handleChange(e)
                      }}
                      className='form-select form-control form-control-solid form-select-solid  '
                      id='recureOnEvery'
                    >
                      <option value="">Select Month</option>
                      <option value='january'>January</option>
                      <option value='february'>February</option>
                      <option value='march'>March</option>
                      <option value='april'>April</option>
                      <option value='may'>May</option>
                      <option value='june'>June</option>
                      <option value='july'>July</option>
                      <option value='august'>August</option>
                      <option value='september'>September</option>
                      <option value='october'>October</option>
                      <option value='november'>November</option>
                      <option value='december'>December</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className={repeatingFrequency == 'weekly' ? 'row mb-6' : 'd-none'}>
                <label className='col-lg-3 col-form-label  fw-bold fs-6' htmlFor='recureOn'>On</label>
                <div className='col-lg-9 fv-row'>
                  <div className='input-group'>
                    <select
                      {...formik.getFieldProps('recureOn')}
                      onChange={(e) => {
                        formik.handleChange(e)
                      }}
                      className='form-select form-control form-control-solid form-select-solid  '
                      id='recureOn'
                    >
                      <option value="">Please Select Day</option>
                      <option value='monday'>Monday</option>
                      <option value='tuesday'>Tuesday</option>
                      <option value='wednesday'>Wednesday</option>
                      <option value='thursday'>Thursday</option>
                      <option value='friday'>Friday</option>
                      <option value='saturday'>Saturday</option>
                      <option value='sunday'>Sunday</option>
                    </select>
                  </div>
                </div>
              </div>
              <div
                className={
                  repeatingFrequency == 'yearly' || repeatingFrequency == 'monthly'
                    ? 'row mb-6'
                    : 'd-none'
                }
              >
                <label className='col-lg-3 col-form-label fw-bold fs-6' htmlFor='recureOn'>On</label>
                <div className='col-lg-9 fv-row'>
                  <div className='input-group'>
                    <select
                      {...formik.getFieldProps('recureOn')}
                      onChange={(e) => {
                        formik.handleChange(e)
                      }}
                      className='form-select form-control form-control-solid form-select-solid'
                      id='recureOn'
                    >
                      <option value="">Please Select..</option>
                      <option value='1'>1</option>
                      <option value='2'>2</option>
                      <option value='3'>3</option>
                      <option value='4'>4</option>
                      <option value='5'>5</option>
                      <option value='6'>6</option>
                      <option value='7'>7</option>
                      <option value='8'>8</option>
                      <option value='9'>9</option>
                      <option value='10'>10</option>
                      <option value='11'>11</option>
                      <option value='12'>12</option>
                      <option value='13'>13</option>
                      <option value='14'>14</option>
                      <option value='15'>15</option>
                      <option value='16'>16</option>
                      <option value='17'>17</option>
                      <option value='18'>18</option>
                      <option value='19'>19</option>
                      <option value='20'>20</option>
                      <option value='21'>21</option>
                      <option value='22'>22</option>
                      <option value='23'>23</option>
                      <option value='24'>24</option>
                      <option value='25'>25</option>
                      <option value='26'>26</option>
                      <option value='27'>27</option>
                      <option value='28'>28</option>
                      <option value='29'>29</option>
                      <option value='30'>30</option>
                      <option value='31'>31</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button
            onClick={props.onHide}
            type='button'
            className='btn btn-light-primary   main-btn-style me-2'
            data-bs-dismiss='modal'
          >
            Close
          </button>
          <div className=' d-flex align-items-center justify-content-start'>
            <button
              type='button'
              className='btn main-btn-style btn-primary'
              disabled={isUpdating || !formik.isValid}
              onClick={formik.handleSubmit}
            >
              {!isUpdating && 'Submit'}
              {isUpdating && (
                <span className='indicator-progress'>
                  Please wait...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default MaintenancesEditModal
