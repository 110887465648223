export interface MaintenanceInterface {
    maintenanceTitle: string | null,
    maintenanceDetails: string | null,
    dueDate: string | null,
    maintenanceBy: string | null,
    maintenanceStatus: string | null,
    leaseExpires: string | null,
    cost: number | null,
    maintenanceReapting: string | null,
    maintenanceFrequency: string | null,
    recureOnEvery: number | null,
    recureOn: string | null,
}
export const defaultMaintenanceDetail: MaintenanceInterface = {
    maintenanceTitle: "",
    maintenanceDetails: null,
    dueDate: null,
    maintenanceBy: null,
    maintenanceStatus: null,
    leaseExpires: null,
    cost: null,
    maintenanceReapting: null,
    maintenanceFrequency: null,
    recureOnEvery: null,
    recureOn: null,
}
