import { dateFormat, defaultDateFormat } from 'config'
import { Modal, Tab, Tabs } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { Images } from '_metronic/assets/Assets'
import { toAbsoluteUrlImage } from '_metronic/helpers'
import { useAuth } from 'App/Modules/Auth'

function WarrantiesDetailModal(props) {
    const { currentUser } = useAuth()
    return (
        <Modal
            className='px-2'
            size='lg'
            {...props}
            aria-labelledby='contained-modal-title-vcenter'
            backdrop='static'
        >
            <Modal.Header closeButton>
                <Modal.Title>Asset Warranty</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Tabs
                    defaultActiveKey='tab1'
                    id='uncontrolled-tab-example'
                    className='mb-5 view-assets-tabs justify-content-start'
                >
                    <Tab eventKey='tab1' title='Warranty Details' className='me-2'>
                        <div className='assetProduct_detail mb-5'>
                            <div className='asset_detail_table table-responsive'>
                                <table
                                    className='table table-bordered detail-table table-sm mb-0'
                                    aria-label='Quick View'
                                    role='presentation'
                                >
                                    <tbody>
                                        <tr>
                                            <td className='label_col bg-light'>Length</td>
                                            <td className='fw-bold detail_col table-elipses'>
                                                {props.assetWarranty?.duration}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className='label_col bg-light'>Expiration Date</td>
                                            <td className='fw-bold detail_col table-elipses'>
                                                {dateFormat(props.assetWarranty?.expirationDate, currentUser?.dateFormat || defaultDateFormat)}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className='label_col bg-light'>Notes</td>
                                            <td className='fw-bold detail_col table-elipses'>
                                                {props.assetWarranty?.description}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className='label_col bg-light'>Created by</td>
                                            <td className='fw-bold detail_col table-elipses'>
                                                {props.assetWarranty?.createdBy}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className='pt-4 p-0 border-top d-flex justify-content-end'>
                            <button
                                onClick={props.onHide}
                                type='button'
                                className='btn btn-light-primary   main-btn-style me-2'
                                data-bs-dismiss='modal'
                            >
                                Close
                            </button>
                        </div>
                    </Tab>
                    <Tab eventKey='tab2' title='Asset Details'>
                        <div className='assetProduct_detail asset-details mb-5'>
                            <div className='row'>
                                <div className='col-lg-4'>
                                    {props.assetWarranty?.userasset?.photo ? (
                                        <div className='overlay-wrapper asset_image rounded overflow-hidden mb-lg-0 mb-5'>
                                            <img
                                                src={toAbsoluteUrlImage(props.assetWarranty?.userasset?.photo)}
                                                className='img-fluid'
                                                alt='Asset'
                                            />
                                        </div>
                                    ) : (
                                        <div className='overlay-wrapper asset_image rounded overflow-hidden mb-lg-0 mb-5'>
                                            <img src={Images.AssetsPlaceholder} className='img-fluid' alt='Asset' />
                                        </div>
                                    )}
                                </div>
                                <div className='col-lg-8'>
                                    <div className='asset_detail_table table-responsive'>
                                        <table
                                            className='table table-bordered detail-table table-sm mb-0'
                                            aria-label='Quick View'
                                            role='presentation'
                                        >
                                            <tbody>
                                                <tr>
                                                    <td className='label_col bg-light'>Asset Tag ID </td>
                                                    <td className='fw-bold detail_col table-elipses'>
                                                        {props.assetWarranty?.userasset?.assetTagId}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className='label_col bg-light'>Description</td>
                                                    <td className='fw-bold detail_col table-elipses'>
                                                        {props.assetWarranty?.userasset?.description}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className='label_col bg-light'>Purchase Date </td>
                                                    <td className='fw-bold detail_col table-elipses'>
                                                        {dateFormat(
                                                            props.assetWarranty?.userasset?.purchasedDate,
                                                            currentUser?.dateFormat || defaultDateFormat
                                                        )}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className='label_col bg-light'>Purchased From</td>
                                                    <td className='fw-bold detail_col table-elipses'>
                                                        {props.assetWarranty?.userasset?.purchasedFrom}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className='label_col bg-light'>Cost</td>
                                                    <td className='fw-bold detail_col table-elipses'>
                                                        {props.assetWarranty?.userasset?.cost}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className='label_col bg-light'>Brand</td>
                                                    <td className='fw-bold detail_col table-elipses'>
                                                        {props.assetWarranty?.userasset?.brand}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className='label_col bg-light'>Model</td>
                                                    <td className='fw-bold detail_col table-elipses'>
                                                        {props.assetWarranty?.userasset?.model}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className='label_col bg-light'>Serial No</td>
                                                    <td className='fw-bold detail_col table-elipses'>
                                                        {props.assetWarranty?.userasset?.serialNo}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className='label_col bg-light'>Site</td>
                                                    <td className='fw-bold detail_col table-elipses'>
                                                        {props.assetWarranty?.userasset?.site?.name}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className='label_col bg-light'>Location</td>
                                                    <td className='fw-bold detail_col table-elipses'>
                                                        {props.assetWarranty?.userasset?.location?.location}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className='label_col bg-light'>Category </td>
                                                    <td className='fw-bold detail_col table-elipses'>
                                                        {props.assetWarranty?.userasset?.category?.category}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className='label_col bg-light'>Department </td>
                                                    <td className='fw-bold detail_col table-elipses'>
                                                        {props.assetWarranty?.userasset?.department?.department}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className='label_col bg-light'>Assigned to</td>
                                                    <td className='fw-bold detail_col table-elipses'>
                                                        {props.assetWarranty?.userasset?.site?.name}{' '}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className='label_col bg-light'>Status</td>
                                                    <td className='fw-bold detail_col table-elipses'>
                                                        {props.assetWarranty?.userasset?.statusType}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='pt-4 p-0 border-top d-flex justify-content-end'>
                            <button
                                onClick={props.onHide}
                                type='button'
                                className='btn btn-light-primary   main-btn-style me-2'
                                data-bs-dismiss='modal'
                            >
                                Close
                            </button>
                            <Link
                                to={'/warranties'}
                                className='btn btn-primary main-btn-style'
                                href='/assets/detail/29962946'
                                id='eventUrl'
                            >
                                More Details
                            </Link>
                        </div>
                    </Tab>
                </Tabs>
            </Modal.Body>
        </Modal>
    )
}

export default WarrantiesDetailModal
