import React, { useState, useEffect } from 'react'
import { useNavigate, Link } from 'react-router-dom'
import { useGetAllCustomersQuery, useGetCustomersQuery } from 'App/Modules/Services/Customers'
import { useAddLeaseMutation } from '../Services/Lease'
import { useGetAllAssetsQuery } from 'App/Modules/Services/Assets'
import LeaseModal from './Modal/LeaseModal'
import DuplicateReserveModal from './Modal/DuplicateReserveModal'
import CustomersModal from 'App/Modules/Apps/CompanySetup/Customers/Components/CustomersModal'
import { checkUserUnauthorized } from '../Auth'
import { LeaseInterface, defaultLeaseDetail as initialValues } from './Core/SetupModel'
import { PageTitle } from '../../../_metronic/layout/core'
import { useIntl } from 'react-intl'
import { useAuth } from '../Auth'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { toast } from 'react-toastify'
import Select from 'react-select'
import SplashScreen from 'App/SplashScreen'
import { log } from 'console'

const accountDetailsSchema = Yup.object().shape({
	leaseBegins: Yup.date()
		.min(new Date(new Date().setHours(0, 0, 0, 0)), 'Lease Begins cannot be a past date')
		.required('Lease Begins is required'),
	leaseExpires: Yup.date()
		.nullable()
		.min(new Date(new Date().setHours(0, 0, 0, 0)), 'Lease Expires cannot be a past date'),
})

const Lease = () => {
	const intl = useIntl()
	const { currentUser, saveAuth, setCurrentUser } = useAuth()
	const navigate = useNavigate()
	const [assets, setAssets] = useState<any>([])
	const [showLeaseModal, setShowLeaseModal] = useState(false)
	const [pendingAssets, setPendingAssets] = useState<any>([])
	const [selectedAssets, setSelectedAssets] = useState<any>([])
	const [selectAll, setSelectAll] = useState(false)
	const [reservedData, setReservedData] = useState<any>([])
	const [duplicateReserveModal, setDuplicateReserveModal] = useState(false)
	const [reserveModalData, setReserveModalData] = useState<any>({})
	const [emailEnabled, setEmailEnabled] = useState(false)
	const [showCustomerModal, setShowCustomerModal] = useState<boolean>(false)
	const [selected, setSelected] = useState<any>([])
	const [page, setPage] = useState<number>(1)
	const [limit, setLimit] = useState<number>(10)
	const [selectedValue, setSelectedValue] = useState('')
	const [customerOptinos, setCustomerOptinos] = useState([])
	const [deleteDataArr, setDeleteDataArr] = useState<number[]>([])
	const [customErrors, setCustomErrors] = useState(false)
	const [customerId, setCustomerId] = useState('')
	const [addLease, { isLoading: isAdding }] = useAddLeaseMutation()
	const { data: customerData } = useGetCustomersQuery({})
	const { data: customerList } = useGetAllCustomersQuery(
		{ search: selectedValue },
		{ skip: !selectedValue }
	)
	const {
		data: assetsData,
		isLoading: assetLoading,
		error,
		isSuccess,
	} = useGetAllAssetsQuery({ body: new URLSearchParams({}).toString(), page, limit, keys: '' })
	const [isNewPersonAdded, setIsNewPersonAdded] = useState(false)

	const formik = useFormik({
		initialValues: { ...initialValues },
		validationSchema: accountDetailsSchema,
		onSubmit: async (values) => {
			try {
				handleLeaseSubmit(values)
				setSelectedValue("")
				formik.resetForm()
			} catch (error) {
				console.error(error)
				toast.error('Something went wrong')
			}
		},
	})
	useEffect(() => {
		if (customerData) {
			const options = customerData?.customers?.map((item) => ({
				value: item?.email,
				label: (
					<div className='d-flex flex-column'>
						<span className='personFullName'>{`${item?.firstName} ${item?.lastName}`}</span>
						<span className='personEmail'> {item?.email}</span>
					</div>
				),
			}))
			setCustomerOptinos(options)
		}
	}, [customerData])

	useEffect(() => {
		if (assetsData) {
			getAssetListing()
		}
	}, [assetsData])

	useEffect(() => {
		if (assets && pendingAssets && showLeaseModal == false) {
			handlePendingList()
		}
	}, [assets, showLeaseModal])
	const getAssetListing = async () => {
		setAssets(assetsData?.userAssets)
	}
	const handlePendingList = () => {
		const data = assets?.filter((asset: any) => pendingAssets.includes(asset.id))
		setSelectedAssets(data)
		setDeleteDataArr([])
	}

	const handleLeaseSubmit = async (values: LeaseInterface) => {
		if (selectedValue === "") {
			setCustomErrors(true)
		  }
		 else if (!customerList) {
			setCustomErrors(true)
		} else {
			try {
				const data = {
					leaseBegins: values?.leaseBegins,
					customerId: values?.customerId,
					leaseExpires: values?.leaseExpires?.length > 0 ? values?.leaseExpires : undefined,
					leaseNotes: values?.leaseNotes === "" ? null : values?.leaseNotes,
					leaseEmail: values?.leaseEmail,
					assetId: pendingAssets,
					overrideAssets: values?.overrideAssets,
				}
				if (emailEnabled) {
					data.leaseEmail = selectedValue
				}
				if (!data.customerId) {
					data.customerId = customerList?.customers[0]?.id
				}
				const result = await addLease(data).unwrap()
				if (result) {
					if (result.status === 409) {
						setReservedData(result?.ifReserveAssets)
						handleDuplicateReserveModal()
						setReserveModalData(data)
					} else {
						toast.dismiss()
						toast.success(result?.message ? result?.message : result?.data?.message)
						formik.resetForm()
						setSelectedAssets([])
						setCustomErrors(false)
						setSelectedValue("")
					}
				}
			} catch (error: any) {
				toast.dismiss()
				toast.error(error?.data?.message)
			}
		}
	}
	const handleShowLeaseModal = () => {
		setShowLeaseModal(true)
	}
	const handleCloseLeaseModal = () => {
		setShowLeaseModal(false)
	}
	const handleDuplicateReserveModal = () => {
		setDuplicateReserveModal(true)
	}
	const handleCloseDuplicateReserveModal = () => {
		setDuplicateReserveModal(false)
	}
	const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.checked) {
			const newSelecteds = assets?.map((item: any) => item.id)
			setSelected(newSelecteds)
			setSelectAll(true)
			setDeleteDataArr(newSelecteds)
		} else {
			setSelected([])
			setSelectAll(false)
			setDeleteDataArr([])
		}
	}

	const handleCheckboxChange = (id: number) => {
		setSelected((prevSelected) => {
			const updatedSelected = prevSelected.includes(id)
				? prevSelected.filter((itemId) => itemId !== id)
				: [...prevSelected, id]
			const isAllSelected = updatedSelected.length === assets?.length
			setSelectAll(isAllSelected)
			return updatedSelected
		})
		setDeleteDataArr((prevSelected) => {
			if (prevSelected.includes(id)) {
				return prevSelected.filter((itemId) => itemId !== id)
			} else {
				return [...prevSelected, id]
			}
		})
	}

	const deleteSelectedAssests = async () => {
		const data = selectedAssets?.filter((asset: any) => !deleteDataArr.includes(asset.id))
		setSelectedAssets(data)
		setDeleteDataArr([])
		setSelectAll(false)
		setSelected([])
	}

	const handleShowCustomerModal = () => {
		setShowCustomerModal(true)
		setIsNewPersonAdded(true)
	}

	const handleCloseCustomerModal = () => {
		setShowCustomerModal(false)
	}
	const handleSelectChange = (selectedOption) => {
		setSelectedValue(selectedOption?.value || "")
		if (selectedOption === null) {
			setCustomErrors(true)
		  } else {
			setCustomErrors(false)
		  }
	}

	const handleCancel = () => {
		setSelectedValue('')
		formik.resetForm()
		setSelectedAssets([])
		setPendingAssets([])
		setDuplicateReserveModal(false)
		setShowLeaseModal(false)
		setCustomErrors(false);
	}

	return (
		<>
			<SplashScreen isLoadingTemplate={assetLoading} />
			<PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'Lease' })}</PageTitle>
			<div className='card mb-5 mb-xl-10'>
				<div className='card-header border-0'>
					<div className='card-title m-0 '>
						<h3 className='fw-bolder m-0'>
							<span className='me-2'>
								<i className='las la-paper-plane fs-1'></i>
							</span>{' '}
							Lease
						</h3>
					</div>
				</div>
				<div id='kt_account_profile_details' className='collapse show'>
					<div className='form'>
						<div className='card-body border-top'>
							<div>
								{' '}
								<p className='fw-bolder m-0 mb-3'>
									Keep track of your assets from customer to customer and create an even more
									detailed history of them.
								</p>
								<div className='card-footer d-flex justify-content-start border-0 p-0'>
									<button
										type='button'
										className='btn btn-light-primary me-3'
										onClick={() => handleShowLeaseModal()}
									>
										<i className='la la-plus fs-3' />
										Select Assets
									</button>
									<LeaseModal
										show={showLeaseModal}
										setPendingAssets={setPendingAssets}
										selectedAssets={selectedAssets}
										handleClose={handleCloseLeaseModal}
										handleCancel={handleCancel}
										setPage={setPage}
										setLimit={setLimit}
										page={page}
										limit={limit}
									/>
									<DuplicateReserveModal
										show={duplicateReserveModal}
										handleLeaseSubmit={handleLeaseSubmit}
										data={reserveModalData}
										reservedData={reservedData}
										handleClose={handleCloseDuplicateReserveModal}
									/>
								</div>
								{selectedAssets && selectedAssets?.length > 0 ? (
									<>
										<div className='separator border-2 my-10'></div>
										<div className='d-flex justify-content-between align-items-center'>
											<h4 className='mt-3'>Assets Pending Lease</h4>
											{deleteDataArr.length > 0 && (
												<button
													type='button'
													className='btn btn-danger  mb-2'
													onClick={() => {
														deleteSelectedAssests()
													}}
												>
													Delete Selected
												</button>
											)}
										</div>
										<div className='checkout-listing-table mb-5 table-responsive pb-4'>
											<table
												id='kt_table_users'
												className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
												role='table'
											>
												<thead>
													<tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
														<th role='columnheader' className='w-10px pe-2'>
															<div className='me-3'>
																<input
																	type='checkbox'
																	className='form-check-input custom-form-check-input'
																	checked={selectAll}
																	onChange={handleSelectAll}
																/>
															</div>
														</th>
														<th role='columnheader' className='min-w-125px'>
															{' '}
															Asset Tag ID{' '}
														</th>
														<th role='columnheader' className='min-w-125px'>
															{' '}
															Description{' '}
														</th>
														<th role='columnheader' className='min-w-125px'>
															{' '}
															Status{' '}
														</th>
														<th role='columnheader' className='min-w-125px'>
															{' '}
															Assigned to{' '}
														</th>
														<th role='columnheader' className='min-w-125px'>
															{' '}
															Site{' '}
														</th>
														<th role='columnheader' className='min-w-125px'>
															{' '}
															Location{' '}
														</th>
														<th role='columnheader' className='min-w-125px'>
															{' '}
															Lease to{' '}
														</th>
													</tr>
												</thead>
												<tbody className='text-gray-600 fw-bol' role='rowgroup'>
													{selectedAssets?.length > 0 ? (
														selectedAssets?.map((item: any) => {
															return (
																<tr role='row'>
																	<td role='cell'>
																		<div>
																			<input
																				type='checkbox'
																				className='form-check-input custom-form-check-input'
																				checked={selected.includes(item.id)}
																				onChange={() => handleCheckboxChange(item.id)}
																			/>
																		</div>
																	</td>
																	<td role='cell'>
																		<div className='d-flex align-items-center'>
																			<div className=' overflow-hidden me-3'>
																				<Link to='/dummy-link'>{item?.assetTagId}</Link>
																			</div>
																		</div>
																	</td>
																	<td role='cell'>
																		<div className='d-flex align-items-center'>
																			<div className=' overflow-hidden me-3'>
																				<Link to='/dummy-link'>{item?.description}</Link>
																			</div>
																		</div>
																	</td>
																	<td role='cell'>
																		<div className='d-flex align-items-center'>
																			<div className='d-flex flex-column'>
																				{item.statusType === 'check_out'
																					? 'Checked Out'
																					: item.statusType === 'lease'
																						? 'Leased'
																						: item.statusType === 'dispose'
																							? 'Disposed'
																							: item.statusType === 'available'
																								? 'Available'
																								: item.statusType}
																			</div>
																		</div>
																	</td>
																	<td role='cell'>
																		<div className='d-flex align-items-center'>
																			<div className=' overflow-hidden me-3'>
																				{item?.assetstatus?.user ? (
																					<div>{`${item?.assetstatus?.user?.firstName || ''} ${item?.assetstatus?.user?.lastName || ''}`}</div>
																				) : item?.assetstatus !== null ? (
																					<div>{`${item?.site?.name || ''} ${item?.site?.name && item.location?.location ? "/" : ""} ${item?.location?.location || ''}`}</div>
																				) : item?.reserveassets?.length > 0 ? (
																					item.reserveassets[0].reserveFor === 'person' ? (
																						<div>{`${item?.reserveassets[0]?.user?.firstName || ''} ${item?.reserveassets[0]?.user?.lastName || ''}`}</div>
																					) : item.reserveassets[0].reserveFor === 'customer' ? (
																						<div>{`${item?.reserveassets[0]?.user?.firstName || ''} ${item?.reserveassets[0]?.user?.lastName || ''}`}</div>
																					) : (
																						<div>{`${item?.reserveassets[0]?.site?.name || ''} ${item?.reserveassets[0]?.location?.location || ''}`}</div>
																					)
																				) : (
																					<div></div>
																				)}
																			</div>
																		</div>
																	</td>
																	<td role='cell'>
																		<div className='d-flex align-items-center'>
																			<div className=' overflow-hidden me-3'>
																				<Link to='/dummy-link'>{item?.site?.name}</Link>
																			</div>
																		</div>
																	</td>
																	<td role='cell'>
																		<div className='d-flex align-items-center'>
																			<div className='d-flex flex-column'>
																				{item?.location?.location}
																			</div>
																		</div>
																	</td>
																	<td role='cell'>
																		<div className='d-flex align-items-center'>
																			<div className='d-flex flex-column'>
																				{item?.statusType === 'lease' && item?.assetslease
																					? `${item?.assetslease?.user?.firstName} ${item?.assetslease?.user?.lastName}`
																					: ''}
																			</div>
																		</div>
																	</td>
																</tr>
															)
														})
													) : (
														<tr>
															<td colSpan={12}>
																<div className='d-flex text-center w-100 align-items-center justify-content-center no-records fw-bold rounded'>
																	No records found
																</div>
															</td>
														</tr>
													)}
												</tbody>
											</table>
										</div>
										<div className='separator border-2 my-10'></div>
										<form onSubmit={formik.handleSubmit} noValidate className='form'>
											<div className='row mb-3'>
												<div className='col-lg-6'>
													<div className='row mb-6'>
														<label className='col-lg-3 col-form-label required fw-bold fs-6'>
															Lease Begins
														</label>
														<div className='col-lg-9 fv-row'>
															<div className='input-group'>
																<input
																	className='form-control form-control-solid'
																	{...formik.getFieldProps('leaseBegins')}
																	type='date'
																	placeholder='Pick date rage'
																	id='kt_daterangepicker_3'
																/>
																<span className='input-group-text border-0'>
																	<i className='fa fa-calendar'></i>
																</span>
															</div>
															{formik.touched.leaseBegins && formik.errors.leaseBegins && (
																<div className='fv-plugins-message-container'>
																	<div className='fv-help-block'>{formik.errors.leaseBegins}</div>
																</div>
															)}
														</div>
													</div>
													<div className='row mb-6'>
														<label className='col-lg-3 col-form-label fw-bold fs-6 required'>
															<span>Leasing Customer</span>
														</label>
														<div className='col-lg-9 fv-row'>
															<div className=' d-flex'>
																<Select
																	onChange={handleSelectChange}
																	isClearable={true}
																	isSearchable={true}
																	options={customerOptinos}
																	placeholder='Select a Customer'
																	classNamePrefix='custom-select'
																	className='custom-react-select w-100'
																/>
																<button
																	type='button'
																	className='btn btn-light-primary d-flex align-items-center ms-3'
																	onClick={() => handleShowCustomerModal()}
																>
																	<i className='la la-plus fs-3' />
																	New
																</button>
															</div>
															{customErrors && (
																<span className='text-danger'>Leasing customer is required</span>
															)}
															{formik.touched.customerId && formik.errors.customerId && (
																<div className='fv-plugins-message-container'>
																	<div className='fv-help-block'>{formik.errors.customerId}</div>
																</div>
															)}
															<CustomersModal
																show={showCustomerModal}
																handleClose={handleCloseCustomerModal}
																setCustomerId={setCustomerId}
																CustomerData={[]}
															/>
														</div>
													</div>
													<div className='row mb-6'>
														<label className='col-lg-3 col-form-label fw-bold fs-6'>
															Lease Expires
														</label>
														<div className='col-lg-9 fv-row'>
															<div className='input-group'>
																<input
																	className='form-control form-control-solid'
																	{...formik.getFieldProps('leaseExpires')}
																	type='date'
																	placeholder='Pick date rage'
																	id='kt_daterangepicker_3'
																/>
																<span className='input-group-text border-0'>
																	<i className='fa fa-calendar'></i>
																</span>
															</div>
															{formik.touched.leaseExpires && formik.errors.leaseExpires && (
																<div className='fv-plugins-message-container'>
																	<div className='fv-help-block'>{formik.errors.leaseExpires}</div>
																</div>
															)}
														</div>
													</div>
												</div>
												<div className='col-lg-6'>
													<div className='row mb-6'>
														<div className='col-lg-3 col-form-label fw-bold fs-6'>
															<div className='form-check form-check-custom  form-check-sm d-flex align-center mb-lg-0 mb-3'>
																<div>
																	<input
																		className='form-check-input me-2'
																		type='checkbox'
																		id='flexRadioLg'
																		checked={emailEnabled}
																		onChange={() => setEmailEnabled(!emailEnabled)}
																	/>
																</div>
																<label
																	className='form-label mb-0 fw-bold fs-6'
																	htmlFor='flexRadioLg'
																>
																	Send Email
																</label>
															</div>
														</div>
														<div className='col-lg-9 fv-row'>
															<div>
																<input
																	type='email'
																	disabled={emailEnabled == true ? false : true}
																	className='form-control form-control-solid'
																	value={selectedValue}
																	placeholder='Email'
																/>
															</div>
														</div>
													</div>
													<div className='row mb-6'>
														<label className='col-lg-3 col-form-label fw-bold fs-6'>
															<span className='lease-notes'>Lease Notes</span>
														</label>
														<div className='col-lg-9 fv-row'>
															<textarea
																className='form-control'
																{...formik.getFieldProps('leaseNotes')}
																cols={20}
																rows={4}
															></textarea>
														</div>
													</div>
												</div>
											</div>
											<div className='d-flex align-items-center justify-content-end'>
												<button
													onClick={handleCancel}
													className='btn btn-light-primary   main-btn-style me-2'
												>
													Cancel
												</button>
												<button type='submit' className='btn btn-primary ms-2' disabled={selectedValue === '' || customErrors || !formik.isValid }>
													{!isAdding && 'Lease'}
													{isAdding && (
														<span className='indicator-progress'>
															Please wait...{' '}
															<span className='spinner-border spinner-border-sm align-middle ms-2'></span>
														</span>
													)}
												</button>
											</div>
										</form>
									</>
								) : (
									''
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	)
}

export default Lease
