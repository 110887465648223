import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { getAuth } from '../Auth'
import { dashboardApi } from './Dashboard'
import { toast } from 'react-toastify'

const API_URL = process.env.REACT_APP_API_URL

export const subUserApi = createApi({
  reducerPath: 'subUserApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${API_URL}/sub-user/`,
    prepareHeaders: (headers) => {
      const auth = getAuth()
      if (auth && auth?.token) {
        headers.set('Authorization', `Bearer ${auth?.token}`)
      }
      return headers
    },
  }),
  tagTypes: ['SubUser'],
  endpoints: (build) => ({
    getSubUsers: build.query<any, any>({
      query: ({ limit = 10, page = 1 }) => ({
        url: `getAll?page=${page}&limit=${limit}`,
        method: 'GET',
      }),
      providesTags: ['SubUser'],
    }),
    getSubUser: build.query<any, any>({
      query: ({ id }) => `get/${id}`,
      providesTags: ['SubUser'],
    }),
    addSubUser: build.mutation<any, any>({
      query: (formData) => ({
        url: `create`,
        method: 'POST',
        body: formData,
      }),
      onQueryStarted: (arg, api) => {
        api.queryFulfilled.then(() => {
          api.dispatch(dashboardApi.util.invalidateTags(['Dashboard']))
        }).catch(err => toast.error(err?.message))
      },
      invalidatesTags: () => [{ type: 'SubUser' }],
    }),
    sendEmailSubUser: build.mutation<any, any>({
      query: (id) => ({
        url: `send-email-user`,
        method: 'POST',
        body: { id },
      }),
      invalidatesTags: () => [{ type: 'SubUser' }],
    }),
    deleteSubUser: build.mutation<any, any>({
      query: (id) => ({
        url: `delete`,
        method: 'DELETE',
        body: { id },
      }),
      onQueryStarted: (arg, api) => {
        api.queryFulfilled.then(() => {
          api.dispatch(dashboardApi.util.invalidateTags(['Dashboard']))
        })
      },
      invalidatesTags: () => [{ type: 'SubUser' }],
    }),
    updateSubUser: build.mutation<any, { id: any; formData: FormData }>({
      query: ({ id, formData }) => ({
        url: `update/${id}`,
        method: 'PUT',
        body: formData,
      }),
      invalidatesTags: ['SubUser'],
    }),

    deleteProfileImage: build.mutation<any, any>({
      query: () => ({
        url: `delete-image`,
        method: 'DELETE',
      }),
      invalidatesTags: ['SubUser'],
    }),
  }),
})

export const {
  useGetSubUsersQuery,
  useGetSubUserQuery,
  useAddSubUserMutation,
  useSendEmailSubUserMutation,
  useDeleteSubUserMutation,
  useUpdateSubUserMutation,
  useDeleteProfileImageMutation,
} = subUserApi
