import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import ConfirmationPopup from '../../../../../../../_metronic/helpers/components/ConfirmationPopup'
import { postMaintenanceData, getMaintenance } from '../../Core/_requests'
import { MaintenanceData, CustomField, Field } from '../../Core/_models'
import { getAuth } from '../../../../../Auth'
import { Link } from 'react-router-dom'

const MaintenanceList = () => {
  const auth = getAuth()
  const [deleteModal, setDeleteModal] = useState<boolean>(false)
  const [deleteSelected, setDeleteSelected] = useState<number>(0)
  const [maintenanceData, setMaintenanceData] = useState<MaintenanceData>({
    hasDetails: false,
    isDetailsRequired: false,
    hasDueDate: false,
    hasMaintenanceBy: false,
    isMaintenanceByRequired: false,
    hasMaintenanceStatus: false,
    isMaintenanceStatusRequired: false,
    isDueDateRequired: false,
    hasDateCompleted: false,
    isDateCompletedRequired: false,
    hasCostOfRepairs: false,
    isCostOfRepairsRequired: false,
    hasRepeating: false,
    isRepeatingRequired: false,
    categoryList: '',
    customFields: [] || true,
  })
  const [customFieldlabel, setCustomFieldlabel] = useState<string>('')
  const [customFieldlabelError, setCustomFieldlabelError] = useState<string>('')
  const [option, setOption] = useState<string>('')
  const [optionError, setOptionError] = useState<string>('')
  const [isRequired, setIsRequired] = useState<boolean>(true)
  const [listOptions, setListOptions] = useState<string>('')
  const [listOptionError, setListOptionError] = useState<string>('')
  const [fields, setFields] = useState<{ id: number; value: string }[]>([])
  const [fieldValues, setFieldValues] = useState<string[]>([])
  const [editMaintenanceIndex, setEditMaintenanceIndex] = useState<number>()
  const [formSubmitted, setFormSubmitted] = useState<boolean>(false)
  const [loading, setLoading] = useState(false)

  const handleCustomFieldLabel = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value
    if (inputValue === '' || inputValue.match(/^ *$/)) {
      setCustomFieldlabel('')
    } else {
      setCustomFieldlabel(inputValue)
    }
  }
  const handleOptionChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setOption(event.target.value)
  }

  const handleIsRequiredChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsRequired(event.target.value === 'yes')
  }

  const handleListOptions = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = event.target.value
    if (inputValue === '' || inputValue.match(/^ *$/)) {
      setListOptions('')
    } else {
      setListOptions(inputValue)
    }
  }

  const handleAddCustomField = () => {
    if (!customFieldlabel || !option) {
      setCustomFieldlabelError('Label is required.')
      setOptionError('Data Type is required.')
      setListOptionError(' Option is required.')
      setFormSubmitted(true)
      return
    }
    if (
      (option === 'Checkbox list' ||
        option === 'Dropdown list' ||
        option === 'Radio button list') &&
      !listOptions
    ) {
      console.error('Option is required.')
      setListOptionError('Option is required.')
      setFormSubmitted(true)
      return
    }

    const newCustomField: CustomField = {
      customFieldlabel,
      option,
      isRequired,
      listOptions,
      fields: [...fields],
    }
    if (maintenanceData && maintenanceData.customFields) {
      if (editMaintenanceIndex !== undefined) {
        maintenanceData.customFields[editMaintenanceIndex] = newCustomField
        setMaintenanceData({ ...maintenanceData })
        setEditMaintenanceIndex(undefined)
        toast.success('Custom field updated successfully.')
      } else {
        if (!Array.isArray(maintenanceData.customFields)) {
          maintenanceData.customFields = []
        }
        maintenanceData.customFields.push(newCustomField)
        setMaintenanceData({ ...maintenanceData })
        toast.success('Custom field added successfully.')
      }
      postMaintenanceData(auth?.token, maintenanceData)
        .then((res) => {
          setMaintenanceData(res?.data?.updatedModel)
        })
        .catch((error) => {
          console.error(error)
        })

      setCustomFieldlabelError('')
      setOptionError('')
      setOption('')
      setListOptions('')
      setCustomFieldlabel('')
      setListOptions('')
      setOption('')
      setFields([])
      setFormSubmitted(false)
    } else {
      console.error('maintenanceData is not defined or has no customFields')
    }
  }

  useEffect(() => { }, [maintenanceData])

  const addNewField = () => {
    const newField: Field = { id: Date.now(), value: '' }
    setFields([...fields, newField])
  }

  const removeField = (index: number) => {
    const newFields = [...fields]
    newFields.splice(index, 1)
    setFields(newFields)
  }

  const handleChange = (index: number, value: string) => {
    const newFields = [...fields]
    newFields[index] = { ...newFields[index], value }
    setFields(newFields)
    const newFieldValues = [...fieldValues]
    newFieldValues[index] = value
    setFieldValues(newFieldValues)
  }

  function selectAllCheckboxes(event: React.ChangeEvent<HTMLInputElement>): void {
    const isChecked = event.target.checked
    setMaintenanceData({
      ...maintenanceData,
      hasDetails: isChecked,
      hasMaintenanceBy: isChecked,
      hasDateCompleted: isChecked,
      hasCostOfRepairs: isChecked,
    })
  }

  const handleCheckboxChange = (checkboxName: string, isChecked: boolean) => {
    if (maintenanceData) {
      if (checkboxName === 'hasDetails') {
        if (isChecked) {
          setMaintenanceData({
            ...maintenanceData,
            hasDetails: isChecked,
            isDetailsRequired: true,
          })
        } else {
          setMaintenanceData({
            ...maintenanceData,
            hasDetails: isChecked,
            isDetailsRequired: false,
          })
        }
      } else if (checkboxName === 'isDetailsRequired') {
        if (!isChecked) {
          setMaintenanceData({
            ...maintenanceData,
            isDetailsRequired: false,
          })
        } else {
          setMaintenanceData({
            ...maintenanceData,
            isDetailsRequired: true,
          })
        }
      } else if (checkboxName === 'hasMaintenanceBy') {
        if (isChecked) {
          setMaintenanceData({
            ...maintenanceData,
            hasMaintenanceBy: isChecked,
            isMaintenanceByRequired: true,
          })
        } else {
          setMaintenanceData({
            ...maintenanceData,
            hasMaintenanceBy: isChecked,
            isMaintenanceByRequired: false,
          })
        }
      } else if (checkboxName === 'isMaintenanceByRequired') {
        if (!isChecked) {
          setMaintenanceData({
            ...maintenanceData,
            isMaintenanceByRequired: false,
          })
        } else {
          setMaintenanceData({
            ...maintenanceData,
            isMaintenanceByRequired: true,
          })
        }
      } else if (checkboxName === 'hasDateCompleted') {
        if (isChecked) {
          setMaintenanceData({
            ...maintenanceData,
            hasDateCompleted: isChecked,
            isDateCompletedRequired: true,
          })
        } else {
          setMaintenanceData({
            ...maintenanceData,
            hasDateCompleted: isChecked,
            isDateCompletedRequired: false,
          })
        }
      } else if (checkboxName === 'isDateCompletedRequired') {
        if (!isChecked) {
          setMaintenanceData({
            ...maintenanceData,
            isDateCompletedRequired: false,
          })
        } else {
          setMaintenanceData({
            ...maintenanceData,
            isDateCompletedRequired: true,
          })
        }
      } else if (checkboxName === 'hasCostOfRepairs') {
        if (isChecked) {
          setMaintenanceData({
            ...maintenanceData,
            hasCostOfRepairs: isChecked,
            isCostOfRepairsRequired: true,
          })
        } else {
          setMaintenanceData({
            ...maintenanceData,
            hasCostOfRepairs: isChecked,
            isCostOfRepairsRequired: false,
          })
        }
      } else if (checkboxName === 'isCostOfRepairsRequired') {
        if (!isChecked) {
          setMaintenanceData({
            ...maintenanceData,
            isCostOfRepairsRequired: false,
          })
        } else {
          setMaintenanceData({
            ...maintenanceData,
            isCostOfRepairsRequired: true,
          })
        }
      } else if (checkboxName === 'isDueDateRequired') {
        if (isChecked) {
          setMaintenanceData({
            ...maintenanceData,
            isDueDateRequired: true,
          })
        } else {
          setMaintenanceData({
            ...maintenanceData,
            isDueDateRequired: false,
          })
        }
      } else if (checkboxName === 'isMaintenanceStatusRequired') {
        if (isChecked) {
          setMaintenanceData({
            ...maintenanceData,
            isMaintenanceStatusRequired: true,
          })
        } else {
          setMaintenanceData({
            ...maintenanceData,
            isMaintenanceStatusRequired: false,
          })
        }
      } else {
        console.warn('Unexpected checkbox name:', checkboxName)
      }
    }
  }

  useEffect(() => {
    getMaintenance(auth?.token).then((res) => {
      setMaintenanceData(res?.data?.maintenance)
    })
  }, [])

  useEffect(() => { }, [maintenanceData])

  const submit = (event?: React.FormEvent<HTMLFormElement>): void => {
    if (event) {
      event?.preventDefault()
    }
    setLoading(true)
    postMaintenanceData(auth?.token, maintenanceData)
      .then((res) => {
        setMaintenanceData(res?.data?.updatedModel)
        toast.success('Database updated successfully')
        setLoading(false)
      })
      .catch((error) => {
        console.error(error)
      })
  }

  const cancel = () => {
    setDeleteModal(false)
  }
  const confirm = async () => {
    maintenanceData.customFields.splice(deleteSelected, 1)
    setMaintenanceData({ ...maintenanceData })
    postMaintenanceData(auth?.token, maintenanceData)
      .then((res) => {
        setMaintenanceData(res.data.updatedModel)
        toast.success('Custom Field deleted successfully ')
      })
      .catch((error) => {
        console.error(error)
        toast.error('Custom Field does not deleted')
      })
    setDeleteModal(false)
  }

  const EditHandler = async (index: number) => {
    const customField = maintenanceData.customFields[index]
    setCustomFieldlabel(customField.customFieldlabel)
    setOption(customField.option || '')
    setIsRequired(customField.isRequired === true ? true : false)
    setListOptions(customField.listOptions || '')
    setFields(customField.fields || [])
    setEditMaintenanceIndex(index)
  }
  const resetFormFields = () => {
    setCustomFieldlabel('')
    setOption('')
    setListOptions('')
    setFields([])
  }

  const handleClose = () => {
    resetFormFields()
    setEditMaintenanceIndex(undefined)
  }

  useEffect(() => { }, [maintenanceData])

  return (
    <>
      <div className='form-title d-flex'>
        <span className='mt-1 Database'>Database </span>
        <span className='Personal/Employee'>Maintenance</span>
      </div>
      <div className='card'>
        <ConfirmationPopup deleteModal={deleteModal} cancel={cancel} confirm={confirm} />
        <div id='database_ajax_wrapper'>
          <form onSubmit={submit}>
            <input
              id='__RequestVerificationToken'
              name='__RequestVerificationToken'
              type='hidden'
              value='bi5mAQGArgtf83vcFjyPEGK8rKsZ-9D3G-eRhsWQ-rTaCYjXK6gswp10gSiUZu3dXYXV2Zm9_cHp6yxRZbnLhFj5ZuJQOiJIAWnGosU6DIm2Zzc3Qcl40A_U_bv_VIr8fFJjrA2'
            />{' '}
            <div className='card-body'>
              <div className='form_section'>
                <div className='form-title d-flex'>
                  <span className='me-2'>
                    <svg
                      version='1.1'
                      xmlns='http://www.w3.org/2000/svg'
                      width='24'
                      height='24'
                      viewBox='0 0 32 32'
                      fill='#c30'
                    >
                      <path
                        fill='#9b9b9b'
                        d='M30.16 23.8l-2.667-2.667 2.667-2.667-1.227-1.133-2.667 2.667-2.667-2.667-1.187 1.173 2.667 2.667-2.667 2.667 1.173 1.173 2.667-2.667 2.667 2.667z'
                      ></path>
                      <path
                        fill='#9b9b9b'
                        d='M29.613 6.973l-4.507 4.613-2.107-2-1.48 1.493 3.587 3.48 6-6.107z'
                      ></path>
                      <path
                        fill='#9b9b9b'
                        d='M0.893 6.973v7.693h19.4v-7.693zM18.307 12.667h-15.427v-3.68h15.427z'
                      ></path>
                      <path
                        fill='#9b9b9b'
                        d='M0.893 17.333v7.707h19.4v-7.707zM18.307 23.027h-15.427v-3.693h15.427z'
                      ></path>
                    </svg>
                  </span>
                  <h4 className='mt-1'>Maintenance Standard Fields</h4>
                </div>
                <div className='mb-sm-6 mb-4'>
                  <p>Select the fields you would like to use for the maintenance table.</p>
                  <div className='table-responsive'>
                    <table
                      className='table table-bordered mb-0'
                      aria-label='Persons/Employees Standard Fields'
                    >
                      <thead className='thead-light  light-yellow-table'>
                        <tr>
                          <th className='w-2'>
                            <label className='checkbox has_nolabel m-auto' htmlFor='field_all'>
                              {maintenanceData && (
                                <input
                                  checked={
                                    maintenanceData.hasDetails &&
                                    maintenanceData.hasMaintenanceBy &&
                                    maintenanceData.hasDateCompleted &&
                                    maintenanceData.hasCostOfRepairs
                                  }
                                  onChange={selectAllCheckboxes}
                                  className='custom-form-check-input form-check-input sig_input'
                                  type='checkbox'
                                  id='field_all'
                                />
                              )}
                              <span className='sr-only'>Field All</span>
                            </label>
                          </th>
                          <th className='w-20'>Field name </th>
                          <th className='w-20'>Data Required </th>
                          <th>Description </th>
                          <th className='w-20'>Example </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>
                            <label className='checkbox has_nolabel m-auto' htmlFor='title'>
                              <input
                                checked={true}
                                className='fieldDisabledCheck custom-form-check-input form-check-input sig_input'
                                data-val='true'
                                data-val-required='Title is required.'
                                disabled={true}
                                type='checkbox'
                                value='true'
                                id='title'
                              />
                              <span className='sr-only'>Field</span>
                            </label>
                          </td>
                          <td className='fw-bolder text-nowrap'>
                            Title<span className='required'></span>
                          </td>
                          <td>
                            <div className='radio-inline'>
                              <label className='radio mb-0' htmlFor='isTitleRequired'>
                                <input
                                  id='isTitleRequired'
                                  name='isTitleRequired'
                                  type='checkbox'
                                  className='custom-form-check-input form-check-input sig_input m_5'
                                  checked={true}
                                />
                                Yes
                              </label>
                            </div>
                          </td>
                          <td>Title of the maintenance.</td>
                          <td>Monthly Calibration</td>
                        </tr>
                        <tr>
                          <td>
                            <label className='checkbox has_nolabel m-auto' htmlFor='hasDetail'>
                              <input
                                checked={maintenanceData?.hasDetails}
                                onChange={(e) =>
                                  handleCheckboxChange('hasDetails', e.target.checked)
                                }
                                className='fieldCheck custom-form-check-input form-check-input sig_input'
                                data-val='true'
                                data-val-required='Details is required.'
                                id='hasDetail'
                                name='hasDetail'
                                type='checkbox'
                                value='true'
                              />
                              <input name='hasDetail' type='hidden' value='false' />
                              <span className='sr-only'>Field</span>
                            </label>
                          </td>
                          <td className='fw-bolder text-nowrap'>Details</td>
                          <td>
                            {maintenanceData?.hasDetails && (
                              <div className='radio-inline standard_radio_can_hide hasDetail_radio d-flex'>
                                <label className='radio mb-0 p_16' htmlFor='isDetailRequired_yes'>
                                  <input
                                    id='isDetailRequired_yes'
                                    name='isDetailsRequired'
                                    type='checkbox'
                                    value='yes'
                                    className='custom-form-check-input form-check-input sig_input m_5'
                                    checked={maintenanceData.isDetailsRequired}
                                    onChange={() => handleCheckboxChange('isDetailsRequired', true)}
                                  />{' '}
                                  Yes
                                </label>
                                <label className='radio mb-0' htmlFor='isDetailRequired_Optional'>
                                  <input
                                    id='isDetailRequired_Optional'
                                    name='isDetailsRequired'
                                    type='checkbox'
                                    value='Optional'
                                    className='custom-form-check-input form-check-input sig_input m_5'
                                    checked={!maintenanceData.isDetailsRequired}
                                    onChange={() =>
                                      handleCheckboxChange('isDetailsRequired', false)
                                    }
                                  />
                                  Optional
                                </label>
                              </div>
                            )}
                          </td>
                          <td>Details of the maintenance</td>
                          <td>Calibrate to 120 units</td>
                        </tr>
                        <tr>
                          <td>
                            <label className='checkbox has_nolabel m-auto' htmlFor='dueDate'>
                              <input
                              id='dueDate'
                                checked={true}
                                className='fieldDisabledCheck custom-form-check-input form-check-input sig_input'
                                data-val='true'
                                data-val-required='Due Date is required.'
                                disabled={true}
                                type='checkbox'
                                value='true'
                              />
                              <span className='sr-only'>Field</span>
                            </label>
                          </td>
                          <td className='fw-bolder text-nowrap'>Due Date</td>
                          <td>
                            <div className='radio-inline hasDueDate_radio'>
                              <label className='radio mb-0 p_16' htmlFor='isDueDateRequired_True'>
                                <input
                                  id='isDueDateRequired_True'
                                  name='isDueDateRequired'
                                  type='checkbox'
                                  value='True'
                                  className='custom-form-check-input form-check-input sig_input m_5'
                                  checked={maintenanceData?.isDueDateRequired}
                                  onChange={() => handleCheckboxChange('isDueDateRequired', true)}
                                />
                                Yes
                              </label>
                              <label className='radio mb-0' htmlFor='isDueDateRequired_False'>
                                <input
                                  id='isDueDateRequired_False'
                                  name='isDueDateRequired'
                                  type='checkbox'
                                  value='False'
                                  className='custom-form-check-input form-check-input sig_input m_5'
                                  checked={!maintenanceData?.isDueDateRequired}
                                  onChange={() => handleCheckboxChange('isDueDateRequired', false)}
                                />
                                Optional
                              </label>
                            </div>
                          </td>
                          <td>Date when maintenance is due</td>
                          <td>3/5/2020</td>
                        </tr>
                        <tr>
                          <td>
                            <label className='checkbox has_nolabel m-auto' htmlFor='hasAssignTo'>
                              <input
                                checked={maintenanceData?.hasMaintenanceBy ?? false}
                                onChange={(e) =>
                                  handleCheckboxChange('hasMaintenanceBy', e.target.checked)
                                }
                                className='fieldCheck custom-form-check-input form-check-input sig_input'
                                data-val='true'
                                data-val-required='Maintenance By is required.'
                                id='hasAssignTo'
                                name='hasAssignTo'
                                type='checkbox'
                                value='true'
                              />
                              <input name='hasAssignTo' type='hidden' value='false' />
                              <span className='sr-only'>Field</span>
                            </label>
                          </td>
                          <td className='fw-bolder text-nowrap'>Maintenance By</td>
                          <td>
                            {maintenanceData?.hasMaintenanceBy && (
                              <div className='radio-inline standard_radio_can_hide hasAssignTo_radio d-flex'>
                                <label className='radio mb-0 p_16' htmlFor='isAssignToRequired_True'>
                                  <input
                                    id='isAssignToRequired_True'
                                    name='isMaintenanceByRequired'
                                    type='checkbox'
                                    value='True'
                                    className='custom-form-check-input form-check-input sig_input m_5'
                                    checked={maintenanceData?.isMaintenanceByRequired}
                                    onChange={() =>
                                      handleCheckboxChange('isMaintenanceByRequired', true)
                                    }
                                  />{' '}
                                  Yes
                                </label>
                                <label className='radio mb-0' htmlFor='isAssignToRequired_False'>
                                  <input
                                    id='isAssignToRequired_False'
                                    name='isMaintenanceByRequired'
                                    type='checkbox'
                                    value='False'
                                    className='custom-form-check-input form-check-input sig_input m_5'
                                    checked={!maintenanceData?.isMaintenanceByRequired}
                                    onChange={() =>
                                      handleCheckboxChange('isMaintenanceByRequired', false)
                                    }
                                  />
                                  Optional
                                </label>
                              </div>
                            )}
                          </td>
                          <td>Person doing maintenance</td>
                          <td>John Doe</td>
                        </tr>
                        <tr>
                          <td>
                            <label className='checkbox has_nolabel m-auto' htmlFor='status'>
                              <input
                              id='status'
                                checked={true}
                                className='fieldDisabledCheck custom-form-check-input form-check-input sig_input'
                                data-val='true'
                                data-val-required='Status is required.'
                                disabled={true}
                                type='checkbox'
                                value='true'
                              />
                              <span className='sr-only'>Field</span>
                            </label>
                          </td>
                          <td className='fw-bolder text-nowrap'>Maintenance Status</td>
                          <td>
                            <div className='radio-inline hasStatus_radio'>
                              <label className='radio mb-0 p_16' htmlFor='isStatusRequired_True'>
                                <input
                                  id='isStatusRequired_True'
                                  name='isMaintenanceStatusRequired'
                                  type='checkbox'
                                  value='True'
                                  className='custom-form-check-input form-check-input sig_input m_5'
                                  checked={maintenanceData?.isMaintenanceStatusRequired}
                                  onChange={() => handleCheckboxChange('isMaintenanceStatusRequired', true)}
                                />{' '}
                                Yes
                              </label>
                              <label className='radio mb-0' htmlFor='isStatusRequired_False'>
                                <input
                                  id='isStatusRequired_False'
                                  name='isMaintenanceStatusRequired'
                                  type='checkbox'
                                  value='False'
                                  className='custom-form-check-input form-check-input sig_input m_5'
                                  checked={!maintenanceData?.isMaintenanceStatusRequired}
                                  onChange={() => handleCheckboxChange('isMaintenanceStatusRequired', false)}
                                />
                                Optional
                              </label>
                            </div>
                          </td>
                          <td>
                            System field to show current status of the maintenance. The possible
                            values are Scheduled, In progress, On Hold, Cancelled, Completed.
                          </td>
                          <td>Scheduled</td>
                        </tr>
                        <tr>
                          <td>
                            <label className='checkbox has_nolabel m-auto' htmlFor='hasDateComplete'>
                              <input
                                checked={maintenanceData?.hasDateCompleted ?? false}
                                onChange={(e) => handleCheckboxChange('hasDateCompleted', e.target.checked)}
                                className='fieldCheck custom-form-check-input form-check-input sig_input'
                                data-val='true'
                                data-val-required='Date Completed is required.'
                                id='hasDateComplete'
                                name='hasDateComplete'
                                type='checkbox'
                                value='true'
                              />
                              <input name='hasDateComplete' type='hidden' value='false' />
                              <span className='sr-only'>Field</span>
                            </label>
                          </td>
                          <td className='fw-bolder text-nowrap'>Date Completed</td>
                          <td>
                            {maintenanceData?.hasDateCompleted && (
                              <div className='radio-inline standard_radio_can_hide hasDateComplete_radio d-flex'>
                                <label className='radio mb-0 p_16' htmlFor='isDateCompleteRequired_True'>
                                  <input
                                    id='isDateCompleteRequired_True'
                                    name='isDateCompletedRequired'
                                    type='checkbox'
                                    value='True'
                                    className='custom-form-check-input form-check-input sig_input m_5'
                                    checked={maintenanceData?.isDateCompletedRequired}
                                    onChange={() => handleCheckboxChange('isDateCompletedRequired', true)}
                                  />{' '}
                                  Yes
                                </label>
                                <label className='radio mb-0' htmlFor='isDateCompleteRequired_False'>
                                  <input
                                    id='isDateCompleteRequired_False'
                                    name='isDateCompletedRequired'
                                    type='checkbox'
                                    value='False'
                                    className='custom-form-check-input form-check-input sig_input m_5'
                                    checked={!maintenanceData.isDateCompletedRequired}
                                    onChange={() => handleCheckboxChange('isDateCompletedRequired', false)}
                                  />{' '}
                                  Optional
                                </label>
                              </div>
                            )}
                          </td>
                          <td>Date when maintenance is completed</td>
                          <td>3/5/2020</td>
                        </tr>
                        <tr>
                          <td>
                            <label className='checkbox has_nolabel m-auto' htmlFor='hasRepairCost'>
                              <input
                                checked={maintenanceData?.hasCostOfRepairs ?? false}
                                onChange={(e) => handleCheckboxChange('hasCostOfRepairs', e.target.checked)}
                                className='fieldCheck custom-form-check-input form-check-input sig_input'
                                data-val='true'
                                data-val-required='Cost of Repairs is required.'
                                id='hasRepairCost'
                                name='hasRepairCost'
                                type='checkbox'
                                value='true'
                              />
                              <input name='hasRepairCost' type='hidden' value='false' />
                              <span className='sr-only'>Field</span>
                            </label>
                          </td>
                          <td className='fw-bolder text-nowrap'>Cost of Repairs</td>
                          <td>
                            {maintenanceData?.hasCostOfRepairs && (
                              <div className='radio-inline standard_radio_can_hide hasRepairCost_radio d-flex'>
                                <label className='radio mb-0 p_16' htmlFor='isRepairCostRequired_True'>
                                  <input
                                    id='isRepairCostRequired_True'
                                    name='isRepairCostRequired'
                                    type='checkbox'
                                    value='True'
                                    className='custom-form-check-input form-check-input sig_input m_5'
                                    checked={maintenanceData?.isCostOfRepairsRequired}
                                    onChange={() => handleCheckboxChange('isCostOfRepairsRequired', true)}
                                  />{' '}
                                  Yes
                                </label>
                                <label className='radio mb-0' htmlFor='isRepairCostRequired_False'>
                                  <input
                                    id='isRepairCostRequired_False'
                                    name='isRepairCostRequired'
                                    type='checkbox'
                                    value='False'
                                    className='custom-form-check-input form-check-input sig_input m_5'
                                    checked={!maintenanceData?.isCostOfRepairsRequired}
                                    onChange={() => handleCheckboxChange('isCostOfRepairsRequired', false)}
                                  />
                                  Optional
                                </label>
                              </div>
                            )}
                          </td>
                          <td>Total cost spent on this maintenance</td>
                          <td>$97.50</td>
                        </tr>
                        <tr>
                          <td>
                            <label className='checkbox has_nolabel m-auto' htmlFor='isRepeating'>
                              <input
                                id='isRepeating'
                                checked={true}
                                className='fieldCheck custom-form-check-input form-check-input sig_input'
                                data-val='true'
                                data-val-required='Repeating is required.'
                                disabled={true}
                                type='checkbox'
                                value='true'
                              />
                              <span className='sr-only'>Field</span>
                            </label>
                          </td>
                          <td className='fw-bolder text-nowrap'>Repeating</td>
                          <td>
                            <div className='radio-inline'>
                              <label className='radio mb-0' htmlFor='isOptionYes'>
                                <input
                                  id='isOptionYes'
                                  type='checkbox'
                                  className='custom-form-check-input form-check-input sig_input m_5'
                                  checked={true}
                                />
                                Yes
                              </label>
                            </div>
                          </td>
                          <td>System fields to define repeating maintenances</td>
                          <td></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div className='form_section' id='custom_ajax_wrapper'>
                <div className='separator separator-solid mb-6'></div>
                <div className='form-title d-flex'>
                  <span className='me-2'>
                    <svg
                      version='1.1'
                      xmlns='http://www.w3.org/2000/svg'
                      width='24'
                      height='24'
                      viewBox='0 0 32 32'
                      fill='#c30'
                    >
                      <path
                        fill='#9b9b9b'
                        d='M30.16 23.8l-2.667-2.667 2.667-2.667-1.227-1.133-2.667 2.667-2.667-2.667-1.187 1.173 2.667 2.667-2.667 2.667 1.173 1.173 2.667-2.667 2.667 2.667z'
                      ></path>
                      <path
                        fill='#9b9b9b'
                        d='M29.613 6.973l-4.507 4.613-2.107-2-1.48 1.493 3.587 3.48 6-6.107z'
                      ></path>
                      <path
                        fill='#9b9b9b'
                        d='M0.893 6.973v7.693h19.4v-7.693zM18.307 12.667h-15.427v-3.68h15.427z'
                      ></path>
                      <path
                        fill='#9b9b9b'
                        d='M0.893 17.333v7.707h19.4v-7.707zM18.307 23.027h-15.427v-3.693h15.427z'
                      ></path>
                    </svg>
                  </span>
                  <h4 className='mt-1'>Maintenance Custom Fields</h4>
                </div>
                <div className='mb-sm-6 mb-4'>
                  <p>Add custom fields to join the standard fields that we provided.</p>
                  <div className='form_section'>
                    <div className='mb-4'>
                      {' '}
                      <Link
                        data-bs-toggle='modal'
                        to='#addEditFieldBox'
                        className='btn btn-light-primary'
                        onClick={handleClose}
                      >
                        {' '}
                        <i className='fa fa-plus' aria-hidden='true'></i> Add Custom Field{' '}
                      </Link>{' '}
                    </div>
                    <div className='table-responsive pb-4 custom-table'>
                      <table
                        className='table table-bordered table-sm vertical_middle mb-0'
                        id='custom_fields_table'
                        aria-label='Asset Custom Fields'
                      >
                        <thead className='thead-light light-yellow-table'>
                          {maintenanceData?.customFields?.length > 0 ? (
                            <tr>
                              <th> Field Name </th>
                              <th> Data Type </th>
                              <th>Required </th>
                              <th className='text-center w-80'> Edit </th>
                              <th className='text-center w-95 td-delete'> Delete </th>
                            </tr>
                          ) : (
                            ''
                          )}
                        </thead>
                        {maintenanceData?.customFields?.map?.((items: any, index: number) => {
                          return (
                            <tbody>
                              <tr>
                                <td className='fw-bolder'>{items?.customFieldlabel}</td>
                                <td> {items?.option} </td>
                                <td>{items && items.isRequired === true ? 'yes' : 'optional'}</td>
                                <td className='text-nowrap'>
                                  <Link
                                    onClick={() => {
                                      EditHandler(index)
                                      setEditMaintenanceIndex(index)
                                    }}
                                    data-bs-toggle='modal'
                                    to='#addEditFieldBox'
                                    className='btn btn-light-primary main-btn-style btn-sm'
                                  >
                                    <i className='fa fa-pencil-alt fs-7' aria-hidden='true'></i>
                                    Edit
                                  </Link>
                                </td>
                                <td className='text-nowrap'>
                                  <span
                                    onClick={() => {
                                      setDeleteModal(true)
                                      setDeleteSelected(index)
                                    }}
                                    className='confirmDeleteCustomField btn btn-light-primary main-btn-style btn-sm'
                                  >
                                    <i className='la la-trash fs-4' aria-hidden='true'></i>
                                    Delete
                                  </span>
                                </td>
                              </tr>
                            </tbody>
                          )
                        })}
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='card-footer right notForWizard'>
              <div className='row'>
                <div className='col-md-12 text-end'>
                  <Link
                    to='/apps/setup/database/maintenance'
                    className='btn btn-light-primary   main-btn-style me-2'
                  >
                    Cancel
                  </Link>
                  <button type='submit' className='btn btn-primary main-btn-style' disabled={loading}>
                    {!loading && 'Submit'}
                    {loading && (
                      <span className='indicator-progress d-block'>
                        Please wait...{' '}
                        <span className='spinner-border spinner-border-sm align-middle'></span>
                      </span>
                    )}
                  </button>
                </div>
              </div>
            </div>
            <input id='hasAssetDescription' name='hasAssetDescription' type='hidden' value='True' />
          </form>
          <form
            action='/assettiger/database/AddField?Length=8'
            className='form-horizontal'
            data-ajax='true'
            data-ajax-begin='javascript: funCustomFormSubmitBegin();'
            data-ajax-method='Post'
            data-ajax-mode='replace'
            data-ajax-success='javascript: funCustomFormSubmited();'
            data-ajax-update='#custom_ajax_wrapper'
            id='formCustomField'
            method='post'
            role='form'
          >
            <input
              name='__RequestVerificationToken'
              type='hidden'
              value='P6Drb77htOawFFsvekNgHfWBWhq60oDpCoubbGMBsqUDjl2iAaD8QBR9--EiX9PwaCFSBKs_1KJeo_cU8xCFJxl-CxmIsOWHGs6UMfZP8TvVLl9DSBSzMsLIvq24QW2RmsX0tQ2'
            />
            <div
              className='modal fade'
              id='addEditFieldBox'
              tabIndex={-1}
              role='dialog'
              aria-hidden='true'
            >
              <div className='modal-dialog modal-dialog-scrollable mw-md-600px'>
                <div className='modal-content'>
                  <div className='modal-header'>
                    <h4 className='modal-title'>Add Custom field</h4>
                    <button
                      type='button'
                      className='btn-close'
                      data-bs-dismiss='modal'
                      onClick={handleClose}
                      aria-hidden='true'
                      aria-label='close'
                    ></button>
                  </div>
                  <div className='modal-body form'>
                    <div className='custom-field-details'>
                      <div className='form-group row'>
                        <label className='col-sm-4 col-form-label text-sm-end required' htmlFor='AddEditCustomFieldModel_Label' > Custom Field Label </label>
                        <div className='col-sm-7'>
                          <input
                            className='form-control input-medium border border-black'
                            data-val='true'
                            data-val-length='Maximum length is 100.'
                            data-val-length-max='100'
                            data-val-required='Label is required.'
                            id='AddEditCustomFieldModel_Label'
                            maxLength={100}
                            name='AddEditCustomFieldModel.Label'
                            type='text'
                            value={customFieldlabel}
                            onChange={handleCustomFieldLabel}
                          />
                          {customFieldlabel === '' && (
                            <div className='text-danger'>{customFieldlabelError}</div>
                          )}
                          <span
                            className='field-validation-valid'
                            data-valmsg-for='AddEditCustomFieldModel.Label'
                            data-valmsg-replace='true'
                          ></span>
                        </div>
                      </div>
                      <div className='form-group row mt-5'>
                        <label className='col-sm-4 col-form-label text-sm-end' htmlFor='AddEditCustomFieldModel_FieldTypeId' > Data Type<span className='required'></span> </label>
                        <div className='col-sm-7'>
                          <select
                            className='form-control input-medium border border-black'
                            data-val='true'
                            data-val-number='The field Data Type must be a number.'
                            data-val-required='Data Type is required.'
                            id='AddEditCustomFieldModel_FieldTypeId'
                            name='AddEditCustomFieldModel.FieldTypeId'
                            value={option}
                            onChange={handleOptionChange}
                          >
                            <option>Select Data Type</option>
                            <option value='Checkbox list'>Checkbox list</option>
                            <option value='Currency'>Currency</option>
                            <option value='Date'>Date</option>
                            <option value='Date and Time'>Date and Time</option>
                            <option value='Dropdown list'>Dropdown list</option>
                            <option value='Email'>Email</option>
                            <option value='GPS Coordinates'>GPS Coordinates</option>
                            <option value='Memo'>Memo</option>
                            <option value='Numeric'>Numeric</option>
                            <option value='Numeric Auto Increment'>Numeric Auto Increment</option>
                            <option value='Radio button list'>Radio button list</option>
                            <option value='Scanner'>Scanner</option>
                            <option value='Text'>Text</option>
                            <option value='URL'>URL</option>
                          </select>
                          {option === '' && <div className='text-danger'>{optionError}</div>}
                          <span
                            className='field-validation-valid'
                            data-valmsg-for='AddEditCustomFieldModel.FieldTypeId'
                            data-valmsg-replace='true'
                          ></span>
                        </div>
                      </div>
                      <div className='form-group row align-items-center mt-5' id='ValidateType'>
                        <label className='col-sm-4 col-form-label text-sm-end'>Data Required</label>
                        <div className='col-sm-7'>
                          <div className='radio-inline'>
                            <label className='radio mb-0 p_16' htmlFor='isValidate_True'>
                              <input
                                id='isValidate_True'
                                type='checkbox'
                                className='form-check-input custom-form-check-input m_3'
                                value='yes'
                                checked={isRequired === true}
                                onChange={handleIsRequiredChange}
                              />
                              Yes
                            </label>
                            <label className='radio mb-0' htmlFor='isValidate_False'>
                              <input
                                id='isValidate_False'
                                type='checkbox'
                                className='form-check-input custom-form-check-input m_3'
                                value='optional'
                                checked={isRequired === false}
                                onChange={handleIsRequiredChange}
                              />
                              Optional
                            </label>
                          </div>
                        </div>
                      </div>
                      <div className='form-group row DataInput d-none' id='StartingValue'>
                        <label
                          className='col-sm-4 col-form-label text-sm-end'
                          htmlFor='AddEditCustomFieldModel_Counter'
                        >
                          Starting Value
                        </label>
                        <div className='col-sm-7'>
                          <input
                            className='form-control i'
                            data-val='true'
                            data-val-number='The field Starting value must be a number.'
                            id='AddEditCustomFieldModel_Counter'
                            maxLength={10}
                            min='0'
                            name='AddEditCustomFieldModel.Counter'
                            type='text'
                          />
                        </div>
                      </div>
                      {(option === 'Checkbox list' ||
                        option === 'Dropdown list' ||
                        option === 'Radio button list') && (
                          <div className='form-group row DataInput customFieldOption '>
                            <label
                              className='col-sm-4 col-form-label text-sm-end'
                              htmlFor='AddEditCustomFieldModel_Option'
                            >
                              List Options
                            </label>
                            <div className='col-sm-8 input_fields_wrap'>
                              <div>
                                <div className='d-flex'>
                                  <div className='me-2'>
                                    <input
                                      className='form-control input-medium border border-black'
                                      data-val='true'
                                      data-val-length='Maximum length is 1000.'
                                      data-val-length-max='1000'
                                      data-val-required='Option is required.'
                                      id='AddEditCustomFieldModel_Option'
                                      maxLength={1000}
                                      name='AddEditCustomFieldModel.Option'
                                      type='text'
                                      value={listOptions}
                                      onChange={handleListOptions}
                                    />
                                  </div>
                                  <div>
                                    <Link
                                      onClick={addNewField}
                                      to='javascript:void(0)'
                                      className='btn btn-light-primary add_field_button'
                                    >
                                      Add
                                    </Link>
                                  </div>
                                </div>
                                {listOptions === '' && (
                                  <div className='text-danger'>{listOptionError}</div>
                                )}
                              </div>

                              {fields.map((field, index) => (
                                <div
                                  key={field.id}
                                  className='form-inline flex-nowrap mt-3 align-items-center'
                                >
                                  <div>
                                    <input
                                      className='form-control input-medium border border-black me-2'
                                      type='text'
                                      value={field.value}
                                      onChange={(e) => handleChange(index, e.target.value)}
                                    />
                                  </div>
                                  <div>
                                    <Link
                                      to={'/dummy-link'}
                                      className='btn btn-light-primary remove_field'
                                      onClick={() => removeField(index)}
                                    >
                                      Remove
                                    </Link>
                                  </div>
                                </div>
                              ))}
                              <span
                                className='field-validation-valid'
                                data-valmsg-for='AddEditCustomFieldModel.Options'
                                data-valmsg-replace='true'
                              ></span>
                              <span
                                className='field-validation-valid'
                                data-valmsg-for='AddEditCustomFieldModel.Option'
                                data-valmsg-replace='true'
                              ></span>
                            </div>
                          </div>
                        )}
                    </div>
                  </div>
                  <div className='modal-footer'>
                    <button
                      type='button'
                      className='btn btn-light-primary   main-btn-style me-2'
                      data-bs-dismiss='modal'
                      onClick={handleClose}
                    >
                      Cancel
                    </button>
                    <button
                      type='button'
                      onClick={handleAddCustomField}
                      className='btn btn-primary'
                      {...(customFieldlabel &&
                        option &&
                        (listOptions || option !== 'Checkbox list') &&
                        (listOptions || option !== 'Dropdown list') &&
                        (listOptions || option !== 'Radio button list')
                        ? { 'data-bs-dismiss': 'modal' }
                        : {})}
                      value='Save'
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <input
              data-val='true'
              data-val-length='Maximum length is 4000.'
              data-val-length-max='4000'
              id='AddEditCustomFieldModel_Options'
              name='AddEditCustomFieldModel.Options'
              type='hidden'
            />
            <input
              data-val='true'
              data-val-required="Is this field visible to assets of selective 'Categories'?"
              id='AddEditCustomFieldModel_hasCategory'
              name='AddEditCustomFieldModel.hasCategory'
              type='hidden'
              value='False'
            />
            <input
              data-val='true'
              data-val-required='Is this field need to be required?'
              id='AddEditCustomFieldModel_isValidate'
              name='AddEditCustomFieldModel.isValidate'
              type='hidden'
              value='True'
            />
            <input
              data-val='true'
              data-val-number='The field CustomFieldId must be a number.'
              data-val-required='The CustomFieldId field is required.'
              id='AddEditCustomFieldModel_CustomFieldId'
              name='AddEditCustomFieldModel.CustomFieldId'
              type='hidden'
              value='0'
            />
          </form>{' '}
          <div
            className='modal fade'
            id='confirmDeleteCustomField'
            tabIndex={-1}
            role='basic'
            aria-hidden='true'
          >
            <div className='modal-dialog'>
              <div className='modal-content'>
                <div className='modal-header'>
                  <h4 className='modal-title'>Delete Custom field</h4>
                  <button
                    type='button'
                    className='btn-close'
                    data-bs-dismiss='modal'
                    aria-hidden='true'
                    aria-label='close'
                  ></button>
                </div>
                <div className='modal-body'>
                  <p>Are you sure you want to delete this Custom field? </p>
                  <p className='alert alert-danger' id='deleteCustomFieldObjWrapper'></p>
                </div>
                <div className='modal-footer'>
                  <button
                    type='button'
                    className='btn btn-light-primary   main-btn-style me-2'
                    data-bs-dismiss='modal'
                  >
                    Cancel
                  </button>
                  <Link
                    to={'/dummy-link'}
                    className='btn btn-danger'
                    id='confirmDeleteCustomFieldBtn'
                  >
                    Confirm Delete
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default MaintenanceList
