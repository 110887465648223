import { Link } from "react-router-dom"

const CustomersHeader = (props: any) => {
	return (
		<div
			className='card-body d-flex border-bottom border-bottom-gray-200 justify-content-between mb-3 align-items-center flex-wrap'
			data-kt-user-table-toolbar='base'
		>
			<h3 className='card-title align-items-start flex-column mb-xl-0 mb-3 me-3'>
				<span className='me-2 align-middle'>
					<i className='la la-list fs-1' aria-hidden='true'></i>
				</span>
				<span className='card-label fw-bold fs-3 mb-1'>List of Customers</span>
			</h3>

			<div className='d-flex flex-wrap'>
				<button
					type='button'
					className='btn btn-light-primary m-1'
					onClick={() => {
						props.setLoading(false);
						props.handleShow();
						props.setUpdateCustomer(false)
					}}
				>
					<i className='la la-plus fs-3' />
					Add{' '}<span className='d-xl-inline d-none'>New Customer</span>
				</button>
				<Link className='btn btn-light-primary main-btn-style d-flex align-items-center m-1' to={'/import'} state={{ importType: "customers" }}>
					<i className='la la-cloud-upload fs-2 me-1'></i><span>Import</span><span className='d-xl-inline d-none ps-1'>Customers</span>
				</Link>

				<div className='d-flex justify-content-end align-items-center'>
					{props.deleteDataArr.length > 0 && (
						<button
							type='button'
							className='btn btn-danger m-1'
							onClick={() => {
								props.setDeleteModal(true)
								props.setCheck(props.deleteDataArr)
								props.setCustomerId("")
							}}
						>
							Delete Selected ({props.deleteDataArr.length})
						</button>
					)}
				</div>
			</div>
		</div>
	)
}

export { CustomersHeader }
