import { useGetAllCategoriesQuery } from 'App/Modules/Services/Categories'
import { useGetAllDepartmentsQuery } from 'App/Modules/Services/Departments'
import { useGetAllSitesQuery } from 'App/Modules/Services/Sites'
import { useEffect, useState } from 'react'
import { SiteResponse } from '../../Sites/Core/_models'

function AssetsTab({ actionChecked, checked, handleCheckboxChange }) {
    const [assetActions, setAssetActions] = useState(false)
    const [selectAllSites, setSelectAllSites] = useState(checked.allSites)
    const [selectedSites, setSelectedSites] = useState<number[]>([])
    const [selectAllCategory, setSelectAllCategory] = useState(false)
    const [selectedCategories, setSelectedCategories] = useState<number[]>([])
    const [selectAllDepartments, setSelectAllDepartments] = useState(false)
    const [selectedDepartments, setSelectedDepartments] = useState<number[]>([])
    const { data: userCategoryList, isLoading: isLoadingCategories } = useGetAllCategoriesQuery({
        page: 0,
        limit: 0,
    })
    const { data: userSitesList, isLoading } = useGetAllSitesQuery({})
    const { data: userDeparmentList, isLoading: isLoadingGetDepartment } = useGetAllDepartmentsQuery({})

    const handleSiteCheckboxChange = (siteId: number) => {
        const updatedSelectedSites = selectedSites.includes(siteId)
            ? selectedSites.filter((id) => id !== siteId)
            : [...selectedSites, siteId]
        handleCheckboxChange('allSites', false)
        setSelectedSites(updatedSelectedSites)
        const allSitesSelected =
            userSitesList?.sites?.length > 0 && updatedSelectedSites.length === userSitesList.sites.length
        setSelectAllSites(allSitesSelected)
    }
    useEffect(() => {
        if (checked.allSites) {
            setSelectedSites(userSitesList?.sites?.map((site) => site.id) || [])
        }
    }, [checked])

    const handleCategoryCheckboxChange = (categoryId: number) => {
        const updatedSelectedCategories = selectedCategories.includes(categoryId)
            ? selectedCategories.filter((id) => id !== categoryId)
            : [...selectedCategories, categoryId]
        handleCheckboxChange('allCategories', false)
        setSelectedCategories(updatedSelectedCategories)
        const allCategoriesSelected =
            userCategoryList?.category?.length > 0 &&
            updatedSelectedCategories.length === userCategoryList.category.length
        setSelectAllCategory(allCategoriesSelected)
    }
    useEffect(() => {
        if (checked.allCategories) {
            setSelectedCategories(userCategoryList?.category?.map((category) => category.id) || [])
        }
    }, [checked])

    const handleDepartmentCheckbox = (siteId: number) => {
        const updatedSelectedDepartments = selectedDepartments.includes(siteId)
            ? selectedDepartments.filter((id) => id !== siteId)
            : [...selectedDepartments, siteId]
        handleCheckboxChange('allDepartments', false)
        setSelectedDepartments(updatedSelectedDepartments)
        const allDepartmentsSelected =
            userDeparmentList?.department?.length > 0 &&
            updatedSelectedDepartments.length === userDeparmentList?.department?.length
        setSelectAllDepartments(allDepartmentsSelected)
    }
    useEffect(() => {
        if (checked.allDepartments) {
            setSelectedDepartments(
                userDeparmentList?.department?.map((department) => department.id) || []
            )
        }
    }, [checked])

    const handleSelectAll = (type: string) => {
        if (type === 'sites') {
            if (selectAllSites) {
                setSelectedSites([])
            } else {
                setSelectedSites(userSitesList?.sites?.map((site) => site.id) || [])
            }
            setSelectAllSites(!selectAllSites)
            handleCheckboxChange('allSites', !selectAllSites)
        } else if (type === 'departments') {
            if (selectAllDepartments) {
                setSelectedDepartments([])
            } else {
                setSelectedDepartments(
                    userDeparmentList?.department?.map((department) => department.id) || []
                )
            }
            setSelectAllDepartments(!selectAllDepartments)
            handleCheckboxChange('allDepartments', !selectAllDepartments)
        } else if (type === 'allCategories') {
            if (selectAllCategory) {
                setSelectedCategories([])
            } else {
                setSelectedCategories(userCategoryList?.category?.map((category) => category.id) || [])
            }
            setSelectAllCategory(!selectAllCategory)
            handleCheckboxChange('allCategories', !selectAllCategory)
        }
    }

    const onClickAssetActions = () => {
        const newAssetActionsState = !assetActions
        setAssetActions(newAssetActionsState)
        handleCheckboxChange('hasCheckout', newAssetActionsState)
        handleCheckboxChange('hasLease', newAssetActionsState)
        handleCheckboxChange('hasMove', newAssetActionsState)
        handleCheckboxChange('hasMaintenance', newAssetActionsState)
        handleCheckboxChange('hasDispose', newAssetActionsState)
    }
    useEffect(() => {
        if (
            checked.hasCheckout &&
            checked.hasLease &&
            checked.hasMove &&
            checked.hasMaintenance &&
            checked.hasDispose
        ) {
            setAssetActions(true)
        } else {
            setAssetActions(false)
        }
    }, [checked])
    return (
        <>
            <div className='mb-5'>
                <h4 className='mb-3'>View and Edit Assets</h4>
                <p className='mb-5'>Set user's permission to add / edit / delete / view assets:</p>
                <div className='table-responsive mb-5 pb-4'>
                    <table className='table table-bordered mb-4 dataTable'>
                        <thead className='thead-light  light-yellow-table'>
                            <tr>
                                <th className='w-14 align-middle'>
                                    <label className='radio mb-0 d-inline-flex' htmlFor='viewAssets'>
                                        <div>
                                            <input
                                                type='checkbox'
                                                className='form-check-input custom-form-check-input sig_input me-2'
                                                checked={checked?.viewAsset}
                                                id='viewAssets'
                                                name='viewAssets'
                                                onChange={(e) => handleCheckboxChange('viewAsset', e.target.checked)}
                                                value='True'
                                            />
                                        </div>
                                        <span className='vertically_align'>View Only</span>
                                    </label>
                                </th>
                                <th className='w-14 align-middle'>
                                    <label className='radio mb-0 d-inline-flex' htmlFor='addReserve'>
                                        <div>
                                            <input
                                                type='checkbox'
                                                className='form-check-input custom-form-check-input sig_input me-2'
                                                checked={checked?.addReserve}
                                                id='addReserve'
                                                name='hasCheckOut'
                                                onChange={(e) => handleCheckboxChange('addReserve', e.target.checked)}
                                                value='True'
                                            />
                                        </div>
                                        <span className='vertically_align'>Reserve</span>
                                    </label>
                                </th>
                                <th className='w-14 align-middle'>
                                    <label className='radio mb-0 d-inline-flex' htmlFor='addAsset'>
                                        <div>
                                            <input
                                                type='checkbox'
                                                className='form-check-input custom-form-check-input sig_input me-2'
                                                checked={checked?.addAsset}
                                                id='addAsset'
                                                name='hasCheckOut'
                                                onChange={(e) => handleCheckboxChange('addAsset', e.target.checked)}
                                                value='True'
                                            />
                                        </div>
                                        <span className='vertically_align'>Add Assets</span>
                                    </label>
                                </th>
                                <th className='w-14 align-middle'>
                                    <label className='radio mb-0 d-inline-flex' htmlFor='updateAsset'>
                                        <div>
                                            <input
                                                type='checkbox'
                                                className='form-check-input custom-form-check-input sig_input me-2'
                                                checked={checked?.updateAsset}
                                                id='updateAsset'
                                                name='hasCheckOut'
                                                onChange={(e) => handleCheckboxChange('updateAsset', e.target.checked)}
                                                value='True'
                                            />
                                        </div>
                                        <span className='vertically_align'>Edit Assets</span>
                                    </label>
                                </th>
                                <th className='w-14 align-middle'>
                                    <label className='radio mb-0 d-inline-flex' htmlFor='deleteAsset'>
                                        <div>
                                            <input
                                                type='checkbox'
                                                className='form-check-input custom-form-check-input sig_input me-2'
                                                checked={checked?.deleteAsset}
                                                id='deleteAsset'
                                                name='hasCheckOut'
                                                onChange={(e) => handleCheckboxChange('deleteAsset', e.target.checked)}
                                                value='True'
                                            />
                                        </div>
                                        <span className='vertically_align'>Delete Assets</span>
                                    </label>
                                </th>
                                <th className='w-14 align-middle'>
                                    <label className='radio mb-0 d-inline-flex' htmlFor='importAsset'>
                                        <div>
                                            <input
                                                type='checkbox'
                                                className='form-check-input custom-form-check-input sig_input me-2'
                                                checked={checked?.importAsset}
                                                id='importAsset'
                                                name='hasCheckOut'
                                                onChange={(e) => handleCheckboxChange('importAsset', e.target.checked)}
                                                value='True'
                                            />
                                        </div>
                                        <span className='vertically_align'>Import Assets</span>
                                    </label>
                                </th>
                                <th className='w-14 align-middle'>
                                    <label className='radio mb-0 d-inline-flex' htmlFor='exportAsset'>
                                        <div>
                                            <input
                                                type='checkbox'
                                                className='form-check-input custom-form-check-input sig_input me-2'
                                                checked={checked?.exportAsset}
                                                id='exportAsset'
                                                name='hasCheckOut'
                                                onChange={(e) => handleCheckboxChange('exportAsset', e.target.checked)}
                                                value='True'
                                            />
                                        </div>
                                        <span className='vertically_align'>Export Assets</span>
                                    </label>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <div>
                                        <p>User can only view assets.</p>
                                        <label className='radio mb-0 d-inline-flex mb-3' htmlFor='hidePriceInfo'>
                                            <div>
                                                <input
                                                    type='checkbox'
                                                    className='form-check-input custom-form-check-input sig_input me-2'
                                                    checked={checked?.hidePriceInfo}
                                                    id='hidePriceInfo'
                                                    name='hidePriceInfo'
                                                    onChange={(e) => handleCheckboxChange('hidePriceInfo', e.target.checked)}
                                                    value='True'
                                                />
                                            </div>
                                            <span className='vertically_align'>Hide Price info.</span>
                                        </label>
                                        <p>
                                            (This option will also curtail any Editing priviledges allowed elsewhere.)
                                        </p>
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        <p>User can reserve assets.</p>
                                        <label className='radio mb-0 d-inline-flex mb-3' htmlFor='overRideReserve'>
                                            <div>
                                                <input
                                                    type='checkbox'
                                                    className='form-check-input custom-form-check-input sig_input me-2'
                                                    checked={checked?.overRideReserve}
                                                    id='overRideReserve'
                                                    name='overRideReserve'
                                                    onChange={(e) =>
                                                        handleCheckboxChange('overRideReserve', e.target.checked)
                                                    }
                                                    value='True'
                                                />
                                            </div>
                                            <span className='vertically_align'>Override Reservation</span>
                                        </label>
                                        <p>(User can delete or override existing reservation with new reservation.)</p>
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        <p>
                                            User can view and create new assets. User can edit or delete{' '}
                                            <strong>self-created</strong> assets.
                                        </p>
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        <p>
                                            User can view and edit <strong>ANY</strong> asset.
                                        </p>
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        <p>
                                            User can view and delete <strong>ANY</strong> asset.
                                        </p>
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        <p>
                                            User can <strong>Import</strong> asset.
                                        </p>
                                    </div>
                                </td>
                                <td>
                                    <div>
                                        <p>
                                            User can <strong>Export</strong> asset.
                                        </p>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div className='mb-5'>
                <h4 className='mb-3'>Filter Assets</h4>
                <p className='mb-5'>Allow access of assets by site or category :</p>
                <div className='row mb-5'>
                    {userSitesList?.sites?.length > 0 && (
                        <div className='col-lg-4'>
                            <div className='table-responsive mb-4'>
                                <table className='table table-bordered mb-4'>
                                    <thead className='thead-light  light-yellow-table'>
                                        <tr>
                                            <th>
                                                <label className='radio mb-0 d-inline-flex' htmlFor='allSites'>
                                                    <div>
                                                        <input
                                                            type='checkbox'
                                                            className='form-check-input custom-form-check-input sig_input me-2'
                                                            id='allSites'
                                                            name='allSites'
                                                            value='True'
                                                            checked={checked?.allSites || selectAllSites}
                                                            onChange={() => handleSelectAll('sites')}
                                                        />
                                                    </div>
                                                    <span className='vertically_align'>All Sites</span>
                                                </label>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {isLoading ? (
                                            <tr>
                                                <td>Loading...</td>
                                            </tr>
                                        ) : userSitesList?.sites?.length > 0 ? (
                                            userSitesList?.sites?.map((site: SiteResponse, idx: number) => (
                                                <tr key={site.id}>
                                                    <td>
                                                        <div>
                                                            <label
                                                                className='radio mb-0 d-inline-flex'
                                                                htmlFor={`site-${site.id}`}
                                                            >
                                                                <div>
                                                                    <input
                                                                        type='checkbox'
                                                                        className='form-check-input custom-form-check-input sig_input me-2'
                                                                        id={`site-${site.id}`}
                                                                        name='selectedSites'
                                                                        value={site.id}
                                                                        checked={selectedSites.includes(site.id)}
                                                                        onChange={() => handleSiteCheckboxChange(site.id)}
                                                                    />
                                                                </div>
                                                                <span className='vertically_align'>{site?.name}</span>
                                                            </label>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td>No sites found</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    )}
                    {userCategoryList?.category?.length > 0 && (
                        <div className='col-lg-4'>
                            <div className='table-responsive mb-4'>
                                <table className='table table-bordered mb-4'>
                                    <thead className='thead-light  light-yellow-table'>
                                        <tr>
                                            <th>
                                                <label className='radio mb-0 d-inline-flex' htmlFor='allCategories'>
                                                    <div>
                                                        <input
                                                            type='checkbox'
                                                            className='form-check-input custom-form-check-input sig_input me-2'
                                                            id='allCategories'
                                                            name='allCategories'
                                                            value='True'
                                                            checked={checked?.allCategories || selectAllCategory}
                                                            onChange={() => handleSelectAll('allCategories')}
                                                        />
                                                    </div>
                                                    <span className='vertically_align'>All Categories</span>
                                                </label>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {isLoadingCategories ? (
                                            <tr>
                                                <td>Loading...</td>
                                            </tr>
                                        ) : userCategoryList?.category?.length > 0 ? (
                                            <>
                                                {userCategoryList?.category?.map((cat) => {
                                                    return (
                                                        <tr>
                                                            <td>
                                                                <div>
                                                                    <label className='radio mb-0 d-inline-flex' htmlFor='newSites'>
                                                                        <div>
                                                                            <input
                                                                                type='checkbox'
                                                                                className='form-check-input custom-form-check-input sig_input me-2'
                                                                                id={`category-${cat.id}`}
                                                                                name='selectedCategories'
                                                                                value={cat.id}
                                                                                checked={selectedCategories.includes(cat.id)}
                                                                                onChange={() => handleCategoryCheckboxChange(cat.id)}
                                                                            />
                                                                        </div>
                                                                        <span className='vertically_align'>{cat.category}</span>
                                                                    </label>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )
                                                })}
                                            </>
                                        ) : (
                                            <tr>
                                                <td>No category found</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    )}
                    {userDeparmentList?.department?.length > 0 && (
                        <div className='col-lg-4'>
                            <div className='table-responsive mb-4'>
                                <table className='table table-bordered mb-4'>
                                    <thead className='thead-light  light-yellow-table'>
                                        <tr>
                                            <th>
                                                <label className='radio mb-0 d-inline-flex' htmlFor='allDepartments'>
                                                    <div>
                                                        <input
                                                            type='checkbox'
                                                            className='form-check-input custom-form-check-input sig_input me-2'
                                                            id='allDepartments'
                                                            name='hasCheckOut'
                                                            checked={checked?.allDepartments || selectAllDepartments}
                                                            onChange={() => handleSelectAll('departments')}
                                                        />
                                                    </div>
                                                    <span className='vertically_align'>All Departments</span>
                                                </label>
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {isLoadingGetDepartment ? (
                                            <tr>
                                                <td>Loading...</td>
                                            </tr>
                                        ) : userDeparmentList?.department?.length > 0 ? (
                                            userDeparmentList?.department?.map((department) => (
                                                <tr key={department.id}>
                                                    <td>
                                                        <div>
                                                            <label
                                                                className='radio mb-0 d-inline-flex'
                                                                htmlFor={`department-${department.id}`}
                                                            >
                                                                <div>
                                                                    <input
                                                                        type='checkbox'
                                                                        className='form-check-input custom-form-check-input sig_input me-2'
                                                                        id={`department-${department.id}`}
                                                                        name='selectedDepartments'
                                                                        value={department.id}
                                                                        checked={selectedDepartments.includes(department.id)}
                                                                        onChange={() => handleDepartmentCheckbox(department.id)}
                                                                    />
                                                                </div>
                                                                <span className='vertically_align'>{department?.department}</span>
                                                            </label>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))
                                        ) : (
                                            <tr>
                                                <td>No departments found</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    )}
                </div>
                <div className='mb-5'>
                    <h4 className='mb-3'>Actions on Assets</h4>
                    <p className='mb-5'>User can perform these actions on assets:</p>
                    <div className='table-responsive mb-5 pb-4'>
                        <table className='table table-bordered mb-4 dataTable'>
                            <thead className='thead-light  light-yellow-table'>
                                <tr>
                                    <th colSpan={5}>
                                        <label className='radio mb-0 d-inline-flex' htmlFor='allActions'>
                                            <div>
                                                <input
                                                    type='checkbox'
                                                    className='form-check-input custom-form-check-input sig_input me-2'
                                                    id='allActions'
                                                    name='allActions'
                                                    checked={assetActions}
                                                    onClick={onClickAssetActions}
                                                />
                                            </div>
                                            <span className='vertically_align'>All Actions</span>
                                        </label>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <label className='radio mb-0 d-inline-flex' htmlFor='checkout'>
                                            <div>
                                                <input
                                                    type='checkbox'
                                                    className='form-check-input custom-form-check-input sig_input me-2'
                                                    id='checkout'
                                                    name='hasCheckOut'
                                                    value='True'
                                                    checked={checked?.hasCheckout}
                                                    onChange={(e) => handleCheckboxChange('hasCheckout', e.target.checked)}
                                                />
                                            </div>
                                            <span className='vertically_align'>Check Out</span>
                                        </label>
                                    </td>
                                    <td>
                                        <label className='radio mb-0 d-inline-flex' htmlFor='hasDispoe'>
                                            <div>
                                                <input
                                                    type='checkbox'
                                                    className='form-check-input custom-form-check-input sig_input me-2'
                                                    id='hasDispoe'
                                                    name='hasDispoe'
                                                    value='True'
                                                    checked={checked?.hasDispose}
                                                    onChange={(e) => handleCheckboxChange('hasDispose', e.target.checked)}
                                                />
                                            </div>
                                            <span className='vertically_align'>Dispose</span>
                                        </label>
                                    </td>
                                    <td className='d-none'>
                                        <label className='radio mb-0 d-inline-flex' htmlFor='donate'>
                                            <div>
                                                <input
                                                    type='checkbox'
                                                    className='form-check-input custom-form-check-input sig_input me-2'
                                                    id='donate'
                                                    name='hasDonate'
                                                    value='True'
                                                />
                                            </div>
                                            <span className='vertically_align'>Donate</span>
                                        </label>
                                    </td>
                                    <td>
                                        <label className='radio mb-0 d-inline-flex' htmlFor='lease'>
                                            <div>
                                                <input
                                                    type='checkbox'
                                                    className='form-check-input custom-form-check-input sig_input me-2'
                                                    id='lease'
                                                    name='hasLease'
                                                    value='True'
                                                    checked={checked?.hasLease}
                                                    onChange={(e) => handleCheckboxChange('hasLease', e.target.checked)}
                                                />
                                            </div>
                                            <span className='vertically_align'>Lease</span>
                                        </label>
                                    </td>
                                    <td className='d-none'>
                                        <label className='radio mb-0 d-inline-flex' htmlFor='broken'>
                                            <div>
                                                <input
                                                    type='checkbox'
                                                    className='form-check-input custom-form-check-input sig_input me-2'
                                                    id='broken'
                                                    name='hasBroken'
                                                    value='True'
                                                />
                                            </div>
                                            <span className='vertically_align'>Broken</span>
                                        </label>
                                    </td>
                                </tr>
                                <tr>
                                    <td className='d-none'>
                                        <label className='radio mb-0 d-inline-flex' htmlFor='lost'>
                                            <div>
                                                <input
                                                    type='checkbox'
                                                    className='form-check-input custom-form-check-input sig_input me-2'
                                                    id='lost'
                                                    name='hasLost'
                                                    value='True'
                                                />
                                            </div>
                                            <span className='vertically_align'>Lost</span>
                                        </label>
                                    </td>
                                    <td>
                                        <label className='radio mb-0 d-inline-flex' htmlFor='maintenance'>
                                            <div>
                                                <input
                                                    type='checkbox'
                                                    className='form-check-input custom-form-check-input sig_input me-2'
                                                    id='maintenance'
                                                    name='maintenance'
                                                    value='True'
                                                    checked={checked?.hasMaintenance}
                                                    onChange={(e) => handleCheckboxChange('hasMaintenance', e.target.checked)}
                                                />
                                            </div>
                                            <span className='vertically_align'>Maintenance</span>
                                        </label>
                                    </td>
                                    <td className='d-none'>
                                        <label className='radio mb-0 d-inline-flex' htmlFor='sell'>
                                            <div>
                                                <input
                                                    type='checkbox'
                                                    className='form-check-input custom-form-check-input sig_input me-2'
                                                    id='Sell'
                                                    name='hasSell'
                                                    value='True'
                                                />
                                            </div>
                                            <span className='vertically_align'>Sell</span>
                                        </label>
                                    </td>
                                    <td>
                                        <label className='radio mb-0 d-inline-flex' htmlFor='move'>
                                            <div>
                                                <input
                                                    type='checkbox'
                                                    className='form-check-input custom-form-check-input sig_input me-2'
                                                    id='move'
                                                    name='hasMove'
                                                    value='True'
                                                    checked={actionChecked.hasMove || checked?.hasMove}
                                                    onChange={(e) => handleCheckboxChange('hasMove', e.target.checked)}
                                                />
                                            </div>
                                            <span className='vertically_align'>Move</span>
                                        </label>
                                    </td>
                                    <td>&nbsp;</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AssetsTab
