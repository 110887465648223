import { useIntl } from 'react-intl'
import { PageTitle } from '../../../_metronic/layout/core'
import { StatisticsWidget5 } from '../../../_metronic/partials/widgets'
import { useGetCompanyQuery } from 'App/Modules/Services/Company'
import { useEffect, useState } from 'react'
import { useGetDashboardStatsQuery } from 'App/Modules/Services/Dashboard'
import { toast } from 'react-toastify'
import SplashScreen from 'App/SplashScreen'
import Loader from 'App/BeatLoader'

const DashboardPage = ({ stats }) => {
	return (
		<>
			<div className='row g-5 g-xl-8'>
				<div className='col-xl-4'>
					<StatisticsWidget5
						className='card-xl-stretch mb-8'
						svgIcon='la la-puzzle-piece fs-2'
						color='body-white'
						iconColor='primary'
						title='Assets'
						records={stats.assets || 0}
						description='List of Assets'
						titleColor='gray-900'
						descriptionColor='gray-400'
						link='/assets'
					/>
				</div>

				<div className='col-xl-4'>
					<StatisticsWidget5
						className='card-xl-stretch mb-8'
						svgIcon='la la-user-friends fs-2'
						color='body-white'
						iconColor='primary'
						title='Users'
						records={stats.users || 0}
						description='List of Users'
						titleColor='gray-900'
						descriptionColor='gray-400'
						link='/users'
					/>
				</div>

				<div className='col-xl-4'>
					<StatisticsWidget5
						className='card-xl-stretch mb-5 mb-8'
						svgIcon='la la-user-tie fs-2'
						color='body-white'
						iconColor='primary'
						title='Employees'
						records={stats.employees || 0}
						description='List of Employees'
						titleColor='gray-900'
						descriptionColor='gray-400'
						link='/persons'
					/>
				</div>
			</div>
		</>
	)
}

const DashboardWrapper = () => {
	const intl = useIntl()
	const { isLoading, data, error } = useGetDashboardStatsQuery()
	const [stats, setStats] = useState<object>({})
	const [loading, setLoading] = useState(true)

	useEffect(() => {
		if (data) {
			setStats(data?.stats)
			setLoading(false)
		} else if (error && 'message' in error) {
			toast.error(error?.message)
		}
	}, [data, error])

	return (
		<>
			<SplashScreen isLoadingTemplate={isLoading} />
			{!isLoading ? (
				<>
					<PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'MENU.DASHBOARD' })}</PageTitle>
					<DashboardPage stats={stats} />{' '}
				</>
			) : null}
			<Loader loading={loading} />
		</>
	)
}

export { DashboardWrapper }
