import { dateFormat, defaultDateFormat } from 'config'
import { Modal, Tab, Tabs } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { WarrantyInterface, defaultWarrantyDetail as initialValues } from '../../Core/_setup'
import { useUpdateWarrantyMutation } from 'App/Modules/Services/Warranty'
import { toast } from 'react-toastify'
import { useEffect } from 'react'
import { useAuth } from 'App/Modules/Auth'
import moment from 'moment'
const accountDetailsSchema = Yup.object().shape({
    expirationDate: Yup.string().required('Expiration Date is required'),
})
function WarrantiesEditModal(props) {
    const [updateWarranty, { isLoading: isUpdating }] = useUpdateWarrantyMutation()
    const { currentUser } = useAuth()

    useEffect(() => {
        if (props.assetWarranty?.assetId) {
            formik.setValues({
                duration: props.assetWarranty?.duration,
                expirationDate: moment(props.assetWarranty?.expirationDate).format('YYYY-MM-DD'),
                description: props.assetWarranty?.description,
            })
        }
    }, [props.assetWarranty?.assetId])

    const formik = useFormik({
        initialValues: { ...initialValues },
        validationSchema: accountDetailsSchema,
        onSubmit: async (values) => {
            try {
                const result = await updateWarranty({ body: values, id: props.assetWarranty?.id }).unwrap()
                if (result.success) {
                    toast.success(result?.message)
                    props.setShowEditModal(false)
                    props.setShowModal(true)
                    props.setLoading(true)
                }
            } catch (error) {
                toast.error(error)
            }
        },
    })
    
    return (
        <>
            <Modal
                className='px-2'
                size='lg'
                {...props}
                aria-labelledby='contained-modal-title-vcenter'
                backdrop='static'
            >
                <Modal.Header closeButton>
                    <Modal.Title>Asset Warranty</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form noValidate className='form'>
                        <div className='inner-site-modal'>
                            <div className='row mb-4'>
                                <label className='col-lg-3 col-form-label fw-bold fs-6'>Length</label>
                                <div className='col-lg-9 fv-row'>
                                    <div className='input-group'>
                                        <input
                                            type='number'
                                            className='form-control  form-control-solid'
                                            {...formik.getFieldProps('duration')}
                                            onChange={(e) => {
                                                formik.handleChange(e)
                                            }}
                                            name='duration'
                                            value={formik.values.duration || ''}
                                        />
                                        <span className='input-group-text border-0'>Months</span>
                                    </div>
                                </div>
                            </div>
                            <div className='row mb-4'>
                                <label className='col-lg-3 col-form-label required fw-bold fs-6'>
                                    Expiration Date
                                </label>
                                <div className='col-lg-9 fv-row'>
                                    <div className='input-group'>
                                        <input
                                            type='date'
                                            className='form-control  form-control-solid'
                                            {...formik.getFieldProps('expirationDate')}
                                            onChange={(e) => {
                                                formik.handleChange(e)
                                            }}
                                            name='expirationDate'
                                            value={formik.values.expirationDate || ''}
                                        />

                                        <span className='input-group-text border-0'>
                                            <i className='fa fa-calendar'></i>
                                        </span>
                                    </div>
                                    {formik.touched.expirationDate && formik.errors.expirationDate && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>{formik.errors.expirationDate}</div>
                                        </div>
                                    )}
                                </div>
                            </div>
                            <div className='row mb-4'>
                                <label className='col-lg-3 col-form-label fw-bold fs-6'>Notes</label>
                                <div className='col-lg-9 fv-row'>
                                    <textarea
                                        {...formik.getFieldProps('description')}
                                        onChange={(e) => {
                                            formik.handleChange(e)
                                        }}
                                        rows={3}
                                        className='form-control form-control-solid mb-3 mb-lg-0'
                                        autoComplete='off'
                                        value={formik.values.description || ''}
                                    />
                                </div>
                            </div>
                        </div>
                    </form>
                </Modal.Body>
                <Modal.Footer>
                    <button
                        onClick={props.onHide}
                        type='button'
                        class='btn btn-light-primary   main-btn-style me-2'
                        data-bs-dismiss='modal'
                    >
                        Close
                    </button>
                    <div className=' d-flex align-items-center justify-content-start'>
                        <button
                            type='button'
                            className='btn btn-primary main-btn-style'
                            disabled={isUpdating || !formik.isValid}
                            onClick={formik.handleSubmit}
                        >
                            {!isUpdating && 'Submit'}
                            {isUpdating && (
                                <span className='indicator-progress'>
                                    Please wait...{' '}
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                            )}
                        </button>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default WarrantiesEditModal
