import React, { useEffect, useState } from 'react'
import { getAuth } from '../../../../../Auth'
import { ContractFields, CustomFieldData, Field } from '../../Core/_models'
import { addContractFields, getContractFields } from '../../Core/_requests'
import ConfirmationPopup from '../../../../../../../_metronic/helpers/components/ConfirmationPopup'
import { toast } from 'react-toastify'
import { Link } from 'react-router-dom'

const ContractList = () => {
	const auth = getAuth()
	const [standardFields, setStandardFields] = useState<ContractFields>({
		hasContractTitle: true,
		isContractTitleRequired: true,
		hasDescription: true,
		isDescriptionRequired: true,
		hasHyperlink: true,
		isHyperlinkRequired: true,
		hasContractNo: true,
		isContractNoRequired: true,
		hasCost: true,
		isCostRequired: true,
		hasStartDate: true,
		isStartDateRequired: true,
		hasEndDate: true,
		isEndDateRequired: true,
		hasNodEndDate: true,
		hasContractPerson: true,
		isContractPersonRequired: true,
		hasPhone: true,
		isPhoneRequired: true,
		hasNoOfLicenses: true,
		isNoOfLicensesRequire: true,
		hasIsSoftware: true,
		hasVendor: true,
		isVendorRequired: true,
		customFields: '',
	})
	const [editStandardFields, setEditStandardFields] = useState<ContractFields>({
		hasContractTitle: true,
		isContractTitleRequired: true,
		hasDescription: true,
		isDescriptionRequired: true,
		hasHyperlink: true,
		isHyperlinkRequired: true,
		hasContractNo: true,
		isContractNoRequired: true,
		hasCost: true,
		isCostRequired: true,
		hasStartDate: true,
		isStartDateRequired: true,
		hasEndDate: true,
		isEndDateRequired: true,
		hasNodEndDate: true,
		hasContractPerson: true,
		isContractPersonRequired: true,
		hasPhone: true,
		isPhoneRequired: true,
		hasNoOfLicenses: true,
		isNoOfLicensesRequire: true,
		hasIsSoftware: true,
		hasVendor: true,
		isVendorRequired: true,
		customFields: '',
	})
	const [customFieldData, setCustomFieldData] = useState<CustomFieldData>({
		customFieldName: '',
		customFieldDataType: '',
		isRequired: false,
		listOptions: [],
	})
	const [customFieldDataArray, setCustomFieldDataArray] = useState<CustomFieldData[] | null>([])
	const [loading, setLoading] = useState(false)
	const [editIdx, setEditIdx] = useState<Number | null>()
	const [deleteIdx, setDeleteIdx] = useState<Number | null>()
	const [deleteModal, setDeleteModal] = useState<boolean>(false)
	const [flag, setFlag] = useState<Boolean>(false)
	const [fields, setFields] = useState<{ id: number; value: string }[]>([])
	const [fieldValues, setFieldValues] = useState<string[]>([])
	const [error, setError] = useState<{ fieldName: string; dataType: string; listOption: string }>({
		fieldName: '',
		dataType: '',
		listOption: '',
	})

	useEffect(() => {
		getContractFields(auth?.token).then((res) => {
			setStandardFields(res)
			setEditStandardFields(res)
			Array.isArray(res.customFields) && setCustomFieldDataArray(res?.customFields)
		})
	}, [flag])

	const selectAllCheckboxes = () => {
		standardFields.hasContractTitle &&
			standardFields.hasDescription &&
			standardFields.hasHyperlink &&
			standardFields.hasContractNo &&
			standardFields.hasCost &&
			standardFields.hasStartDate &&
			standardFields.hasEndDate &&
			standardFields.hasNodEndDate &&
			standardFields.hasContractPerson &&
			standardFields.hasPhone &&
			standardFields.hasNoOfLicenses &&
			standardFields.hasIsSoftware &&
			standardFields.hasVendor
			? setStandardFields({
				...standardFields,
				hasContractTitle: false,
				hasDescription: false,
				hasHyperlink: false,
				hasContractNo: false,
				hasCost: false,
				hasStartDate: false,
				hasEndDate: false,
				hasNodEndDate: false,
				hasContractPerson: false,
				hasPhone: false,
				hasNoOfLicenses: false,
				hasIsSoftware: false,
				hasVendor: false,
			})
			: setStandardFields({
				...standardFields,
				hasContractTitle: true,
				hasDescription: true,
				hasHyperlink: true,
				hasContractNo: true,
				hasCost: true,
				hasStartDate: true,
				hasEndDate: true,
				hasNodEndDate: true,
				hasContractPerson: true,
				hasPhone: true,
				hasNoOfLicenses: true,
				hasIsSoftware: true,
				hasVendor: true,
			})
	}

	const handleCustomFieldData = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
		const { name, value } = e.target
		if (name === 'optional') {
			customFieldData.isRequired = false
			setCustomFieldData({ ...customFieldData })
		} else if (name === 'yes') {
			customFieldData.isRequired = true
			setCustomFieldData({ ...customFieldData })
		} else {
			setCustomFieldData({ ...customFieldData, [name]: value })
		}
	}

	const submitCustomField = () => {
		if (
			customFieldData?.customFieldName &&
			customFieldData?.customFieldDataType &&
			(customFieldData?.customFieldDataType === 'Checkbox list' ||
				customFieldData?.customFieldDataType === 'Radio button list' ||
				customFieldData?.customFieldDataType === 'Dropdown list'
				? fields[0]?.value
				: true)
		) {
			if (customFieldDataArray && typeof editIdx === 'number') {
				customFieldDataArray[editIdx] = customFieldData
			} else {
				customFieldDataArray?.push(customFieldData)
			}
			setCustomFieldDataArray([...(customFieldDataArray ?? [])])
			setEditStandardFields({
				...editStandardFields,
				customFields: JSON.stringify(customFieldDataArray),
			})
			const updatedFields = {
				...editStandardFields,
				customFields: JSON.stringify(customFieldDataArray),
			}
			addContractFields(updatedFields, auth?.token).then(() => {
				setFlag(!flag)
				toast.success('Custom field updated successfully.')
			})
			setCustomFieldData({
				customFieldName: '',
				customFieldDataType: '',
				isRequired: false,
				listOptions: [],
			})
			setError({
				fieldName: '',
				dataType: '',
				listOption: '',
			})
		} else {
			setError({
				...error,
				fieldName: !customFieldData?.customFieldName ? 'Label is required.' : '',
				dataType: !customFieldData?.customFieldDataType ? 'Data Type is required.' : '',
				listOption: !fields[0].value ? 'Option is required.' : '',
			})
		}
	}

	const confirm = () => {
		if (typeof deleteIdx === 'number') {
			customFieldDataArray?.splice(deleteIdx, 1)
		}
		setEditStandardFields({
			...editStandardFields,
			customFields: JSON.stringify(customFieldDataArray),
		})
		const updatedFields = {
			...editStandardFields,
			customFields: JSON.stringify(customFieldDataArray),
		}
		addContractFields(updatedFields, auth?.token).then(() => {
			setFlag(!flag)
			toast.success('Custom field deleted successfully.')
		})
		setDeleteModal(false)
	}

	const submitCustomersFields = (event: React.MouseEvent<HTMLButtonElement>) => {
		event?.preventDefault()
		const updatedFields = {
			...standardFields,
			customFields: JSON.stringify(standardFields.customFields),
		}
		setLoading(true)
		addContractFields(updatedFields, auth?.token)
			.then(() => {
				setFlag(!flag)
				toast.success('Database updated successfully.')
				setLoading(false)
			})
			.catch((error) => {
				setLoading(false)
			})
	}

	const addCustomField = () => {
		setCustomFieldData({
			customFieldName: '',
			customFieldDataType: '',
			isRequired: false,
			listOptions: [],
		})
		setError({
			fieldName: '',
			dataType: '',
			listOption: '',
		})
		setEditIdx(null)
		setFields([{ id: Date.now(), value: '' }])
	}

	const addNewField = () => {
		const newField: Field = { id: Date.now(), value: '' }
		setFields([...fields, newField])
	}

	const removeField = (index: number) => {
		const newFields = [...fields]
		newFields.splice(index, 1)
		setFields(newFields)
	}

	const handleChange = (index: number, value: string) => {
		const newFields = [...fields]
		newFields[index] = { ...newFields[index], value }
		setFields(newFields)
		const newFieldValues = [...fieldValues]
		newFieldValues[index] = value
		setFieldValues(newFieldValues)
		setCustomFieldData({ ...customFieldData, listOptions: newFields })
	}

	const cancel = () => {
		setDeleteModal(false)
	}

	return (
		<div className='card'>
			<ConfirmationPopup deleteModal={deleteModal} cancel={cancel} confirm={confirm} />
			<div id='database_ajax_wrapper'>
				<form
					action='/assettiger/database'
					className='form-horizontal'
					data-ajax='true'
					data-ajax-method='Post'
					data-ajax-mode='before'
					data-ajax-update='#database_ajax_wrapper'
					id='assetDatabaseForm'
					method='post'
					role='form'
				>
					<input
						name='__RequestVerificationToken'
						type='hidden'
						value='bi5mAQGArgtf83vcFjyPEGK8rKsZ-9D3G-eRhsWQ-rTaCYjXK6gswp10gSiUZu3dXYXV2Zm9_cHp6yxRZbnLhFj5ZuJQOiJIAWnGosU6DIm2Zzc3Qcl40A_U_bv_VIr8fFJjrA2'
					/>{' '}
					<div className='card-body'>
						<div className='form_section'>
							<div className='form-title d-flex'>
								<span className='me-2'>
									<svg
										version='1.1'
										xmlns='http://www.w3.org/2000/svg'
										width='24'
										height='24'
										viewBox='0 0 32 32'
										fill='#c30'
									>
										<path
											fill='#9b9b9b'
											d='M30.16 23.8l-2.667-2.667 2.667-2.667-1.227-1.133-2.667 2.667-2.667-2.667-1.187 1.173 2.667 2.667-2.667 2.667 1.173 1.173 2.667-2.667 2.667 2.667z'
										></path>
										<path
											fill='#9b9b9b'
											d='M29.613 6.973l-4.507 4.613-2.107-2-1.48 1.493 3.587 3.48 6-6.107z'
										></path>
										<path
											fill='#9b9b9b'
											d='M0.893 6.973v7.693h19.4v-7.693zM18.307 12.667h-15.427v-3.68h15.427z'
										></path>
										<path
											fill='#9b9b9b'
											d='M0.893 17.333v7.707h19.4v-7.707zM18.307 23.027h-15.427v-3.693h15.427z'
										></path>
									</svg>
								</span>
								<h4 className='mt-1'>Contract Standard Fields</h4>
							</div>
							<div className='text_indent'>
								<p>Select the fields you would like to use for the contract table.</p>
								<div className='table-responsive'>
									<table
										className='table table-bordered mb-0'
										aria-label='Persons/Employees Standard Fields'
									>
										<thead className='thead-light'>
											<tr>
												<th className='w-2'>
													<label className='checkbox has_nolabel m-auto' htmlFor='field_all'>
														<input
															type='checkbox'
															checked={
																standardFields.hasContractTitle &&
																standardFields.hasDescription &&
																standardFields.hasHyperlink &&
																standardFields.hasContractNo &&
																standardFields.hasCost &&
																standardFields.hasStartDate &&
																standardFields.hasEndDate &&
																standardFields.hasNodEndDate &&
																standardFields.hasContractPerson &&
																standardFields.hasPhone &&
																standardFields.hasNoOfLicenses &&
																standardFields.hasIsSoftware &&
																standardFields.hasVendor
															}
															onChange={selectAllCheckboxes}
															id='field_all'
															className='custom-form-check-input form-check-input sig_input'
														/>
														<span className='sr-only'>Field All</span>
													</label>
												</th>
												<th className='w-20'>Field name </th>
												<th className='w-20'>Data Required </th>
												<th>Description </th>
												<th className='w-32'>Example </th>
											</tr>
										</thead>
										<tbody>
											<tr>
												<td>
													<label className='checkbox has_nolabel m-auto' htmlFor='contractTitle'>
														<input
															checked={true}
															className='fieldDisabledCheck custom-form-check-input form-check-input sig_input'
															data-val='true'
															data-val-required='Contract Title is required.'
															disabled={true}
															id='contractTitle'
															type='checkbox'
															value='true'
														/>
														<span className='sr-only'>Field</span>
													</label>
												</td>
												<td className='fw-bolder text-nowrap'>
													Contract Title <span className='required'></span>
												</td>
												<td>
													<div className='radio-inline'>
														<label className='radio mb-0' htmlFor='yesCheckbox'>
															<input
																type='checkbox'
																className='m_5 custom-form-check-input form-check-input sig_input'
																checked={true}
																id='yesCheckbox'
															/>
															Yes
														</label>
													</div>
												</td>
												<td>Title of Contract</td>
												<td>Notebooks Annual Contract</td>
											</tr>
											<tr>
												<td>
													<label className='checkbox has_nolabel m-auto' htmlFor='hasDescription'>
														<input
															checked={standardFields?.hasDescription}
															onChange={() =>
																setStandardFields({
																	...standardFields,
																	hasDescription: !standardFields.hasDescription,
																})
															}
															className='fieldCheck custom-form-check-input form-check-input sig_input'
															data-val='true'
															data-val-required='Contract Description is required.'
															id='hasDescription'
															name='hasDescription'
															type='checkbox'
															value='true'
														/>
														<input name='hasDescription' type='hidden' value='false' />
														<span className='sr-only'>Field</span>
													</label>
												</td>
												<td className='fw-bolder text-nowrap'>Description</td>
												<td>
													{standardFields?.hasDescription && (
														<div
															className='radio-inline standard_radio_can_hide hasDescription_radio d-flex'
														>
															<label className='radio mb-0 p_16' htmlFor='isDescriptionRequired_True'>
																<input
																	id='isDescriptionRequired_True'
																	checked={standardFields.isDescriptionRequired}
																	onChange={() =>
																		setStandardFields({
																			...standardFields,
																			isDescriptionRequired: true,
																		})
																	}
																	name='isDescriptionRequired'
																	type='checkbox'
																	value='True'
																	className='m_5 custom-form-check-input form-check-input sig_input'
																/>
																Yes
															</label>
															<label className='radio mb-0' htmlFor='isDescriptionRequired_False'>
																<input
																	id='isDescriptionRequired_False'
																	checked={!standardFields.isDescriptionRequired}
																	onChange={() =>
																		setStandardFields({
																			...standardFields,
																			isDescriptionRequired: false,
																		})
																	}
																	name='isDescriptionRequired'
																	type='checkbox'
																	value='False'
																	className='m_5 custom-form-check-input form-check-input sig_input'
																/>
																Optional
															</label>
														</div>
													)}
												</td>
												<td>Description of the contract.</td>
												<td>Maintenance Contract for Marketing department Notebooks</td>
											</tr>
											<tr>
												<td>
													<label className='checkbox has_nolabel m-auto' htmlFor='hasHyperlink'>
														<input
															checked={standardFields?.hasHyperlink}
															onChange={() =>
																setStandardFields({
																	...standardFields,
																	hasHyperlink: !standardFields.hasHyperlink,
																})
															}
															className='fieldCheck custom-form-check-input form-check-input sig_input'
															data-val='true'
															data-val-required='Hyperlink is required.'
															id='hasHyperlink'
															name='hasHyperlink'
															type='checkbox'
															value='true'
														/>
														<input name='hasHyperlink' type='hidden' value='false' />
														<span className='sr-only'>Field</span>
													</label>
												</td>
												<td className='fw-bolder text-nowrap'>Hyperlink</td>
												<td>
													{standardFields?.hasHyperlink && (
														<div
															className='radio-inline standard_radio_can_hide hasHyperlink_radio d-flex'
														>
															<label className='radio mb-0 p_16' htmlFor='isHyperlinkRequired_True'>
																<input
																	id='isHyperlinkRequired_True'
																	checked={standardFields.isHyperlinkRequired}
																	onChange={() =>
																		setStandardFields({
																			...standardFields,
																			isHyperlinkRequired: true,
																		})
																	}
																	name='isHyperlinkRequired'
																	type='checkbox'
																	value='True'
																	className='m_5 custom-form-check-input form-check-input sig_input'
																/>
																Yes
															</label>
															<label className='radio mb-0' htmlFor='isHyperlinkRequired_False'>
																<input
																	id='isHyperlinkRequired_False'
																	checked={!standardFields.isHyperlinkRequired}
																	onChange={() =>
																		setStandardFields({
																			...standardFields,
																			isHyperlinkRequired: false,
																		})
																	}
																	name='isHyperlinkRequired'
																	type='checkbox'
																	value='False'
																	className='m_5 custom-form-check-input form-check-input sig_input'
																/>
																Optional
															</label>
														</div>
													)}
												</td>
												<td>HyperLink of Contract</td>
												<td>https://www.example.com</td>
											</tr>
											<tr>
												<td>
													<label className='checkbox has_nolabel m-auto' htmlFor='hasContractNo'>
														<input
															checked={standardFields?.hasContractNo}
															onChange={() =>
																setStandardFields({
																	...standardFields,
																	hasContractNo: !standardFields.hasContractNo,
																})
															}
															className='fieldCheck custom-form-check-input form-check-input sig_input'
															data-val='true'
															data-val-required='Contract No. is required.'
															id='hasContractNo'
															name='hasContractNo'
															type='checkbox'
															value='true'
														/>
														<input name='hasContractNo' type='hidden' value='false' />

														<span className='sr-only'>Field</span>
													</label>
												</td>
												<td className='fw-bolder text-nowrap'>Contract No.</td>
												<td>
													{standardFields?.hasContractNo && (
														<div
															className='radio-inline standard_radio_can_hide hasContractNo_radio d-flex'
														>
															<label className='radio mb-0 p_16' htmlFor='isContractNoRequired_True'>
																<input
																	id='isContractNoRequired_True'
																	checked={standardFields.isContractNoRequired}
																	onChange={() =>
																		setStandardFields({
																			...standardFields,
																			isContractNoRequired: true,
																		})
																	}
																	name='isContractNoRequired'
																	type='checkbox'
																	value='True'
																	className='m_5 custom-form-check-input form-check-input sig_input'
																/>
																Yes
															</label>
															<label className='radio mb-0' htmlFor='isContractNoRequired_False'>
																<input
																	id='isContractNoRequired_False'
																	checked={!standardFields.isContractNoRequired}
																	onChange={() =>
																		setStandardFields({
																			...standardFields,
																			isContractNoRequired: false,
																		})
																	}
																	name='isContractNoRequired'
																	type='checkbox'
																	value='False'
																	className='m_5 custom-form-check-input form-check-input sig_input'
																/>
																Optional
															</label>
														</div>
													)}
												</td>
												<td>Contract No.</td>
												<td>AMC984763</td>
											</tr>
											<tr>
												<td>
													<label className='checkbox has_nolabel m-auto' htmlFor='hasCost'>
														<input
															checked={standardFields?.hasCost}
															onChange={() =>
																setStandardFields({
																	...standardFields,
																	hasCost: !standardFields.hasCost,
																})
															}
															className='fieldCheck custom-form-check-input form-check-input sig_input'
															data-val='true'
															data-val-required='Cost is required.'
															id='hasCost'
															name='hasCost'
															type='checkbox'
															value='true'
														/>
														<input name='hasCost' type='hidden' value='false' />
														<span className='sr-only'>Field</span>
													</label>
												</td>
												<td className='fw-bolder text-nowrap'>Cost</td>
												<td>
													{standardFields?.hasCost && (
														<div
															className='radio-inline standard_radio_can_hide hasCost_radio d-flex'
														>
															<label className='radio mb-0 p_16' htmlFor='isCostRequired_True'>
																<input
																	id='isCostRequired_True'
																	checked={standardFields.isCostRequired}
																	onChange={() =>
																		setStandardFields({ ...standardFields, isCostRequired: true })
																	}
																	name='isCostRequired'
																	type='checkbox'
																	value='True'
																	className='m_5 custom-form-check-input form-check-input sig_input'
																/>
																Yes
															</label>
															<label className='radio mb-0' htmlFor='isCostRequired_False'>
																<input
																	id='isCostRequired_False'
																	checked={!standardFields.isCostRequired}
																	onChange={() =>
																		setStandardFields({ ...standardFields, isCostRequired: false })
																	}
																	name='isCostRequired'
																	type='checkbox'
																	value='False'
																	className='m_5 custom-form-check-input form-check-input sig_input'
																/>
																Optional
															</label>
														</div>
													)}
												</td>
												<td>Cost of Contract</td>
												<td>$499.95</td>
											</tr>
											<tr>
												<td>
													<label className='checkbox has_nolabel m-auto' htmlFor='startDate'>
														<input
															checked={true}
															id='startDate'
															className='fieldDisabledCheck custom-form-check-input form-check-input sig_input'
															data-val='true'
															data-val-required='Contract Start Date is required.'
															disabled={true}
															type='checkbox'
															value='true'
														/>

														<span className='sr-only'>Field</span>
													</label>
												</td>
												<td className='fw-bolder text-nowrap'>
													Start Date <span className='required'></span>
												</td>
												<td>
													<div className='radio-inline'>
														<label htmlFor='checkboxYes' className='radio mb-0'>
															<input id='checkboxYes' type='checkbox' className='m_5 custom-form-check-input form-check-input sig_input' checked={true} />
															Yes
														</label>
													</div>
												</td>
												<td>Start Date of Contract</td>
												<td>7/4/2020</td>
											</tr>
											<tr>
												<td>
													<label htmlFor='contractEndDateCheckbox' className='checkbox has_nolabel m-auto'>
														<input
															id='contractEndDateCheckbox'
															checked={true}
															className='fieldDisabledCheck custom-form-check-input form-check-input sig_input'
															data-val='true'
															data-val-required='Contract End Date is required.'
															disabled={true}
															type='checkbox'
															value='true'
														/>
														<span className='sr-only'>Field</span>
													</label>
												</td>
												<td className='fw-bolder text-nowrap'>
													End Date <span className='required'></span>
												</td>
												<td>
													<div className='radio-inline'>
														<label htmlFor='yesCheckbox' className='radio mb-0'>
															<input
																id='yesCheckbox'
																type='checkbox'
																className='m_5 custom-form-check-input form-check-input sig_input'
																checked={true}
															/>
															Yes
														</label>
													</div>
												</td>
												<td>End Date of Contract</td>
												<td>7/3/2021</td>
											</tr>
											<tr>
												<td>
													<label htmlFor='contractNoEndDateCheckbox' className='checkbox has_nolabel m-auto'>
														<input
															id='contractNoEndDateCheckbox'
															checked={true}
															className='fieldDisabledCheck custom-form-check-input form-check-input sig_input'
															data-val='true'
															data-val-required='Contract No End Date is required.'
															disabled={true}
															type='checkbox'
															value='true'
														/>
														<span className='sr-only'>Field</span>
													</label>
												</td>
												<td className='fw-bolder text-nowrap'>No End Date</td>
												<td></td>
												<td>No End Date</td>
												<td>No</td>
											</tr>
											<tr>
												<td>
													<label htmlFor='hasVendor' className='checkbox has_nolabel m-auto'>
														<input
															id='hasVendor'
															checked={standardFields?.hasVendor}
															onChange={() =>
																setStandardFields({
																	...standardFields,
																	hasVendor: !standardFields.hasVendor,
																})
															}
															className='fieldCheck custom-form-check-input form-check-input sig_input'
															data-val='true'
															data-val-required='Vendor is required.'
															name='hasVendor'
															type='checkbox'
															value='true'
														/>
														<input name='hasVendor' type='hidden' value='false' />
														<span className='sr-only'>Field</span>
													</label>
												</td>
												<td className='fw-bolder text-nowrap'>Vendor</td>
												<td>
													{standardFields?.hasVendor && (
														<div
															className='radio-inline standard_radio_can_hide hasVendor_radio d-flex'
														>
															<label htmlFor='isVendorRequired_True' className='radio mb-0 p_16'>
																<input
																	id='isVendorRequired_True'
																	checked={standardFields.isVendorRequired}
																	onChange={() =>
																		setStandardFields({ ...standardFields, isVendorRequired: true })
																	}
																	name='isVendorRequired'
																	type='checkbox'
																	value='True'
																	className='m_5 custom-form-check-input form-check-input sig_input'
																/>
																Yes
															</label>
															<label htmlFor='isVendorRequired_False' className='radio mb-0'>
																<input
																	id='isVendorRequired_False'
																	checked={!standardFields.isVendorRequired}
																	onChange={() =>
																		setStandardFields({ ...standardFields, isVendorRequired: false })
																	}
																	name='isVendorRequired'
																	type='checkbox'
																	value='False'
																	className='m_5 custom-form-check-input form-check-input sig_input'
																/>
																Optional
															</label>
														</div>
													)}
												</td>
												<td>Vendor of Contract</td>
												<td>CompuByte Computer Services</td>
											</tr>
											<tr>
												<td>
													<label htmlFor='hasPerson' className='checkbox has_nolabel m-auto'>
														<input
															checked={standardFields?.hasContractPerson}
															onChange={() =>
																setStandardFields({
																	...standardFields,
																	hasContractPerson: !standardFields.hasContractPerson,
																})
															}
															className='fieldCheck custom-form-check-input form-check-input sig_input'
															data-val='true'
															data-val-required='Contact Person is required.'
															id='hasPerson'
															name='hasPerson'
															type='checkbox'
															value='true'
														/>
														<input name='hasPerson' type='hidden' value='false' />

														<span className='sr-only'>Field</span>
													</label>
												</td>
												<td className='fw-bolder text-nowrap'>Contract Person</td>
												<td>
													{standardFields?.hasContractPerson && (
														<div
															className='radio-inline standard_radio_can_hide hasPerson_radio d-flex'
														>
															<label htmlFor='isPersonRequired_True' className='radio mb-0 p_16'>
																<input
																	id='isPersonRequired_True'
																	checked={standardFields.isContractPersonRequired}
																	onChange={() =>
																		setStandardFields({
																			...standardFields,
																			isContractPersonRequired: true,
																		})
																	}
																	name='isPersonRequired'
																	type='checkbox'
																	value='True'
																	className='m_5 custom-form-check-input form-check-input sig_input'
																/>
																Yes
															</label>
															<label htmlFor='isPersonRequired_False' className='radio mb-0'>
																<input
																	id='isPersonRequired_False'
																	checked={!standardFields.isContractPersonRequired}
																	onChange={() =>
																		setStandardFields({
																			...standardFields,
																			isContractPersonRequired: false,
																		})
																	}
																	name='isPersonRequired'
																	type='checkbox'
																	value='False'
																	className='m_5 custom-form-check-input form-check-input sig_input'
																/>
																Optional
															</label>
														</div>
													)}
												</td>
												<td>Contact Person</td>
												<td>James Brown</td>
											</tr>
											<tr>
												<td>
													<label htmlFor='hasPhone' className='checkbox has_nolabel m-auto'>
														<input
															checked={standardFields?.hasPhone}
															onChange={() =>
																setStandardFields({
																	...standardFields,
																	hasPhone: !standardFields.hasPhone,
																})
															}
															className='fieldCheck custom-form-check-input form-check-input sig_input'
															data-val='true'
															data-val-required='Phone is required.'
															id='hasPhone'
															name='hasPhone'
															type='checkbox'
															value='true'
														/>
														<input name='hasPhone' type='hidden' value='false' />

														<span className='sr-only'>Field</span>
													</label>
												</td>
												<td className='fw-bolder text-nowrap'>Phone</td>
												<td>
													{standardFields?.hasPhone && (
														<div
															className='radio-inline standard_radio_can_hide hasPhone_radio d-flex'
														>
															<label htmlFor='isPhoneRequired_True' className='radio mb-0 p_16'>
																<input
																	id='isPhoneRequired_True'
																	checked={standardFields.isPhoneRequired}
																	onChange={() =>
																		setStandardFields({ ...standardFields, isPhoneRequired: true })
																	}
																	name='isPhoneRequired'
																	type='checkbox'
																	value='True'
																	className='m_5 custom-form-check-input form-check-input sig_input'
																/>
																Yes
															</label>
															<label htmlFor='isPhoneRequired_False' className='radio mb-0'>
																<input
																	id='isPhoneRequired_False'
																	checked={!standardFields.isPhoneRequired}
																	onChange={() =>
																		setStandardFields({ ...standardFields, isPhoneRequired: false })
																	}
																	name='isPhoneRequired'
																	type='checkbox'
																	value='False'
																	className='m_5 custom-form-check-input form-check-input sig_input'
																/>
																Optional
															</label>
														</div>
													)}
												</td>
												<td>Phone</td>
												<td>(555) 123-4567</td>
											</tr>
											<tr>
												<td>
													<label htmlFor='hasLicense' className='checkbox has_nolabel m-auto'>
														<input
															checked={standardFields?.hasNoOfLicenses}
															onChange={() =>
																setStandardFields({
																	...standardFields,
																	hasNoOfLicenses: !standardFields.hasNoOfLicenses,
																})
															}
															className='fieldCheck custom-form-check-input form-check-input sig_input'
															data-val='true'
															data-val-required='Licenses is required.'
															id='hasLicense'
															name='hasLicense'
															type='checkbox'
															value='true'
														/>
														<input name='hasLicense' type='hidden' value='false' />

														<span className='sr-only'>Field</span>
													</label>
												</td>
												<td className='fw-bolder text-nowrap'>No. of Licenses</td>
												<td>
													{standardFields?.hasNoOfLicenses && (
														<div
															className='radio-inline standard_radio_can_hide hasLicense_radio d-flex'
														>
															<label htmlFor='isLicenseRequired_True' className='radio mb-0 p_16'>
																<input
																	id='isLicenseRequired_True'
																	checked={standardFields.isNoOfLicensesRequire}
																	onChange={() =>
																		setStandardFields({
																			...standardFields,
																			isNoOfLicensesRequire: true,
																		})
																	}
																	name='isLicenseRequired'
																	type='checkbox'
																	value='True'
																	className='m_5 custom-form-check-input form-check-input sig_input'
																/>
																Yes
															</label>
															<label htmlFor='isLicenseRequired_False' className='radio mb-0'>
																<input
																	id='isLicenseRequired_False'
																	checked={!standardFields.isNoOfLicensesRequire}
																	onChange={() =>
																		setStandardFields({
																			...standardFields,
																			isNoOfLicensesRequire: false,
																		})
																	}
																	name='isLicenseRequired'
																	type='checkbox'
																	value='False'
																	className='m_5 custom-form-check-input form-check-input sig_input'
																/>
																Optional
															</label>
														</div>
													)}
												</td>
												<td>No. of Licenses</td>
												<td>10</td>
											</tr>
											<tr>
												<td>
													<label htmlFor='hasIsSoftware' className='checkbox has_nolabel m-auto'>
														<input
															checked={standardFields?.hasIsSoftware}
															onChange={() =>
																setStandardFields({
																	...standardFields,
																	hasIsSoftware: !standardFields.hasIsSoftware,
																})
															}
															className='fieldCheck custom-form-check-input form-check-input sig_input'
															data-val='true'
															data-val-required='Is Software is required.'
															id='hasIsSoftware'
															name='hasIsSoftware'
															type='checkbox'
															value='true'
														/>
														<input name='hasIsSoftware' type='hidden' value='false' />
														<span className='sr-only'></span>
													</label>
												</td>
												<td className='fw-bolder text-nowrap'>Is Software</td>
												<td></td>
												<td>Is Contract a Software ?</td>
												<td>No</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
						<div className='form_section' id='custom_ajax_wrapper'>
							<div className='separator separator-solid mb-6'></div>
							<div className='form-title d-flex'>
								<span className='me-2'>
									<svg
										version='1.1'
										xmlns='http://www.w3.org/2000/svg'
										width='24'
										height='24'
										viewBox='0 0 32 32'
										fill='#c30'
									>
										<path
											fill='#9b9b9b'
											d='M30.16 23.8l-2.667-2.667 2.667-2.667-1.227-1.133-2.667 2.667-2.667-2.667-1.187 1.173 2.667 2.667-2.667 2.667 1.173 1.173 2.667-2.667 2.667 2.667z'
										></path>
										<path
											fill='#9b9b9b'
											d='M29.613 6.973l-4.507 4.613-2.107-2-1.48 1.493 3.587 3.48 6-6.107z'
										></path>
										<path
											fill='#9b9b9b'
											d='M0.893 6.973v7.693h19.4v-7.693zM18.307 12.667h-15.427v-3.68h15.427z'
										></path>
										<path
											fill='#9b9b9b'
											d='M0.893 17.333v7.707h19.4v-7.707zM18.307 23.027h-15.427v-3.693h15.427z'
										></path>
									</svg>
								</span>
								<h4 className='mt-1'>
									<strong>Contract Custom Fields</strong>
								</h4>
							</div>
							<div className='text_indent'>
								<p>Add custom fields to join the standard fields that we provided.</p>
								<div className='form_section'>
									<div className='mb-4' onClick={addCustomField}>
										{' '}
										<Link data-bs-toggle='modal' to='#addEditFieldBox' className='btn btn-light-primary'>
											{' '}
											<i className='fa fa-plus' aria-hidden='true'></i> Add Custom Field{' '}
										</Link>{' '}
									</div>
									<div className="table-responsive pb-4 custom-table">
										<table
											className='table table-bordered table-sm vertical_middle mb-0'
											id='custom_fields_table'
											aria-label='Asset Custom Fields'
										>
											<thead className='thead-light'>
												<tr>
													<th> Field Name </th>
													<th> Data Type </th>
													<th> Required </th>
													<th className='text-center w-80'>
														{' '}
														Edit{' '}
													</th>
													<th className='text-center w-95'>
														{' '}
														Delete{' '}
													</th>
												</tr>
											</thead>
											<tbody>
												{Array.isArray(standardFields.customFields) &&
													standardFields.customFields.map((item, idx) => (
														<tr key={idx}>
															<td className='fw-bolder'>{item?.customFieldName}</td>
															<td>{item?.customFieldDataType}</td>
															<td>{item?.isRequired === true ? 'Yes' : 'Optional'}</td>
															<td className='text-nowrap'>
																<Link
																	data-bs-toggle='modal'
																	onClick={() => {
																		setEditIdx(idx)
																		setCustomFieldData(
																			customFieldDataArray?.length && customFieldDataArray[idx]
																				? customFieldDataArray[idx]
																				: {
																					customFieldName: '',
																					customFieldDataType: '',
																					isRequired: false,
																					listOptions: [],
																				}
																		)
																		setFields(
																			customFieldDataArray?.length && customFieldDataArray[idx]
																				? customFieldDataArray[idx].listOptions
																				: []
																		)
																	}}
																	to='#addEditFieldBox'
																	className='btn btn-light-primary main-btn-style btn-sm'
																>
																	<i className='fa fa-pencil-alt fs-7' aria-hidden='true'></i>Edit
																</Link>
															</td>
															<td className='text-nowrap'>
																<Link
																	to={'/dummy-link'}
																	className='confirmDeleteCustomField btn btn-light-primary main-btn-style btn-sm'
																	onClick={() => {
																		setDeleteModal(true)
																		setDeleteIdx(idx)
																	}}
																>
																	<i className='la la-trash fs-4' aria-hidden='true'></i>Delete
																</Link>
															</td>
														</tr>
													))}
											</tbody>
										</table>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className='card-footer right notForWizard'>
						<div className='row'>
							<div className='col-md-12 text-end'>
								<Link to='/apps/setup/database/contract' className='btn btn-light-primary   main-btn-style me-2'>
									Cancel
								</Link>
								<button
									type='submit'
									onClick={(e) => submitCustomersFields(e)}
									className='btn btn-primary main-btn-style'
								>
									{loading ? (
										<span className='indicator-progress d-block'>
											Please wait...{' '}
											<span className='spinner-border spinner-border-sm align-middle ms-2'></span>
										</span>
									) : (
										'Submit'
									)}
								</button>
							</div>
						</div>
					</div>
					<input id='hasAssetDescription' name='hasAssetDescription' type='hidden' value='True' />
				</form>
				<form
					action='/assettiger/database/AddField?Length=8'
					className='form-horizontal'
					data-ajax='true'
					data-ajax-begin='javascript: funCustomFormSubmitBegin();'
					data-ajax-method='Post'
					data-ajax-mode='replace'
					data-ajax-success='javascript: funCustomFormSubmited();'
					data-ajax-update='#custom_ajax_wrapper'
					id='formCustomField'
					method='post'
					role='form'
				>
					<input
						name='__RequestVerificationToken'
						type='hidden'
						value='P6Drb77htOawFFsvekNgHfWBWhq60oDpCoubbGMBsqUDjl2iAaD8QBR9--EiX9PwaCFSBKs_1KJeo_cU8xCFJxl-CxmIsOWHGs6UMfZP8TvVLl9DSBSzMsLIvq24QW2RmsX0tQ2'
					/>
					<div
						className='modal fade'
						id='addEditFieldBox'
						tabIndex={-1}
						role='dialog'
						aria-hidden='true'
					>
						<div className='modal-dialog modal-dialog-scrollable mw-md-600px'>
							<div className='modal-content'>
								<div className='modal-header'>
									<h4 className='modal-title'>Add Custom field</h4>
									<button
										type='button'
										className='btn-close'
										data-bs-dismiss='modal'
										aria-hidden='true'
										aria-label='close'
									></button>
								</div>
								<div className='modal-body form'>
									<div className='custom-field-details'>
										<div className='form-group row'>
											<label className='col-sm-4 col-form-label text-sm-end required' htmlFor='AddEditCustomFieldModel_Label'> Custom Field Label </label>
											<div className='col-sm-7'>
												<input
													className='form-control input-medium'
													onChange={handleCustomFieldData}
													value={customFieldData?.customFieldName}
													data-val='true'
													data-val-length='Maximum length is 100.'
													data-val-length-max='100'
													data-val-required='Label is required.'
													id='AddEditCustomFieldModel_Label'
													maxLength={100}
													name='customFieldName'
													type='text'
												/>
												<span
													className='field-validation-valid'
													data-valmsg-for='AddEditCustomFieldModel.Label'
													data-valmsg-replace='true'
												></span>
												<div className='text-danger mt-1'>{error.fieldName}</div>
											</div>
										</div>
										<div className='form-group row mt-5'>
											<label className='col-sm-4 col-form-label text-sm-end' htmlFor='AddEditCustomFieldModel_FieldTypeId' > Data Type<span className='required'></span>
											</label>
											<div className='col-sm-7'>
												<select
													className='form-select input-medium valid'
													value={customFieldData.customFieldDataType}
													onChange={handleCustomFieldData}
													data-val='true'
													data-val-number='The field Data Type must be a number.'
													data-val-required='Data Type is required.'
													id='AddEditCustomFieldModel_FieldTypeId'
													name='customFieldDataType'
												>
													<option>Select Data Type</option>
													<option value='Checkbox list'>Checkbox list</option>
													<option value='Currency'>Currency</option>
													<option value='Date'>Date</option>
													<option value='Date and Time'>Date and Time</option>
													<option value='Dropdown list'>Dropdown list</option>
													<option value='Email'>Email</option>
													<option value='GPS Coordinates'>GPS Coordinates</option>
													<option value='Memo'>Memo</option>
													<option value='Numeric'>Numeric</option>
													<option value='Numeric Auto Increment'>Numeric Auto Increment</option>
													<option value='Radio button list'>Radio button list</option>
													<option value='Scanner'>Scanner</option>
													<option value='Text'>Text</option>
													<option value='URL'>URL</option>
												</select>
												<span
													className='field-validation-valid'
													data-valmsg-for='AddEditCustomFieldModel.FieldTypeId'
													data-valmsg-replace='true'
												></span>
												<div className='text-danger mt-1'>{error.dataType}</div>
											</div>
										</div>
										<div className='form-group row align-items-center mt-5' id='ValidateType'>
											<label className='col-sm-4 col-form-label text-sm-end'>Data Required</label>
											<div className='col-sm-7'>
												<div className='radio-inline'>
													<label htmlFor='isValidate_True' className='radio mb-0 p_16'>
														<input
															id='isValidate_True'
															checked={customFieldData?.isRequired}
															onChange={handleCustomFieldData}
															type='checkbox'
															name='yes'
															className='m_5 custom-form-check-input form-check-input sig_input'
														/>
														Yes
													</label>
													<label htmlFor='isValidate_False' className='radio mb-0'>
														<input
															id='isValidate_False'
															checked={!customFieldData?.isRequired}
															onChange={handleCustomFieldData}
															type='checkbox'
															name='optional'
															className='m_5 custom-form-check-input form-check-input sig_input'
														/>
														Optional
													</label>
												</div>
											</div>
										</div>
										<div
											className='form-group row DataInput d-none'
											id='StartingValue'
										>
											<label className='col-sm-4 col-form-label text-sm-end' htmlFor='AddEditCustomFieldModel_Counter' > Starting Value </label>
											<div className='col-sm-7'>
												<input
													className='form-control i'
													data-val='true'
													data-val-number='The field Starting value must be a number.'
													id='AddEditCustomFieldModel_Counter'
													maxLength={10}
													min='0'
													name='AddEditCustomFieldModel.Counter'
													type='text'
												/>
											</div>
										</div>
										{(customFieldData?.customFieldDataType === 'Checkbox list' ||
											customFieldData?.customFieldDataType === 'Radio button list' ||
											customFieldData?.customFieldDataType === 'Dropdown list') && (
												<div className='form-group row DataInput customFieldOption'>
													<label className='col-sm-4 col-form-label text-sm-end' htmlFor='AddEditCustomFieldModel_Option' > List Options </label>
													<div className='col-sm-8 input_fields_wrap'>
														<div>
															{fields.map((field, index) => (
																<div
																	key={field.id}
																	className='form-inline flex-nowrap align-items-baseline mb-2'
																>
																	<div >
																		<input
																			className='form-control input-medium border border-black me-2'
																			type='text'
																			value={field.value}
																			onChange={(e) => handleChange(index, e.target.value)}
																		/>
																		{index === 0 && (
																			<div className='text-danger mt-1'>{error.listOption}</div>
																		)}
																	</div>
																	{index !== 0 && (
																		<div >
																			<Link
																				to={'/dummy-link'}
																				className='btn btn-light-primary remove_field'
																				onClick={() => removeField(index)}
																			>
																				Remove
																			</Link>
																		</div>
																	)}
																	{index === 0 && (
																		<div >
																			<Link
																				onClick={addNewField}
																				to='javascript:void(0)'
																				className='btn btn-light-primary add_field_button'
																			>
																				Add
																			</Link>
																		</div>
																	)}
																</div>
															))}
														</div>
														<span
															className='field-validation-valid'
															data-valmsg-for='AddEditCustomFieldModel.Options'
															data-valmsg-replace='true'
														></span>
														<span
															className='field-validation-valid'
															data-valmsg-for='AddEditCustomFieldModel.Option'
															data-valmsg-replace='true'
														></span>
													</div>
												</div>
											)}
									</div>
								</div>
								<div className='modal-footer'>
									<button type='button' className='btn btn-light-primary   main-btn-style me-2' data-bs-dismiss='modal'>
										Cancel
									</button>
									<button
										type='button'
										className='btn btn-primary'
										{...(customFieldData?.customFieldName &&
											customFieldData?.customFieldDataType &&
											(customFieldData?.customFieldDataType === 'Checkbox list' ||
												customFieldData?.customFieldDataType === 'Radio button list' ||
												customFieldData?.customFieldDataType === 'Dropdown list'
												? fields[0]?.value
												: true)
											? { 'data-bs-dismiss': 'modal' }
											: {})}
										value='Save'
										onClick={submitCustomField}
									>
										Save
									</button>
								</div>
							</div>
						</div>
					</div>
					<input
						data-val='true'
						data-val-length='Maximum length is 4000.'
						data-val-length-max='4000'
						id='AddEditCustomFieldModel_Options'
						name='AddEditCustomFieldModel.Options'
						type='hidden'
					/>
					<input
						data-val='true'
						data-val-required="Is this field visible to assets of selective 'Categories'?"
						id='AddEditCustomFieldModel_hasCategory'
						name='AddEditCustomFieldModel.hasCategory'
						type='hidden'
						value='False'
					/>
					<input
						data-val='true'
						data-val-required='Is this field need to be required?'
						id='AddEditCustomFieldModel_isValidate'
						name='AddEditCustomFieldModel.isValidate'
						type='hidden'
						value='True'
					/>
					<input
						data-val='true'
						data-val-number='The field CustomFieldId must be a number.'
						data-val-required='The CustomFieldId field is required.'
						id='AddEditCustomFieldModel_CustomFieldId'
						name='AddEditCustomFieldModel.CustomFieldId'
						type='hidden'
						value='0'
					/>
				</form>{' '}
				<div
					className='modal fade'
					id='confirmDeleteCustomField'
					tabIndex={-1}
					role='basic'
					aria-hidden='true'
				></div>
			</div>
		</div>
	)
}

export default ContractList
