import React, { useState, useEffect } from 'react'
import { ProfileDetails } from './Cards/ProfileDetails'
import { TwoFactorAuth } from './Cards/TwoFactorAuth'
import { useDeleteAccountMutation, useGetProfileQuery } from 'App/Modules/Services/Profile'
import SplashScreen from 'App/SplashScreen'
import { checkUserUnauthorized } from 'App/Modules/Auth'
import { useAuth } from 'App/Modules/Auth'
import { toast } from 'react-toastify'
import DeleteAccount from 'App/Modules/Apps/CompanySetup/CompnayInfo/DeleteAccount'
import { useDeleteCompanyMutation, useGetCompanyQuery } from 'App/Modules/Services/Company'


export function Settings() {
	const { data, isLoading, isError, error } = useGetProfileQuery()
	const [loader, setLoader] = useState(false)
	const { currentUser, saveAuth, setCurrentUser } = useAuth()
	const [deleteCompany, { isLoading: isLoadingDeleteCompany }] = useDeleteCompanyMutation();
	const { data: companyData } = useGetCompanyQuery();
	const [deleteAccount, { data: deleteAccData, isError: isDeleteAccErr, error: deleteAccErr, isSuccess: isDeleteAccSuccess }] = useDeleteAccountMutation()


	const handleDelete = () => {
		if (currentUser) {
			deleteAccount()
				.then((res: any) => {
					console.log(res)
					if (res.data) {
						toast.success(res?.data?.message)
						window.location.reload();
					} else {
						toast.error(res?.error?.data?.message)
					}
				})
				.catch((err: any) => {
					toast.error('Error in response!')
					checkUserUnauthorized(err?.data, saveAuth, setCurrentUser, toast)
				})
		}
	}
	useEffect(() => {
		if (isError) {
			const errorData = error as { data: any };
			checkUserUnauthorized(errorData?.data, saveAuth, setCurrentUser, toast)
		}
	}, [isError])

	return (
		<>
			<SplashScreen isLoadingTemplate={isLoading || loader} />
			{!(isLoading || loader) ?
				<>
					<ProfileDetails userData={data} />
					<TwoFactorAuth userData={data}  />
					<DeleteAccount handleDelete={handleDelete} />

				</>
				: null
			}
		</>
	)
}
