import React, { useEffect, useRef } from 'react';
import flatpickr from "flatpickr";
import 'flatpickr/dist/flatpickr.min.css';

function EmailTime() {

    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (inputRef.current) {
            const options: flatpickr.Options.Options = {
                enableTime: true,
                noCalendar: true,
                dateFormat: "H:i",
                time_24hr: false,   
                minuteIncrement: 1,
            };
            flatpickr(inputRef.current, options);
        }
    }, []);


    return (
        <div className="row mb-6">
            <div className="col-lg-2 col-form-label fw-bold fs-6">Stop Email</div>
            <div className="col-lg-10 fv-row">
                <p className='mb-2 text-muted'> <i className='la la-info-circle fs-5 text-muted'></i> How often do you want an alert email sent to you?</p>
                <div className='position-relative'>
                    <input ref={inputRef} type="text" className='bg-transparent form-control' placeholder="Select time" />
                    <span className="input-group-text input-time-icon"><i className="la la-clock-o fs-3 pe-0" aria-hidden="true"></i></span>
                </div>
            </div>
        </div>
    )
}

export default EmailTime