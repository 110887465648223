import { ProfileDetails } from 'App/Modules/Accounts/Components/Settings/Cards/ProfileDetails'
import { useGetProfileQuery } from 'App/Modules/Services/Profile'
import { useIntl } from 'react-intl'
import { PageTitle } from '_metronic/layout/core'
import ManageGroup from './ManageGroup'
import { useEffect, useState } from 'react'
import { AddSubUser } from 'App/Modules/Accounts/Components/Settings/Cards/AddSubUser'
import { useFormik } from 'formik'
import { IProfileDetails } from './SettingsModel'
import * as Yup from 'yup'
import { useAddSubUserMutation } from 'App/Modules/Services/SubUser'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router'

const addSubUserSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(3, 'First name must be at least 3 characters')
    .required('First name is required')
    .nullable(),

  lastName: Yup.string()
    .min(3, 'Last name must be at least 3 characters')
    .required('Last name is required')
    .nullable(),
  title: Yup.string().min(5, 'Title must be at least 5 characters').nullable(),
  phone: Yup.string()
    .matches(
      /^(\+)?[0-9]*$/,
      'Phone number must contain only numeric characters and may start with a plus sign'
    )
    .test('is-valid-number', 'Phone number must be between 10 and 15 digits', (value) => {
      if (value && value.trim().length > 0) {
        const stringValue = String(value).replace(/\D/g, '');
        return stringValue.length >= 10 && stringValue.length <= 15;
      }
      return true;
    })
    .nullable(),
  email: Yup.string()
    .matches(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/, 'Invalid email address')
    .max(50, 'Maximum 50 characters')
    .required('Email is required'),
  timeZone: Yup.string().required('Time zone is required').nullable(),
  dateFormat: Yup.string().required('Date Format is required').nullable(),
  timeFormat: Yup.string().required('Time Format is required').nullable(),
  groupName: Yup.number().required('Group Name is required')
})

function AddUsers() {
  const intl = useIntl()
  const { data } = useGetProfileQuery()
  const [selectedGroupName, setSelectedGroupName] = useState('');
  const [loading, setLoading] = useState(false)
  const [addSubUser] = useAddSubUserMutation()
  const navigate = useNavigate()

  const handleGroupSelect = (groupName) => {
    setSelectedGroupName(groupName);
  };

  const formik = useFormik<IProfileDetails>({
    initialValues: {
      image: null,
      firstName: '',
      lastName: '',
      title: '',
      phone: '',
      email: '',
      timeZone: '',
      dateFormat: '',
      timeFormat: '',
      groupName: ''
    },
    validationSchema: addSubUserSchema,
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const formData = new FormData();
        for (const key of Object.keys(values)) {
          if (Object.prototype.hasOwnProperty.call(values, key)) {
            const value = values[key as keyof IProfileDetails];
            formData.append(key, value);
          }
        }
        const { data, error }: any = await addSubUser(formData);
        console.log(data, error)
        if (data?.success) {
          toast.success(data?.message);
          navigate('/users');
        } else {
          toast.error(error?.data?.message);
        }
      } catch (error: any) {
        toast.error(error?.message || 'An error occurred');
      } finally {
        setLoading(false);
      }
    },
  })

  return (
    <div>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'Add Users' })}</PageTitle>
      <ManageGroup formik={formik} onSelectGroup={handleGroupSelect} userData={[]} selectedGroupName={selectedGroupName} />
      <AddSubUser loading={loading} formik={formik} userData={data} selectedGroupName={selectedGroupName} />
    </div>
  )
}

export default AddUsers