import { Editor } from '@tinymce/tinymce-react'
import { Modal } from 'react-bootstrap'
import EmailTo from './EmailTo'
import EmailSubject from './EmailSubject'
import EmailFrequency from './EmailFrequency'
import EmailTime from './EmailTime'
import StopEmails from './StopEmails'

const SetupEmailModal = (props: any) => {
	const { frequencyAllDays } = props;
	const { stopEmailPara_1 } = props;
	const handleClose = () => {
        props.handleClose()
    }
	const handleKeyDown = (event) => {
        if (event.key === 'Escape') {
            handleClose()
        }
    }

	return (
		<div>
			<>
				<Modal
					className='custom-modal-lg px-2'
					show={props.show}
					onHide={props.handleCancel}
					backdrop='static'
					size='lg'
					onKeyDown={handleKeyDown}
				>
					<Modal.Header closeButton>
						<Modal.Title>Asset Past Due Alerts</Modal.Title>
					</Modal.Header>
					<Modal.Body>
						<EmailTo/>
						<EmailSubject/>
						<div className="row mb-6">
							<div className="col-lg-2 col-form-label fw-bold fs-6">Email Body</div>
							<div className="col-lg-10 fv-row">
								<Editor/>
							</div>
						</div>
						<EmailFrequency frequencyAllDays={frequencyAllDays}/>
						<EmailTime />
						<StopEmails stopEmailPara_1={stopEmailPara_1}/>
					</Modal.Body>
				</Modal>
			</>
		</div>
	)
}

export default SetupEmailModal