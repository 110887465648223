import React, { useState, useEffect } from 'react'
import { toast } from 'react-toastify'
import Pagination from 'rc-pagination'
import 'rc-pagination/assets/index.css'
import localeInfo from 'rc-pagination/lib/locale/en_US'

import ConfirmationPopup from '../../../../../../_metronic/helpers/components/ConfirmationPopup'
import ActionIcon from '../../../../../../_metronic/assets/icons/ActionIcon'
import DepartmentsModel from './DepartmentsModel'
import { Department } from '../Core/_models'
import { useAuth } from '../../../../Auth'
import {
	useDeleteDepartmentMutation,
	useGetAllDepartmentsQuery,
} from '../../../../Services/Departments'
import { useNavigate } from 'react-router-dom'
import { Link } from 'react-router-dom'
import SplashScreen from 'App/SplashScreen'
import { checkUserUnauthorized } from '../../../../Auth'
import { setupKeys } from 'config'
import Loader from 'App/BeatLoader'

const DepartmentsListing = () => {
	const { currentUser, saveAuth, setCurrentUser } = useAuth()
	const navigate = useNavigate()
	const [show, setShow] = useState<boolean>(false)
	const [deleteModal, setDeleteModal] = useState<boolean>(false)
	const [selectAll, setSelectAll] = useState<boolean>(false)
	const [check, setCheck] = useState<string[]>([])
	const [selected, setSelected] = useState<string[]>([])
	const [deleteDataArr, setDeleteDataArr] = useState<string[]>([])
	const [data, setData] = useState({})
	const [deleteDepartment, { isLoading: isLoadingDeleteDepartment }] = useDeleteDepartmentMutation()
	const [isSitesExist, setIsSitesExist] = useState<any>(false)
	const [pagination, setPagination] = useState<any>({})
	const [loading, setLoading] = useState<boolean>(false)
	const [page, setPage] = useState<number>(1)
	const [limit, setLimit] = useState<number>(10)
	const [deleteCascade, setDeleteCascade] = useState(false)
	const { data: userDeparmentList, isLoading: isLoadingGetDepartment, isError, error } = useGetAllDepartmentsQuery({
		page,
		limit,
	})

	useEffect(() => {
		if (isError) {
			const errorData = error as { data: any };
			checkUserUnauthorized(errorData?.data, saveAuth, setCurrentUser, toast)
		} else if (!userDeparmentList?.department?.length && page > 1) {
			setPage(page - 1)
		}
	}, [isError, userDeparmentList])

	const checkVistingPage = (dataList: any) => {
		if (isSitesExist === false) {
			if (dataList && dataList?.length > 0) {
				setIsSitesExist(dataList?.length)
			} else {
				setIsSitesExist(0)
			}
		}
	}
	const handleCheckboxChange = (id: string) => {
		setSelected(prevSelected => {
			const updatedSelected = prevSelected.includes(id) ? prevSelected.filter(itemId => itemId !== id) : [...prevSelected, id];
			const isAllSelected = updatedSelected.length === userDeparmentList?.department?.length;
			setSelectAll(isAllSelected);
			return updatedSelected;
		});
		setDeleteDataArr(prevSelected => {
			if (prevSelected.includes(id)) {
				return prevSelected.filter(itemId => itemId !== id);
			} else {
				return [...prevSelected, id];
			}
		});
	};

	const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.checked) {
			const newSelecteds = userDeparmentList?.department?.map((item: any) => item.id)
			setSelected(newSelecteds)
			setSelectAll(true)
			setDeleteDataArr(newSelecteds)
		} else {
			setSelected([])
			setSelectAll(false)
			setDeleteDataArr([])
		}
	}

	const handleShow = (item: Department) => {
		setData(item)
		setShow(true)
	}

	const cancel = () => {
		setDeleteModal(false)
		setDeleteCascade(false)
	}

	const confirm = () => {
		deleteSelectedDepartments(check)
	}

	const handlePageChange = (currentPage: any) => {
		setLoading(true)
		setPage(currentPage)
	}

	const handleClose = () => setShow(false)

	const deleteSelectedDepartments = async (ids: any[]) => {
		try {
			const departmentIds = { departmentIds: ids }
			const res = await deleteDepartment({
				cascade: deleteCascade ? 1 : 0,
				id: departmentIds,
			}).unwrap()
			toast.success(res?.message)
			setSelected([])
			setDeleteDataArr([])
			setCheck([])
			setDeleteModal(false)
			setSelectAll(false)
			userDeparmentList()
			setLoading(true)
		} catch (err: any) {
			setCheck([])
			setDeleteDataArr([])
			setSelectAll(false)
			setDeleteModal(false)
			setDeleteCascade(false)
			setSelected([])
			toast.error(err?.data?.message)
			checkUserUnauthorized(err?.data, saveAuth, setCurrentUser, toast)
		}
	}
	useEffect(() => {
		if (currentUser && userDeparmentList) {
			checkVistingPage(userDeparmentList?.department)
			setPagination(userDeparmentList?.pagination)
			setLoading(false)
		}
	}, [userDeparmentList])

	const handleLimitChange = (newLimit: number) => {
		setLoading(true);
		setLimit(Number(newLimit));
		setPage(1);

	};
	const currentPage = userDeparmentList?.pagination?.page || 0
	const totalRecords = userDeparmentList?.pagination?.total || 0
	const pageSize = limit || 10
	const startRecord = (currentPage - 1) * pageSize + 1
	const endRecord = Math.min(currentPage * pageSize, totalRecords)
	return (
		<>
			<SplashScreen isLoadingTemplate={isLoadingGetDepartment || isLoadingDeleteDepartment} />
			{!(isLoadingGetDepartment || isLoadingDeleteDepartment) ?
				<div className='post d-flex flex-column-fluid' id='kt_post'>
					<div id='kt_content_container' className='container'>
						<div className='card'>
							<div className='card-body d-flex border-bottom border-bottom-gray-200 justify-content-between mb-3 align-items-center flex-wrap'>
								<h3 className='card-title align-items-start flex-column mb-xl-0 mb-3 me-3'>
									<span className='me-2 align-middle'>
										<i className='la la-list fs-1' aria-hidden='true'></i>
									</span>
									<span className='card-label fw-bold fs-3 mb-1'>List of Departments</span>
								</h3>
								<div className='card-toolbar d-flex flex-wrap'>
									<div className='d-flex flex-wrap' data-kt-user-table-toolbar='base'>
										<button
											type='button'
											className='btn btn-light-primary main-btn-style m-1'
											onClick={() => {
												setShow(true)
												setData({})
											}}
										>
											<i className='la la-plus fs-3' />
											Add{' '}<span className='d-xl-inline d-none'>New Department</span>
										</button>
										<Link className='btn btn-light-primary main-btn-style d-flex align-items-center m-1' to={'/import'} state={{ importType: "departments" }}>
											<i className='la la-cloud-upload fs-2 me-1'></i><span>Import</span><span className='d-xl-inline d-none ps-1'>Departments</span>
										</Link>
										<ConfirmationPopup
											deleteModal={deleteModal}
											cancel={cancel}
											confirm={confirm}
											deleteCascade={deleteCascade}
											setupName={setupKeys.department}
										/>
										<DepartmentsModel handleClose={handleClose} show={show} data={data} setLoading={setLoading} />
										{deleteDataArr.length > 0 && (
											<button
												type='button'
												className='btn btn-danger m-1'
												onClick={() => {
													setDeleteModal(true)
													setCheck(deleteDataArr)
												}}
											>
												Delete Selected ({deleteDataArr.length})
											</button>
										)}
									</div>
								</div>
							</div>

							<div className='card-body py-4'>
								<p className='text-dark'>
									Add <strong>departments</strong> that own or house the particular assets. Make them
									as broad or as specific as you want. <strong>Departments</strong> can be{' '}
									<em>'Accounting'</em>, <em>'Marketing'</em>, or <em>'Executive'</em>. Customize to
									your particular need.
								</p>{' '}
								<div className='d-flex limit-options align-items-center mb-3'>
									<span className="text-muted">Showing</span>
									<select
										className='form-select form-select-sm fw-bold mx-3 border-1'
										onChange={(e) => handleLimitChange(Number(e.target.value))}
										value={limit}
									>
										<option value='10'>10</option>
										<option value='15'>15</option>
										<option value='20'>20</option>
										<option value='25'>25</option>
										<option value='50'>50</option>
									</select>
									<span className="text-muted">entries</span>
								</div>
								<div className='table-responsive pb-4 custom-table'>
									<table
										id='kt_table_users'
										className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
										role='table'
									>
										<thead>
											<tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
												<th role='columnheader' className='w-10px pe-2'>
													<div className='header-checkbox me-3'>
														<input
															className='form-check-input custom-form-check-input'
															type='checkbox'
															data-kt-check='false'
															data-kt-check-target='#kt_table_users .form-check-input'
															checked={selectAll}
															onChange={handleSelectAll}
														/>
													</div>
												</th>
												<th role='columnheader' className='min-w-125px'>
													Name
												</th>
												<th role='columnheader' className='w-100px text-center'>
													Actions
												</th>
											</tr>
										</thead>

										<tbody className='text-gray-600 fw-bol' role='rowgroup'>
											{userDeparmentList?.department?.length > 0 ? (
												userDeparmentList?.department?.map((item: Department, idx: number) => {
													return (
														<tr key={item?.id} role='row'>
															<td role='cell' >
																<div className='form-check form-check-sm form-check-custom '>
																	<input
																		className='form-check-input'
																		type='checkbox'
																		data-kt-check='false'
																		data-kt-check-target='#kt_table_users .form-check-input'
																		checked={selected.includes(item?.id)}
																		onChange={() => handleCheckboxChange(item?.id)}
																	/>
																</div>
															</td>
															<td role='cell' >
																<div className='d-flex align-items-center'>
																	<div>
																		{item?.department}
																	</div>
																</div>
															</td>
															<td role='cell' className='text-center'>
																<div className='dropdown'>
																	<button
																		className='dropdown-toggle'
																		type='button'
																		id='dropdownMenuButton1'
																		data-bs-toggle='dropdown'
																		aria-expanded='false'
																	>
																		Action
																		<ActionIcon />
																	</button>
																	<ul className='dropdown-menu actions-dropdown' aria-labelledby='dropdownMenuButton1'>
																		<li>
																			<button className='dropdown-item' onClick={() => handleShow(item)}>
																				Edit
																			</button>
																		</li>
																		<li>
																			<button
																				className='dropdown-item'
																				onClick={() => {
																					setDeleteModal(true)
																					setCheck([item?.id])
																				}}
																			>
																				Delete
																			</button>
																		</li>
																	</ul>
																</div>
															</td>
														</tr>
													)
												})
											) : (
												<tr>
													<td colSpan={12}>
														<div className='d-flex text-center w-100 align-items-center justify-content-center no-records fw-bold rounded'>
															No records found
														</div>
													</td>
												</tr>
											)}
										</tbody>
									</table>
								</div>
								{pagination && userDeparmentList?.department?.length > 0 && (
									<div className='d-sm-flex align-items-center justify-content-between flex-wrap pt-3'>
										<div className='m-1'>
											Showing {startRecord} to {endRecord} of {totalRecords} records
										</div>
										<Pagination
											className='m-1 custom-pagination'
											defaultCurrent={1}
											pageSize={limit}
											current={pagination?.page}
											total={userDeparmentList?.pagination?.total}
											onChange={handlePageChange}
											locale={localeInfo}
										/>
									</div>
								)}
							</div>
							{isSitesExist === 0 ? (
								<div className='card-footer d-flex justify-content-end py-6 px-9'>
									<button
										onClick={() => {
											navigate(`/apps/setup/database/asset`)
										}}
										className='btn btn-primary'
									>
										Continue
									</button>
								</div>
							) : (
								''
							)}
						</div>
					</div>
					<Loader loading={loading} />
				</div>
				: null
			}
		</>
	)
}

export default DepartmentsListing
