export type Asset = {
  assetTagId?: string
  brand?: string | null
  categoryId?: number
  createdAt?: string
  departmentId?: number
  description?: string
  locationId?: number
  model?: string | null
  purchasedDate?: string
  purchasedFrom?: string | null
  serialNo?: string | null
  siteId?: number
  cost?: number | null
  statusType?: string
  photo?: any
}

export const initialAssets: Asset = {
  assetTagId: '',
  brand: null,
  categoryId: undefined,
  createdAt: '',
  departmentId: undefined,
  description: '',
  locationId: undefined,
  model: null,
  purchasedDate: undefined,
  purchasedFrom: null,
  serialNo: null,
  siteId: undefined,
  cost: null,
  statusType: 'Available',
  photo: '',
}

export type AssetResponse = {
  id: number
  assetTagId?: string
  brand?: string
  categoryId?: number
  createdAt?: string
  departmentId?: number
  description?: string
  locationId?: number
  model?: string
  purchasedDate?: string
  purchasedFrom?: string
  serialNo?: string
  siteId?: number
  userId?: number
  cost?: number | null
  statusType?: string
  assetstatus?: any
  site?: any
  location?: any
  photo?: any
}
