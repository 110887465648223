import Accordion from 'react-bootstrap/Accordion';
import "./Faqs.css"
import ContactUs from '../ContactUs/ContactUs';

function Faqs() {
	return (
		<section className="faqs-section position-relative z-2" id='faqs'>
			<div className="custom-container container">
				<h2 className="h-main mb-xl-5 mb-4">Frequently Asked Questions</h2>
				<div className="row pt-5">
					<div className="col-lg-7">
						<Accordion defaultActiveKey="0" className='faq-accordion  mb-lg-0 mb-10'>
							<Accordion.Item eventKey="0">
								<Accordion.Header>What is Asset Rack?</Accordion.Header>
								<Accordion.Body>
									Asset Rack is a comprehensive asset management system designed to help organizations track, manage, and maintain their assets, including computer equipment, devices, vehicles, and more.
								</Accordion.Body>
							</Accordion.Item>
							<Accordion.Item eventKey="1">
								<Accordion.Header>How does Asset Rack help with asset tracking?</Accordion.Header>
								<Accordion.Body>
									Asset Rack provides tools for logging, categorizing, and monitoring assets. It includes features like barcode scanning, detailed reporting, and maintenance scheduling to keep track of asset status and history.
								</Accordion.Body>
							</Accordion.Item>
							<Accordion.Item eventKey="2">
								<Accordion.Header>Can multiple users access Asset Rack?</Accordion.Header>
								<Accordion.Body>
									Yes, Asset Rack supports unlimited users with customizable access levels, allowing you to assign specific permissions based on each user's role within the organization.
								</Accordion.Body>
							</Accordion.Item>
							<Accordion.Item eventKey="3">
								<Accordion.Header>Is Asset Rack cloud-based?</Accordion.Header>
								<Accordion.Body>
									Yes, Asset Rack is entirely cloud-based, eliminating the need for physical software installations and allowing for continuous updates and access from anywhere with an internet connection.
								</Accordion.Body>
							</Accordion.Item>
							<Accordion.Item eventKey="4">
								<Accordion.Header>How can I schedule maintenance for my assets?</Accordion.Header>
								<Accordion.Body>
									Asset Rack allows you to set up and customize maintenance schedules for each asset, with configurable email alerts to remind you of upcoming maintenance tasks, ensuring your assets remain in optimal condition.
								</Accordion.Body>
							</Accordion.Item>
							<Accordion.Item eventKey="5">
								<Accordion.Header>Can Asset Rack generate custom reports?</Accordion.Header>
								<Accordion.Body>
									Yes, Asset Rack offers the ability to create and save unlimited custom reports, allowing you to view detailed information about your assets based on various criteria such as status, depreciation, and maintenance history.
								</Accordion.Body>
							</Accordion.Item>
							<Accordion.Item eventKey="6">
								<Accordion.Header>How does Asset Rack handle data security?</Accordion.Header>
								<Accordion.Body>
									Asset Rack uses advanced security measures to protect your data, including secure, access-controlled servers and PCI-compliant data centers for storing sensitive information.
								</Accordion.Body>
							</Accordion.Item>
							<Accordion.Item eventKey="7">
								<Accordion.Header>What types of assets can be managed with Asset Rack?</Accordion.Header>
								<Accordion.Body>
									Asset Rack can manage a wide range of assets, including IT equipment, vehicles, machinery, office supplies, and more. The system is versatile and adaptable to various asset management needs.
								</Accordion.Body>
							</Accordion.Item>
							<Accordion.Item eventKey="8">
								<Accordion.Header>Does Asset Rack offer mobile capabilities?</Accordion.Header>
								<Accordion.Body>
									Yes, Asset Rack includes mobile features like barcode scanning, allowing you to update asset information on the go using a mobile device, making asset tracking and audits more efficient.
								</Accordion.Body>
							</Accordion.Item>
							<Accordion.Item eventKey="9">
								<Accordion.Header>How do I get started with Asset Rack?</Accordion.Header>
								<Accordion.Body>
									Yes, Asset Rack i Getting started with Asset Rack is easy. Sign up on our website, set up your account, and begin adding your assets. Our support team is available to assist you with any questions or setup needs.
								</Accordion.Body>
							</Accordion.Item>
						</Accordion>
					</div>
					<div className="col-lg-5">
						<ContactUs />
					</div>
				</div>
			</div>
		</section>
	)
}

export default Faqs