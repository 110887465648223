export interface LeaseInterface {
    leaseBegins: string | Date,
    customerId: string,
    leaseExpires: string,
    leaseNotes: string | null,
    leaseEmail: string | null,
    assetId: number,
    overrideAssets: string | false,

}
export const defaultLeaseDetail: LeaseInterface = {
    leaseBegins: new Date().toISOString().split('T')[0],
    customerId: "",
    leaseExpires: '',
    leaseNotes: null,
    leaseEmail: null,
    assetId: 0,
    overrideAssets: false,

}
