
const DeleteAccount = ({ handleDelete }) => {

    return (
        <>
            <div className='card card-custom'>
                <div className='card-header'>
                    <h3 className='card-title fw-bolder'>Delete Company and Close Account</h3>
                    <div className='card-toolbar'></div>
                </div>
                <div className='card-body'>
                    <p>
                        This operation will delete your company account, ALL user accounts and ALL asset data
                        from Asset Rack. <strong>This operation is irrevocable.</strong>
                    </p>
                    <button
                        type='button'
                        data-bs-toggle='modal'
                        data-bs-target='#kt_modal_1'
                        className='btn btn-danger d-flex'
                    >
                        Delete Company <span className='d-sm-block d-none'>, User Accounts and ALL Data</span>
                    </button>
                </div>
            </div>
            <div className='modal fade' tabIndex={-1} id='kt_modal_1'>
                <div className='modal-dialog modal-lg'>
                    <div className='modal-content'>
                        <div className='modal-header'>
                            <h5 className='modal-title'>Delete Company and Close Account</h5>
                            <div className='btn-close ms-2 cursor-pointer' data-bs-dismiss='modal' aria-label='Close' ></div>
                        </div>
                        <div className='modal-body'>
                            <p className='mb-3'>
                                Are you sure you want to delete your company, ALL user accounts and ALL asset data
                                from Asset Rack?
                            </p>

                            <div className='form-group d-flex flex-wrap align-items-center mb-3'>
                                <span
                                    className='field-validation-valid'
                                    data-valmsg-for='txt_validate_delete'
                                    data-valmsg-replace='true'
                                ></span>
                            </div>
                            <p className='text-danger'>This operation is irrevocable.</p>
                        </div>
                        <div className='modal-footer'>
                            <button type='button' className='btn btn-light-primary   main-btn-style me-2' data-bs-dismiss='modal'>
                                Close
                            </button>
                            <button
                                type='button'
                                onClick={() => handleDelete()}
                                data-bs-dismiss='modal'
                                className='btn btn-danger d-flex'
                            >
                                Delete Company <span className='d-sm-block d-none'>, User Accounts and ALL Data</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default DeleteAccount;
