import '../import.css'
import { Dispatch, SetStateAction } from 'react'
interface FormFieldProps {
  type: Number
  setFormState: Dispatch<SetStateAction<any>>
  formState: any
}
function FormField({ type, setFormState, formState }: FormFieldProps) {
  return (
    <table className='table table-bordered mb-8' aria-label='Verify and Import' role='presentation'>
      {type === 1 ? (
        <tbody>
          <tr>
            <td className='fw-bold text-sm-end w-45'>
              If import data contains an Asset Tag ID already existing in Asset Rack, then?
            </td>
            <td className='warning w-55'>
              <div className='radio-inline'>
                <label className='radio mb-2 d-flex' htmlFor='radOverright'>
                  <div>
                    <input
                      type='checkbox'
                      id='radOverright'
                      className='form-check-input custom-form-check-input me-2'
                      name='radOverright'
                      checked={formState.updateAssests}
                      onChange={() =>
                        setFormState((prevState) => ({
                          ...prevState,
                          updateAssests: !prevState.updateAssests,
                        }))
                      }
                    />
                  </div>
                  <span>Update Asset</span>
                </label>

                <label className='radio mb-2 d-flex' htmlFor='radIgnoreAsset'>
                  <div>
                    <input
                      type='checkbox'
                      id='radIgnoreAsset'
                      className='form-check-input custom-form-check-input me-2'
                      name='radOverright'
                      checked={!formState.updateAssests}
                      onChange={() =>
                        setFormState((prevState) => ({
                          ...prevState,
                          updateAssests: false,
                        }))
                      }
                    />
                  </div>
                  <span>Ignore Asset</span>
                </label>
              </div>
            </td>
          </tr>
          <tr id='updateBlankWrapper'>
            <td className='fw-bold text-sm-end w-45'>
              While updating, if the existing asset has a value in the cell and import data is
              blank, then...
            </td>
            <td className='warning w-55'>
              <div className='radio-inline'>
                <label className='radio mb-2 d-flex' htmlFor='radBlank_false'>
                  <div>
                    <input
                      type='checkbox'
                      id='radBlank_false'
                      className='form-check-input custom-form-check-input me-2'
                      name='radBlank'
                      checked={formState.keepExistingValueAssets}
                      onChange={() =>
                        setFormState((prevState) => ({
                          ...prevState,
                          keepExistingValueAssets: !prevState.keepExistingValueAssets,
                        }))
                      }
                    />
                  </div>
                  <span>Keep existing value</span>
                </label>

                <label className='radio mb-2 d-flex' htmlFor='radBlank_true'>
                  <div>
                    <input
                      type='checkbox'
                      id='radBlank_true'
                      className='form-check-input custom-form-check-input me-2'
                      name='radBlank'
                      checked={!formState.keepExistingValueAssets}
                      onChange={() =>
                        setFormState((prevState) => ({
                          ...prevState,
                          keepExistingValueAssets: !prevState.keepExistingValueAssets,
                        }))
                      }
                    />
                  </div>
                  <span>Make field blank</span>
                </label>
              </div>
            </td>
          </tr>
          <tr>
            <td className='fw-bold text-sm-end w-45'>
              If import data has linking fields (like categories, sites, locations, etc.) and
              existing table is missing data, then in the linking table ...
            </td>
            <td className='warning w-55'>
              <div className='radio-inline'>
                <label className='radio mb-2 d-flex' htmlFor='radLinking'>
                  <div>
                    <input
                      type='checkbox'
                      id='radLinking'
                      className='form-check-input custom-form-check-input me-2'
                      name='radLinking'
                      checked={formState.LinkedFieldsAssets}
                      onChange={() =>
                        setFormState((prevState) => ({
                          ...prevState,
                          LinkedFieldsAssets: !prevState.LinkedFieldsAssets,
                        }))
                      }
                    />
                  </div>
                  <span>Insert record</span>
                </label>

                <label className='radio mb-2 d-flex' htmlFor='radSkipRecord'>
                  <div>
                    <input
                      type='checkbox'
                      id='radSkipRecord'
                      className='form-check-input custom-form-check-input me-2'
                      name='radLinking'
                      checked={!formState.LinkedFieldsAssets}
                      onChange={() =>
                        setFormState((prevState) => ({
                          ...prevState,
                          LinkedFieldsAssets: !prevState.LinkedFieldsAssets,
                        }))
                      }
                    />
                  </div>
                  <span>Skip record</span>
                </label>
              </div>
            </td>
          </tr>
        </tbody>
      ) : type === 3 ? (
        <tbody>
          <tr>
            <td className='fw-bold text-sm-end w-45'>
              Update existing data if duplicate Customer Name?
            </td>
            <td className='warning w-55'>
              <div className='radio-inline'>
                <label className='radio mb-2 d-flex' htmlFor='radOverwrite_yes'>
                  <div>
                    <input
                      type='checkbox'
                      id='radOverwrite_yes'
                      className='form-check-input custom-form-check-input me-2'
                      name='radOverwrite'
                      checked={formState.updateCustomers}
                      onChange={() =>
                        setFormState((prevState) => ({
                          ...prevState,
                          updateCustomers: true,
                        }))
                      }
                    />
                  </div>
                  <span>Yes</span>
                </label>

                <label className='radio mb-2 d-flex' htmlFor='radOverwrite_no'>
                  <div>
                    <input
                      type='checkbox'
                      id='radOverwrite_no'
                      className='form-check-input custom-form-check-input me-2'
                      name='radOverwrite'
                      checked={!formState.updateCustomers}
                      onChange={() =>
                        setFormState((prevState) => ({
                          ...prevState,
                          updateCustomers: false,
                        }))
                      }
                    />
                  </div>
                  <span> No</span>
                </label>
              </div>
            </td>
          </tr>
        </tbody>
      ) : type === 6 ? (
        <tbody>
          {!formState.deleteExistingMaintenance ? (
            <tr>
              <td className='fw-bold text-sm-end w-45'>
                Delete all existing maintenance records for ALL assets and replace with new records.
              </td>
              <td className='warning w-55'>
                <div className='radio-inline'>
                  <label className='radio mb-2 d-flex' htmlFor='radBlank_yes'>
                    <div>
                      <input
                        type='checkbox'
                        id='radBlank_yes'
                        className='form-check-input custom-form-check-input me-2'
                        name='radBlank'
                        checked={formState.deleteAllMaintenance}
                        onChange={() =>
                          setFormState((prevState) => ({
                            ...prevState,
                            deleteAllMaintenance: true,
                          }))
                        }
                      />
                    </div>
                    <span>Yes</span>
                  </label>

                  <label className='radio mb-2 d-flex' htmlFor='radBlank_false'>
                    <div>
                      <input
                        type='checkbox'
                        id='radBlank_false'
                        className='form-check-input custom-form-check-input me-2'
                        name='radBlank'
                        checked={!formState.deleteAllMaintenance}
                        onChange={() =>
                          setFormState((prevState) => ({
                            ...prevState,
                            deleteAllMaintenance: false,
                          }))
                        }
                      />
                    </div>
                    <span>No</span>
                  </label>
                </div>
              </td>
            </tr>
          ) : null}
          {!formState.deleteAllMaintenance ? (
            <>
              <tr>
                <td className='fw-bold text-sm-end w-45'>
                  Delete existing maintenance records (if any) ONLY FOR assets being imported and
                  replace with new records.
                </td>
                <td className='warning w-55'>
                  <div className='radio-inline'>
                    <label className='radio mb-2 d-flex' htmlFor='radBlank_true'>
                      <div>
                        <input
                          type='checkbox'
                          id='radBlank_true'
                          className='form-check-input custom-form-check-input me-2'
                          name='rad_yes'
                          checked={formState.deleteExistingMaintenance}
                          onChange={() =>
                            setFormState((prevState) => ({
                              ...prevState,
                              deleteExistingMaintenance: true,
                            }))
                          }
                        />
                      </div>
                      <span>Yes</span>
                    </label>

                    <label className='radio mb-2 d-flex' htmlFor='radBlank_false'>
                      <div>
                        <input
                          type='checkbox'
                          id='radBlank_false'
                          className='form-check-input custom-form-check-input me-2'
                          name='rad_yes'
                          checked={!formState.deleteExistingMaintenance}
                          onChange={() =>
                            setFormState((prevState) => ({
                              ...prevState,
                              deleteExistingMaintenance: false,
                            }))
                          }
                        />
                      </div>
                      <span>No</span>
                    </label>
                  </div>
                </td>
              </tr>
              {!formState.deleteAllMaintenance && !formState.deleteExistingMaintenance ? (
                <tr id='updateBlankWrapper'>
                  <td className='fw-bold text-sm-end w-45'>
                    If import data contains a maintenance record for an asset (by matching Asset Tag
                    ID, Maintenance Title and Maintenance Due Date) then
                  </td>
                  <td className='warning w-55'>
                    <div className='radio-inline'>
                      <label className='radio mb-2 d-flex' htmlFor='radBlank_false_update'>
                        <div>
                          <input
                            type='checkbox'
                            id='radBlank_false_update'
                            className='form-check-input custom-form-check-input me-2'
                            name='rad_update'
                            checked={formState.updateAssetsMaintenance}
                            onChange={() =>
                              setFormState((prevState) => ({
                                ...prevState,
                                updateAssetsMaintenance: true,
                              }))
                            }
                          />
                        </div>
                        <span>Update record</span>
                      </label>

                      <label className='radio mb-2 d-flex' htmlFor='radBlank_true_update'>
                        <div>
                          <input
                            type='checkbox'
                            id='radBlank_true_update'
                            className='form-check-input custom-form-check-input me-2'
                            name='rad_update'
                            checked={!formState.updateAssetsMaintenance}
                            onChange={() =>
                              setFormState((prevState) => ({
                                ...prevState,
                                updateAssetsMaintenance: false,
                              }))
                            }
                          />
                        </div>
                        <span>Skip record</span>
                      </label>
                    </div>
                  </td>
                </tr>
              ) : null}
            </>
          ) : null}
        </tbody>
      ) : type === 7 ? (
        <tbody>
          <tr>
            <td className='fw-bold text-sm-end w-45'>
              Update existing data if duplicate Person Name?{' '}
            </td>
            <td className='warning w-55'>
              <div className='radio-inline'>
                <label className='radio mb-2 d-flex' htmlFor='radOverright_yes_person'>
                  <div>
                    <input
                      type='checkbox'
                      id='radOverright_yes_person'
                      className='form-check-input custom-form-check-input me-2'
                      name='radOverright'
                      checked={formState.updatePerson}
                      onChange={() =>
                        setFormState((prevState) => ({
                          ...prevState,
                          updatePerson: true,
                        }))
                      }
                    />
                  </div>
                  <span>Yes</span>
                </label>

                <label className='radio mb-2 d-flex' htmlFor='radOverright_no_person'>
                  <div>
                    <input
                      type='checkbox'
                      id='radOverright_no_person'
                      className='form-check-input custom-form-check-input me-2'
                      name='radOverright'
                      checked={!formState.updatePerson}
                      onChange={() =>
                        setFormState((prevState) => ({
                          ...prevState,
                          updatePerson: false,
                        }))
                      }
                    />
                  </div>
                  <span>No</span>
                </label>
              </div>
            </td>
          </tr>
        </tbody>
      ) : type === 8 ? (
        <tbody>
          <tr>
            <td className='fw-bold text-sm-end w-45'>
              Update existing Site if duplicate Site Name?
            </td>
            <td className='warning w-55'>
              <div className='radio-inline'>
                <label className='radio mb-2 d-flex' htmlFor='radOverright_yes'>
                  <div>
                    <input
                      type='checkbox'
                      id='radOverright_yes'
                      className='form-check-input custom-form-check-input me-2'
                      name='radOverright'
                      checked={formState.isUpdateSite}
                      onChange={() =>
                        setFormState((prevState) => ({
                          ...prevState,
                          isUpdateSite: true,
                        }))
                      }
                    />
                  </div>
                  <span>Yes</span>
                </label>

                <label className='radio mb-2 d-flex' htmlFor='radOverright_no'>
                  <div>
                    <input
                      type='checkbox'
                      id='radOverright_no'
                      className='form-check-input custom-form-check-input me-2'
                      name='radOverright'
                      checked={!formState.isUpdateSite}
                      onChange={() =>
                        setFormState((prevState) => ({
                          ...prevState,
                          isUpdateSite: false,
                        }))
                      }
                    />
                  </div>
                  <span>No</span>
                </label>
              </div>
            </td>
          </tr>
        </tbody>
      ) : type === 9 ? (
        <tbody>
          <tr>
            <td className='fw-bold text-sm-end w-45'>Update existing Warranty ?</td>
            <td className='warning w-55'>
              <div className='radio-inline'>
                <label className='radio mb-2 d-flex' htmlFor='radOverright_yes'>
                  <div>
                    <input
                      type='checkbox'
                      id='radOverright_yes'
                      className='form-check-input custom-form-check-input me-2'
                      name='radOverright'
                      checked={formState.updateWarranty}
                      onChange={() =>
                        setFormState((prevState) => ({
                          ...prevState,
                          updateWarranty: true,
                        }))
                      }
                    />
                  </div>
                  <span>Yes</span>
                </label>

                <label className='radio mb-2 d-flex' htmlFor='radOverright_no'>
                  <div>
                    <input
                      type='checkbox'
                      id='radOverright_no'
                      className='form-check-input custom-form-check-input me-2'
                      name='radOverright'
                      checked={!formState.updateWarranty}
                      onChange={() =>
                        setFormState((prevState) => ({
                          ...prevState,
                          updateWarranty: false,
                        }))
                      }
                    />
                  </div>
                  <span>No</span>
                </label>
              </div>
            </td>
          </tr>
        </tbody>
      ) : null}
    </table>
  )
}

export default FormField