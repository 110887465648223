import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { assetsApi } from './Assets'
import { getAuth } from '../Auth'
import { toast } from 'react-toastify'

const API_URL = process.env.REACT_APP_API_URL

export const maintenanceApi = createApi({
	reducerPath: 'maintenanceApi',
	baseQuery: fetchBaseQuery({
		baseUrl: `${API_URL}/assets-maintenance/`,
		prepareHeaders: (headers) => {
			const auth = getAuth()
			if (auth && auth?.token) {
				headers.set('Authorization', `Bearer ${auth?.token}`)
			}
			return headers
		},
	}),
	tagTypes: ['Maintenance'],
	endpoints: (build) => ({
		addMaintenance: build.mutation<any, any>({
			query: (data) => ({
				url: 'add',
				method: 'POST',
				body: data,
			}),
			onQueryStarted: (arg, api) => {
				api.queryFulfilled.then(() => {
					api.dispatch(assetsApi.util.invalidateTags(['Assets']))
				}).catch(err => toast.error(err?.message))
			},
			invalidatesTags: () => [{ type: 'Maintenance' }],
		}),

		getAllMaintenances: build.query<any, { body: any; page: any; limit: any; keys: any }>({
			query: ({ body, page, limit, keys }) => ({
				url: `list/?${body}&page=${page}&limit=${limit}&keys=${keys}`,
				method: 'GET',
			}),
			providesTags: ['Maintenance'],
		}),

		getMaintenance: build.query<any, any>({
			query: ({ id }) => ({
				url: `/get/${id}`,
				method: 'GET',
			}),
			providesTags: ['Maintenance'],
		}),
		updateMaintenance: build.mutation<any, any>({
			query: ({ body, id }) => ({
				url: `/update/${id}`,
				method: 'PUT',
				body,
			}),
			invalidatesTags: () => [{ type: 'Maintenance' }],
		}),

		deleteMaintenance: build.mutation<any, any>({
			query(id) {
				return {
					url: `/delete/${id}`,
					method: 'DELETE',
					body: id,
				}
			},
			invalidatesTags: (userId, id) => [{ type: 'Maintenance' }],
		}),
	}),
})

export const {
	useAddMaintenanceMutation,
	useGetAllMaintenancesQuery,
	useUpdateMaintenanceMutation,
	useGetMaintenanceQuery,
} = maintenanceApi
