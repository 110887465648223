import moment from 'moment'
import timezones from 'timezones-list'

const Localization = ({ formik }) => {
	return (
		<div className='card-body border-top p-lg-9 p-md-7 p-6'>
			<h3 className='card-title mb-4'>
				<span className='me-2 align-middle'>
					<i className='las la-globe fs-1' aria-hidden='true'></i>
				</span>
				<span className='card-label fw-bold fs-3 mb-1'>Localization</span>
			</h3>
			<p className='ms-md-9 mb-5'>
				Adjust the settings to fit your profile, style, and your assets. You can override the
				company’s timezone, date, and time format to view them in your own local format. All
				asset-related events like ‘checkout’, ‘check in’, etc., will be shown in your selected
				timezone, date, and time format.
			</p>
			<div className='ms-md-9'>
				<div className='row mb-6'>
					<label className='col-lg-3 col-form-label required fw-bold fs-6'>Time Zone</label>
					<div className='col-lg-9 fv-row'>
						<select
							className='form-select form-control form-control-solid form-select-solid  '
							{...formik.getFieldProps('timeZone')}
						>
							<option selected hidden disabled> Select Time Zone </option>
							{timezones?.map((time) => (
								<option value={time?.label}>{time?.label}</option>
							))}
						</select>
						{formik.touched.timeZone && formik.errors.timeZone && (
							<div className='fv-plugins-message-container'>
								<div className='fv-help-block'>{formik.errors.timeZone}</div>
							</div>
						)}
					</div>
				</div>
				<div className='row mb-6'>
					<label className='col-lg-3 col-form-label required fw-bold fs-6'>Date Format</label>
					<div className='col-lg-9 fv-row'>
						<select
							className='form-select form-control form-control-solid form-select-solid  '
							{...formik.getFieldProps('dateFormat')}
						>
							<option value="">Select Date Format</option>
							<option value='DD-MM-YYYY'>DD-MM-YYYY (e.g {moment().format('DD-MM-YYYY')})</option>
							<option value='DD/MM/YYYY'>DD/MM/YYYY (e.g {moment().format('DD/MM/YYYY')})</option>
							<option value='MM-DD-YYYY'>MM-DD-YYYY (e.g {moment().format('MM-DD-YYYY')})</option>
							<option value='MM/DD/YYYY'>MM/DD/YYYY (e.g {moment().format('MM/DD/YYYY')})</option>
							<option value='YYYY-MM-DD'>YYYY-MM-DD (e.g {moment().format('YYYY-MM-DD')})</option>
							<option value='YYYY/MM/DD'>YYYY/MM/DD (e.g {moment().format('YYYY/MM/DD')})</option>
							<option value='D MMM, YYYY'>Short - D MMM, YYYY (e.g {moment().format('D MMM, YYYY')})</option>
							<option value='D MMMM, YYYY'>Medium - D MMMM, YYYY (e.g {moment().format('D MMMM, YYYY')})</option>
							<option value='DDDD, DD MMMM, YYYYY'>Full - DDDD, DD MMMM, YYYY (e.g {moment().format('DDDD, DD MMMM, YYYY')})</option>

						</select>
						{formik.touched.dateFormat && formik.errors.dateFormat && (
							<div className='fv-plugins-message-container'>
								<div className='fv-help-block'>{formik.errors.dateFormat}</div>
							</div>
						)}
					</div>
				</div>
				<div className='row mb-6'>
					<label className='col-lg-3 col-form-label required fw-bold fs-6'>Time Format</label>
					<div className='col-lg-9 fv-row'>
						<select
							className='form-select form-control form-control-solid form-select-solid  '
							{...formik.getFieldProps('timeFormat')}
						>
							<option selected hidden disabled>
								Select Time Format
							</option>
							<option value=""> Select Time Format </option>
							<option value='hh:mm A'>12-hour short - 09:58 PM</option>
							<option value='hh:mm:ss A'>12-hour long - 09:58:30 PM</option>
							<option value='hh:mm'>24-hour short - 21:58</option>
							<option value='hh:mm:ss'>24-hour long - 21:58:30</option>
						</select>
						{formik.touched.timeFormat && formik.errors.timeFormat && (
							<div className='fv-plugins-message-container'>
								<div className='fv-help-block'>{formik.errors.timeFormat}</div>
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	)
}

export default Localization
