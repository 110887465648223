import React from 'react'
import { Link } from 'react-router-dom'

type Props = {
	className: string
	color: string
	svgIcon: string
	iconColor: string
	title: string
	titleColor?: string
	description: string
	descriptionColor?: string
	link: string
	records: number
}

const StatisticsWidget5: React.FC<Props> = ({
	className,
	color,
	svgIcon,
	iconColor,
	title,
	titleColor,
	description,
	descriptionColor,
	link,
	records
}) => {
	return (
		<Link to={link} className={`card bg-${color} hoverable ${className}`}>
			<div className='card-body position-relative d-flex align-items-center justify-content-between flex-wrap'	>
				<div className='icon position-absolute d-flex align-items-center justify-content-center'>
					<i className={`text-${iconColor} ${svgIcon}`}></i>
				</div>
				<div>
					<div className={`text-${titleColor} fw-bold fs-2 mb-1`}>{title}</div>
					<div className="d-flex justify-content-between">
						<div className={`fw-semibold text-${descriptionColor}`}>{description}</div>
					</div>
				</div>
				<div className={`fw-bold record-number mb-0 text-${titleColor}`}>{records}</div>
			</div>
		</Link>
	)
}

export { StatisticsWidget5 }
