import { useState } from "react"
import { KTSVG } from "../../../../../_metronic/helpers"
import AssetsModal from "../Asset/Components/AssetsModal"
import LocationModal from "../Locations/Components/LocationModal"
import CategoryModel from "../Categories/Components/CategoryModel"
import DepartmentsModel from "../Departments/Components/DepartmentsModel"


const EditAssets = () => {
    const [loading, setLoading] = useState(false)
    const [data, setData] = useState({})
    const [showSiteModal, setShowSiteModal] = useState<boolean>(false)
    const [showLocationModal, setShowLocationModal] = useState<boolean>(false)
    const [showCategoryModal, setShowCategoryModal] = useState<boolean>(false)
    const [showDepartmentModal, setShowDepartmentModal] = useState<boolean>(false)

    const handleCloseSiteModal = () => {
        setShowSiteModal(false)
    }

    const handleShowSiteModal = () => {
        setShowSiteModal(true)
    }

    const handleCloseLocationModal = () => {
        setShowLocationModal(false)
    }

    const handleShowLocationModal = () => {
        setShowLocationModal(true)
    }

    const handleCloseCategoryModal = () => {
        setShowCategoryModal(false)
    }

    const handleShowCategoryModal = () => {
        setShowCategoryModal(true)
    }

    const handleCloseDepartmentModal = () => {
        setShowDepartmentModal(false)
    }

    const handleShowDepartmentModal = () => {
        setShowDepartmentModal(true)
    }

    return (
        <>
            <div className='card mb-5 mb-xl-10'>
                <div
                    className='card-header border-0 cursor-pointer'
                    role='button'
                    data-bs-toggle='collapse'
                    data-bs-target='#kt_account_profile_details'
                    aria-expanded='true'
                    aria-controls='kt_account_profile_details'
                >
                    <div className='card-title m-0'>
                        <h3 className='fw-bolder m-0'>Edit an Asset</h3>
                    </div>
                </div>
                <div id='kt_account_profile_details' className='collapse show'>
                    <form noValidate className='form'>
                        <div className='card-body border-top p-lg-9 p-md-7 p-6'>
                            <div className='pb-5'>
                                {' '}
                                <h3 className='fw-bolder m-0 mb-3'>Asset details</h3>
                            </div>
                            <div className="row mb-6">
                                <div className="col-lg-6">
                                    <div className='row mb-6'>
                                        <label className='col-lg-3 col-form-label required fw-bold fs-6' htmlFor="description">Description</label>
                                        <div className='col-lg-9 fv-row'>
                                            <input
                                            id="description"
                                                type='text'
                                                className='form-control  form-control-solid'
                                            />
                                        </div>
                                    </div>
                                    <div className='row mb-6'>
                                        <label className='col-lg-3 col-form-label required fw-bold fs-6' htmlFor="assetTagId">Asset Tag ID</label>
                                        <div className='col-lg-9 fv-row'>
                                            <input
                                            id="assetTagId"
                                                type='text'
                                                className='form-control  form-control-solid'
                                            />
                                        </div>
                                    </div>
                                    <div className='row mb-6'>
                                        <label className='col-lg-3 col-form-label required fw-bold fs-6' htmlFor="purchaseDate">Purchase Date</label>
                                        <div className='col-lg-9 fv-row'>
                                            <div className="input-group">
                                                <input type="date" id="purchaseDate" className="form-control  form-control-solid" aria-label="Amount (to the nearest dollar)" />
                                                <span className="input-group-text border-0"><i className="fa fa-calendar"></i></span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='row mb-6'>
                                        <label className='col-lg-3 col-form-label required fw-bold fs-6' htmlFor="cost">Cost</label>
                                        <div className='col-lg-9 fv-row'>
                                            <div className="input-group">
                                                <span className="input-group-text border-0">$</span>
                                                <div>
                                                    <input type="text" id="cost" className="form-control  form-control-solid" aria-label="Amount (to the nearest dollar)" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className='row mb-6'>
                                        <label className='col-lg-3 col-form-label required fw-bold fs-6' htmlFor="purchasedFrom">Purchased From</label>
                                        <div className='col-lg-9 fv-row'>
                                            <input
                                            id="purchasedFrom"
                                                type='text'
                                                className='form-control  form-control-solid'
                                            />
                                        </div>
                                    </div>
                                    <div className='row mb-6'>
                                        <label className='col-lg-3 col-form-label required fw-bold fs-6' htmlFor="brand">Brand</label>
                                        <div className='col-lg-9 fv-row'>
                                            <input
                                                id="brand"
                                                type='text'
                                                className='form-control  form-control-solid'
                                            />
                                        </div>
                                    </div>
                                    <div className='row mb-6'>
                                        <label className='col-lg-3 col-form-label required fw-bold fs-6' htmlFor="Model">Model</label>
                                        <div className='col-lg-9 fv-row'>
                                            <input
                                            id="Model"
                                                type='text'
                                                className='form-control  form-control-solid'
                                            />
                                        </div>
                                    </div>
                                    <div className='row mb-6'>
                                        <label className='col-lg-3 col-form-label required fw-bold fs-6' htmlFor="serialNum">Serial No</label>
                                        <div className='col-lg-9 fv-row'>
                                            <input
                                                type='text'
                                                className='form-control  form-control-solid'
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='pb-5 border-top pt-9'>
                                {' '}
                                <h3 className='fw-bolder m-0 mb-3'>Site, Location, Category and Department</h3>
                            </div>
                            <div className="row mb-6">
                                <div className="col-lg-6">
                                    <div className='row mb-6'>
                                        <label className='col-lg-3 col-form-label fw-bold fs-6' htmlFor="site"> <span className='required'>Site</span> </label>
                                        <div className='col-lg-9 fv-row d-flex'>
                                            <select
                                                className='form-select form-control form-control-solid form-select-solid  '
                                            >
                                                <option value="">Select Site</option>
                                            </select>
                                            <button
                                                type='button'
                                                className='btn btn-light-primary d-flex align-items-center ms-3'
                                                onClick={() => handleShowSiteModal()}
                                            >
                                                <i className='la la-plus fs-3' />
                                                New
                                            </button>
                                            <AssetsModal show={showSiteModal} handleClose={handleCloseSiteModal} SiteData={data} />
                                        </div>
                                    </div>
                                    <div className='row mb-6'>
                                        <label className='col-lg-3 col-form-label fw-bold fs-6' htmlFor="location"> <span className='required'>Location</span> </label>
                                        <div className='col-lg-9 fv-row d-flex'>
                                            <select
                                                className='form-select form-control form-control-solid form-select-solid  '
                                            >
                                                <option value="">Select Location</option>
                                            </select>
                                            <button
                                                type='button'
                                                className='btn btn-light-primary d-flex align-items-center ms-3'
                                                onClick={() => handleShowLocationModal()}
                                            >
                                                <i className='la la-plus fs-3' />
                                                New
                                            </button>
                                            <LocationModal show={showLocationModal} handleClose={handleCloseLocationModal} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className='row mb-6'>
                                        <label className='col-lg-3 col-form-label fw-bold fs-6' htmlFor="Category"> <span className='required'>Category</span> </label>
                                        <div className='col-lg-9 fv-row d-flex'>
                                            <select
                                                className='form-select form-control form-control-solid form-select-solid  '
                                            >
                                                <option value="">Select Category</option>
                                            </select>
                                            <button
                                                type='button'
                                                className='btn btn-light-primary d-flex align-items-center ms-3'
                                                onClick={() => handleShowCategoryModal()}
                                            >
                                                <i className='la la-plus fs-3' />
                                                New
                                            </button>
                                            <CategoryModel show={showCategoryModal} handleClose={handleCloseCategoryModal} />
                                        </div>
                                    </div>
                                    <div className='row mb-6'>
                                        <label className='col-lg-3 col-form-label fw-bold fs-6' htmlFor="Department"> <span className='required'>Department</span> </label>
                                        <div className='col-lg-9 fv-row d-flex'>
                                            <select
                                                className='form-select form-control form-control-solid form-select-solid  '
                                            >
                                                <option value="">Select Department</option>
                                            </select>
                                            <button
                                                type='button'
                                                className='btn btn-light-primary d-flex align-items-center ms-3'
                                                onClick={() => handleShowDepartmentModal()}
                                            >
                                                <i className='la la-plus fs-3' />
                                                New
                                            </button>
                                            <DepartmentsModel show={showDepartmentModal} handleClose={handleCloseDepartmentModal} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='pb-5 border-top pt-9'>
                                {' '}
                                <h3 className='fw-bolder m-0 mb-3'>Asset Photo</h3>
                            </div>
                            <div className="choose-file">
                                <input type="file" className="btn btn-primary mb-3" />
                                <p className='fw-bolder m-0'>Only (JPG, GIF, PNG) are allowed.</p>
                            </div>
                        </div>
                        <div className='card-footer d-flex justify-content-end py-6 px-9'>
                            <button type='submit' className='btn btn-primary main-btn-style' disabled={loading}>
                                {!loading && 'Submit'}
                                {loading && (
                                    <span className='indicator-progress d-block'>
                                        Please wait...{' '}
                                        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                    </span>
                                )}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default EditAssets