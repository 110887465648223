import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { AsideMenuItemWithSub } from './AsideMenuItemWithSub'
import { AsideMenuItem } from './AsideMenuItem'
import { useGetCompanyQuery } from 'App/Modules/Services/Company'
import { useAuth } from 'App/Modules/Auth'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import SplashScreen from 'App/SplashScreen'
import DotsLoader from 'App/DotsLoader'
import { useGetAllMaintenancesQuery } from 'App/Modules/Services/Maintenance'
import Columns from '../../../../App/Modules/Maintenance/Components/Columns.json'

export function AsideMenuMain() {
  const intl = useIntl()
  const [loading, setLoading] = useState(true)
  const [searchAssets, setSearchAssets] = useState<any>({
    keyword: '',
    searchField: '',
    siteId: '',
    locationId: '',
    categoryId: '',
    departmentId: '',
    person: '',
    customer: '',
    status: '',
    groupBy: '',
    recordCount: '',
    dateRangeBy: 'purchasedDate',
    quickDateRange: '',
    startDate: '',
    endDate: '',
    exportToExcel: false,
    maintenanceFilter: '',
  })
  const [skip, setSkip] = useState<boolean>(false)
  const [selectedColumns, setSelectedColumns] = useState<{ key: any; value: any }[]>(Columns)
  const selectedColumnsRender = selectedColumns.filter((column) => column.value === true)
  const [page, setPage] = useState<number>(1)
  const [limit, setLimit] = useState<number>(10)
  const [dueLength, setDueLength] = useState<number>()
  const [overDueLength, setOverDueLength] = useState<number>()
  const { currentUser } = useAuth()
  const navigate = useNavigate()
  const location = useLocation()
  const path = localStorage.getItem('path')
  const param = useParams()
  const id = param?.id
  const personUser = currentUser?.userType === '2'
  const customerUser = currentUser?.userType === '4'

  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 1000)
  }, [])

  useEffect(() => {
    if (currentUser?.isSetupCompleted) {
      if (personUser || customerUser) {
        return navigate('/assets')
      }
      navigate(path ? path : '/dashboard')
    }
    else {
      toast.dismiss()
      if (personUser || customerUser) {
        return navigate('/assets')
      }
      navigate('/apps/setup/wizard')
      if (!(personUser || customerUser)) {
        toast.info('Complete the steps to move further!')
      }
    }
  }, [currentUser?.isSetupCompleted, personUser, customerUser])
  const excelKeys = selectedColumnsRender.map((column) => column.key.replace(/_/g, ' '))
  const { data: maintenancesData } = useGetAllMaintenancesQuery(
    { body: new URLSearchParams(searchAssets).toString(), page, limit, keys: excelKeys },
    { skip }
  )
  useEffect(() => {
    if (maintenancesData) {
      setDueLength(maintenancesData?.dueCount[0]?.count);
      setOverDueLength(maintenancesData?.overDueCount[0]?.count);
    }
  }, [maintenancesData])

  return (
    <>
      <div className='aside-loader d-flex justify-content-center pt-3'>
        <DotsLoader loading={loading} />
      </div>
      {loading ? null : (
        <>
          <AsideMenuItemWithSub
            to='/apps/asset'
            title={`${currentUser?.firstName} ${currentUser?.lastName}`}
            photo={'true'}
          >
            <AsideMenuItem
              to={
                location.pathname === '/crafted/account/overview'
                  ? '/crafted/account/overview'
                  : '/crafted/account/settings'
              }
              title='My Profile'
              icon={'la la-user fs-2 me-3'}
            />
            <AsideMenuItem
              to='/crafted/account/change-password'
              icon={'la la-key fs-2 me-3'}
              title='Change Password'
            />
            <AsideMenuItem
              actionType={1}
              to='/auth/login'
              title='Logout'
              icon={'la la-sign-out fs-2 me-3'}
            />
            <AsideMenuItem
              to='/crafted/pages/account-details/overview'
              title='Account Details'
              icon={'la la-user fs-2 me-3'}
            />
          </AsideMenuItemWithSub>

          {!currentUser?.isSetupCompleted ? (
            ''
          ) : (
            <>
              {!(personUser || customerUser) && (
                <AsideMenuItem to='/dashboard' icon={'las la-home fs-2 me-3'} title={'Dashboard'} />
              )}
            </>
          )}
          {(!currentUser?.isSetupCompleted && !(personUser || customerUser)) ? (
            ''
          ) : (
            <AsideMenuItemWithSub
              to={location.pathname === '/asset' ? '/apps/asset' : `/asset/view/${id}`}
              title='Assets'
              icon={'las la-puzzle-piece fs-2 me-3'}
            >
              {(currentUser || (personUser || customerUser)) && (
                <AsideMenuItem
                  to='/assets'
                  title={(personUser || customerUser) ? 'My Assets' : 'List of Assets'}
                  icon={'las la-list fs-2 me-3'}
                />
              )}
              {!(personUser || customerUser) && (
                <AsideMenuItem
                  to='/asset/add'
                  icon={'la la-plus-circle fs-2 me-3'}
                  title={'Add an Asset'}
                />
              )}
              {!(personUser || customerUser) && (
                <AsideMenuItem
                  to='/checkout'
                  icon={'las la-user-check fs-2 me-3'}
                  title={'Check out'}
                />
              )}
              {!(personUser || customerUser) && (
                <AsideMenuItem
                  to='/checkin'
                  icon={'las la-user-times fs-2 me-3'}
                  title={'Check in'}
                />
              )}
              {!(personUser || customerUser) && (
                <AsideMenuItem to='/lease' icon={'las la-paper-plane fs-2 me-3'} title={'Lease'} />
              )}
              {!(personUser || customerUser) && (
                <AsideMenuItem
                  to='/lease-return'
                  icon={'las la-paper-plane fs-2 me-3'}
                  title={'Lease Return'}
                />
              )}
              {!(personUser || customerUser) && (
                <AsideMenuItem to='/dispose' icon={'las la-recycle fs-2 me-3'} title={'Dispose'} />
              )}
              {!(personUser || customerUser) && (
                <AsideMenuItem
                  to='/maintenance'
                  icon={'las la-cogs fs-2 me-3'}
                  title={'Maintenance'}
                />
              )}
              {!(personUser || customerUser) && (
                <AsideMenuItem to='/move' icon={'las la-arrows-alt fs-2 me-3'} title={'Move'} />
              )}
              {!(personUser || customerUser) && (
                <AsideMenuItem to='/reserve' icon={'la la-calendar fs-2 me-3'} title={'Reserve'} />
              )}
            </AsideMenuItemWithSub>
          )}

          {!currentUser?.isSetupCompleted ? (
            ''
          ) : (
            <div className='d-none'>
              {!(personUser || customerUser) && (
                <AsideMenuItemWithSub
                  to={location.pathname === '/asset' ? '/apps/asset' : `/asset/view/${id}`}
                  title='Alerts'
                  icon={'la la-flag fs-2 me-3'}
                  badge={'danger'}
                  notification={(dueLength ? dueLength : 0) + (overDueLength ? overDueLength : 0)}
                >
                  <>
                    <AsideMenuItem
                      to='/assets'
                      hasBullet={true}
                      badge={'primary'}
                      title='Assets Past Due'
                      notification={1}
                    />
                    <AsideMenuItem
                      to='/asset/add'
                      hasBullet={true}
                      badge={'warning'}
                      title={'Leases Expiring'}
                      notification={1}
                    />
                    <AsideMenuItem
                      to='/alerts/maintenances/due'
                      hasBullet={true}
                      badge={'danger'}
                      title={'Maintenance Due'}
                      notification={dueLength ? dueLength : 0}
                    />
                    <AsideMenuItem
                      to='/alerts/maintenances/overdue'
                      hasBullet={true}
                      badge={'danger'}
                      title={'Maintenance Overdue'}
                      notification={overDueLength ? overDueLength : 0}
                    />
                    <AsideMenuItem
                      to='/warranties/expire'
                      hasBullet={true}
                      badge={'danger'}
                      title={'Warranties Expiring'}
                      notification={1}
                    />
                  </>
                </AsideMenuItemWithSub>
              )}
            </div>
          )}
          {!currentUser?.isSetupCompleted
            ? ''
            : !(personUser || customerUser) && (
              <AsideMenuItemWithSub to='/' title='Lists' icon={'las la-list fs-2 me-3'}>
                <AsideMenuItem to='/list-of-assets' title='List of Assets' />
                <AsideMenuItem to='/maintenances' title='List of Maintenances' />
                <AsideMenuItem to='/warranties' title='List of Warranties' />
              </AsideMenuItemWithSub>
            )}

          {!currentUser?.isSetupCompleted ? (
            !(personUser || customerUser) && (
              <AsideMenuItemWithSub to='/apps/setup' title='Setup' icon={'las la-cog fs-2 me-3'}>
                <AsideMenuItem
                  to='/apps/setup/wizard'
                  title='Setup Wizard'
                  icon={'las la-briefcase fs-2 me-3'}
                />
              </AsideMenuItemWithSub>
            )
          ) : (
            !(personUser || customerUser) && (
              <AsideMenuItemWithSub to='/apps/setup' title='Setup' icon={'las la-cog fs-2 me-3'}>
                <AsideMenuItem
                  to='/apps/setup/company'
                  title='Company Info.'
                  icon={'las la-briefcase fs-2 me-3'}
                />
                <AsideMenuItem
                  to='/apps/setup/sites'
                  title='Sites'
                  icon={'las la-map-marker fs-2 me-3'}
                />
                <AsideMenuItem
                  to='/apps/setup/location'
                  title='Locations'
                  icon={'las la-map-signs fs-2 me-3'}
                />
                <AsideMenuItem
                  to='/apps/setup/categories'
                  title='Categories'
                  icon={'far fa-list-alt fs-3 me-3'}
                />
                <AsideMenuItem
                  to='/apps/setup/department'
                  title='Departments'
                  icon={'las la-border-all fs-2 me-3'}
                />
              </AsideMenuItemWithSub>
            )
          )}
          {!currentUser?.isSetupCompleted
            ? ''
            : !(personUser || customerUser) && (
              <AsideMenuItemWithSub
                to='/apps/tools'
                title='Tools'
                icon={'la la-wrench fs-2 me-3'}
              >
                <AsideMenuItem
                  to='/import'
                  title='Import'
                  icon={'la la-cloud-upload fs-2 me-15'}
                />
                <AsideMenuItem
                  to='/export'
                  title='Export'
                  icon={'la la-cloud-download fs-2 me-15'}
                />
              </AsideMenuItemWithSub>
            )}
          {!currentUser?.isSetupCompleted
            ? ''
            : !(personUser || customerUser) && (
              <AsideMenuItemWithSub
                to={
                  location.pathname === '/group-manager'
                    ? '/apps/advanced'
                    : '/group-manager/creategroup'
                }
                title='Advanced'
                icon={'la la-toolbox fs-2 me-3'}
              >
                <AsideMenuItem
                  to='/persons'
                  title='Persons/Employees'
                  icon={'la la-user-tie fs-2 me-15'}
                />
                <AsideMenuItem
                  to='/customers'
                  title='Customers'
                  icon={'la la-users fs-2 me-15'}
                />
                <AsideMenuItem to='/users' title='Users' icon={'la la-user-friends fs-2 me-15'} />
                <AsideMenuItem
                  to='/group-manager'
                  title='Security Groups'
                  icon={'la la-users-cog fs-2 me-15'}
                />
              </AsideMenuItemWithSub>
            )}
          {!currentUser?.isSetupCompleted
            ? ''
            : !(personUser || customerUser) && (
              <div className='d-none'>
                <AsideMenuItemWithSub
                  to='/apps/reports'
                  title='Reports'
                  icon={'la la-file-invoice fs-2 me-3'}
                >
                  <AsideMenuItemWithSub to='/apps/reports' title='Asset Reports'>
                    <AsideMenuItem
                      hasBullet={true}
                      to='/apps/reports/assetbyassettag'
                      title='by Asset Tag'
                    />
                    <AsideMenuItem
                      hasBullet={true}
                      to='/apps/reports/assetsbytagwithpictures'
                      title='by Tag with Pictures'
                    />
                    <AsideMenuItem
                      hasBullet={true}
                      to='/apps/reports/assetbycategory'
                      title='by Category'
                    />
                    <AsideMenuItem
                      hasBullet={true}
                      to='/apps/reports/assetbysite'
                      title='by Site'
                    />
                    <AsideMenuItem
                      hasBullet={true}
                      to='/apps/reports/assetbylocation'
                      title='by Location'
                    />
                    <AsideMenuItem
                      hasBullet={true}
                      to='/apps/reports/assetbydepartment'
                      title='by Dapartment'
                    />
                    <AsideMenuItem
                      hasBullet={true}
                      to='/apps/reports/assetbywarrantyinfo'
                      title='by Warranty Info.'
                    />
                  </AsideMenuItemWithSub>
                  <AsideMenuItemWithSub to='/apps/reports' title='Check-Out Reports'>
                    <AsideMenuItem
                      hasBullet={true}
                      to='/apps/reports/by-person-employee'
                      title='by Person/Employee'
                    />
                  </AsideMenuItemWithSub>
                </AsideMenuItemWithSub>
              </div>
            )}
        </>
      )}
    </>
  )
}
