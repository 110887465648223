import { useSendEmailSubUserMutation } from 'App/Modules/Services/SubUser'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
interface ColumnDataProps {
  column: string
  assetsData?: any
  currencyLogo?: any
  setSendEmail?: any
}

export const ColumnData: React.FC<ColumnDataProps> = ({ column, assetsData, setSendEmail }) => {
  const [sendEmailMutation] = useSendEmailSubUserMutation()
  const sendEmail = async (id: number) => {
    try {
      const data = await sendEmailMutation(id).unwrap()
      const { success, message } = data;
      if (!success) return toast.error(message)
      toast.success(message)
    } catch (error: any) {
      if (!error?.data?.success) {
        toast.error(error?.data?.message)
      }
    }
  }

  const renderData = () => {
    switch (column) {
      case 'Name':
        return assetsData.allAssets.map((asset: any, index: number) => (
          <div>
            <div className='text-truncate me-2'>{`${asset.firstName} ${asset.lastName}`}</div>
            {!asset?.isEmailVerified ? (
              <button
                className='btn bg-success fw-bold text-white px-3 py-2 fs-8'
                onClick={() => {
                  sendEmail(asset?.id)
                  setSendEmail(true)
                }}
              >
                {'Send Set Password Email'}
              </button>
            ) : (
              ''
            )}
          </div>
        ))
      case 'Created By':
        return assetsData.allAssets.map((asset: any, index: number) => (
          <span key={index}>
            {asset?.user ? `${asset?.user?.firstName} ${asset?.user?.lastName}` : ''}
          </span>
        ))
      case 'Group':
        return assetsData.allAssets.map((asset: any, index: number) => (
          <span key={index}>{asset?.security_group?.name || ''}</span>
        ))
      case 'Department':
        return assetsData.allAssets.map((asset: any, index: number) => (
          <span key={index}>{asset?.department?.department}</span>
        ))
      case 'Email':
        return assetsData.allAssets.map((asset: any, index: number) => (
          <span key={index}>{asset?.email}</span>
        ))
      case 'Employee ID':
        return assetsData.allAssets.map((asset: any, index: number) => (
          <span key={index}>{asset?.employeeId}</span>
        ))
      case 'Location':
        return assetsData.allAssets.map((asset: any, index: number) => (
          <span key={index}>{asset?.location?.location}</span>
        ))
      case 'Notes':
        return assetsData.allAssets.map((asset: any, index: number) => (
          <span key={index}>{asset?.notes}</span>
        ))
      case 'Phone':
        return assetsData.allAssets.map((asset: any, index: number) => (
          <span key={index}>{asset?.phone}</span>
        ))
      case 'Site':
        return assetsData.allAssets.map((asset: any, index: number) => (
          <span key={index}>{asset?.site?.name}</span>
        ))
      case 'Title':
        return assetsData.allAssets.map((asset: any, index: number) => (
          <span key={index}>{asset?.title}</span>
        ))
      default:
        return ''
    }
  }

  return <>{renderData()}</>
}

export const SampleColumnData: React.FC<ColumnDataProps> = ({ column }) => {
  const renderData = () => {
    switch (column) {
      case 'Created By':
        return 'Sample Created By'
      case 'Name':
        return 'Sample Name'
      case 'Group':
        return 'Admin Group'
      case 'Department':
        return 'Sample Department'
      case 'Email':
        return 'Sample Email'
      case 'Employee ID':
        return 'Sample Employee ID'
      case 'Location':
        return 'Sample Location'
      case 'Notes':
        return 'Sample Notes'
      case 'Phone':
        return 'Sample Phone'
      case 'Site':
        return 'Sample Site'
      case 'Title':
        return 'Sample Title'
      default:
        return ''
    }
  }

  return <>{renderData()}</>
}
