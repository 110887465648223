import React from 'react'
import { Link } from 'react-router-dom'

function WarrantiesTagList({ criteria, clearSearch }) {

  return (
    <div className='inputtags'>
      {Object.keys(criteria).map((key, index) => {
        const value = criteria[key]
        if (value) {
          return (
            <span className='tag' key={index}>
              <span className='lbl'>
                {key == 'keyword'
                  ? 'Keyword'
                  : key == 'searchField'
                    ? 'Search Field'
                    : key == 'siteId'
                      ? 'Site'
                      : key == 'locationId'
                        ? 'Location'
                        : key == 'categoryId'
                          ? 'Category'
                          : key == 'departmentId'
                            ? 'Department'
                            : key == 'person'
                              ? 'Person'
                              : key == 'customer'
                                ? 'Customer'
                                : key == 'status'
                                  ? 'Status'
                                  : key == 'groupBy'
                                    ? 'Group By'
                                    : key == 'recordCount'
                                      ? 'Records Count'
                                      : key == 'dateRangeBy'
                                        ? 'Date Range By'
                                        : key == 'quickDateRange'
                                          ? 'Quick Date Range'
                                          : key == 'startDate'
                                            ? 'Start Date'
                                            : key == 'endDate'
                                              ? 'End Date'
                                              : ''}
              </span>
              <div className='d-flex align-items-center justify-content-between'>
                <span>{' ' + value}</span>
                <button title='Remove tag' onClick={() => clearSearch(key)} className='bg-transparent border-0 ms-5'>
                  <i className='la la-close fs-5 text-secondary vertical-1'></i>
                </button>
              </div>
            </span>
          )
        }
        return null
      })}
    </div>
  )
}

export default WarrantiesTagList
