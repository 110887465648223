import React from 'react'

function EmailTo() {
    return (
        <div>
            <div className="row mb-6">
                <div className="col-lg-2 col-form-label fw-bold fs-6">To Emails</div>
                <div className="col-lg-10 fv-row">
                    <p className='mb-1 text-muted'> <i className='la la-info-circle fs-5 text-muted'></i> List the email addresses that you wish to send alerts to.</p>
                    <input type="email" className="form-control form-control-solid" name="emailTo"/>
                </div>
            </div>
        </div>
    )
}

export default EmailTo