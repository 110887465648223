import { dateFormat, defaultDateFormat } from 'config'
import { Modal, Tab, Tabs } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { toAbsoluteUrlImage } from '_metronic/helpers'
import { Images } from '_metronic/assets/Assets'
import { useAuth } from 'App/Modules/Auth'

function MaintenancesDetailModal(props) {
  const { currentUser } = useAuth()
  return (
    <>
      <Modal
        className='px-2'
        size='lg'
        {...props}
        aria-labelledby='contained-modal-title-vcenter'
        backdrop='static'
      >
        <Modal.Header closeButton>
          <Modal.Title>Asset Maintenance</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Tabs
            defaultActiveKey='tab1'
            id='uncontrolled-tab-example'
            className='mb-5 view-assets-tabs justify-content-start'
          >
            <Tab eventKey='tab1' title='Maintenance Details' className='me-2'>
              <div className='assetProduct_detail mb-5'>
                <div className='asset_detail_table table-responsive'>
                  <table
                    className='table table-bordered detail-table table-sm mb-0'
                    aria-label='Quick View'
                    role='presentation'
                  >
                    <tbody>
                      <tr>
                        <td className='label_col bg-light'>Title</td>
                        <td className='fw-bold detail_col table-elipses'>
                          {props.assetMaintenance?.maintenanceTitle}
                        </td>
                      </tr>
                      <tr>
                        <td className='label_col bg-light'>Details</td>
                        <td className='fw-bold detail_col table-elipses'>
                          {props.assetMaintenance?.maintenanceDetails}
                        </td>
                      </tr>
                      <tr>
                        <td className='label_col bg-light'>Due Date</td>
                        <td className='fw-bold detail_col table-elipses'>
                          {dateFormat(
                            props.assetMaintenance?.maintenanceDueDate,
                            currentUser?.dateFormat || defaultDateFormat
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td className='label_col bg-light'>Maintenance By</td>
                        <td className='fw-bold detail_col table-elipses'>
                          {props.assetMaintenance?.maintenanceBy}
                        </td>
                      </tr>
                      <tr>
                        <td className='label_col bg-light'>Maintenance Status</td>
                        <td className='fw-bold detail_col table-elipses'>
                          {props.assetMaintenance?.maintenanceStatus}
                        </td>
                      </tr>
                      <tr>
                        <td className='label_col bg-light'>Date completed</td>
                        <td className='fw-bold detail_col table-elipses'>
                          {dateFormat(
                            props.assetMaintenance?.maintenanceDateCompleted,
                            currentUser?.dateFormat || defaultDateFormat
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td className='label_col bg-light'>Maintenance Cost</td>
                        <td className='fw-bold detail_col table-elipses'>
                          {props.assetMaintenance?.maintenanceCost}
                        </td>
                      </tr>
                      <tr>
                        <td className='label_col bg-light'>Repeating</td>
                        <td className='fw-bold detail_col table-elipses'>
                          {props.assetMaintenance?.maintenanceReapting}
                        </td>
                      </tr>
                      <tr>
                        <td className='label_col bg-light'>Frequency</td>
                        <td className='fw-bold detail_col table-elipses'>
                          {props.assetMaintenance?.maintenanceFrequency}
                        </td>
                      </tr>
                      <tr>
                        <td className='label_col bg-light'>Recur on every</td>
                        <td className='fw-bold detail_col table-elipses'>
                          {props.assetMaintenance?.recureOnEvery}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className='pt-4 p-0 border-top d-flex justify-content-end'>
                <button
                  onClick={props.onHide}
                  type='button'
                  className='btn btn-light-primary   main-btn-style me-2'
                  data-bs-dismiss='modal'
                >
                  Close
                </button>
              </div>
            </Tab>
            <Tab eventKey='tab2' title='Asset Details'>
              <div className='assetProduct_detail asset-details mb-5'>
                <div className='row'>
                  <div className='col-lg-4'>
                    {props.assetMaintenance?.userasset?.photo ? (
                      <div className='overlay-wrapper asset_image rounded overflow-hidden mb-lg-0 mb-5'>
                        <img
                          src={toAbsoluteUrlImage(props.assetMaintenance?.userasset?.photo)}
                          className='img-fluid'
                          alt='Asset'
                        />
                      </div>
                    ) : (
                      <div className='overlay-wrapper asset_image rounded overflow-hidden mb-lg-0 mb-5'>
                        <img src={Images.AssetsPlaceholder} className='img-fluid' alt='Asset' />
                      </div>
                    )}
                  </div>
                  <div className='col-lg-8'>
                    <div className='asset_detail_table table-responsive'>
                      <table
                        className='table table-bordered detail-table table-sm mb-0'
                        aria-label='Quick View'
                        role='presentation'
                      >
                        <tbody>
                          <tr>
                            <td className='label_col bg-light'>Asset Tag ID </td>
                            <td className='fw-bold detail_col table-elipses'>
                              {props.assetMaintenance?.userasset?.assetTagId}
                            </td>
                          </tr>
                          <tr>
                            <td className='label_col bg-light'>Description</td>
                            <td className='fw-bold detail_col table-elipses'>
                              {props.assetMaintenance?.userasset?.description}
                            </td>
                          </tr>
                          <tr>
                            <td className='label_col bg-light'>Purchase Date </td>
                            <td className='fw-bold detail_col table-elipses'>
                              {dateFormat(
                                props.assetMaintenance?.userasset?.purchasedDate,
                                currentUser?.dateFormat || defaultDateFormat
                              )}
                            </td>
                          </tr>
                          <tr>
                            <td className='label_col bg-light'>Purchased From</td>
                            <td className='fw-bold detail_col table-elipses'>
                              {props.assetMaintenance?.userasset?.purchasedFrom}
                            </td>
                          </tr>
                          <tr>
                            <td className='label_col bg-light'>Cost</td>
                            <td className='fw-bold detail_col table-elipses'>
                              {props.assetMaintenance?.userasset?.cost}
                            </td>
                          </tr>
                          <tr>
                            <td className='label_col bg-light'>Brand</td>
                            <td className='fw-bold detail_col table-elipses'>
                              {props.assetMaintenance?.userasset?.brand}
                            </td>
                          </tr>
                          <tr>
                            <td className='label_col bg-light'>Model</td>
                            <td className='fw-bold detail_col table-elipses'>
                              {props.assetMaintenance?.userasset?.model}
                            </td>
                          </tr>
                          <tr>
                            <td className='label_col bg-light'>Serial No</td>
                            <td className='fw-bold detail_col table-elipses'>
                              {props.assetMaintenance?.userasset?.serialNo}
                            </td>
                          </tr>
                          <tr>
                            <td className='label_col bg-light'>Site</td>
                            <td className='fw-bold detail_col table-elipses'>
                              {props.assetMaintenance?.userasset?.site?.name}
                            </td>
                          </tr>
                          <tr>
                            <td className='label_col bg-light'>Location</td>
                            <td className='fw-bold detail_col table-elipses'>
                              {props.assetMaintenance?.userasset?.location?.location}
                            </td>
                          </tr>
                          <tr>
                            <td className='label_col bg-light'>Category </td>
                            <td className='fw-bold detail_col table-elipses'>
                              {props.assetMaintenance?.userasset?.category?.category}
                            </td>
                          </tr>
                          <tr>
                            <td className='label_col bg-light'>Department </td>
                            <td className='fw-bold detail_col table-elipses'>
                              {props.assetMaintenance?.userasset?.department?.department}
                            </td>
                          </tr>
                          <tr>
                            <td className='label_col bg-light'>Assigned to</td>
                            <td className='fw-bold detail_col table-elipses'>
                              {props.assetMaintenance?.userasset?.site?.name}{' '}
                            </td>
                          </tr>
                          <tr>
                            <td className='label_col bg-light'>Status</td>
                            <td className='detail_col table-elipses'>
                              <div
                                className={`d-inline-block align-items-center px-3 py-2 rounded ${props.assetMaintenance?.userasset?.statusType === 'available'
                                  ? 'bg-light-green'
                                  : props.assetMaintenance?.userasset?.statusType === 'lease'
                                    ? 'bg-light-blue'
                                    : props.assetMaintenance?.userasset?.statusType === 'dispose'
                                      ? 'bg-light-pink'
                                      : props.assetMaintenance?.userasset?.statusType === 'check_out'
                                        ? 'bg-light-blue'
                                        : ''
                                  }`}
                              >
                                {props.assetMaintenance?.userasset?.statusType === 'check_out'
                                  ? 'Checked out'
                                  : props.assetMaintenance?.userasset?.statusType === 'lease'
                                    ? 'Leased'
                                    : props.assetMaintenance?.userasset?.statusType === 'dispose'
                                      ? 'Disposed'
                                      : props.assetMaintenance?.userasset?.statusType === 'available'
                                        ? 'Available'
                                        : props.assetMaintenance?.userasset?.statusType}
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div className='pt-4 p-0 border-top d-flex justify-content-end'>
                <button
                  onClick={props.onHide}
                  type='button'
                  className='btn btn-light-primary   main-btn-style me-2'
                  data-bs-dismiss='modal'
                >
                  Close
                </button>
                <Link
                  to={'/maintenances'}
                  className='btn btn-primary main-btn-style'
                  href='/assets/detail/29962946'
                  id='eventUrl'
                >
                  More Details
                </Link>
              </div>
            </Tab>
          </Tabs>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default MaintenancesDetailModal
