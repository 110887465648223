import { headersSplitApi  } from './HeadersConfig'

export const importsApi = headersSplitApi.enhanceEndpoints({addTagTypes: ['Imports']}).injectEndpoints({
	endpoints: (build) => ({
		importCategories: build.mutation<any, any>({
			query: ({ data }) => ({
				url: `import/categories`,
				method: 'POST',
				body: data,
			}),
			invalidatesTags: () => [{ type: 'Imports' }],
		}),
		importSites: build.mutation<any, any>({
			query: ({ data }) => ({
				url: `import/sites`,
				method: 'POST',
				body: data,
			}),
			invalidatesTags: () => [{ type: 'Imports' }],
		}),
		importCustomers: build.mutation<any, any>({
			query: ({ data }) => ({
				url: `import/customers`,
				method: 'POST',
				body: data,
			}),
			invalidatesTags: () => [{ type: 'Imports' }],
		}),
		importLocations: build.mutation<any, any>({
			query: ({ data }) => ({
				url: `import/locations`,
				method: 'POST',
				body: data,
			}),
			invalidatesTags: () => [{ type: 'Imports' }],
		}),
		importAssets: build.mutation<any, any>({
			query: ({ data }) => ({
				url: 'import/assets',
				method: 'POST',
				body: data,
			}),
			invalidatesTags: () => [{ type: 'Imports' }],
		}),
		importMaintenances: build.mutation<any, any>({
			query: ({ data }) => ({
				url: `import/maintenances`,
				method: 'POST',
				body: data,
			}),
			invalidatesTags: () => [{ type: 'Imports' }],
		}),
		importPersons: build.mutation<any, any>({
			query: ({ data }) => ({
				url: `import/persons`,
				method: 'POST',
				body: data,
			}),
			invalidatesTags: () => [{ type: 'Imports' }],
		}),
		importDepartments: build.mutation<any, any>({
			query: ({ data }) => ({
				url: `import/departments`,
				method: 'POST',
				body: data,
			}),
			invalidatesTags: () => [{ type: 'Imports' }],
		}),
		importWarranties: build.mutation<any, any>({
			query: ({ data }) => ({
				url: `import/warranties`,
				method: 'POST',
				body: data,
			}),
			invalidatesTags: () => [{ type: 'Imports' }],
		}),
		uploads: build.mutation<any, any>({
			query: ({ data, type }) => ({
				url: `import/uploads/${type}`,
				method: 'POST',
				body: data,
			}),
			invalidatesTags: () => [{ type: 'Imports' }],
		}),
		previewData: build.mutation<any, any>({
			query: ({ data, type }) => ({
				url: `import/preview/${type}`,
				method: 'POST',
				body: data,
			}),
			invalidatesTags: () => [{ type: 'Imports' }],
		}),
	}),
});

export const {
	useImportCategoriesMutation,
	useImportAssetsMutation,
	useImportCustomersMutation,
	useImportLocationsMutation,
	useImportMaintenancesMutation,
	useImportPersonsMutation,
	useImportSitesMutation,
	useImportDepartmentsMutation,
	useImportWarrantiesMutation,
	useUploadsMutation,
	usePreviewDataMutation,
} = importsApi