import { useEffect, useState } from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import { Link, useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import { login } from '../core/_requests'
import { useAuth } from '../core/Auth'
import { toast } from 'react-toastify'
import ReCAPTCHA from 'react-google-recaptcha'
import OTPModal from './Modal/OtpModal'
import { invalidateCache } from '../core/AuthHelpers'
import { useDispatch } from 'react-redux'
import moment from 'moment'

const loginSchema = Yup.object().shape({
	email: Yup.string()
		.email('Wrong email format')
		.min(3, 'Minimum 3 symbols')
		.max(50, 'Maximum 50 symbols')
		.required('Email is required'),
	password: Yup.string()
		.min(3, 'Minimum 3 symbols')
		.max(50, 'Maximum 50 symbols')
		.required('Password is required'),
	reCaptcha: Yup.string().required('ReCaptcha is required'),
})

const initialValues = {
	email: '',
	password: '',
	rememberme: false,
	reCaptcha: '',
}

export function Login() {
	const navigate = useNavigate()
	const [loading, setLoading] = useState(false)
	const [showPassword, setShowPassword] = useState(false)
	const { saveAuth, setCurrentUser } = useAuth()
	const [showOTP, setShowOTP] = useState(false)
	const handleClose = () => setShowOTP(false)
	const [captchaLoaded, setCaptchaLoaded] = useState(false)
	const dispatch = useDispatch()
	const [isTime, setIsTime] = useState(false)
	const [remainingTime, setRemainingTime] = useState<any>()
	const handleCaptchaChange = (response: string | null): void => {
		if (response) {
			formik.setFieldValue('reCaptcha', response || '')
		}
	}

	const handleCaptchaLoad = () => {
		setCaptchaLoaded(true)
	}
	const formik = useFormik({
		initialValues,
		validationSchema: loginSchema,
		onSubmit: async (values, { setSubmitting }) => {
			setLoading(true)
			try {
				const { data: user } = await login(values?.email, values?.password, values?.rememberme)
				if (user?.message?.isOTPRequired) {
					setShowOTP(true)
					setRemainingTime(moment.duration(1, 'minutes'))
					setIsTime(true)
					setLoading(false)
					toast.dismiss()
					toast.success(user?.message?.message)
					return
				}
				setCurrentUser(user.data)
				saveAuth({ token: user.token })
				toast.dismiss()
				toast.success(user?.message)
			} catch (error: any) {
				toast.dismiss()
				setLoading(false)
				setCurrentUser(undefined)
				saveAuth(undefined)
				if (error.response.status === 403) {
					toast.error(error.response.data.message)
					navigate(`/auth/verify-email?email=${values.email}`)
				} else {
					toast.error(error.response.data.message)
				}
				setSubmitting(false)
			}
		},
	})

	useEffect(() => {
		invalidateCache(dispatch)
	}, [])

	return (
		<form
			className='form w-100'
			onSubmit={formik.handleSubmit}
			noValidate
			id='kt_login_signin_form'
		>
			<div className='text-center mb-7'>
				<h1 className='text-dark mb-2'>Sign In to Asset Rack</h1>
				<div className='text-gray-700 fs-5'>
					New Here?{' '}
					<Link to='/auth/registration' className='link-primary fw-bolder'>
						Create an Account
					</Link>
				</div>
			</div>

			{formik.status && (
				<div className='mb-lg-15 alert alert-danger'>
					<div className='alert-text font-weight-bold'>{formik.status}</div>
				</div>
			)}

			<div className='fv-row mb-4'>
				<label className='form-label fs-6 fw-bolder text-dark required'>Email</label>
				<div>
					<input
						placeholder='Email'
						{...formik.getFieldProps('email')}
						className={clsx(
							'form-control  form-control-solid',
							{ 'is-invalid': formik.touched.email && formik.errors.email },
							{
								'is-valid': formik.touched.email && !formik.errors.email,
							}
						)}
						type='email'
						name='email'
						autoComplete='off'
					/>
				</div>
				{formik.touched.email && formik.errors.email && (
					<div className='fv-plugins-message-container'>
						<div className='fv-help-block'>
							<span role='alert'>{formik.errors.email}</span>
						</div>
					</div>
				)}
			</div>
			<div className='fv-row mb-4'>
				<div className='d-flex justify-content-between'>
					<div className='d-flex flex-stack mb-2'>
						<label className='form-label fw-bolder text-dark fs-6 required mb-0'>Password</label>
					</div>
				</div>
				<div className='d-flex position-relative'>
					<div className='w-100'>
						<input
							type={showPassword ? 'text' : 'password'}
							autoComplete='off'
							placeholder='Password'
							{...formik.getFieldProps('password')}
							className={clsx(
								'form-control  form-control-solid',
								{
									'is-invalid': formik.touched.password && formik.errors.password,
								},
								{
									'is-valid': formik.touched.password && !formik.errors.password,
								}
							)}
						/>
					</div>
					<i
						onClick={() => setShowPassword((prevState) => !prevState)}
						className={
							showPassword
								? 'bi bi-eye-slash fs-md-5 fs-6' + ' login-eye'
								: 'bi bi-eye fs-md-5 fs-6' + ' login-eye'
						}
					/>
				</div>
				{formik.touched.password && formik.errors.password && (
					<div className='fv-plugins-message-container'>
						<div className='fv-help-block'>
							<span role='alert'>{formik.errors.password}</span>
						</div>
					</div>
				)}
			</div>
			<div className='form-check form-check-custom  fv-row mb-4 d-flex justify-content-between flex-wrap'>
				<Link
					to='/auth/forgot-password'
					className='form-check-label text-danger fs-6'
				>
					Forgot Password?
				</Link>
			</div>
			<div className='fv-row mb-sm-7 mb-4 d-flex flex-column justify-content-center align-items-center'>
				<div className='site-recaptcha'>
					<div className='captcha'>
						<ReCAPTCHA
							sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY as string}
							onChange={handleCaptchaChange}
							onExpired={() => formik.setFieldValue('reCaptcha', '')}
							onErrored={() => formik.setFieldValue('reCaptcha', '')}
							asyncScriptOnLoad={handleCaptchaLoad}
						/>
						{!captchaLoaded ? <> Captcha Loading...</> : <></>}
					</div>
				</div>
				{formik.touched.reCaptcha && formik.errors.reCaptcha && (
					<div className='fv-plugins-message-container site-recaptcha-message'>
						<div className='fv-help-block'>
							<span role='alert'>{formik.errors.reCaptcha}</span>
						</div>
					</div>
				)}
			</div>
			<button
				type='submit'
				id='kt_sign_in_submit'
				className='btn btn-lg btn-primary w-100 mb-0 text-center'
				disabled={formik.isSubmitting || !formik.isValid || !formik.dirty}
			>
				{!loading && <span className='indicator-label'>Continue</span>}
				{loading && (
					<span className='indicator-progress d-block'>
						Please wait...
						<span className='spinner-border spinner-border-sm align-middle ms-2'></span>
					</span>
				)}
			</button>
			<OTPModal setRemainingTime={setRemainingTime} isTime={isTime} setIsTime={setIsTime} remainingTime={remainingTime} showOTP={showOTP} handleClose={handleClose} loginValues={formik?.values} />
		</form>
	)
}
