import { useEffect, useState } from 'react'
import PreviewTable from './PreviewTable';

const TableColumns = (props: any) => {

    const { setEditableData, editableData, setShowColumns, showColumns, setTableData } = props
    const [organizedData, setOrganizedData] = useState({});

    useEffect(() => {
        const calculatedOrganizedData = editableData?.reduce((acc: any, item: any) => {
            if (!acc[item.columnHeading]) {
                acc[item.columnHeading] = [];
            }
            acc[item.columnHeading].push(item);
            return acc;
        }, {});

        setOrganizedData(calculatedOrganizedData);
    }, [editableData]);


    const handleCheckboxChange = (columnHeading: string, itemId: number): void => {
        setEditableData((prevData: any) => {
            const updatedData = prevData.map((item: any) =>
                item.columnHeading === columnHeading && item.id === itemId
                    ? { ...item, defaultActive: !item.defaultActive }
                    : item
            );

            const hasTrueValue = updatedData.some((item: any) => item.defaultActive);

            if (!hasTrueValue && updatedData.length > 1) {
                return prevData;
            }

            return updatedData;
        });
    };


    const submit = () => {
        setShowColumns(!showColumns)
        if (document.getElementById('table-data')) {
            setTableData(document.getElementById('table-data')?.innerHTML)
        }
    }

    return (
        <>
            <div className='d-flex'>
                {Object.entries(organizedData)?.map(([heading, items]: any) => (
                    <div key={heading} className='org-heading'>
                        <h2>{heading}</h2>
                        <ul>
                            {items.map((item: any) => (
                                <li key={item?.id}>
                                    <label htmlFor={`column-checkbox-${item.id}`}>
                                        <input
                                            id={`column-checkbox-${item.id}`}
                                            type="checkbox"
                                            checked={item?.defaultActive}
                                            onChange={() => handleCheckboxChange(heading, item?.id)}
                                        />
                                        {item?.columnName}
                                    </label>
                                </li>
                            ))}
                        </ul>
                    </div>
                ))}
            </div>

            <div className='table' id="table-data">
                <PreviewTable editableData={editableData} />
            </div>


            <div className=' d-flex align-items-center justify-content-end'>
                <div className='d-flex align-items-center'>
                    <button type='submit' className='btn btn-light-primary   main-btn-style me-2' onClick={() => setShowColumns(!showColumns)}> Cancel </button>
                    <button type='submit' className='btn btn-primary' onClick={() => submit()}> Save </button>
                </div>
            </div>
        </>
    )
}

export default TableColumns