import { useEffect, useState } from "react";
import ManageGroup from "./ManageGroup";
import { useDeleteSubUserMutation, useGetSubUserQuery, useUpdateSubUserMutation } from "App/Modules/Services/SubUser";
import { useNavigate, useParams } from "react-router";
import { EditUserDetails } from "./EditUserDetails";
import SplashScreen from "App/SplashScreen";
import { useFormik } from "formik";
import { IProfileDetails, profileDetailsInitValues as initialValues } from './SettingsModel';
import * as Yup from 'yup'
import { toast } from "react-toastify";

const profileDetailsSchema = Yup.object().shape({
  firstName: Yup.string()
    .min(3, 'First name must be at least 3 characters')
    .required('First name is required')
    .nullable(),

  lastName: Yup.string()
    .min(3, 'Last name must be at least 3 characters')
    .required('Last name is required')
    .nullable(),
  title: Yup.string().min(5, 'Title must be at least 5 characters').nullable(),
  timeZone: Yup.string().required('Time zone is required').nullable(),
  dateFormat: Yup.string().required('Date Format is required').nullable(),
  timeFormat: Yup.string().required('Time Format is required').nullable(),
})

const EditUser = () => {
  const { id } = useParams()
  const { data: userData, isLoading: isLoadingGetSubUser } = useGetSubUserQuery({ id: id })
  const [selectedGroupName, setSelectedGroupName] = useState('');
  const [loading, setLoading] = useState(false)
  const [updateSubUser] = useUpdateSubUserMutation()
  const navigate = useNavigate()
  const handleGroupSelect = (groupName) => {
    setSelectedGroupName(groupName);
  };

  const formik = useFormik<IProfileDetails>({
    initialValues,
    validationSchema: profileDetailsSchema,
    onSubmit: async (values) => {
      setLoading(true)
      try {
        if (!values.image) {
          delete values['image']
        }
        const formData = new FormData()
        for (const key of Object.keys(values)) {
          if (Object.prototype.hasOwnProperty.call(values, key)) {
            if (values) {
              const value = values[key as keyof IProfileDetails]
              formData.append(key, value)
            }
          }
        }
        const { data, error }: any = await updateSubUser({ formData, id })
        if (data?.success) {
          toast.dismiss()
          navigate('/users')
          toast.success(data?.message)
        } else {
          toast.dismiss()
          navigate('/users')
          toast.error(error?.data?.message)
        }
      } catch (error: any) {
        toast.dismiss()
        toast.error(error?.data?.message)
      } finally {
        setLoading(false)
      }
    }
  })

  useEffect(() => {
    formik.setValues({
      image: userData?.user?.photo || '',
      firstName: userData?.user?.firstName || '',
      lastName: userData?.user?.lastName || '',
      title: userData?.user?.title || '',
      phone: userData?.user?.phone || "",
      email: userData?.user?.email || "",
      timeZone: userData?.user?.timeZone || "",
      dateFormat: userData?.user?.dateFormat || "",
      timeFormat: userData?.user?.timeFormat || "",
      groupName: userData?.user?.groupName || ""
    });
  }, [userData]);

  return (
    <>
      <SplashScreen isLoadingTemplate={isLoadingGetSubUser} />
      <ManageGroup formik={formik} onSelectGroup={handleGroupSelect} userData={userData} selectedGroupName={selectedGroupName} />
      <EditUserDetails loading={loading} formik={formik} userData={userData} id={id} selectedGroupName={selectedGroupName} />
    </>
  )
}

export default EditUser
