import { useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { initialWarranty } from '../../Core/_models'
import {
    useAddWarrantyMutation,
    useDeleteWarrantyMutation,
    useGetWarrantyQuery,
    useUpdateWarrantyMutation,
} from 'App/Modules/Services/Warranty'
import { checkUserUnauthorized, useAuth } from 'App/Modules/Auth'
import { toast } from 'react-toastify'
import Loader from 'App/BeatLoader'
import moment from 'moment'
import DeleteConfirmationModal from './DeleteConfirmationModal'
import Select from 'react-select'
import { useGetAllPersonsQuery, useGetPersonsQuery } from 'App/Modules/Services/Persons'

const WarrantySchema = Yup.object().shape({
    duration: Yup.number().required('Month length is required'),
    expirationDate: Yup.string().required('Expiration date is required'),
    description: Yup.string().trim(),
})

const CheckedoutModal = (props: any) => {
    const { currentUser, saveAuth, setCurrentUser } = useAuth()
    const [loading, setLoading] = useState(false)
    const [assignOptions, setAssignOptions] = useState<any>([])
    const warrantyId = props.warrantyId
    const {
        data: singleWarrantyData,
        error: singleWarrantyDataErr,
        isLoading,
        isSuccess,
    } = useGetWarrantyQuery({ id: warrantyId }, { skip: !warrantyId })
    const [addWarranty, { isLoading: isWarrantyAdd }] = useAddWarrantyMutation()
    const [updateWarranty, { isLoading: isUpdateWarranty }] = useUpdateWarrantyMutation()

    const { data: personData } = useGetPersonsQuery({})
	const setOptionsForSelect = () => {
		const options: any = []
		personData?.persons?.forEach((person) => {
			options.push({
				label: (
					<div className='d-flex flex-column'>
						<span className='personFullName'>{`${person?.firstName} ${person?.lastName}`}</span>
						<span className='personEmail'> {person?.email}</span>
					</div>
				),
				value: person?.email,
			})
		})
		setAssignOptions(options)
	}

    useEffect(() => {
		setOptionsForSelect()
	}, [personData])

    useEffect(() => {
        if (isWarrantyAdd || isUpdateWarranty || isLoading) setLoading(true)
        else setLoading(false)
    }, [isWarrantyAdd, isUpdateWarranty, isLoading])

    const formik = useFormik({
        initialValues: { ...initialWarranty },
        validationSchema: WarrantySchema,
        onSubmit: async (values, { resetForm }) => {
            values.assetId = Number(props.assetId)
            values.userId = props.assetUserId
            if (currentUser) {
                try {
                    const data: any =
                        warrantyId > 0 && singleWarrantyData?.warranty?.id
                            ? await updateWarranty({ body: values, id: warrantyId }).unwrap()
                            : await addWarranty(values).unwrap()
                    if (data?.success) {
                        toast.dismiss()
                        toast.success(data?.message)
                        resetForm()
                        props.handleClose()
                    } else {
                        toast.dismiss()
                        toast.error(data?.message)
                        props.handleClose()
                        resetForm()
                    }
                } catch (error: any) {
                    toast.error(error?.data?.message)
                    props.handleClose()
                    checkUserUnauthorized(error?.data, saveAuth, setCurrentUser, toast)
                }
            }
        },
    })

    useEffect(() => {
        setLoading(false)
        if (isSuccess && singleWarrantyData) {
            formik.setValues({
                duration: singleWarrantyData?.warranty?.duration,
                expirationDate: moment(singleWarrantyData?.warranty?.expirationDate).format('YYYY-MM-DD'),
                description: singleWarrantyData?.warranty?.description,
            })
        } else if (!isSuccess && singleWarrantyDataErr) {
            props.handleClose()
            toast.error('something went wrong')
        }
    }, [singleWarrantyData, isSuccess])

    const renderModalContent = () => {
        if (props.assetViewModal === 'checkIn') {
            return (
                <>
                    <Modal.Header closeButton>
                        <Modal.Title>Check-In</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <form onSubmit={formik.handleSubmit} noValidate className='form'>
                            <div className='inner-site-modal'>
                                <div className='row mb-6 align-items-center'>
                                    <label className='col-lg-3 col-form-label fw-bold fs-6'>Check-in from</label>
                                    <div className='col-lg-9 fv-row'>
                                        <div className='d-flex'>
                                            <div className='m-2 form-check-custom'>
                                                <div>
                                                    <input
                                                        className='form-check-input custom-form-check-input'
                                                        {...formik.getFieldProps('checkedTo')}
                                                        type='checkbox'
                                                        value={'person'}
                                                        name={'checkedTo'}
                                                        id='person'
                                                    />
                                                </div>
                                                <label className='form-check-label fw-bold fs-6' htmlFor='person'>
                                                    Person
                                                </label>
                                            </div>
                                            <div className='m-2 form-check-custom'>
                                                <div>
                                                    <input
                                                        className='form-check-input custom-form-check-input'
                                                        type='checkbox'
                                                        {...formik.getFieldProps('checkedTo')}
                                                        onClick={() => {
                                                            formik.setFieldValue('assignTo', undefined)
                                                        }}
                                                        value={'site'}
                                                        name={'checkedTo'}
                                                        id='location'
                                                    />
                                                </div>
                                                <label className='form-check-label fw-bold fs-6' htmlFor='location'>
                                                    Site / Location
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row mb-6'>
                                    <label className={'col-lg-3 col-form-label fw-bold fs-6 required'}>
                                        Return Date
                                    </label>
                                    <div className='col-lg-9 fv-row'>
                                        <div className='input-group'>
                                            <input
                                                className='form-control form-control-solid'
                                                {...formik.getFieldProps('returnDate')}
                                                type='date'
                                                placeholder='Pick date rage'
                                                id='kt_daterangepicker_3'
                                                onChange={(e) => {
                                                    formik.handleChange(e)
                                                }}
                                            />
                                            <span className='input-group-text border-0'>
                                                <i className='fa fa-calendar'></i>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className='row mb-6'>
                                    <label className='col-lg-3 col-form-label fw-bold fs-6'>Site</label>
                                    <div className='col-lg-9 fv-row'>
                                        <select
                                            className='form-select form-select-solid mb-3'
                                            {...formik.getFieldProps('siteId')}
                                            aria-label='Select example'
                                            onChange={(e) => {
                                                formik.handleChange(e)
                                            }}
                                        >
                                            <option>Select Site</option>
                                        </select>
                                    </div>
                                </div>
                                <div className='row mb-6'>
                                    <label className='col-lg-3 col-form-label fw-bold fs-6'>Location</label>
                                    <div className='col-lg-9 fv-row'>
                                        <select
                                            className='form-select form-select-solid mb-3'
                                            {...formik.getFieldProps('locationId')}
                                            aria-label='Select example'
                                            onChange={(e) => {
                                                formik.handleChange(e)
                                                const value = e.target.value
                                                if (value === '') {
                                                    formik.setFieldValue('locationId', undefined)
                                                }
                                            }}
                                        >
                                            <option>Select Location</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className=' d-flex align-items-center justify-content-end'>
                                <button
                                    onClick={props.handleClose}
                                    className='btn btn-light-primary   main-btn-style me-2'
                                >
                                    Cancel
                                </button>
                                <button
                                    type='submit'
                                    className='btn btn-primary main-btn-style'
                                    disabled={isLoading}
                                >
                                    Check-In
                                </button>
                            </div>
                        </form>
                    </Modal.Body>
                </>
            )
        } else if (props.assetViewModal === 'checkout') {
            return (
                <>
                    <Modal.Header closeButton>
                        <Modal.Title>Check-Out</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <form onSubmit={formik.handleSubmit} noValidate className='form'>
                            <div className='inner-site-modal'>
                                <div className='row mb-6 align-items-center'>
                                    <label className='col-lg-3 col-form-label fw-bold fs-6'>Check-out To</label>
                                    <div className='col-lg-9 fv-row'>
                                        <div className='d-flex'>
                                            <div className='m-2 form-check-custom'>
                                                <div>
                                                    <input
                                                        className='form-check-input custom-form-check-input'
                                                        {...formik.getFieldProps('checkedTo')}
                                                        type='checkbox'
                                                        value={1}
                                                        name={'checkedTo'}
                                                        id='person'
                                                    />
                                                </div>
                                                <label className='form-check-label fs-6 fw-bold' htmlFor='person'>
                                                    Person
                                                </label>
                                            </div>
                                            <div className='m-2 form-check-custom'>
                                                <div>
                                                    <input
                                                        className='form-check-input custom-form-check-input'
                                                        type='checkbox'
                                                        {...formik.getFieldProps('checkedTo')}
                                                        value={0}
                                                        name={'checkedTo'}
                                                        id='location'
                                                    />
                                                </div>
                                                <label className='form-check-label fs-6 fw-bold' htmlFor='location'>
                                                    Site / Location
                                                </label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='row mb-6'>
                                    <label className='col-lg-3 col-form-label fw-bold fs-6'>Check-out Date</label>
                                    <div className='col-lg-9 fv-row'>
                                        <input
                                            className='form-control form-control-solid'
                                            {...formik.getFieldProps('checkoutDate')}
                                            type='date'
                                            placeholder='Pick date rage'
                                            id='kt_daterangepicker_3'
                                        />
                                    </div>
                                </div>
                                <div className='row mb-6'>
                                    <label className='col-lg-3 col-form-label fw-bold fs-6 required'>
                                        <span>Assign To</span>
                                    </label>
                                    <div className='col-lg-9 fv-row'>
                                        <div className='d-flex'>
                                            <div className='flex-1'>
                                                <Select
                                                    options={assignOptions}
                                                    isClearable={true}
                                                    isSearchable={true}
                                                    placeholder='Select a Person'
                                                    classNamePrefix='custom-select'
                                                    className='custom-react-select w-100'
                                                />
                                            </div>
                                            <button
                                                type='button'
                                                className='btn btn-light-primary d-flex align-items-center ms-3'
                                            >
                                                <i className='la la-plus fs-3' />
                                                New
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className='row mb-6'>
                                    <label className='col-lg-3 col-form-label fw-bold fs-6'>
                                        <span>Site</span>
                                    </label>
                                    <div className='col-lg-9 fv-row'>
                                        <select
                                            className='form-select form-control form-control-solid form-select-solid '
                                        >
                                            <option>Select Site</option>
                                        </select>
                                    </div>
                                </div>
                                <div className='row mb-6'>
                                    <label className='col-lg-3 col-form-label fw-bold fs-6'>
                                        <span>Location</span>
                                    </label>
                                    <div className='col-lg-9 fv-row d-flex'>
                                        <select
                                            className='form-select form-control form-control-solid form-select-solid  '
                                            {...formik.getFieldProps('locationId')}
                                            onChange={(e) => {
                                                formik.handleChange(e)
                                                const value = e.target.value
                                                if (value === '') {
                                                    formik.setFieldValue('locationId', undefined)
                                                }
                                            }}
                                        >
                                            <option>Select Location</option>
                                        </select>
                                    </div>
                                </div>
                                <div className='row mb-6'>
                                    <label className='col-lg-3 col-form-label fw-bold fs-6'>Due Date</label>
                                    <div className='col-lg-9 fv-row'>
                                        <div className='input-group'>
                                            <input
                                                className='form-control form-control-solid'
                                                {...formik.getFieldProps('dueDate')}
                                                type='date'
                                                placeholder='Pick date rage'
                                                id='kt_daterangepicker_3'
                                            />
                                            <span className='input-group-text border-0'>
                                                <i className='fa fa-calendar'></i>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className='row mb-6'>
                                    <label className='col-lg-3 col-form-label fw-bold fs-6'>Check-out Notes</label>
                                    <div className='col-lg-9 fv-row'>
                                        <textarea
                                            className='form-control'
                                            {...formik.getFieldProps('checkoutNotes')}
                                            cols={20}
                                            rows={4}
                                        ></textarea>
                                    </div>
                                </div>
                            </div>
                            <div className=' d-flex align-items-center justify-content-end'>
                                <button
                                    onClick={props.handleClose}
                                    className='btn btn-light-primary   main-btn-style me-2'
                                >
                                    Cancel
                                </button>
                                <button
                                    type='submit'
                                    className='btn btn-primary main-btn-style'
                                    disabled={isLoading}
                                >
                                    {!loading && 'Check-out'}
                                    {loading && (
                                        <span className='indicator-progress d-block'>
                                            Please wait...{' '}
                                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                        </span>
                                    )}
                                </button>
                            </div>
                        </form>
                    </Modal.Body>
                </>
            )
        } else if (props.assetViewModal === 'lease') {
            return (
                <>
                    <Modal.Header closeButton>
                        <Modal.Title>Lease</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <form onSubmit={formik.handleSubmit} noValidate className='form'>
                            <div className='inner-site-modal'>
                                <div className='row mb-6'>
                                    <label className='col-lg-3 col-form-label required fw-bold fs-6'>
                                        Lease Begins
                                    </label>
                                    <div className='col-lg-9 fv-row'>
                                        <div className='input-group'>
                                            <input
                                                className='form-control form-control-solid'
                                                {...formik.getFieldProps('leaseBegins')}
                                                type='date'
                                                placeholder='Pick date rage'
                                                id='kt_daterangepicker_3'
                                            />
                                            <span className='input-group-text border-0'>
                                                <i className='fa fa-calendar'></i>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className='row mb-6'>
                                    <label className='col-lg-3 col-form-label fw-bold fs-6 required'>
                                        <span>Leasing Customer</span>
                                    </label>
                                    <div className='col-lg-9 fv-row'>
                                        <div className=' d-flex'>
                                            <Select
                                                isSearchable={true}
                                                placeholder='Select a Customer'
                                                classNamePrefix='custom-select'
                                                className='custom-react-select w-100'
                                            />
                                            <button
                                                type='button'
                                                className='btn btn-light-primary d-flex align-items-center ms-3'
                                            >
                                                <i className='la la-plus fs-3' />
                                                New
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className='row mb-6'>
                                    <label className='col-lg-3 col-form-label fw-bold fs-6'>Lease Expires</label>
                                    <div className='col-lg-9 fv-row'>
                                        <div className='input-group'>
                                            <input
                                                className='form-control form-control-solid'
                                                {...formik.getFieldProps('leaseExpires')}
                                                type='date'
                                                placeholder='Pick date rage'
                                                id='kt_daterangepicker_3'
                                            />
                                            <span className='input-group-text border-0'>
                                                <i className='fa fa-calendar'></i>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className='row mb-6'>
                                    <label className='col-lg-3 col-form-label fw-bold fs-6'>
                                        <span className='lease-notes'>Lease Notes</span>
                                    </label>
                                    <div className='col-lg-9 fv-row'>
                                        <textarea
                                            className='form-control'
                                            {...formik.getFieldProps('leaseNotes')}
                                            cols={20}
                                            rows={4}
                                        ></textarea>
                                    </div>
                                </div>
                            </div>
                            <div className='d-flex align-items-center justify-content-end'>
                                <button
                                    onClick={props.handleClose}
                                    className='btn btn-light-primary   main-btn-style me-2'
                                >
                                    Cancel
                                </button>
                                <button type='submit' className='btn btn-primary ms-2'>
                                    Lease
                                </button>
                            </div>
                        </form>
                    </Modal.Body>
                </>
            )
        } else if (props.assetViewModal === 'dispose') {
            return (
                <>
                    <Modal.Header closeButton>
                        <Modal.Title>Dispose Asset</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <form onSubmit={formik.handleSubmit} noValidate className='form'>
                            <div className='inner-site-modal'>
                                <div className='row mb-6'>
                                    <label className='col-lg-3 col-form-label required fw-bold fs-6'>
                                        Date Disposed
                                    </label>
                                    <div className='col-lg-9 fv-row'>
                                        <div className='input-group'>
                                            <input
                                                {...formik.getFieldProps('dateDispose')}
                                                type='date'
                                                className='form-control  form-control-solid'
                                                placeholder='Pick date rage'
                                                id='kt_daterangepicker_3'
                                                aria-label='Amount (to the nearest dollar)'
                                            />
                                            <span className='input-group-text border-0'>
                                                <i className='fa fa-calendar'></i>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className='row mb-6'>
                                    <label className='col-lg-3 col-form-label fw-bold fs-6'>
                                        <span>Dispose to</span>
                                    </label>
                                    <div className='col-lg-9 fv-row d-flex'>
                                        <input
                                            type='text'
                                            className='form-control form-control-solid mb-3 mb-lg-0'
                                            autoComplete='off'
                                            {...formik.getFieldProps('disposedTo')}
                                        />
                                    </div>
                                </div>
                                <div className='row mb-6'>
                                    <label className='col-lg-3 col-form-label fw-bold fs-6'>Notes</label>
                                    <div className='col-lg-9 fv-row'>
                                        <textarea
                                            className='form-control'
                                            {...formik.getFieldProps('notes')}
                                            cols={20}
                                            rows={4}
                                        ></textarea>
                                    </div>
                                </div>
                            </div>
                            <div className='d-flex align-items-center justify-content-end'>
                                <button
                                    onClick={props.handleClose}
                                    className='btn btn-light-primary   main-btn-style me-2'
                                >
                                    Cancel
                                </button>
                                <button
                                    type='submit'
                                    className='btn btn-primary main-btn-style'
                                >
                                    Dispose
                                </button>
                            </div>
                        </form>
                    </Modal.Body>
                </>
            )
        }
    }

    return (
        <>
            {loading ? (
                <Loader loading={loading} />
            ) : (
                <Modal
                    className='custom-modal-sm'
                    show={props.show}
                    onHide={() => {
                        formik.resetForm()
                        props.handleClose()
                    }}
                    backdrop='static'
                    keyboard={false}
                >
                    {renderModalContent()}
                </Modal>
            )}
        </>
    )
}

export default CheckedoutModal
