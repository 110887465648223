import {useEffect, useState} from 'react'
import {PageTitle} from '_metronic/layout/core'
import {useIntl} from 'react-intl'
import {AssetsReportHeader} from '../AssetsReportHeader'
import SetupColumns from '../../../Asset/Components/SetupColumns'
import {useLocation} from 'react-router'
import {useGetCompanyQuery} from 'App/Modules/Services/Company'
import {useGetAllAssetsReportsQuery} from 'App/Modules/Services/AssetsReports'
import Columns from '../../../Asset/Components/Columns.json'
import {toast} from 'react-toastify'
import SetupCoulmnListing from 'App/Modules/Reports/AssetReports/Components/SetupCoulmnListing'
import {useGetAllCategoriesQuery} from 'App/Modules/Services/Categories'
import {Category} from '../../../Categories/Core/_models'
import {useAuth} from 'App/Modules/Auth'
import Pagination from 'rc-pagination'
import {currentUserAssets, nameKeys} from 'config'
import localeInfo from 'rc-pagination/lib/locale/en_US'
import {ColumnData} from 'App/Modules/Reports/AssetReports/Components/RenderColumnData'
import {Link} from 'react-router-dom'
import moment from 'moment'
import SplashScreen from 'App/SplashScreen'
import Loader from 'App/BeatLoader'
import getSymbolFromCurrency from 'currency-symbol-map'
function ReportByCategories() {
  const {currentUser, saveAuth, setCurrentUser} = useAuth()
  const [currencyLogo, setCurrencyLogo] = useState<any>()
  const intl = useIntl()
  const [showSetupColumns, setShowSetupColumns] = useState<boolean>(false)
  const [selected, setSelected] = useState<any>([])
  const [deleteDataArr, setDeleteDataArr] = useState<any>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [selectedColumns, setSelectedColumns] = useState<{key: any; value: any}[]>(Columns)
  const selectedColumnsRender = selectedColumns.filter((column) => column.value === true)
  const [page, setPage] = useState<number>(1)
  const [limit, setLimit] = useState<number>(10)
  const [skip, setSkip] = useState<boolean>(false)
  const [exportButton, setExportButton] = useState<boolean>(false)
  const [toastRendered, setToastRendered] = useState(false)
  const [categories, setCategories] = useState<Category[]>([])
  const [pagination, setPagination] = useState<any>({})
  const [assets, setAssets] = useState<any>([])
  const [selectAll, setSelectAll] = useState<any>(false)
  const [criteria, setCriteria] = useState([])
  const [selectedCategoryName, setSelectedCategoryName] = useState('')
  const location = useLocation()
  const {pathname} = location
  const [searchAssets, setSearchAssets] = useState<any>({
    keyword: '',
    searchField: '',
    siteId: '',
    locationId: '',
    categoryId: '',
    departmentId: 5,
    person: '',
    customer: '',
    status: '',
    groupBy: '',
    recordCount: '',
    dateRangeBy: 'purchasedDate',
    quickDateRange: '',
    startDate: '',
    endDate: '',
    exportToExcel: false,
    requestUrl: pathname,
  })
  useEffect(() => {
    // Fetch default category or set default categoryId
    if (categories.length > 0) {
      const defaultCategoryId = categories[0].id // Assuming categories are available
      setSearchAssets((prevState) => ({
        ...prevState,
        categoryId: defaultCategoryId,
      }))
      setSelectedCategoryName(categories[0].category)
      handleSearchSubmit() // Fetch data with default category
    }
  }, [categories])
  const excelKeys = selectedColumnsRender.map((column) => column.key.replace(/_/g, ' '))
  const {
    data: assetsData,
    isLoading: isLoadingGetAssets,
    error,
    isSuccess,
    isError,
  } = useGetAllAssetsReportsQuery(
    {body: new URLSearchParams(searchAssets).toString(), page, limit, keys: excelKeys},
    {skip}
  )
  const {
    data: companyData,
    isSuccess: company,
    isLoading: isLoadingGetCompany,
    refetch: refetchComapnyData,
  } = useGetCompanyQuery()
  useEffect(() => {
    refetchComapnyData()
  }, [])
  useEffect(() => {
    const res = getSymbolFromCurrency(companyData?.company?.currencySymbol)
    setCurrencyLogo(res)
  }, [company])
  const handleReportsFilter = ({target: {value}}: React.ChangeEvent<HTMLSelectElement>) => {
    setSearchAssets((prevState) => ({
      ...prevState,
      categoryId: value,
    }))
    const selectedCategory = categories?.find((category) => category.id == value)
    if (selectedCategory) {
      setSelectedCategoryName(selectedCategory.category)
    }
    handleSearchSubmit()
  }
  const handleSearchAssets = (e) => {
    const {name, value, update, target} = e
    if (name === nameKeys.searchField || name === nameKeys.status) {
      const valuesArray = value.map((obj) => obj.value)
      setSearchAssets({...searchAssets, searchField: valuesArray.toString()})
      setCriteria((prevCriteria: any) => ({
        ...prevCriteria,
        searchField: valuesArray.toString(),
      }))
    } else if (name === nameKeys.customDate) {
      const startDate = update[0] ? moment(update[0]).format('YYYY-MM-DD') : ''
      const endDate = update[1] ? moment(update[1]).format('YYYY-MM-DD') : ''
      setSearchAssets({...searchAssets, startDate, endDate})
      setCriteria((prevCriteria: any) => ({
        ...prevCriteria,
        startDate,
      }))
      setCriteria((prevCriteria: any) => ({
        ...prevCriteria,
        endDate,
      }))
    } else if (name === nameKeys.recordCount) {
      const {value: recordCount} = target
      setLimit(recordCount)
      setCriteria((prevCriteria: any) => ({
        ...prevCriteria,
        recordCount,
      }))
    } else {
      const {name: targetName, value: targetValue, selectedOptions} = target
      setSearchAssets({...searchAssets, [targetName]: targetValue})
      setCriteria((prevCriteria: any) => ({
        ...prevCriteria,
        [targetName]: selectedOptions ? selectedOptions[0]?.label : targetValue,
      }))
    }
  }
  const {data: userCategoryList}: any = useGetAllCategoriesQuery({
    page,
    limit,
  })
  const handlePageChange = (currentPage: any) => {
    setLoading(true)
    setPage(currentPage)
    setSkip(false)
  }
  const handleCheckboxChange = (id: number) => {
    setSelected((prevSelected) => {
      const updatedSelected = prevSelected.includes(id)
        ? prevSelected.filter((itemId) => itemId !== id)
        : [...prevSelected, id]
      const isAllSelected = updatedSelected.length === assetsData?.assetsByCategory?.assets.length
      setSelectAll(isAllSelected)
      return updatedSelected
    })
  }
  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = assetsData?.assetsByCategory?.assets?.map((item: any) => item.id)
      setSelected(newSelecteds)
      setSelectAll(true)
    } else {
      setSelected([])
      setSelectAll(false)
    }
  }
  useEffect(() => {
    if (currentUser && userCategoryList) {
      setCategories(userCategoryList?.category)
      setLoading(false)
    }
  }, [userCategoryList])
  useEffect(() => {
    if (isSuccess) {
      setLoading(false)
      setExportButton(false)
      const {message} = assetsData
      if (isSuccess && !toastRendered) {
        toast.dismiss()
        toast.success(message)
        setToastRendered(true)
      }
    } else if (isError) {
      setLoading(false)
      if ('message' in error) {
        const {message} = error
        if (isError && !toastRendered) {
          toast.dismiss()
          toast.error(message)
          setToastRendered(true)
        }
      }
    }
    return () => {
      setToastRendered(false)
    }
  }, [assetsData, error, isError, isSuccess, assets])
  useEffect(() => {
    setSkip(false)
  }, [])
  useEffect(() => {
    if (currentUser && assetsData?.assetsByCategory?.assets?.length > 0) {
      setAssets(currentUserAssets(currentUser, assetsData))
      setPagination(assetsData?.pagination)
      setSkip(true)
      setLoading(false)
    }
  }, [assetsData])
  const handleSearchSubmit = () => {
    setLoading(true)
    setPage(1)
    setSkip(false)
  }
  const handleExport = () => {
    setExportButton(true)
    setSearchAssets((prevSearchAssets) => ({
      ...prevSearchAssets,
      exportToExcel: true,
    }))
    handleSearchSubmit()
  }
  const handleLimitChange = (newLimit: number) => {
    setLoading(true)
    setLimit(Number(newLimit))
    setPage(1)
    setSkip(false)
  }
  const currentPage = pagination?.page || 0
  const totalRecords = assetsData?.totalAssetsByCategory || 0
  const pageSize = limit || 10
  const startRecord = (currentPage - 1) * pageSize + 1
  const endRecord = Math.min(currentPage * pageSize, totalRecords)
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'Report'})}</PageTitle>
      <SplashScreen isLoadingTemplate={isLoadingGetAssets || isLoadingGetCompany} />
      {!(isLoadingGetAssets || isLoadingGetCompany || loading) ? (
        <>
          <SetupCoulmnListing
            showSetupColumns={showSetupColumns}
            setShowSetupColumns={setShowSetupColumns}
            selectedColumns={selectedColumns}
            setSelectedColumns={setSelectedColumns}
            assetsData={assetsData}
          />
          <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
            <div className='post d-flex flex-column-fluid' id='kt_post'>
              <div id='kt_content_container' className='container'>
                <div className='card'>
                  <div className='card-body'>
                    <AssetsReportHeader handleExport={handleExport} title='Assets by Category' />
                    <div className='d-flex align-items-center justify-content-between flex-wrap mb-4'>
                      <div className='form-group d-flex align-items-center mb-0 me-3 fiter-select'>
                        <label className='col-form-label fw-bold fs-6 me-2'>
                          <span>Category: </span>
                        </label>
                        <select
                          value={searchAssets.categoryId}
                          onChange={handleReportsFilter}
                          className='form-select flex-1'
                        >
                          {categories?.length > 0
                            ? categories?.map((category, index) => {
                                return (
                                  <option key={index} value={category?.id}>
                                    {category?.category}
                                  </option>
                                )
                              })
                            : ''}
                        </select>
                      </div>
                      <SetupColumns setShowSetupColumns={setShowSetupColumns} />
                    </div>
                    <div className='d-flex limit-options align-items-center mb-sm-0 mb-3'>
                      <span className='text-muted'>Showing</span>
                      <select
                        onChange={(e) => handleLimitChange(Number(e.target.value))}
                        value={limit}
                        className='form-select form-select-sm fw-bold mx-3 border-1'
                      >
                        <option value='10'>10</option>
                        <option value='15'>15</option>
                        <option value='20'>20</option>
                        <option value='25'>25</option>
                        <option value='50'>50</option>
                      </select>
                      <span className='text-muted'>entries</span>
                    </div>
                    <div className='fw-bold py-3'>
                      <span>Category Name : {selectedCategoryName}</span>
                    </div>
                    <div className='react-grid-card'>
                      <div className='table-responsive pb-4'>
                        <table
                          id='kt_table_users'
                          className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
                        >
                          <thead>
                            <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                              <th role='columnheader' className='w-10px pe-2'>
                                <div className='header-checkbox me-3'>
                                  <input
                                    type='checkbox'
                                    className='form-check-input custom-form-check-input'
                                    checked={selectAll}
                                    onChange={handleSelectAll}
                                  />
                                </div>
                              </th>
                              {selectedColumnsRender.map((column, index) => (
                                <th key={index}>{column.key.replace(/_/g, ' ')}</th>
                              ))}
                              <th className='text-center'>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {assetsData?.assetsByCategory?.assets?.length > 0 ? (
                              <>
                                {assetsData?.assetsByCategory?.assets?.length > 0 ? (
                                  assetsData?.assetsByCategory?.assets?.map(
                                    (asset: any, rowIndex: number) => (
                                      <tr key={rowIndex} role='row'>
                                        <td role='cell'>
                                          <div className='form-check form-check-sm form-check-custom'>
                                            <input
                                              type='checkbox'
                                              className='form-check-input'
                                              checked={selected.includes(asset?.id)}
                                              onChange={() => handleCheckboxChange(asset?.id)}
                                            />
                                          </div>
                                        </td>
                                        {selectedColumnsRender.map((column, colIndex) => (
                                          <td key={colIndex} className='table-elipses'>
                                            <ColumnData
                                              column={column.key.replace(/_/g, ' ')}
                                              assetsData={{userAssets: [asset]}}
                                              currencyLogo={currencyLogo}
                                            />
                                          </td>
                                        ))}
                                        <td className='text-center'>
                                          <Link
                                            to={`/asset/view/${asset.id}`}
                                            className='menu-link px-5 btn btn-light-secondary p-2 view-btn-style'
                                          >
                                            View
                                          </Link>
                                        </td>
                                      </tr>
                                    )
                                  )
                                ) : (
                                  <tr>
                                    <td
                                      colSpan={selectedColumnsRender?.length + 2}
                                      className='text-center'
                                    >
                                      <div className='d-flex text-center w-100 align-items-center justify-content-center no-records fw-bold rounded'>
                                        No records found
                                      </div>
                                    </td>
                                  </tr>
                                )}
                              </>
                            ) : (
                              <tr>
                                <td
                                  colSpan={selectedColumnsRender?.length + 2}
                                  className='text-center'
                                >
                                  <div className='d-flex text-center w-100 align-items-center justify-content-center no-records fw-bold rounded'>
                                    No records found
                                  </div>
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    {assetsData?.assetsByCategory?.assets?.length ? (
                      <div className='d-sm-flex align-items-center justify-content-between mt-3'>
                        <div className='mt-3'>
                          Total Assets: {assetsData?.assetsByCategory?.assets?.length} Assets
                        </div>
                        <div className='mt-3'>Total Cost: {assetsData?.assetsByCategory?.sum}</div>
                      </div>
                    ) : (
                      ''
                    )}
                    {assetsData?.assetsByCategory?.assets?.length ? (
                      <div className='d-sm-flex align-items-center justify-content-between flex-wrap mt-3'>
                        <div className='m-1'>
                          Showing {startRecord} to {endRecord} of {totalRecords} records
                        </div>

                        {pagination && (
                          <Pagination
                            className='m-1 custom-pagination'
                            defaultCurrent={1}
                            pageSize={limit}
                            current={pagination?.page}
                            total={pagination?.total}
                            onChange={handlePageChange}
                            locale={localeInfo}
                          />
                        )}
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  )
}
export default ReportByCategories
