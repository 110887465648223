import {useEffect, useState} from 'react'
import {PageTitle} from '_metronic/layout/core'
import {useIntl} from 'react-intl'
import {AssetsReportHeader} from '../AssetsReportHeader'
import localeInfo from 'rc-pagination/lib/locale/en_US'

import SetupColumns from '../../../Asset/Components/SetupColumns'
import {useGetCompanyQuery} from 'App/Modules/Services/Company'
import {useDeleteAssetsMutation, useGetAllAssetsQuery} from 'App/Modules/Services/Assets'
import {toast} from 'react-toastify'
import getSymbolFromCurrency from 'currency-symbol-map'
import {checkUserUnauthorized, useAuth} from 'App/Modules/Auth'
import {currentUserAssets, nameKeys} from 'config'
import moment from 'moment'
import {AssetResponse} from '../../../Asset/Core/_models'
import Columns from '../../../Asset/Components/Columns.json'
import {useGetAllAssetsReportsQuery} from 'App/Modules/Services/AssetsReports'
import {useLocation} from 'react-router'
import AssetsSearch from '../../../Asset/Components/AssetsSearch'
import SetupCoulmnListing from 'App/Modules/Reports/AssetReports/Components/SetupCoulmnListing'
import {ColumnData} from 'App/Modules/Reports/AssetReports/Components/RenderColumnData'
import Pagination from 'rc-pagination'
import {Link} from 'react-router-dom'
function ReportByAssetTags() {
  const intl = useIntl()
  const {currentUser, saveAuth, setCurrentUser} = useAuth()
  const [deleteDataArr, setDeleteDataArr] = useState<any>([])
  const [selectAllData, setSelectAllData] = useState<boolean>(false)
  const [selectedData, setSelectedData] = useState<boolean>(false)
  const [assets, setAssets] = useState<any>([])
  const [deleteModal, setDeleteModal] = useState<boolean>(false)
  const [check, setCheck] = useState<number[]>([])
  const [data, setData] = useState({})
  const [show, setShow] = useState<boolean>(false)
  const [showSearch, setShowSearch] = useState<boolean>(false)
  const [skip, setSkip] = useState<boolean>(false)
  const [selected, setSelected] = useState<any>([])
  const [currencyLogo, setCurrencyLogo] = useState<any>()
  const [selectAll, setSelectAll] = useState<any>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [exportButton, setExportButton] = useState<boolean>(false)
  const [pagination, setPagination] = useState<any>({})
  const [page, setPage] = useState<number>(1)
  const [limit, setLimit] = useState<number>(10)
  const [criteria, setCriteria] = useState<any>([])
  const [showSetupColumns, setShowSetupColumns] = useState<boolean>(false)
  const [selectedColumns, setSelectedColumns] = useState<{key: any; value: any}[]>(Columns)
  const selectedColumnsRender = selectedColumns.filter((column) => column.value === true)
  const [toastRendered, setToastRendered] = useState(false)
  const location = useLocation()
  const {pathname} = location
  const [searchAssets, setSearchAssets] = useState<any>({
    keyword: '',
    searchField: '',
    siteId: '',
    locationId: '',
    categoryId: '',
    departmentId: '',
    person: '',
    customer: '',
    status: '',
    groupBy: '',
    recordCount: '',
    dateRangeBy: 'purchasedDate',
    quickDateRange: '',
    startDate: '',
    endDate: '',
    exportToExcel: false,
    requestUrl: pathname,
  })
  const {
    data: companyData,
    isSuccess: company,
    isLoading: isLoadingGetCompany,
    refetch: refetchComapnyData,
  } = useGetCompanyQuery()
  const excelKeys = selectedColumnsRender.map((column) => column.key.replace(/_/g, ' '))
  const {
    data: assetsData,
    isLoading: isLoadingGetAssets,
    error,
    isSuccess,
    isError,
  } = useGetAllAssetsReportsQuery(
    {body: new URLSearchParams(searchAssets).toString(), page, limit, keys: excelKeys},
    {skip}
  )
  const [deleteAssets, {isLoading: isLoadingDelete}] = useDeleteAssetsMutation()
  useEffect(() => {
    if (isSuccess) {
      setLoading(false)
      setExportButton(false)
      const {message} = assetsData
      if (isSuccess && !toastRendered) {
        toast.dismiss()
        toast.success(message)
        setToastRendered(true)
      }
    } else if (isError) {
      setLoading(false)
      if ('message' in error) {
        const {message} = error
        if (isError && !toastRendered) {
          toast.dismiss()
          toast.error(message)
          setToastRendered(true)
        }
      }
    }
    return () => {
      setToastRendered(false)
    }
  }, [assetsData, error, isError, isSuccess])
  useEffect(() => {
    refetchComapnyData()
  }, [])
  useEffect(() => {
    const res = getSymbolFromCurrency(companyData?.company?.currencySymbol)
    setCurrencyLogo(res)
  }, [company])
  useEffect(() => {
    if (isError) {
      const errorData = error as {data: any}
      checkUserUnauthorized(errorData?.data, saveAuth, setCurrentUser, toast)
    }
  }, [isError])
  const handleSearchAssets = (e) => {
    const {name, value, update, target} = e
    if (name === nameKeys.searchField || name === nameKeys.status) {
      const valuesArray = value.map((obj) => obj.value)
      setSearchAssets({...searchAssets, searchField: valuesArray.toString()})
      setCriteria((prevCriteria: any) => ({
        ...prevCriteria,
        searchField: valuesArray.toString(),
      }))
    } else if (name === nameKeys.customDate) {
      const startDate = update[0] ? moment(update[0]).format('YYYY-MM-DD') : ''
      const endDate = update[1] ? moment(update[1]).format('YYYY-MM-DD') : ''
      setSearchAssets({...searchAssets, startDate, endDate})
      setCriteria((prevCriteria: any) => ({
        ...prevCriteria,
        startDate,
      }))
      setCriteria((prevCriteria: any) => ({
        ...prevCriteria,
        endDate,
      }))
    } else if (name === nameKeys.recordCount) {
      const {value: recordCount} = target
      setLimit(recordCount)
      setCriteria((prevCriteria: any) => ({
        ...prevCriteria,
        recordCount,
      }))
    } else {
      const {name: targetName, value: targetValue, selectedOptions} = target
      setSearchAssets({...searchAssets, [targetName]: targetValue})
      setCriteria((prevCriteria: any) => ({
        ...prevCriteria,
        [targetName]: selectedOptions ? selectedOptions[0]?.label : targetValue,
      }))
    }
  }
  const clearSearch = (keyToRemove) => {
    const updatedCriteria = {...criteria}
    delete updatedCriteria[keyToRemove]
    setCriteria(updatedCriteria)
    const updatedSearchAssets = {...searchAssets}
    delete updatedSearchAssets[keyToRemove]
    setSearchAssets(updatedSearchAssets)
    handleSearchSubmit()
    setSearchAssets({
      keyword: '',
      searchField: '',
      siteId: '',
      locationId: '',
      categoryId: '',
      departmentId: '',
      person: '',
      customer: '',
      status: '',
      groupBy: '',
      recordCount: '',
      dateRangeBy: 'purchasedDate',
      quickDateRange: '',
      startDate: '',
      endDate: '',
      exportToExcel: false,
    })
    setLoading(false)
  }
  const handleSearchSubmit = () => {
    setLoading(true)
    setPage(1)
    setSkip(false)
    setShowSearch(false)
  }
  useEffect(() => {
    if (isSuccess) {
      setSkip(true)
    }
  }, [showSearch])
  useEffect(() => {
    setSkip(false)
  }, [])
  useEffect(() => {
    if (currentUser && assetsData?.userAssets?.length > 0) {
      setAssets(currentUserAssets(currentUser, assetsData))
      setPagination(assetsData?.pagination)
      setSkip(true)
      setLoading(false)
    }
  }, [assetsData])
  const handleShow = (item: AssetResponse) => {
    setData(item)
    setShow(true)
  }
  const handleClose = () => {
    setShow(false)
  }
  const handleCheckboxChange = (id: number) => {
    setSelected((prevSelected) => {
      const updatedSelected = prevSelected.includes(id)
        ? prevSelected.filter((itemId) => itemId !== id)
        : [...prevSelected, id]
      const isAllSelected = updatedSelected.length === assetsData?.userAssets?.length
      setSelectAll(isAllSelected)
      return updatedSelected
    })
    setDeleteDataArr((prevSelected) => {
      if (prevSelected.includes(id)) {
        return prevSelected.filter((itemId) => itemId !== id)
      } else {
        return [...prevSelected, id]
      }
    })
  }
  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = assetsData?.userAssets?.map((item: any) => item.id)
      setSelected(newSelecteds)
      setSelectAll(true)
      setDeleteDataArr(newSelecteds)
    } else {
      setSelected([])
      setSelectAll(false)
      setDeleteDataArr([])
    }
  }
  useEffect(() => {
    if (selectAllData) {
      const newSelecteds = assetsData?.userAssets?.map((item: any) => item.id) || []
      setSelected(newSelecteds)
      setDeleteDataArr(newSelecteds)
    }
    setSelectAllData(false)
  }, [selectedData])
  useEffect(() => {
    if (deleteDataArr && deleteDataArr.length === 0) {
      const multicheckSelectCheckboxes = document.querySelectorAll(nameKeys.multiCheckSelectName)
      multicheckSelectCheckboxes.forEach((checkbox) => {
        if (checkbox instanceof HTMLInputElement) {
          checkbox.checked = false
        }
      })
    } else if (selectAll == true) {
      const multicheckSelectCheckboxes = document.querySelectorAll(nameKeys.multiCheckSelectName)
      multicheckSelectCheckboxes.forEach((checkbox) => {
        if (checkbox instanceof HTMLInputElement) {
          checkbox.checked = true
        }
      })
    } else if (selectAll == false) {
      const multicheckSelectCheckbox = document.querySelector(
        nameKeys.multiCheckSelectName
      ) as HTMLInputElement
      if (multicheckSelectCheckbox) {
        multicheckSelectCheckbox.checked = false
      }
    }
  }, [deleteDataArr])
  const deleteSelectedAssets = async (id: number[]) => {
    try {
      const deleteIds = {deleteIds: id}
      const res = await deleteAssets({id: deleteIds}).unwrap()
      toast.dismiss()
      toast.success(res?.message)
      setSkip(false)
    } catch (err: any) {
      toast.error(err?.data?.message)
    }
    setCheck([])
    setDeleteDataArr([])
    setSelectAll(false)
    setDeleteModal(false)
    setSelected([])
    setLoading(true)
  }
  const cancel = () => setDeleteModal(false)
  const confirm = () => deleteSelectedAssets(check)
  const handlePageChange = (currentPage: any) => {
    setLoading(true)
    setPage(currentPage)
    setSkip(false)
  }
  const removeFilter = (fieldName) => {
    setSearchAssets((prevSearchAssets) => ({
      ...prevSearchAssets,
      [fieldName]: '',
    }))
  }
  const handleLimitChange = (newLimit: number) => {
    setLoading(true)
    setLimit(Number(newLimit))
    setPage(1)
    setSkip(false)
  }
  const handleExport = () => {
    setExportButton(true)
    setSearchAssets((prevSearchAssets) => ({
      ...prevSearchAssets,
      exportToExcel: true,
    }))
    handleSearchSubmit()
  }
  useEffect(() => {
    if (assetsData?.fileName && searchAssets?.exportToExcel) {
      downloadAndRefresh()
    }
  }, [exportButton])
  const downloadAndRefresh = () => {
    const link = document.createElement('a')
    link.href = process.env.REACT_APP_PUBLIC_URL + assetsData?.fileName
    link.target = `_blank`
    link.download = `downloaded-file-name`
    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
    setSearchAssets((prevSearchAssets) => ({
      ...prevSearchAssets,
      exportToExcel: false,
    }))
  }
  const currentPage = pagination?.page || 0
  const totalRecords = pagination?.total || 0
  const pageSize = limit || 10
  const startRecord = (currentPage - 1) * pageSize + 1
  const endRecord = Math.min(currentPage * pageSize, totalRecords)
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'Report'})}</PageTitle>
      {!(isLoadingGetAssets || isLoadingGetCompany || isLoadingDelete || loading) ? (
        <>
          <AssetsSearch
            showSearch={showSearch}
            setShowSearch={setShowSearch}
            searchAssets={searchAssets}
            handleSearchAssets={handleSearchAssets}
            handleSearchSubmit={handleSearchSubmit}
            setLoading={setLoading}
          />
          <SetupCoulmnListing
            showSetupColumns={showSetupColumns}
            setShowSetupColumns={setShowSetupColumns}
            selectedColumns={selectedColumns}
            setSelectedColumns={setSelectedColumns}
            assetsData={assetsData}
          />
          <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
            <div className='post d-flex flex-column-fluid' id='kt_post'>
              <div id='kt_content_container' className='container'>
                <div className='card'>
                  <div className='card-body'>
                    <AssetsReportHeader handleExport={handleExport} title='Assets by Asset Tag' />
                    <div className='d-flex align-items-center justify-content-between flex-wrap mb-4'>
                      <div className='d-flex limit-options align-items-center mb-sm-0 mb-3'>
                        <span className='text-muted'>Showing</span>
                        <select
                          onChange={(e) => handleLimitChange(Number(e.target.value))}
                          value={limit}
                          className='form-select form-select-sm fw-bold mx-3 border-1'
                        >
                          <option value='10'>10</option>
                          <option value='15'>15</option>
                          <option value='20'>20</option>
                          <option value='25'>25</option>
                          <option value='50'>50</option>
                        </select>
                        <span className='text-muted'>entries</span>
                      </div>
                      <SetupColumns setShowSetupColumns={setShowSetupColumns} />
                    </div>
                    <div className='react-grid-card'>
                      <div className='table-responsive pb-4'>
                        <table
                          id='kt_table_users'
                          className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
                        >
                          <thead>
                            <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                              <th role='columnheader' className='w-10px pe-2'>
                                <div className='header-checkbox me-3'>
                                  <input
                                    type='checkbox'
                                    className='form-check-input custom-form-check-input'
                                    checked={selectAll}
                                    onChange={handleSelectAll}
                                  />
                                </div>
                              </th>
                              {selectedColumnsRender.map((column, index) => (
                                <th key={index}>{column.key.replace(/_/g, ' ')}</th>
                              ))}
                              <th className='text-center'>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {assets?.length > 0 ? (
                              assets.map((asset: any, rowIndex: number) => (
                                <tr key={rowIndex} role='row'>
                                  <td role='cell'>
                                    <div className='form-check form-check-sm form-check-custom'>
                                      <input
                                        type='checkbox'
                                        className='form-check-input'
                                        checked={selected.includes(asset?.id)}
                                        onChange={() => handleCheckboxChange(asset?.id)}
                                      />
                                    </div>
                                  </td>
                                  {selectedColumnsRender.map((column, colIndex) => (
                                    <td key={colIndex} className='table-elipses'>
                                      <ColumnData
                                        column={column.key.replace(/_/g, ' ')}
                                        assetsData={{userAssets: [asset]}}
                                        currencyLogo={currencyLogo}
                                      />
                                    </td>
                                  ))}
                                  <td className='text-center'>
                                    <Link
                                      to={`/asset/view/${asset.id}`}
                                      className='menu-link px-5 btn btn-light-secondary p-2 view-btn-style'
                                    >
                                      View
                                    </Link>
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td
                                  colSpan={selectedColumnsRender?.length + 2}
                                  className='text-center'
                                >
                                  <div className='d-flex text-center w-100 align-items-center justify-content-center no-records fw-bold rounded'>
                                    No records found
                                  </div>
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    {assetsData?.userAssets?.length ? (
                      <div className='d-sm-flex align-items-center justify-content-between mt-3'>
                        <div className='mt-3'>
                          Total Assets: {assetsData?.userAssets?.length} Assets
                        </div>
                        <div className='mt-3'>
                          Total Cost: {currencyLogo} {assetsData?.totalCost}{' '}
                        </div>
                      </div>
                    ) : (
                      ''
                    )}
                    {assetsData?.userAssets?.length ? (
                      <div className='d-sm-flex align-items-center justify-content-between flex-wrap mt-3'>
                        <div className='m-1'>
                          Showing {startRecord} to {endRecord} of {totalRecords} records
                        </div>

                        {pagination && (
                          <Pagination
                            className='m-1 custom-pagination'
                            defaultCurrent={1}
                            pageSize={limit}
                            current={pagination?.page}
                            total={pagination?.total}
                            onChange={handlePageChange}
                            locale={localeInfo}
                          />
                        )}
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : null}
    </>
  )
}
export default ReportByAssetTags
