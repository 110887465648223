import React, {useEffect, useState} from 'react'
import {PageTitle} from '../../../_metronic/layout/core'
import {useIntl} from 'react-intl'
import {useAuth} from '../Auth'
import {toast} from 'react-toastify'
import {
  useUploadsMutation,
  useImportCategoriesMutation,
  useImportDepartmentsMutation,
  useImportLocationsMutation,
  useImportSitesMutation,
  useImportPersonsMutation,
  useImportAssetsMutation,
  useImportCustomersMutation,
  useImportMaintenancesMutation,
  useImportWarrantiesMutation,
} from '../Services/Imports'
import ImportForm from './VerfiyAndImport'
import Modal from './Modal/Modal'
import {toAbsoluteUrlFile} from '../../../_metronic/helpers'
import enMessages from '../../../_metronic/i18n/messages/en.json'
import Loader from 'App/BeatLoader'
import {useLocation} from 'react-router-dom'

type FormStateType = {
  isUpdateSite: boolean
  updatePerson: boolean
  deleteAllMaintenance: boolean
  deleteExistingRecords: boolean
  updateAssests: boolean
  keepExistingValueAssets: boolean
  linkedFieldsAssets: boolean
  updateCustomers: boolean
  updateMaintenanceRecords: boolean
  deleteExistingMaintenance: boolean
  updateAssetsMaintenance: boolean
  updateWarranty: boolean
}

function Import() {
  const {state} = useLocation()
  const intl = useIntl()
  const {currentUser} = useAuth()
  const [file, setFile] = useState<any>()
  const [fileError, setFileError] = useState('')
  const [execlData, setExeclData] = useState([])
  const [titleIndexMap, setTitleIndexMap] = useState<any>({})
  const [selectedValue, setSelectedValue] = useState<string>('assets')
  const [title, setTitle] = useState([])
  const [option, setOption] = useState({})
  const [row, setRow] = useState<any>([])
  const [previewModal, setPreviewModal] = useState(false)
  const [previewError, setPreviewError] = useState(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [showLoader, setShowLoader] = useState<boolean>(false)
  const [show, setShow] = useState<boolean>(false)
  const [formState, setFormState] = useState<FormStateType>({
    isUpdateSite: true,
    updatePerson: true,
    deleteAllMaintenance: false,
    deleteExistingRecords: false,
    updateAssests: true,
    keepExistingValueAssets: false,
    linkedFieldsAssets: true,
    updateCustomers: false,
    updateWarranty: true,
    updateMaintenanceRecords: true,
    deleteExistingMaintenance: false,
    updateAssetsMaintenance: false,
  })
  const [modalState, setModalState] = useState({
    uploads: true,
    uploadAgain: false,
    preview: false,
    mapAgain: false,
  })
  const [uploads] = useUploadsMutation()
  const [importCategories] = useImportCategoriesMutation()
  const [importDepartments] = useImportDepartmentsMutation()
  const [importWarranties] = useImportWarrantiesMutation()
  const [importLocations] = useImportLocationsMutation()
  const [importSites] = useImportSitesMutation()
  const [importPersons] = useImportPersonsMutation()
  const [importAssets] = useImportAssetsMutation()
  const [importCustomers] = useImportCustomersMutation()
  const [importMaintenances] = useImportMaintenancesMutation()

  useEffect(() => {
    if (state?.importType) {
      setSelectedValue(state.importType)
    }
  }, [state])

  const handleChange = ({target: {value = ''}}: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedValue(value)
    setPreviewError(false)
  }

  const downloadTemplateExcel = (fileName: any) => {
    const fileNameTemp = toAbsoluteUrlFile(fileName)
    const link = document.createElement('a')
    link.href = fileNameTemp
    link.download = fileNameTemp
    link.click()
  }

  const handleTemplateSubmit = () => downloadTemplateExcel(selectedValue)

  const handleLimitTemplateSubmit = () => {
    downloadTemplateExcel('fixedLimit')
  }

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0]
    const allowedExtensions = ['xlsx', 'csv']
    if (selectedFile) {
      const fileNameParts = selectedFile.name.split('.')
      const fileExtension = fileNameParts[fileNameParts.length - 1].toLowerCase()
      if (allowedExtensions.includes(fileExtension)) {
        setFile(selectedFile)
        setFileError('')
      } else {
        setFileError('File type not supported. Please select a .xlsx or .csv file.')
        e.target.value = null
      }
    }
    setPreviewError(false)
  }

  const handleUpload = async () => {
    if (!file) {
      setFileError('Please select a file.')
      return
    }
    setPreviewError(false)
    setLoading(true)
    setShowLoader(true)
    const formData = new FormData()
    formData.append('file', file)
    const type = returnSelectedType()
    try {
      const {data, message} = await uploads({data: formData, type: type}).unwrap()
      if (data) {
        const {titles, options} = data
        setTitle(titles)
        setOption(options)
        setModalState((prevState) => ({
          ...prevState,
          uploads: false,
          uploadAgain: true,
          preview: true,
        }))
        setFileError('')
        setTitleIndexMap({})
        toast.dismiss()
        toast.success(message ? message : data?.message)
        setLoading(false)
        setPreviewModal(true)
      }
    } catch (error: any) {
      toast.dismiss()
      toast.error(error.data.message)
      setFile(null)
      const fileInput = document.getElementById('File') as HTMLInputElement
      if (fileInput) {
        fileInput.value = ''
      }
    }
  }

  const handleImports = async (e: any) => {
    e?.preventDefault()
    try {
      const value: any = [JSON.stringify(titleIndexMap)]
      const formData: any = new FormData()
      formData.append('file', file)
      formData.append('indexs', value)

      if (selectedValue === 'assets') {
        formData.append('updateAssests', formState.updateAssests)
        formData.append('keepExistingValueAssets', formState.keepExistingValueAssets)
        formData.append('linkedFieldsAssets', formState.linkedFieldsAssets)

        try {
          const {data, message, rowsWithEmptyFields = [], rowsWithDuplicateRecord = [], noPermissionForAssets = [], noAssignedUserExists=[]} = await importAssets({data: formData}).unwrap()
          toast.dismiss()
          if (rowsWithEmptyFields.length > 0 || rowsWithDuplicateRecord.length > 0 || noPermissionForAssets.length > 0 || noAssignedUserExists.length > 0) {
            toast.info(message ? message : data?.message)
            if(rowsWithEmptyFields.length > 0 ) {
              setRow(rowsWithEmptyFields)
              setPreviewError(true)
            }
          } else {
            toast.success(message ? message : data?.message)
          }
          setModalState((prevState) => ({
            ...prevState,
            uploadAgain: false,
          }))
          setPreviewModal(false)
          setFile(null)
        } catch (err: any) {
          toast.error(err.data.message)
          setRow(err.data.rowsWithEmptyFields)
          setModalState((prevState) => ({
            ...prevState,
            uploadAgain: false,
          }))
          setPreviewModal(false)
          err.data.rowsWithEmptyFields > 0  && setPreviewError(true)
          setFile(null)
        }
      } else if (selectedValue === 'categories') {
        try {
          const {data, message, rowsWithEmptyFields = []} = await importCategories({
            data: formData,
            userId: currentUser?.id,
          }).unwrap()
          toast.dismiss()
          if (rowsWithEmptyFields.length > 0) {
            toast.info(message ? message : data?.message)
            setRow(rowsWithEmptyFields)
            setPreviewError(true)
          } else {
            toast.success(message ? message : data?.message)
          }
          setModalState((prevState) => ({
            ...prevState,
            uploadAgain: false,
          }))
          setPreviewModal(false)
          setFile(null)
        } catch (err: any) {
          toast.error(err.data.message)
          setRow(err.data.rowsWithEmptyFields)
          setModalState((prevState) => ({
            ...prevState,
            uploadAgain: false,
          }))
          setPreviewModal(false)
          err.data.rowsWithEmptyFields > 0  && setPreviewError(true)
          setFile(null)
        }
      } else if (selectedValue === 'customers') {
        formData.append('updateCustomers', formState.updateCustomers)
        try {
          const {data, message, rowsWithEmptyFields=[], rowsWithDuplicateRecord=[], noPermissionForAssets=[], peronsNotBelong=[]} = await importCustomers({
            data: formData,
            userId: currentUser?.id,
          }).unwrap()
          toast.dismiss()
          if (rowsWithEmptyFields.length > 0 || rowsWithDuplicateRecord.length > 0 || noPermissionForAssets.length > 0 || peronsNotBelong.length > 0) {
            toast.info(message ? message : data?.message)
            if(rowsWithEmptyFields.length > 0){
              setRow(rowsWithEmptyFields)
              setPreviewError(true)
            }
          } else {
            toast.success(message ? message : data?.message)
          }
          setModalState((prevState) => ({
            ...prevState,
            uploadAgain: false,
          }))
          setPreviewModal(false)
          setFile(null)
        } catch (err: any) {
          toast.error(err.data.message)
          setRow(err.data.rowsWithEmptyFields)
          setModalState((prevState) => ({
            ...prevState,
            uploadAgain: false,
          }))
          setPreviewModal(false)
          err.data.rowsWithEmptyFields > 0  && setPreviewError(true)
          setFile(null)
        }
      } else if (selectedValue === 'departments') {
        try {
          const {data, message, rowsWithEmptyFields=[]} = await importDepartments({
            data: formData,
            userId: currentUser?.id,
          }).unwrap()
          toast.dismiss()
          if (rowsWithEmptyFields.length > 0) {
            toast.info(message ? message : data?.message)
            setRow(rowsWithEmptyFields)
            setPreviewError(true)
          } else {
            toast.success(message ? message : data?.message)
          }
          setModalState((prevState) => ({
            ...prevState,
            uploadAgain: false,
          }))
          setPreviewModal(false)
          setFile(null)
        } catch (err: any) {
          toast.error(err.data.message)
          setRow(err.data.rowsWithEmptyFields)
          setModalState((prevState) => ({
            ...prevState,
            uploadAgain: false,
          }))
          setPreviewModal(false)
          err.data.rowsWithEmptyFields > 0  && setPreviewError(true)
          setFile(null)
        }
      } else if (selectedValue === 'locations') {
        try {
          const {data, message, rowsWithEmptyFields=[]} = await importLocations({
            data: formData,
            userId: currentUser?.id,
          }).unwrap()
          toast.dismiss()
          if (rowsWithEmptyFields.length > 0) {
            toast.info(message ? message : data?.message)
            setRow(rowsWithEmptyFields)
            setPreviewError(true)
          } else {
            toast.success(message ? message : data?.message)
          }
          setModalState((prevState) => ({
            ...prevState,
            uploadAgain: false,
          }))
          setPreviewModal(false)
          setFile(null)
        } catch (err: any) {
          toast.error(err.data.message)
          setRow(err.data.rowsWithEmptyFields)
          setModalState((prevState) => ({
            ...prevState,
            uploadAgain: false,
          }))
          setPreviewModal(false)
          err.data.rowsWithEmptyFields > 0  && setPreviewError(true)
          setFile(null)
        }
      } else if (selectedValue === 'maintenances') {
        formData.append('deleteAllMaintenance', formState.deleteAllMaintenance)
        formData.append('deleteExistingMaintenance', formState.deleteExistingMaintenance)
        formData.append('updateAssetsMaintenance', formState.updateAssetsMaintenance)

        try {
          const {data, message, rowsWithEmptyFields = [], rowsWithDuplicateRecord = [], noPermissionForAssets = []} = await importMaintenances({
            data: formData,
            userId: currentUser?.id,
          }).unwrap()
          toast.dismiss()
          if (rowsWithEmptyFields.length > 0 || rowsWithDuplicateRecord.length > 0 || noPermissionForAssets.length > 0) {
            toast.info(message ? message : data?.message)
            if(rowsWithEmptyFields.length > 0){
              setRow(rowsWithEmptyFields)
              setPreviewError(true)
            }
          } else {
            toast.success(message ? message : data?.message)
          }
          setModalState((prevState) => ({
            ...prevState,
            uploadAgain: false,
          }))
          setPreviewModal(false)
          setFile(null)
        } catch (err: any) {
          toast.error(err.data.message)
          setRow(err.data.rowsWithEmptyFields)
          setModalState((prevState) => ({
            ...prevState,
            uploadAgain: false,
          }))
          setPreviewModal(false)
          err.data.rowsWithEmptyFields > 0  && setPreviewError(true)
          setFile(null)
        }
      } else if (selectedValue === 'person') {
        formData.append('updatePerson', formState.updatePerson)

        try {
          const {data, message, rowsWithEmptyFields = [], rowsWithDuplicateRecord = [], noPermissionForAssets = [], peronsNotBelong = []} = await importPersons({
            data: formData,
            userId: currentUser?.id,
          }).unwrap()
          toast.dismiss()
          if (rowsWithEmptyFields.length > 0 || rowsWithDuplicateRecord.length > 0 || noPermissionForAssets.length > 0 || peronsNotBelong.length > 0) {
            toast.info(message ? message : data?.message)
            if(rowsWithEmptyFields.length > 0){
              setRow(rowsWithEmptyFields)
            setPreviewError(true)
            }
          } else {
            toast.success(message ? message : data?.message)
          }
          setModalState((prevState) => ({
            ...prevState,
            uploadAgain: false,
          }))
          setPreviewModal(false)
          setFile(null)
        } catch (err: any) {
          toast.error(err.data.message)
          setRow(err.data.rowsWithEmptyFields)
          setModalState((prevState) => ({
            ...prevState,
            uploadAgain: false,
          }))
          setPreviewModal(false)
          err.data.rowsWithEmptyFields > 0  && setPreviewError(true)
          setFile(null)
        }
      } else if (selectedValue === 'warranties') {
        formData.append('updateWarranty', formState.updateWarranty)
        try {
          const {data, message, rowsWithEmptyFields = [], rowsWithDuplicateRecord = [], noPermissionForAssets = []} = await importWarranties({
            data: formData,
            userId: currentUser?.id,
          }).unwrap()
          toast.dismiss()
          if (rowsWithEmptyFields.length > 0 || rowsWithDuplicateRecord.length > 0 || noPermissionForAssets.length > 0) {
            toast.info(message ? message : data?.message)
            if(rowsWithEmptyFields.length > 0){
              setRow(rowsWithEmptyFields)
              setPreviewError(true)
            }
          } else {
            toast.success(message ? message : data?.message)
          }
          setModalState((prevState) => ({
            ...prevState,
            uploadAgain: false,
          }))
          setPreviewModal(false)
          setFile(null)
        } catch (err: any) {
          toast.error(err.data.message)
          setRow(err.data.rowsWithEmptyFields)
          setModalState((prevState) => ({
            ...prevState,
            uploadAgain: false,
          }))
          setPreviewModal(false)
          err.data.rowsWithEmptyFields > 0  && setPreviewError(true)
          setFile(null)
        }
      } else {
        formData.append('isUpdateSite', formState.isUpdateSite)
        try {
          const {data, message, rowsWithEmptyFields=[], rowsWithDuplicateRecord = [], noPermissionForAssets = []} = await importSites({
            data: formData,
            userId: currentUser?.id,
          }).unwrap()
          toast.dismiss()
          if (rowsWithEmptyFields.length > 0 || rowsWithDuplicateRecord.length > 0 || noPermissionForAssets.length > 0) {
            toast.info(message ? message : data?.message)
            if(rowsWithEmptyFields.length > 0){
              setRow(rowsWithEmptyFields)
              setPreviewError(true)
            }
          } else {
            toast.success(message ? message : data?.message)
          }
          setModalState((prevState) => ({
            ...prevState,
            uploadAgain: false,
          }))
          setPreviewModal(false)
          setFile(null)
        } catch (err: any) {
          toast.error(err.data.message)
          setRow(err.data.rowsWithEmptyFields)
          setModalState((prevState) => ({
            ...prevState,
            uploadAgain: false,
          }))
          setPreviewModal(false)
          setPreviewError(true)
          setFile(null)
        }
      }
    } catch (error: any) {
      const {message} = error
      toast.dismiss()
      toast.error(message ? message : error?.message)
    }
  }

  const cancelImports = (e: any) => {
    e?.preventDefault()
    setExeclData([])
    setTitle([])
    setOption([])
    setPreviewModal(false)
    setModalState({
      uploads: true,
      uploadAgain: false,
      preview: false,
      mapAgain: false,
    })
    setPreviewError(false)
    setFile(null)
  }

  const returnSelectedType = (): number => {
    const type =
      selectedValue === 'assets'
        ? 1
        : selectedValue === 'categories'
        ? 2
        : selectedValue === 'customers'
        ? 3
        : selectedValue === 'departments'
        ? 4
        : selectedValue === 'locations'
        ? 5
        : selectedValue === 'maintenances'
        ? 6
        : selectedValue === 'person'
        ? 7
        : selectedValue === 'sites'
        ? 8
        : 9
    return type
  }

  useEffect(() => {
    const initialSelectedOptionIndices = {}
    if (title && option) {
      title?.forEach((val: string, index: number) => {
        const firstRow = option['0']
        if (firstRow) {
          initialSelectedOptionIndices[val.toString()] = index
        }
      })
      setTitleIndexMap(initialSelectedOptionIndices)
      const value: any = option ? Object.values(option) : []
      setExeclData(value)
    }
  }, [title, option])

  useEffect(() => {
    const timeout = setTimeout(() => {
      setLoading(false)
      setShowLoader(false)
    }, 500)
    return () => clearTimeout(timeout)
  }, [loading])

  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'Import'})}</PageTitle>
      <div className='card mb-5 mb-xl-10'>
        <div className='card-header border-0'>
          <div className='card-title m-0 '>
            <h3 className='fw-bolder m-0'>
              <span className='me-2 align-middle'>
                <i className='la la-cloud-upload fs-1'></i>
              </span>{' '}
              Import Wizard
            </h3>
          </div>
        </div>
        <div id='kt_account_profile_details' className='collapse show'>
          <div className='form'>
            <div className='card-body border-top'>
              <div className='pb-5 mb-5'>
                <h5>Step 1: Upload File</h5>
                <p className='fw-bolder m-0 mb-4'>
                  Import assets using an Excel spreadsheet. Download our template, fill it in, and
                  upload. Also download 'Field Limits Info' to make sure your data is within
                  character limits for all fields. There is no limit on the number of you can have.
                  But you can import up to <strong>500 records</strong> in one spreadsheet.
                </p>
              </div>
              {modalState.uploadAgain ? (
                <div className='card-footer d-flex justify-content-end pt-6 p-0'>
                  <button
                    type='button'
                    className='btn btn-primary d-flex justify-content-center'
                    onClick={() => {
                      setShow(true)
                      setPreviewError(false)
                      setFile(null)
                    }}
                  >
                    <i className='la la-cloud-upload fs-2 me-1'></i>
                    Upload Again
                  </button>
                </div>
              ) : (
                <>
                  <div className='row mb-5'>
                    <div className='col-xxl-6'>
                      <div className='row mb-6'>
                        <div className='col-xxl-3 col-form-label fw-bold fs-6'>Import To</div>
                        <div className='col-xxl-9 fv-row'>
                          <select
                            className='form-select'
                            data-val='true'
                            onChange={(e) => handleChange(e)}
                            value={selectedValue}
                          >
                            <option value='assets'>Assets</option>
                            <option value='categories'>Categories</option>
                            <option value='customers'>Customers</option>
                            <option value='departments'>Departments</option>
                            <option value='locations'>Locations</option>
                            <option value='maintenances'>Maintenances</option>
                            <option value='person'>Persons/Employees</option>
                            <option value='sites'>Sites</option>
                            <option value='warranties'>Warranties</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className='col-xxl-6'>
                      <div className='row'>
                      <div className='col-md-6'>
                      <button
                        type='button'
                        onClick={() => handleTemplateSubmit()}
                        className='btn btn-light-primary d-flex w-100 mb-5 justify-content-center'
                      >
                        <i className='la la-cloud-download fs-2 me-1'></i>
                        Download Template
                      </button>
                    </div>
                    <div className='col-md-6'>
                      <button
                        type='button'
                        onClick={() => handleLimitTemplateSubmit()}
                        className='btn btn-light-primary d-flex w-100 mb-5 justify-content-center'
                      >
                        <i className='la la-cloud-download fs-2 me-1'></i>
                        Download Field Limits
                      </button>
                    </div>
                      </div>
                    </div>
                    <div className='col-xxl-6'>
                      <div className='row mb-6'>
                        <div className='col-xxl-3 col-form-label required fw-bold fs-6'>
                          Select File
                        </div>
                        <div className='col-xxl-9 fv-row'>
                          <div>
                            <div>
                              <input
                                accept='.xls,.csv,.xlsx'
                                onChange={(e) => handleFileChange(e)}
                                className='form-control'
                                data-val='true'
                                data-val-required='File is required.'
                                id='File'
                                name='File'
                                type='file'
                              />
                            </div>
                            {fileError && <div className='error-msg'>{fileError}</div>}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='card-footer d-flex justify-content-end pt-6 p-0'>
                    <button
                      type='button'
                      onClick={() => handleUpload()}
                      className='btn btn-primary main-btn-style d-flex justify-content-center'
                    >
                      <i className='la la-cloud-upload fs-2 me-1'></i>
                      Upload File
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      {previewModal ? (
        <ImportForm
          type={returnSelectedType()}
          title={title}
          option={execlData}
          importFunction={handleImports}
          cancel={cancelImports}
          formState={formState}
          setFormState={setFormState}
          dateColumns={["Purchased Date", "Check-out Date", "Due Date", "Warranty Expiration Date", "Maintenance Due Date", "Maintenance Completion Date", "Expiration Date"]}
        />
      ) : null}
      {show ? (
        <Modal
          setShow={setShow}
          show={show}
          click={(e) => {
            setShow(false)
            cancelImports(e)
          }}
        />
      ) : (
        ''
      )}

      {previewError ? (
        <div className='card mb-5 mb-xl-10'>
          <div className='card-header border-0'>
            <h3 className='fw-bolder m-0 d-flex align-items-center text-danger'>
              {' '}
              <span className='me-2 align-middle'>
                <i className='las la-exclamation-triangle text-danger fs-1'></i>
              </span>{' '}
              Error Summary
            </h3>
          </div>
          <div id='kt_account_profile_details' className='collapse show'>
            <div className='form'>
              <div className='card-body border-top p-lg-9 p-md-7 p-6'>
                <p>
                  There are so many errors in the spreadsheet.{' '}
                  {row.length === 1 ? 'Row #' : 'Rows #'}
                  {row
                    ? row.map((value, index) => (
                        <React.Fragment key={index}>
                          {index !== 0 && index !== row.length - 1 && ', '}
                          {index === row.length - 1 && index !== 0 && ' and '} {value}
                        </React.Fragment>
                      ))
                    : null}
                  {` ha${row.length === 1 ? 's' : 've'} incorrect data!`}
                </p>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ''
      )}
      <Loader loading={showLoader} />
    </>
  )
}

export default Import
