import React from 'react'

function StopEmails(stopEmailPara_1) {
    
    return (
        <div className="row mb-6">
            <div className="col-lg-2 col-form-label fw-bold fs-6">Email Frequency</div>
            <div className="col-lg-10 fv-row">
                <p className='mb-2 text-muted'> <i className='la la-info-circle fs-5 text-muted'></i> When do you want us to stop sending the alert email?</p>
                <p>{stopEmailPara_1.stopEmailPara_1}</p>
            </div>
        </div>
    )
}

export default StopEmails