import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { getAuth } from '../Auth'
import { dashboardApi } from './Dashboard'
const API_URL = process.env.REACT_APP_API_URL

export const assetsReportsApi = createApi({
  reducerPath: 'assetsReportsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${API_URL}/reports/`,
    prepareHeaders: (headers) => {
      const auth = getAuth()
      if (auth && auth?.token) {
        headers.set('Authorization', `Bearer ${auth?.token}`)
      }
      return headers
    },
  }),
  
  tagTypes: ['AssetsReports'],
  endpoints: (build) => ({
    getAllAssetsReports: build.query<any, { body: any; page: any; limit: any; keys: any }>({
      query: ({ body, page, limit, keys }) => ({
        url: `asset-reports/?${body}&page=${page}&limit=${limit}&keys=${keys}`,
        method: 'GET',
      }),
      providesTags: ['AssetsReports'],
    }),
    getSingleAsset: build.query<any, { assetId: any }>({
      query: ({ assetId }) => `get/${assetId}`,
      providesTags: ['AssetsReports'],
    }),
    addAsset: build.mutation<any, FormData>({
      query: (formData) => ({
        url: `create`,
        method: 'POST',
        body: formData,
      }),
      onQueryStarted: (arg, api) => {
        api.queryFulfilled.then(() => {
          api.dispatch(dashboardApi.util.invalidateTags(['Dashboard']))
        })
      },
      invalidatesTags: () => [{ type: 'AssetsReports' }],
    }),
    updateAsset: build.mutation<any, { assetId: any; formData: FormData }>({
      query: ({ assetId, formData }) => ({
        url: `update/${assetId}`,
        method: 'PUT',
        body: formData,
      }),
      invalidatesTags: (result, error, { assetId }) => [{ type: 'AssetsReports' }],
    }),
    deleteAssets: build.mutation<any, { id: any }>({
      query({ id }) {
        return {
          url: `delete-all`,
          method: 'DELETE',
          body: id,
        }
      },
      onQueryStarted: (arg, api) => {
        api.queryFulfilled.then(() => {
          api.dispatch(dashboardApi.util.invalidateTags(['Dashboard']))
        })
      },
      invalidatesTags: (userId, id) => [{ type: 'AssetsReports' }],
    }),
  }),
})
export const {
  useGetAllAssetsReportsQuery,
  useGetSingleAssetQuery,
  useAddAssetMutation,
  useUpdateAssetMutation,
  useDeleteAssetsMutation,
} = assetsReportsApi
