import { useEffect } from 'react'
import { Outlet, Route, Routes, Link } from 'react-router-dom'
import { Registration } from './Components/Registration'
import { ForgotPassword } from './Components/ForgotPassword'
import { Login } from './Components/Login'
import ResetPassword from './Components/ResetPassword'
import { toAbsoluteUrl } from '../../../_metronic/helpers'
import { Images } from '_metronic/assets/Assets'
import VerifyEmail from './Components/VerifyEmail'
import CreatePassword from './Components/CreatePassword'

const AuthLayout = () => {
  useEffect(() => {
    document.body.style.backgroundImage = 'none'
    return () => { }
  }, [])

  return (
    <div
      className='d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed'
    >
      <div className='d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20'>
        <Link to={'/'} className='mb-xl-5 mb-4'>
          <img alt='Logo' src={toAbsoluteUrl(Images.DefaultBlackLogo)} className='h-25px h-xl-30px' />
        </Link>
        <div className='w-sm-500px bg-body rounded shadow-sm p-10 p-lg-12 w-100 mx-auto'>
          <Outlet />
        </div>
      </div>
    </div>
  )
}

const AuthPage = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route path='login' element={<Login />} />
      <Route path='registration' element={<Registration />} />
      <Route path='verify-email' element={<VerifyEmail />} />
      <Route path='forgot-password' element={<ForgotPassword />} />
      <Route path='new-password/:token' element={<ResetPassword />} />
      <Route path='create-password/:token' element={<CreatePassword />} />
      <Route index element={<Login />} />
    </Route>
  </Routes>
)

export { AuthPage }
