import { useFormik } from 'formik'
import Modal from 'react-bootstrap/Modal'
import { initialSites, SiteResponse } from '../Core/_models'
import * as Yup from 'yup'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { getAllSites } from '../Core/_requests'
import { useAuth } from '../../../../Auth'
import { countriesList } from './Countries'
import { useAddSiteMutation, useUpdateSiteMutation } from '../../../../Services/Sites'
import { checkUserUnauthorized } from '../../../../Auth'
import { addressRegex } from 'config'

const SitesSechma = Yup.object().shape({
	name: Yup.string().required('Site name is required').matches(addressRegex, "Site does not allow extra spaces"),
})

const SitesModal = (props: any) => {
	const { currentUser, saveAuth, setCurrentUser } = useAuth()
	const [addSite, { isLoading: isAdding }] = useAddSiteMutation()
	const [updateSite, { isLoading: isUpdating }] = useUpdateSiteMutation()

	useEffect(() => {
		formik.resetForm()
		if (props?.SiteData?.id) {
			formik.setValues({
				name: props?.SiteData?.name,
				description: props?.SiteData?.description,
				address: props?.SiteData?.address,
				aptSuite: props?.SiteData?.aptSuite,
				city: props?.SiteData?.city,
				state: props?.SiteData?.state,
				postalCode: props?.SiteData?.postalCode,
				country: props?.SiteData?.country,
			})
		}
	}, [props])

	const formik = useFormik({
		initialValues: { ...initialSites },
		validationSchema: SitesSechma,
		onSubmit: async (values) => {
			if (currentUser) {
				values.name = values.name.trim()
				try {
					const data = props?.SiteData?.id
						? await updateSite({ siteId: props.SiteData.id, values }).unwrap()
						: await addSite({ ...values }).unwrap()
					if (data) {
						toast.dismiss()
						if (data?.success) {
							toast.success(data?.message)
							formik.resetForm()
							props.handleClose(true)
							props.setLoading(true)
						} else {
							toast.error(data?.message)
						}
					}
				} catch (error: any) {
					toast.error(error?.data?.message)
					checkUserUnauthorized(error?.data, saveAuth, setCurrentUser, toast)
					formik.setSubmitting(false)
				}
			}
		},
	})

	const handleClose = (e: any) => {
		e?.preventDefault()
		formik.resetForm()
		props.handleClose()
	}

	const formikSubmit = (e: any) => {
		e?.preventDefault()
		formik.handleSubmit()
	}

	return (
		<Modal
			className='custom-modal-sm'
			show={props.show}
			onHide={props.handleClose}
			backdrop='static'
			keyboard={false}
		>
			<Modal.Header closeButton>
				<Modal.Title>{props?.SiteData?.id ? 'Update Site' : 'Add Site'}</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<p className='mb-4 fs-6'>
					Enter the data about your new site in the fields below and we will add it to your list.
				</p>
				<form noValidate className='form'>
					<div className='inner-site-modal'>
						<div className='mb-4'>
							<label className='required fw-bold fs-6 mb-2'>Site Name</label>
							<div>
								<input
									placeholder='Site name'
									{...formik.getFieldProps('name')}
									type='text'
									className='form-control form-control-solid mb-3 mb-lg-0'
									autoComplete='off'
								/>
							</div>
							{formik?.touched?.name && formik?.errors?.name && (
								<div className='fv-plugins-message-container'>
									<div className='fv-help-block'>{formik?.errors?.name}</div>
								</div>
							)}
						</div>
						<div className='mb-4'>
							<label className='fw-bold fs-6 mb-2'>Description</label>
							<div>
								<input
									placeholder='Description'
									{...formik.getFieldProps('description')}
									type='text'
									className='form-control form-control-solid mb-3 mb-lg-0'
									autoComplete='off'
								/>
							</div>
						</div>
						<div className='mb-4'>
							<label className=' fw-bold fs-6 mb-2'>Address</label>
							<div>
								<input
									placeholder='Address'
									{...formik.getFieldProps('address')}
									type='text'
									className='form-control form-control-solid mb-3 mb-lg-0'
									autoComplete='off'
								/>
							</div>
						</div>
						<div className='mb-4'>
							<label className=' fw-bold fs-6 mb-2'>Apt./Suite</label>
							<div>
								<input
									placeholder='Apt./Suite'
									{...formik.getFieldProps('aptSuite')}
									type='text'
									className='form-control form-control-solid mb-3 mb-lg-0'
									autoComplete='off'
								/>
							</div>
						</div>
						<div className='mb-4'>
							<label className=' fw-bold fs-6 mb-2'>Country</label>

							<div className='fv-row w-100'>
								<select
									className='form-select form-control form-control-solid form-select-solid  '
									{...formik.getFieldProps('country')}
								>
									<option selected>
										{' '}
										Select country
									</option>
									{countriesList?.map((item: any, idx: number) => {
										return (
											<option key={idx} value={item.code}>
												{item.name}
											</option>
										)
									})}
								</select>
							</div>
						</div>
						<div className='mb-4'>
							<label className=' fw-bold fs-6 mb-2'>City</label>
							<div>
								<input
									placeholder='City'
									{...formik.getFieldProps('city')}
									type='text'
									className='form-control form-control-solid mb-3 mb-lg-0'
									autoComplete='off'
								/>
							</div>
						</div>
						<div className='mb-4'>
							<label className=' fw-bold fs-6 mb-2'>State</label>
							<div>
								<input
									placeholder='State'
									{...formik.getFieldProps('state')}
									type='text'
									className='form-control form-control-solid mb-3 mb-lg-0'
									autoComplete='off'
								/>
							</div>
						</div>
						<div className='mb-4'>
							<label className=' fw-bold fs-6 mb-2'>Postal Code</label>
							<div>
								<input
									placeholder='Postal Code'
									{...formik.getFieldProps('postalCode')}
									type='text'
									className='form-control form-control-solid mb-3 mb-lg-0'
									autoComplete='off'
								/>
							</div>
						</div>
					</div>

					<div className='text-end pt-2'>
						<button
							className='btn btn-light-primary   main-btn-style me-2'
							onClick={(e) => handleClose(e)}
						>
							Close{' '}
						</button>
						<button
							type='submit'
							className='btn btn-primary main-btn-style'
							onClick={(e) => formikSubmit(e)}
							disabled={formik.isSubmitting || !formik.isValid || !formik.dirty}
						>
							{!isAdding && !isUpdating && (
								<span className='indicator-label'>
									{props?.SiteData?.id ? 'Update Site' : 'Add Site'}
								</span>
							)}
							{(isAdding || isUpdating) && (
								<span className='indicator-progress d-block'>
									Please wait...
									<span className='spinner-border spinner-border-sm align-middle ms-2'></span>
								</span>
							)}
						</button>
					</div>
				</form>
			</Modal.Body>
		</Modal>
	)
}

export default SitesModal
