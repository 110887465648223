import { FC } from 'react'
import { Link } from 'react-router-dom'
import notFoundImage from '../../../../Images/404.png'
import { useAuth } from 'App/Modules/Auth'

const Error404: FC = () => {
    const { currentUser } = useAuth()
    const personUser = currentUser?.userType === '2'
    const customerUser = currentUser?.userType === '4'
    return (
        <div className='d-flex flex-column flex-root error-page'>
            <div className='d-flex flex-column flex-center flex-column-fluid p-10'>
                <img
                    src={notFoundImage}
                    alt='icon'
                    className='mw-100 mb-10 h-lg-450px img-fluid rounded'
                />
                <h1 className='fw-bold mb-10'>Seems there is nothing here</h1>
                {personUser || customerUser ? (
                    <Link to='/assets' className='btn btn-primary'>
                        Return Home
                    </Link>
                ) : (
                    <Link to='/' className='btn btn-primary'>
                        Return Home
                    </Link>
                )}
            </div>
        </div>
    )
}

export { Error404 }
