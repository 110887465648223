import FormField from './FormField';
import "../import.css";
import { Dispatch, SetStateAction } from "react";
import { importDateFormat } from 'config';

interface ImportFormProps {
    type: Number,
    title: any[],
    option: String[],
    importFunction: (e) => void,
    cancel: (e) => void,
    setFormState: Dispatch<SetStateAction<any>>,
    formState: any,
    dateColumns: string[] 
}

const formatDate = (date) => importDateFormat(date);

const formatOptionDates = (options, dateColumns) => {
    return options.map(option => {
        const formattedOption = { ...option };
        Object.keys(formattedOption).forEach(key => {
            const value = formattedOption[key];
            const parsedDate = new Date(value);
            if (dateColumns.includes(key) && typeof value === 'string' && !isNaN(Date.parse(value)) && !isNaN(parsedDate.getTime())) {
                formattedOption[key] = formatDate(parsedDate);
            }
        });
        return formattedOption;
    });
};

function ImportForm({
    type, title, option,
    importFunction, cancel, setFormState,
    formState, dateColumns
}: ImportFormProps) {
    const formattedOption = formatOptionDates(option, dateColumns);

    return (
        <div className='card'>
            <form className="form-horizontal">
                <div>
                    <input name="__RequestVerificationToken" type="hidden" />
                </div>
                <div className="card-body form-body">
                    <div className="step3">
                        <div className="form-title">
                            <h5>Step 2. Verify and Import</h5>
                        </div>
                        <div className="table-responsive mb-4 pb-2">
                            <table className="table table-bordered table-sm dataTable" aria-label="Verify and Import">
                                <thead className='thead-light light-yellow-table text-nowrap'>
                                    <tr>
                                        {
                                            title && title.length > 0 && title.map((val, ind) => {
                                                return (
                                                    <th className="align-middle" key={ind}>{val}</th>
                                                )
                                            })
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        formattedOption && formattedOption.length > 0 && formattedOption.slice(0, 9).map((innerArray: any, index: number) => {
                                            return (
                                                <tr key={index}>
                                                    {
                                                        title.map((columnName: string, innerIndex: number) => {
                                                            return (
                                                                <td key={innerIndex}>{innerArray[columnName]}</td>
                                                            )
                                                        })
                                                    }
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <FormField
                        type={type}
                        setFormState={setFormState}
                        formState={formState}
                    />
                    <div className="row">
                        <div className="d-flex align-items-center justify-content-end">
                            <button className="btn btn-light-primary   main-btn-style me-2" onClick={cancel}>Cancel</button>
                            <button type="submit" className="btn btn-primary main-btn-style" id="importdatawrapper" onClick={importFunction}>Import Data</button>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    );
}

export default ImportForm;
