import React, { useState } from 'react';

function LeadTime(props) {
	const { text, time } = props;
	const [leadTime, setLeadTime] = useState(time);
	const handleLeadTimeChange = (e) => {
		const newValue = parseInt(e.target.value, 10);
		setLeadTime(newValue);
	};

	return (
		<div>
			<div className="d-flex align-items-center">
				<span className='me-2'>Lead Time:</span>
				<input className='form-control form-control-solid w-65px text-center' type='text' value={leadTime} onChange={handleLeadTimeChange} />
				<span className='ms-3'>Days</span>
			</div>
			<p>{text}</p>
		</div>
	)
}

export default LeadTime