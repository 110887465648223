import { useEffect, useState } from 'react'
import { MenuComponent } from '../../../../../../../_metronic/assets/ts/components'
import { initialQueryState, KTSVG } from '../../../../../../../_metronic/helpers'
import { useQueryRequest } from '../../Core/QueryRequestProvider'
import { useQueryResponse } from '../../Core/QueryResponseProvider'

const UsersListFilter = () => {
	const { updateState } = useQueryRequest()
	const { isLoading } = useQueryResponse()
	const [role, setRole] = useState<string | undefined>()
	const [lastLogin, setLastLogin] = useState<string | undefined>()

	useEffect(() => {
		MenuComponent.reinitialization()
	}, [])

	const resetData = () => {
		updateState({ filter: undefined, ...initialQueryState })
	}

	const filterData = () => {
		updateState({
			filter: { role, last_login: lastLogin },
			...initialQueryState,
		})
	}

	return (
		<>
			<button
				disabled={isLoading}
				type='button'
				className='btn btn-light-primary me-3'
				data-kt-menu-trigger='click'
				data-kt-menu-placement='bottom-end'
			>
				<KTSVG path='/media/icons/duotune/general/gen031.svg' className='svg-icon-2' />
				Filter
			</button>
			<div className='menu menu-sub menu-sub-dropdown w-300px w-md-325px' data-kt-menu='true'>
				<div className='px-7 py-5'>
					<div className='fs-5 text-dark fw-bolder'>Filter Options</div>
				</div>
				<div className='separator border-gray-200'></div>
				<div className='px-7 py-5' data-kt-user-table-filter='form'>
					<div className='mb-10'>
						<label className='form-label fs-6 fw-bold'>Role:</label>
						<select
							className='form-select form-select-solid fw-bolder'
							data-kt-select2='true'
							data-placeholder='Select option'
							data-allow-clear='true'
							data-kt-user-table-filter='role'
							data-hide-search='true'
							onChange={(e) => setRole(e.target.value)}
							value={role}
						>
							<option></option>
							<option value='Administrator'>Administrator</option>
							<option value='Analyst'>Analyst</option>
							<option value='Developer'>Developer</option>
							<option value='Support'>Support</option>
							<option value='Trial'>Trial</option>
						</select>
					</div>
					<div className='mb-10'>
						<label className='form-label fs-6 fw-bold'>Last login:</label>
						<select
							className='form-select form-select-solid fw-bolder'
							data-kt-select2='true'
							data-placeholder='Select option'
							data-allow-clear='true'
							data-kt-user-table-filter='two-step'
							data-hide-search='true'
							onChange={(e) => setLastLogin(e.target.value)}
							value={lastLogin}
						>
							<option></option>
							<option value='Yesterday'>Yesterday</option>
							<option value='20 mins ago'>20 mins ago</option>
							<option value='5 hours ago'>5 hours ago</option>
							<option value='2 days ago'>2 days ago</option>
						</select>
					</div>
					<div className='d-flex justify-content-end'>
						<button
							type='button'
							disabled={isLoading}
							onClick={filterData}
							className='btn btn-light-primary me-2 px-6'
							data-kt-menu-dismiss='true'
							data-kt-user-table-filter='reset'
						>
							Reset
						</button>
						<button
							disabled={isLoading}
							type='button'
							onClick={resetData}
							className='btn btn-primary fw-bold px-6'
							data-kt-menu-dismiss='true'
							data-kt-user-table-filter='filter'
						>
							Apply
						</button>
					</div>
				</div>
			</div>
		</>
	)
}

export { UsersListFilter }
