import { Navigate, Routes, Route, Outlet } from 'react-router-dom'
import { PageLink, PageTitle } from '../../../_metronic/layout/core'
import { ProfileHeader } from './ProfileHeader'

const profileBreadCrumbs: Array<PageLink> = [
	{
		title: 'Profile',
		path: '/crafted/pages/profile/overview',
		isSeparator: false,
		isActive: false,
	},
	{
		title: '',
		path: '',
		isSeparator: true,
		isActive: false,
	},
]

const ProfilePage = () => (
	<Routes>
		<Route
			element={
				<>
					<ProfileHeader />
					<Outlet />
				</>
			}
		>
			<Route index element={<Navigate to='/crafted/pages/profile/overview' />} />
		</Route>
	</Routes>
)

export default ProfilePage
