import { useFormik } from 'formik'
import Modal from 'react-bootstrap/Modal'
import { initialAssets } from '../Core/_models'
import * as Yup from 'yup'
import { useEffect, useState } from 'react'
import { useAuth } from 'App/Modules/Auth'
import { useAddAssetMutation, useUpdateAssetMutation } from 'App/Modules/Services/Assets'
import { useGetAllSitesQuery } from 'App/Modules/Services/Sites'
import { useGetAllCategoriesQuery } from 'App/Modules/Services/Categories'
import { useGetAllLocationsQuery } from 'App/Modules/Services/Locations'
import { useGetAllDepartmentsQuery } from 'App/Modules/Services/Departments'
import SitesModal from 'App/Modules/Apps/CompanySetup/Sites/Components/SitesModal'
import LocationModal from 'App/Modules/Apps/CompanySetup/Locations/Components/LocationModal'
import CategoryModel from 'App/Modules/Apps/CompanySetup/Categories/Components/CategoryModel'
import DepartmentsModel from 'App/Modules/Apps/CompanySetup/Departments/Components/DepartmentsModel'

const AssetsSechma = Yup.object().shape({
  description: Yup.string().required('Description is required'),
  assetTagId: Yup.string().required('Asset tag id is required'),
  cost: Yup.number()
    .required('Asset cost is required')
    .positive('Cost should be positive')
    .moreThan(0, 'Cost must be greater than 0')
    .typeError('Please enter numbers only'),
  purchasedDate: Yup.date().required('Purchase date is required'),
  siteId: Yup.number().moreThan(0, 'Please select site').required('Please select site'),
  categoryId: Yup.number().moreThan(0, 'Please select category').required('Please select category'),
  departmentId: Yup.number()
    .moreThan(0, 'Please select department')
    .required('Please select department'),
  locationId: Yup.number().moreThan(0, 'Please select location').required('Please select location'),
})

const WarrantiesModal = (props: any) => {
  const { currentUser } = useAuth()
  const [siteModalShow, setSiteModalShow] = useState<boolean>(false)
  const [categoryModalShow, setCategoryModalShow] = useState<boolean>(false)
  const [locationModalShow, setLocationModalShow] = useState<boolean>(false)
  const [departmentModalShow, setDepartmentModalShow] = useState<boolean>(false)
  const [addAsset, { isLoading: isAdding }] = useAddAssetMutation()
  const [updateAsset, { isLoading: isUpdating }] = useUpdateAssetMutation()
  const { data: siteData } = useGetAllSitesQuery({ userId: currentUser?.id })
  const { data: categoryData } = useGetAllCategoriesQuery({ userId: currentUser?.id, page: 1 })
  const { data: locationData } = useGetAllLocationsQuery(currentUser?.id)
  const { data: departmentData } = useGetAllDepartmentsQuery({ userId: currentUser?.id })

  const handleSiteModalClose = () => {
    setSiteModalShow(false)
    setLocationModalShow(false)
    setCategoryModalShow(false)
    setDepartmentModalShow(false)
  }

  const handleSiteModalShow = (modalType) => {
    if (modalType == 'Site') {
      setSiteModalShow(true)
    }
    if (modalType == 'Location') {
      setLocationModalShow(true)
    }
    if (modalType == 'Category') {
      setCategoryModalShow(true)
    }
    if (modalType == 'Department') {
      setDepartmentModalShow(true)
    }
  }

  useEffect(() => {
    formik.resetForm()
    if (props?.AssetData?.id) {
      const purchaseDateFromDatabase = props?.AssetData?.purchasedDate
      const purchasedDate = new Date(purchaseDateFromDatabase)
      const localPurchaseDate = purchasedDate.toLocaleDateString('en-CA')
      formik.setValues({
        assetTagId: props?.AssetData?.assetTagId,
        brand: props?.AssetData?.brand,
        categoryId: props?.AssetData?.categoryId,
        departmentId: props?.AssetData?.departmentId,
        description: props?.AssetData?.description,
        locationId: props?.AssetData?.locationId,
        model: props?.AssetData?.model,
        purchasedDate: localPurchaseDate,
        purchasedFrom: props?.AssetData?.purchasedFrom,
        serialNo: props?.AssetData?.serialNo,
        siteId: props?.AssetData?.siteId,
        cost: props?.AssetData?.cost,
        statusType: props?.AssetData?.statusType,
      })
    }
  }, [props])

  const formik = useFormik({
    initialValues: { ...initialAssets },
    validationSchema: AssetsSechma,
    onSubmit: async (values) => {
      if (currentUser) {
      }
    },
  })

  const handleClose = () => {
    formik.resetForm()
    props.handleClose()
  }

  return (
    <>
      <SitesModal show={siteModalShow} handleClose={handleSiteModalClose} SiteData={[]} />
      <LocationModal
        show={locationModalShow}
        handleClose={handleSiteModalClose}
        locationData={[]}
      />
      <CategoryModel show={categoryModalShow} handleClose={handleSiteModalClose} data={[]} />
      <DepartmentsModel show={departmentModalShow} handleClose={handleSiteModalClose} data={[]} />
      <Modal
        className='custom-modal-lg px-2'
        show={props.show}
        onHide={props.handleClose}
        backdrop='static'
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>{props?.AssetData?.id ? 'Update Asset' : 'Add Asset'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={formik.handleSubmit} noValidate className='form'>
            <div className='row'>
              <div className='col-xl-6'>
                <div className='mb-4'>
                  <label className='required fw-bold fs-6 mb-2'>Description</label>
                  <input
                    placeholder='Description'
                    {...formik.getFieldProps('description')}
                    type='text'
                    className='form-control form-control-solid mb-3 mb-lg-0'
                    autoComplete='off'
                  />
                  {formik.touched.description && formik.errors.description && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.description}</div>
                    </div>
                  )}
                </div>
              </div>
              <div className='col-xl-6'>
                <div className='mb-4'>
                  <label className='required fw-bold fs-6 mb-2'>Asset Tag ID</label>
                  <input
                    placeholder='Asset Tag ID'
                    {...formik.getFieldProps('assetTagId')}
                    type='text'
                    className='form-control form-control-solid mb-3 mb-lg-0'
                    autoComplete='off'
                  />
                  {formik.touched.assetTagId && formik.errors.assetTagId && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.assetTagId}</div>
                    </div>
                  )}
                </div>
              </div>
              <div className='col-xl-6'>
                <div className='mb-4'>
                  <label className=' fw-bold fs-6 mb-2'>Purchase From</label>
                  <input
                    placeholder='Purchased From'
                    {...formik.getFieldProps('purchasedFrom')}
                    type='text'
                    className='form-control form-control-solid mb-3 mb-lg-0'
                    autoComplete='off'
                  />
                </div>
              </div>
              <div className='col-xl-6'>
                <div className='mb-4'>
                  <label className='required fw-bold fs-6 mb-2'>Purchase Date</label>
                  <input
                    className='form-control form-control-lg form-control-solid'
                    type='date'
                    {...formik.getFieldProps('purchasedDate')}
                  />
                  {formik.touched.purchasedDate && formik.errors.purchasedDate && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.purchasedDate}</div>
                    </div>
                  )}
                </div>
              </div>
              <div className='col-xl-6'>
                <div className='mb-4'>
                  <label className=' fw-bold fs-6 mb-2'>Brand</label>
                  <input
                    placeholder='Brand'
                    {...formik.getFieldProps('brand')}
                    type='text'
                    className='form-control form-control-solid mb-3 mb-lg-0'
                    autoComplete='off'
                  />
                </div>
              </div>
              <div className='col-xl-6'>
                <div className='mb-4'>
                  <label className='required fw-bold fs-6 mb-2'>Cost</label>
                  <input
                    placeholder='cost'
                    {...formik.getFieldProps('cost')}
                    type='text'
                    className='form-control form-control-solid mb-3 mb-lg-0'
                    autoComplete='off'
                  />
                  {formik.touched.cost && formik.errors.cost && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>{formik.errors.cost}</div>
                    </div>
                  )}
                </div>
              </div>
              <div className='col-xl-6'>
                <div className='mb-4'>
                  <label className=' fw-bold fs-6 mb-2'>Model</label>
                  <input
                    placeholder='Model'
                    {...formik.getFieldProps('model')}
                    type='text'
                    className='form-control form-control-solid mb-3 mb-lg-0'
                    autoComplete='off'
                  />
                </div>
              </div>
              <div className='col-xl-6'>
                <div className='mb-4'>
                  <label className=' fw-bold fs-6 mb-2'>Serial No</label>
                  <input
                    placeholder='Serial No'
                    {...formik.getFieldProps('serialNo')}
                    type='text'
                    className='form-control form-control-solid mb-3 mb-lg-0'
                    autoComplete='off'
                  />
                </div>
              </div>
              <div className='col-xl-6'>
                <div className='mb-4'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>Site</label>

                  <div className='col-lg-8 fv-row w-100'>
                    <div className='d-flex'>
                      <select
                        className='form-select form-control form-control-solid form-select-solid form-select-lg flex-1 me-2'
                        {...formik.getFieldProps('siteId')}
                      >
                        <option selected>
                          {' '}
                          Select Site
                        </option>
                        {siteData?.sites.map((item: any, idx: number) => {
                          return (
                            <option key={idx} value={item.id}>
                              {item.name}
                            </option>
                          )
                        })}
                      </select>
                      <span
                        className={'py-2 px-3 plus-new-btn'}
                        onClick={() => {
                          handleSiteModalShow('Site')
                        }}
                      >
                        + New
                      </span>
                    </div>
                    {formik.touched.siteId && formik.errors.siteId && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.siteId}</div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className='col-xl-6'>
                <div className='mb-4'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>Category</label>

                  <div className='col-lg-8 fv-row w-100'>
                    <div className='d-flex'>
                      <select
                        className='form-select form-control form-control-solid form-select-solid form-select-lg flex-1 me-2'
                        {...formik.getFieldProps('categoryId')}
                      >
                        <option selected>
                          {' '}
                          Select Category
                        </option>
                        {categoryData?.category?.map((item: any, idx: number) => {
                          return (
                            <option key={idx} value={item.id}>
                              {item.category}
                            </option>
                          )
                        })}
                      </select>
                      <span
                        className={'py-2 px-3 plus-new-btn'}
                        onClick={() => {
                          handleSiteModalShow('Category')
                        }}
                      >
                        + New
                      </span>
                    </div>
                    {formik.touched.categoryId && formik.errors.categoryId && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.categoryId}</div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className='col-xl-6'>
                <div className='mb-4'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>Location</label>

                  <div className='col-lg-8 fv-row w-100'>
                    <div className='d-flex'>
                      <select
                        className='form-select form-control form-control-solid form-select-solid form-select-lg flex-1 me-2'
                        {...formik.getFieldProps('locationId')}
                      >
                        <option selected>
                          {' '}
                          Select Location
                        </option>
                        {locationData?.locations?.map((item: any, idx: number) => {
                          return (
                            <option key={idx} value={item.id}>
                              {item.location}
                            </option>
                          )
                        })}
                      </select>
                      <span
                        className={'py-2 px-3 plus-new-btn'}
                        onClick={() => {
                          handleSiteModalShow('Location')
                        }}
                      >
                        + New
                      </span>
                    </div>
                    {formik.touched.locationId && formik.errors.locationId && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.locationId}</div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className='col-xl-6'>
                <div className='mb-4'>
                  <label className='col-lg-4 col-form-label fw-bold fs-6'>Department</label>

                  <div className='col-lg-8 fv-row w-100'>
                    <div className='d-flex'>
                      <select
                        className='form-select form-control form-control-solid form-select-solid form-select-lg flex-1 me-2'
                        {...formik.getFieldProps('departmentId')}
                      >
                        <option selected>
                          {' '}
                          Select Department
                        </option>
                        {departmentData?.department?.map((item: any, idx: number) => {
                          return (
                            <option key={idx} value={item.id}>
                              {item.department}
                            </option>
                          )
                        })}
                      </select>
                      <span
                        className={'py-2 px-3 plus-new-btn'}
                        onClick={() => {
                          handleSiteModalShow('Department')
                        }}
                      >
                        + New
                      </span>
                    </div>
                    {formik.touched.departmentId && formik.errors.departmentId && (
                      <div className='fv-plugins-message-container'>
                        <div className='fv-help-block'>{formik.errors.departmentId}</div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className='text-end pt-2'>
              <button
                className='btn btn-light-primary   main-btn-style me-2'
                onClick={handleClose}
              >
                Close{' '}
              </button>

              <button
                type='submit'
                className='btn btn-primary main-btn-style'
                disabled={formik.isSubmitting || !formik.isValid}
              >
                {!isAdding && !isUpdating && (
                  <span className='indicator-label'>
                    {props?.AssetData?.id ? 'Update Asset' : 'Add Asset'}
                  </span>
                )}
                {(isAdding || isUpdating) && (
                  <span className='indicator-progress d-block'>
                    Please wait...
                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                  </span>
                )}
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  )
}

export default WarrantiesModal
