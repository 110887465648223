import { useFormik } from 'formik'
import Modal from 'react-bootstrap/Modal'
import { initialLocation, Site } from '../Core/_models'
import * as Yup from 'yup'
import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { useAuth } from '../../../../Auth'
import { useAddLocationMutation, useUpdateLocationMutation } from '../../../../Services/Locations'
import { useGetAllSitesQuery } from '../../../../Services/Sites'
import { checkUserUnauthorized } from '../../../../Auth'

const LocationSechma = Yup.object().shape({
	location: Yup.string().trim().required('Location is required').min(2),
	siteId: Yup.number().typeError('Site Name is required').required('Site Name is required'),
})

const LocationModal = (props: any) => {
	const { currentUser, saveAuth, setCurrentUser } = useAuth()
	const [sites, setSites] = useState<Site[] | undefined>()
	const [addLocation, { isLoading: isAdding }] = useAddLocationMutation()
	const [updateLocation, { isLoading: isUpdating }] = useUpdateLocationMutation()
	const { data: userSitesList } = useGetAllSitesQuery({ userId: currentUser?.id })

	const formik = useFormik({
		initialValues: {
			...initialLocation,
		},
		validationSchema: LocationSechma,
		onSubmit: async (values) => {
			if (currentUser) {
				try {
					values.location = values?.location?.trim()
					const data = props?.locationData?.id
						? await updateLocation({ id: props.locationData.id, values }).unwrap()
						: await addLocation({ data: values }).unwrap()
					if (data?.success) {
						toast.dismiss()
						toast.success(data?.message)
						formik.resetForm()
						props.handleClose()
						props.setLoading(true)
					} else {
						toast.dismiss()
						toast.error(data?.message)
					}
				} catch (error: any) {
					toast.error(error?.data?.message)
					checkUserUnauthorized(error?.data, saveAuth, setCurrentUser, toast)
				}
			}
		},
	})

	const handleClose = (e) => {
		e?.preventDefault()
		formik.resetForm()
		props.handleClose()
	}
	const handleChangeSiteId = (e) => {
		formik?.setFieldValue('siteId', e?.target?.value)
		if (props?.setSiteId && props?.setSiteId !== undefined) {
			props.setSiteId(e?.target?.value)
		}
	}

	useEffect(() => {
		if (currentUser) {
			formik.resetForm()
			if (userSitesList && userSitesList?.sites) {
				setSites(userSitesList?.sites)
			}
		}
	}, [userSitesList])

	useEffect(() => {
		if (props?.locationData?.id) {
			formik.setValues({
				location: props?.locationData?.location,
				siteId: props?.locationData?.siteId,
			})
		}
	}, [props.locationData])

	return (
		<Modal show={props.show} onHide={props.handleClose} backdrop='static' keyboard={false}>
			<Modal.Header closeButton onClick={handleClose}>
				<Modal.Title>
					{props?.locationData?.siteId ? 'Update Location' : 'Add a Location'}
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<form onSubmit={formik.handleSubmit} noValidate className='form'>
					<p className='mb-4 fs-6'>
						Enter the data about your new location in the fields below and we will add it to your
						list.
					</p>
					<div className='row mb-2 add-location-modal'>
						<label className='col-lg-4 col-form-label required fw-bold fs-6' htmlFor='siteName'>Site Name</label>
						<div className='col-lg-8 fv-row w-100'>
							<select
								id='siteName'
								className='form-select form-control form-control-solid form-select-solid  '
								onChange={(e) => handleChangeSiteId(e)}
								value={formik.values.siteId ?? ''}
							>
								<option>
									Select any Site
								</option>{' '}
								{sites?.map((item: Site, idx: number) => {
									return (
										<option key={idx} value={item.id}>
											{item.name}
										</option>
									)
								})}
							</select>
							{formik.touched.siteId && formik.errors.siteId && (
								<div className='fv-plugins-message-container'>
									<div className='fv-help-block'>{formik.errors.siteId}</div>
								</div>
							)}
						</div>
					</div>
					<div className='row mb-6 add-location-modal'>
						<label className='col-lg-4 col-form-label required fw-bold fs-6' htmlFor='addLocation'>Add Location</label>
						<div className='col-lg-8 fv-row w-100'>
							<input
								id='addLocation'
								placeholder='Site location'
								{...formik.getFieldProps('location')}
								type='text'
								name='location'
								className='form-control form-control-solid mb-3 mb-lg-0'
								autoComplete='off'
							/>
						</div>
						{formik.touched.location && formik.errors.location && (
							<div className='fv-plugins-message-container'>
								<div className='fv-help-block'>
									<span role='alert'>{formik.errors.location}</span>
								</div>
							</div>
						)}
					</div>
					<div className='text-end pt-2'>
						<button className='btn btn-light-primary   main-btn-style me-2' onClick={handleClose}>
							Close{' '}
						</button>
						<button
							type='submit'
							className='btn btn-primary main-btn-style'
							disabled={formik.isSubmitting || !formik.isValid || !formik.dirty }
						>
							{isAdding || isUpdating ? (
								<span className='indicator-progress d-block'>
									Please wait...
									<span className='spinner-border spinner-border-sm align-middle ms-2'></span>
								</span>
							) : (
								<span className='indicator-label'>
									{props?.locationData?.siteId ? 'Update Location' : 'Add Location'}
								</span>
							)}
						</button>
					</div>
				</form>
			</Modal.Body>
		</Modal>
	)
}

export default LocationModal
