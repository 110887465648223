import AssetTagging from './Components/AssetTagging'
const Options = () => {
  return (
    <>
      <AssetTagging />
    </>
  )
}

export default Options
