export interface WarrantyInterface {
    duration: string | null,
    expirationDate: string | null,
    description: string | null,
  }
  export const defaultWarrantyDetail: WarrantyInterface = {
    duration: null,
    expirationDate: null,
    description: null,
    
  }
  