import { Link } from 'react-router-dom'
import { useGetProfileQuery } from 'App/Modules/Services/Profile'
import SplashScreen from 'App/SplashScreen'

export function Overview() {
	const { data, isLoading } = useGetProfileQuery()

	return (
		<>
			<SplashScreen isLoadingTemplate={isLoading} />
			{!isLoading ?
				<div className='card mb-5 mb-xl-10' id='kt_profile_details_view'>
					<div className='card-body d-flex border-bottom border-bottom-gray-200 justify-content-between align-items-center pb-3'>
						<div className='card-title m-0'>
							<h3 className='fw-bolder m-0'>Profile Details</h3>
						</div>
						<Link to='/crafted/account/settings' className='btn btn-light-primary align-self-center'>
							Edit Profile
						</Link>
					</div>
					<div className='card-body p-9'>
						<div className='row mb-7'>
							<label className='col-lg-4 fw-bold'>Full Name</label>
							<div className='col-lg-8'>
								<span className='fw-bolder fs-6 text-muted'>{data?.user?.firstName || "-"} {data?.user?.lastName}</span>
							</div>
						</div>
						<div className='row mb-7'>
							<label className='col-lg-4 fw-bold'>Title</label>
							<div className='col-lg-8'>
								<span className='fw-bolder fs-6 text-muted'>{data?.user?.title || "-"}</span>
							</div>
						</div>
						<div className='row mb-7'>
							<label className='col-lg-4 fw-bold'>Email</label>
							<div className='col-lg-8'>
								<span className='fw-bolder fs-6 text-muted'>{data?.user?.email || "-"}</span>
							</div>
						</div>
						<div className='row mb-7'>
							<label className='col-lg-4 fw-bold'>
								Phone
							</label>
							<div className='col-lg-8 d-flex align-items-center'>
								<span className='fw-bolder fs-6 me-2 text-muted'>{data?.user?.phone || "-"}</span>
							</div>
						</div>
					</div>
				</div>
			: null}

		</>
	)
}
