import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import CompanyInfo from './CompnayInfo/CompanyInfo'
import Sites from './Sites/Sites'
import Location from './Locations/Location'
import Categories from './Categories/Categories'
import Assets from './Asset/Assets'
import { TableOption } from './TableOptions/TableOption'
import Events from './Event/Components/Events'
import { wizardArray } from 'config'
import { useGetCompanyQuery } from 'App/Modules/Services/Company'
import { useAuth } from 'App/Modules/Auth'
import { UpdateProfile } from 'App/Modules/Accounts/Components/Settings/SettingRequest'
import { toast } from 'react-toastify'
import timezones from 'timezones-list'
import { BeatLoader } from 'react-spinners'
import { getUserByToken } from 'App/Modules/Auth/core/_requests'
import Loader from 'App/BeatLoader'

function SetupWizard() {
	const { currentUser, setCurrentUser } = useAuth()
	const [setupPage, setSetupPage] = useState<string>('')
	const [selectedIndex, setSelectedIndex] = useState<number>(0)
	const { data: companyData } = useGetCompanyQuery()
	const [loading, setLoading] = useState(true)
	const [showContinue, setShowContinue] = useState(false)
	const navigate = useNavigate()
	const personUser = currentUser?.userType === '2'
	const customerUser = currentUser?.userType === '4'

	useEffect(() => {
		getUser()
		setLoading(false)
	}, [])

	useEffect(() => {
		setLoading(true)
		switch (currentUser?.onStep) {
			case 1: {
				setSetupPage('company')
				setSelectedIndex(0)
				break;
			}
			case 2: {
				setSetupPage('sites')
				setSelectedIndex(1)
				break
			}
			case 3: {
				setSetupPage('location')
				setSelectedIndex(2)
				break
			}
			case 4: {
				setSetupPage('categories')
				setSelectedIndex(3)
				break

			}
			default: {
				setSetupPage('company')
				setSelectedIndex(0)
				break;
			}
		}
		setLoading(false)
	}, [currentUser])

	useEffect(() => {
		if (companyData) {
			setShowContinue(true)
		}
	}, [companyData])

	const getUser = async () => {
		const token = localStorage.getItem("kt-auth-react-v")
		const apiToken = JSON.parse(token || "")
		const {
			data: { user, infoNote }
		}: any = await getUserByToken(apiToken?.token)
		if (user) {
			setCurrentUser(user)
			if (infoNote) {
				toast.info(infoNote)
			}
		}
	}

	const goToTop = () => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		})
	}

	const handleContinue = () => {
		const onStep = setupPage === "company" ? 2 : setupPage === "sites" ? 3 : setupPage === "location" ? 4 : 1
		handleUpdateSteps(onStep)
		if (selectedIndex < wizardArray.length - 1) {
			setSelectedIndex(selectedIndex + 1)
			setSetupPage(wizardArray[selectedIndex + 1].path)
			goToTop()
		}
	}

	const handleUpdateSteps = async (onStep) => {
		const data = {
			firstName: currentUser?.firstName,
			lastName: currentUser?.lastName,
			title: currentUser?.title || '',
			phone: currentUser?.phone || '',
			email: currentUser?.email,
			timeZone: currentUser?.timeZone || timezones[0]?.label,
			isSetupCompleted: true,
			dateFormat: currentUser?.dateFormat || 'MM/DD/YYYY',
			timeFormat: currentUser?.timeFormat || 'hh:mm:ss A'
		}
		await UpdateProfile(data)
	}

	return (
		<>
			<div className='wizard-wrap'>
				<div id='kt_content_container' className='container'>
					<div className='card rounded-bottom-0'>
						<div className='border-bottom'>
							<div className='wizard-steps border-bottom'>
								{wizardArray.map((item, index) => {
									return (
										<span className='wizard-step' key={index}>
											<div className='wizard-label'>
												<span className="wizard-icon">
													<svg fill={`${index <= selectedIndex ? "#001a42" : "#838383"}`}>{item.image}</svg>
												</span>
												<h2 className={`wizard-title ${index <= selectedIndex ? "active" : ""}`}>{item.title}</h2>
											</div>
											{index === wizardArray.length - 1 ? null : (
												<span className={`wizard-arrow ${index <= selectedIndex ? 'active' : ''}`}>
													<i className="fas fa-chevron-right" aria-hidden='true'></i>
												</span>
											)}
										</span>
									)
								})}
							</div>
							<div className='wizard-content'>
								{setupPage === 'company' ? (
									<CompanyInfo showContinue={showContinue} handleContinue={handleContinue} />
								) : setupPage === 'sites' ? (
									<Sites />
								) : setupPage === 'location' ? (
									<Location />
								) : setupPage === 'categories' ? (
									<Categories />
								) : null}
							</div>
							{setupPage !== 'company' ? (
								<div id='kt_content_container' className='container'>
									<div className='card rounded-top-0'>
										<div className='card-footer'>
											<div className='d-flex justify-content-end align-items-center flex-wrap'>
												<button
													className='btn btn-light-primary   main-btn-style me-2'
													onClick={() => {
														const onStep = setupPage === "sites" ? 1 : setupPage === "location" ? 2 : setupPage === "categories" ? 3 : 4
														handleUpdateSteps(onStep)
														if (selectedIndex !== 0) {
															setSelectedIndex(selectedIndex - 1)
															setSetupPage(wizardArray[selectedIndex - 1].path)
															goToTop()
														}
													}}
												>
													Back
												</button>
												{selectedIndex !== wizardArray.length - 1 ? (
													<button
														className='btn btn-primary'
														onClick={() => {
															handleContinue()
														}}
													>
														Continue
													</button>
												) : (
													<button
														className='btn btn-primary'
														onClick={async () => {
															const data = {
																firstName: currentUser?.firstName,
																lastName: currentUser?.lastName,
																title: currentUser?.title || '',
																phone: currentUser?.phone || '',
																email: currentUser?.email,
																timeZone: currentUser?.timeZone || timezones[0]?.label,
																isSetupCompleted: true,
																dateFormat: currentUser?.dateFormat || 'MM/DD/YYYY',
																timeFormat: currentUser?.timeFormat || 'hh:mm:ss A'
															}
															await UpdateProfile(data)
															if (!(personUser || customerUser)) {
																navigate("/dashboard")
															}
															else {
																navigate('/assets')
															}
															window.location.reload()
														}}
													>
														Finish
													</button>
												)}
											</div>
										</div>
									</div>
								</div>
							) : null
							}
						</div >
					</div >
				</div >
			</div >
			<Loader loading={loading} />
		</>

	)
}

export default SetupWizard
