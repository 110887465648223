import React, { useEffect, useState } from 'react'
import { PageTitle } from "../../../_metronic/layout/core"
import { useIntl } from 'react-intl'
import {
	useGetTableColumnsMutation,
	useGetExportCategoriesMutation,
	useGetExportDepartmentsMutation,
	useGetExportSitesMutation,
	useGetExportCustomersMutation,
	useGetExportLocationsMutation,
	useGetExportAssetsMutation,
	useGetExportMaintenancesMutation,
	useGetExportPersonsMutation,
	useGetExportWarrantiesMutation
} from '../Services/Exports'
import OrderTableColumns from './OrderTableColumns'
import { toast } from 'react-toastify'
import Loader from 'App/BeatLoader'

function Export() {
	const intl = useIntl()
	const [exportbtn, setExportbtn] = useState<boolean>(false)
	const [selectedValue, setSelectedValue] = useState<string>()
	const [columnsData, setColumnsData] = useState<any>()
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const [loader, setLoader] = useState(false)
	const [fileType, setFileType] = useState<string>("excel")
	const [getColumnsName] = useGetTableColumnsMutation()
	const [exportCategories] = useGetExportCategoriesMutation();
	const [exportDepartments] = useGetExportDepartmentsMutation();
	const [exportSites] = useGetExportSitesMutation();
	const [exportCustomers] = useGetExportCustomersMutation();
	const [exportLocations] = useGetExportLocationsMutation();
	const [exportAssets] = useGetExportAssetsMutation();
	const [exportMaintenances] = useGetExportMaintenancesMutation();
	const [exportPersons] = useGetExportPersonsMutation();
	const [exportWarranties] = useGetExportWarrantiesMutation();

	const handleChange = async (e: React.ChangeEvent<HTMLSelectElement>) => {
		setExportbtn(e.target.value !== "" && e.target.value !== "Select");
		setSelectedValue(e.target.value)
		setIsLoading(true)
		getColumns(e.target.value)
	}

	const getColumns = async (tableName: string) => {
		const obj: any = { tableName: tableName }
		const data: any = await getColumnsName(obj)
		setColumnsData(data?.data?.columns)
		setIsLoading(false)
	}
	const downloadExcel = (fileName: any) => {
		const file = process.env.REACT_APP_PUBLIC_URL + fileName;
		const link = document.createElement('a');
		link.href = file;
		link.download = file;
		link.click();
	}

	const handleSubmit = async () => {
		setLoader(true)
		const activeColumns = columnsData?.filter((column: any) => column.defaultActive);
		const filterColumns = activeColumns.map(({ header, key }) => ({ header, key, width: 20 }));
		const obj = { fileType: fileType, columns: filterColumns }
		if (selectedValue === 'assets') {
			const { data }: any = await exportAssets(obj)
			downloadExcel(data?.fileName)
		} else if (selectedValue === 'categories') {
			const { data }: any = await exportCategories(obj)
			downloadExcel(data?.fileName)
		} else if (selectedValue === 'customers') {
			const { data, error }: any = await exportCustomers(obj)
			if (data) {
				downloadExcel(data?.fileName)
			} else {
				toast.error(error?.data?.message);
			}
		} else if (selectedValue === 'departments') {
			const { data }: any = await exportDepartments(obj)
			downloadExcel(data?.fileName)
		} else if (selectedValue === 'warranties') {
			const { data }: any = await exportWarranties(obj)
			downloadExcel(data?.fileName)
		}
		else if (selectedValue === 'locations') {
			const { data }: any = await exportLocations(obj)
			downloadExcel(data?.fileName)
		} else if (selectedValue === 'maintenances') {
			const { data }: any = await exportMaintenances(obj)
			downloadExcel(data?.fileName)
		} else if (selectedValue === 'persons') {
			const { data, error }: any = await exportPersons(obj)
			if (data) {
				downloadExcel(data?.fileName)
			} else {
				toast.error(error?.data?.message);
			}
		} else if (selectedValue === 'sites') {
			const { data }: any = await exportSites(obj)
			downloadExcel(data?.fileName)
		}
		setLoader(false)
	}

	return (
		<>
			<PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'Export' })}</PageTitle>
			<div className='card mb-5 mb-xl-10'>
				<div
					className='card-header border-0'
				>
					<div className='card-title m-0 '>
						<h3 className='fw-bolder m-0'><span className='me-2 align-middle'><i className='la la-cloud-download fs-1'></i></span>Export Tables</h3>
					</div>
				</div>
				<div id='kt_account_profile_details' className='collapse show'>
					<div className='form'>
						<div className='card-body border-top'>
							<div className='pb-5 mb-5'>
								<h5>Step 1: Select Table</h5>
							</div>
							<div className="row mb-5">
								<div className="col-xl-6">
									<div className='row mb-6'>
										<div className='col-lg-3 col-form-label fw-bold fs-6'>Export Table</div>
										<div className='col-lg-9 fv-row'>
											<select className="form-select" onChange={(e) => handleChange(e)}>
												<option>Select</option>
												<option value="assets" >Assets</option>
												<option value="categories">Categories</option>
												<option value="customers">Customers</option>
												<option value="departments">Departments</option>
												<option value="locations">Locations</option>
												<option value="maintenances">Maintenances</option>
												<option value="persons">Persons/Employees</option>
												<option value="sites">Sites</option>
												<option value="warranties">Warranties</option>
											</select>
										</div>
									</div>
								</div>
							</div>
							{selectedValue && isLoading ?
								<div className='card-body border-top px-0 pb-3'>Loading...</div>
								: selectedValue && columnsData?.length > 0
									?
									<OrderTableColumns setColumnsData={setColumnsData} columnsData={columnsData} />
									:
									null
							}
							{exportbtn && <div className='card-body border-top px-0 pb-0'>
								<div className='heading'>
									<h5>Step 3: Export File Format</h5>
								</div>
								<div className='mb-5'>
									Select a file format that works for you.
								</div>
								<div className="col-xl-6">
									<div className='row mb-6'>
										<div className='col-lg-3 col-form-label fw-bold fs-6'>File type</div>
										<div className='col-lg-9 fv-row'>
											<select className="form-select mb-5" onChange={(e) => setFileType(e.target.value)}>
												<option value="excel">Excel</option>
												<option value="csv" >CSV</option>
											</select>
										</div>
									</div>
								</div>
								<div className='card-footer d-flex justify-content-end pt-6 p-0'>
									<button type='button' className='btn btn-primary d-flex justify-content-center' onClick={() => handleSubmit()}>
										<i className='la la-cloud-download fs-1 me-1'></i>
										Export
									</button>
								</div>
							</div>}
						</div>
					</div>
				</div>
			</div>
			<Loader loading={loader} />
		</>
	)
}

export default Export