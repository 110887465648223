export const toAbsoluteUrlFile = (pathname: string) => {
    if (pathname === "assets") {
        const filePath = "templates/ImportAssetTemplate.xlsx";
        return `${process.env.REACT_APP_ASSET_URL}${filePath}`;
    } else if (pathname === "categories") {
        const filePath = "templates/ImportCategoriesTemplate.xlsx";
        return `${process.env.REACT_APP_ASSET_URL}${filePath}`;
    } else if (pathname === "customers") {
        const filePath = "templates/ImportCustomersTemplate.xlsx";
        return `${process.env.REACT_APP_ASSET_URL}${filePath}`;
    } else if (pathname === "departments") {
        const filePath = "templates/ImportDepartmentsTemplate.xlsx";
        return `${process.env.REACT_APP_ASSET_URL}${filePath}`;
    } else if (pathname === "locations") {
        const filePath = "templates/ImportLocationsTemplate.xlsx";
        return `${process.env.REACT_APP_ASSET_URL}${filePath}`;
    } else if (pathname === "maintenances") {
        const filePath = "templates/ImportMaintenancesTemplate.xlsx";
        return  `${process.env.REACT_APP_ASSET_URL}${filePath}`;
    } else if (pathname === "person") {
        const filePath = "templates/ImportPersonsTemplate.xlsx";
        return `${process.env.REACT_APP_ASSET_URL}${filePath}`;
    } else if (pathname === "sites") {
        const filePath = "templates/ImportSitesTemplate.xlsx";
        return `${process.env.REACT_APP_ASSET_URL}${filePath}`;
    } else if (pathname === "warranties") {
        const filePath = "templates/ImportWarrantiesTemplate.xlsx";
        return `${process.env.REACT_APP_ASSET_URL}${filePath}`;
    } else if (pathname === "fixedLimit") {
        const filePath = "templates/FieldLimits.xlsx";
        return `${process.env.REACT_APP_ASSET_URL}${filePath}`;
    } else {
        return ""
    }
}
