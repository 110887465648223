import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { getAuth } from '../Auth'
import { dashboardApi } from './Dashboard'
import { toast } from 'react-toastify'

const API_URL = process.env.REACT_APP_API_URL

export const assetsApi = createApi({
	reducerPath: 'assetsApi',
	baseQuery: fetchBaseQuery({
		baseUrl: `${API_URL}/user-assets/`,
		prepareHeaders: (headers) => {
			const auth = getAuth()
			if (auth && auth?.token) {
				headers.set('Authorization', `Bearer ${auth?.token}`);
			}
			return headers;
		},
	}),
	tagTypes: ['Assets', 'Asset'],
	endpoints: (build) => ({
		getAllAssets: build.query<any, { body: any, page: any, limit: any; keys: any; }>({
			query: ({ body, page, limit, keys }) => ({
				url: `list/?${body}&page=${page}&limit=${limit}&keys=${keys}`,
				method: 'GET',
			}),
			providesTags: ['Assets'],
		}),
		getSingleAsset: build.query<any, { assetId: any }>({
			query: ({ assetId }) => ({
				url: `get/${assetId}`,
				method: 'GET',
			}),
			providesTags: ['Assets'],
		}),
		addAsset: build.mutation<any, FormData>({
			query: (formData) => ({
				url: `create`,
				method: 'POST',
				body: formData,
			}),
			onQueryStarted: (arg, api) => {
				api.queryFulfilled.then(() => {
					api.dispatch(dashboardApi.util.invalidateTags(['Dashboard']))
				}).catch(err=> toast.error(err.message))
			},
			invalidatesTags: () => [{ type: 'Assets' }],
		}),
		updateAsset: build.mutation<any, { assetId: any; formData: FormData }>({
			query: ({ assetId, formData }) => ({
				url: `update/${assetId}`,
				method: 'PUT',
				body: formData,
			}),
			invalidatesTags: (result, error, { assetId }) => [{ type: 'Assets' }],
		}),
		deleteAssets: build.mutation<any, { id: any }>({
			query({ id }) {
				return {
					url: `delete-all`,
					method: 'DELETE',
					body: id
				}
			},
			onQueryStarted: (arg, api) => {
				api.queryFulfilled.then(() => {
					api.dispatch(dashboardApi.util.invalidateTags(['Dashboard']))
				}).catch(err=> toast.error(err.message))
			},
			invalidatesTags: (userId, id) => [{ type: 'Assets' }],
		}),
	}),
})

export const {
	useGetAllAssetsQuery,
	useGetSingleAssetQuery,
	useAddAssetMutation,
	useUpdateAssetMutation,
	useDeleteAssetsMutation,
} = assetsApi
