import { useState } from 'react'
import { useParams } from 'react-router-dom'
import * as Yup from 'yup'
import clsx from 'clsx'
import { Link, useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import { resetPassword } from '../core/_requests'
import { toast } from 'react-toastify'
import { passwordRegex } from 'config'

const initialValues = {
  newPassword: '',
  passwordConfirmation: '',
}

const changePasswordValidationSchema = Yup.object().shape({
  newPassword: Yup.string()
    .min(8, 'Password must be at least 8 characters')
    .max(50, 'Password must not exceed 50 characters')
    .matches(
      passwordRegex,
      'Password must include at least one uppercase letter, one lowercase letter, one number, one symbol and no spaces'
    )
    .required('New password is required'),
  passwordConfirmation: Yup.string()
    .min(8, 'Password must be at least 8 characters')
    .max(50, 'Password must not exceed 50 characters')
    .matches(
      passwordRegex,
      'Password must include at least one uppercase letter, one lowercase letter, one number, one symbol and no spaces'
    )
    .required('Confirm password is required')
    .oneOf([Yup.ref('newPassword'), null], 'Passwords must match'),
})

const ResetPassword = () => {
  const { token } = useParams()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const [showPassword, setShowPassword] = useState(false)
  const [showConPassword, setShowConPassword] = useState(false)

  const formik = useFormik({
    initialValues,
    validationSchema: changePasswordValidationSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      setHasErrors(undefined)
      if (token) {
        const { passwordConfirmation, newPassword } = values
        const flag = 0
        resetPassword(newPassword, passwordConfirmation, token, flag)
          .then(({ data: { result } }) => {
            setHasErrors(false)
            setLoading(false)
            toast.success('Password changed successfuly')
            navigate('/auth')
          })
          .catch((err) => {
            toast.error(err?.response?.data?.message)
            setHasErrors(true)
            setLoading(false)
            setSubmitting(false)
          })
      } else {
        setStatus('Something went wrong')
      }
    },
  })
  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_password_reset_form'
      onSubmit={formik.handleSubmit}
    >
      <div className='text-center mb-10'>
        <h1 className='text-dark mb-3'>Reset Password</h1>
      </div>
      <div className='fv-row mb-4'>
        <label className='form-label fw-bolder text-gray-900 fs-6 required'>New Password</label>
        <div className='d-flex position-relative'>
          <input
            type={showPassword ? 'text' : 'password'}
            placeholder='New Password'
            autoComplete='off'
            {...formik.getFieldProps('newPassword')}
            className={clsx(
              'form-control  form-control-solid',
              { 'is-invalid': formik.touched.newPassword && formik.errors.newPassword },
              {
                'is-valid': formik.touched.newPassword && !formik.errors.newPassword,
              }
            )}
          />
          <i
            onClick={() => setShowPassword((prevState) => !prevState)}
            className={
              showPassword
                ? 'bi bi-eye-slash fs-md-5 fs-6' + ' login-eye'
                : 'bi bi-eye fs-md-5 fs-6' + ' login-eye'
            }
          />
        </div>
        {formik.touched.newPassword && formik.errors.newPassword && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.newPassword}</span>
            </div>
          </div>
        )}
      </div>
      <div className='fv-row mb-10'>
        <label className='form-label fw-bolder text-gray-900 fs-6 required'>
          Confirm Password
        </label>
        <div className='d-flex position-relative'>
          <input
            type={showConPassword ? 'text' : 'password'}
            placeholder='Confirm Password'
            autoComplete='off'
            {...formik.getFieldProps('passwordConfirmation')}
            className={clsx(
              'form-control  form-control-solid',
              {
                'is-invalid':
                  formik.touched.passwordConfirmation && formik.errors.passwordConfirmation,
              },
              {
                'is-valid':
                  formik.touched.passwordConfirmation && !formik.errors.passwordConfirmation,
              }
            )}
          />
          <i
            onClick={() => setShowConPassword((prevState) => !prevState)}
            className={
              showConPassword
                ? 'bi bi-eye-slash fs-md-5 fs-6' + ' login-eye'
                : 'bi bi-eye fs-md-5 fs-6' + ' login-eye'
            }
          />
        </div>
        {formik.touched.passwordConfirmation && formik.errors.passwordConfirmation && (
          <div className='fv-plugins-message-container'>
            <div className='fv-help-block'>
              <span role='alert'>{formik.errors.passwordConfirmation}</span>
            </div>
          </div>
        )}
      </div>
      <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
        <button
          type='submit'
          id='kt_password_reset_submit'
          className='btn btn-lg btn-primary w-100 mb-sm-4 mb-3'
          disabled={formik.isSubmitting || !formik.isValid || !formik.dirty}
        >
          {!loading && <span className='indicator-label'>Submit</span>}
          {loading && (
            <span className='indicator-progress'>
              Please wait...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
        <Link to='/auth/login' className='btn btn-lg btn-light-primary main-btn-style w-100'>
          Cancel
        </Link>{' '}
      </div>
    </form>
  )
}

export default ResetPassword
