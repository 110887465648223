import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { assetsApi } from './Assets'
import { getAuth } from '../Auth'


const API_URL = process.env.REACT_APP_API_URL

export const disposeApi = createApi({
    reducerPath: 'disposeApi',
    baseQuery: fetchBaseQuery({
        baseUrl: `${API_URL}/`,
        prepareHeaders: (headers) => {
            const auth = getAuth()
            if (auth && auth?.token) {
                headers.set('Authorization', `Bearer ${auth?.token}`);
            }
            return headers;
        },
    }),
    tagTypes: ['Dispose'],
    endpoints: (build) => ({
        addDispose: build.mutation<any, any>({
            query: (data) => ({
                url: `dispose`,
                method: 'POST',
                body: data,
            }),
            onQueryStarted: (arg, api) => {
                api.queryFulfilled.then(() => {
                    api.dispatch(
                        assetsApi.util.invalidateTags(["Assets"])
                    );
                });
            },
            invalidatesTags: () => [{ type: 'Dispose' }],
        }),
    }),
})

export const {
    useAddDisposeMutation,
} = disposeApi
