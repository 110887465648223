export type Site = {
  name: string
  description?: string | null
  address?: string | null
  aptSuite?: string | null
  city?: string | ''
  state?: string | null
  postalCode?: string | null
  country?: string | null
}

export const initialSites: Site = {
  name: '',
  description: null,
  address: null,
  aptSuite: null,
  city: '',
  state: null,
  postalCode: null,
  country: null,
}

export type SiteResponse = {
  id: number
  name: string
  description?: string | null
  address?: string | null
  aptSuite?: string | null
  city?: string | ''
  state?: string | null
  postalCode?: string | null
  country?: string | null
  createdAt?: string | null
  updatedAT?: string | null
}
