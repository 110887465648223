import { useState, useEffect } from 'react'
import { useGetAllSitesQuery } from '../../Modules/Services/Sites'
import { useGetAllLocationsBySiteQuery } from '../../Modules/Services/Locations'
import { useAddReserveMutation } from 'App/Modules/Services/Reserve'
import { useGetAllAssetsQuery } from 'App/Modules/Services/Assets'
import { useGetAllPersonsQuery, useGetPersonsQuery } from 'App/Modules/Services/Persons'
import { useGetAllCustomersQuery, useGetCustomersQuery } from 'App/Modules/Services/Customers'
import { ReserveInterface, defaultReserveDetail as initialValues } from './Core/SetupModel'
import { FetchBaseQueryError } from '@reduxjs/toolkit/query/react'
import CustomersModal from 'App/Modules/Apps/CompanySetup/Customers/Components/CustomersModal'
import DuplicateReserveModal from './Modal/DuplicateReserveModal'
import PersonsModal from 'App/Modules/Apps/CompanySetup/Persons/Components/PersonsModal'
import { checkUserUnauthorized } from '../../Modules/Auth'
import { PageTitle } from '../../../_metronic/layout/core'
import { useIntl } from 'react-intl'
import ReserveModal from './Modal/ReserveModal'
import { useAuth } from '../../Modules/Auth'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { toast } from 'react-toastify'
import { useNavigate, Link } from 'react-router-dom'
import ReactDatePicker from 'react-datepicker'
import moment from 'moment'
import Select from 'react-select'
import SplashScreen from 'App/SplashScreen'
import Loader from 'App/BeatLoader'

const accountDetailsSchema = Yup.object().shape({
  startDate: Yup.date()
  .min(new Date(new Date().setHours(0, 0, 0, 0)), 'Start date cannot be a past date')
  .required('Start date is required'),
  endDate:Yup.date()
  .min(new Date(new Date().setHours(0, 0, 0, 0)), 'End date cannot be a past date')
  .required('End date is required'),
  siteId: Yup.string().when('reserveFor', {
    is: 'site',
    then: Yup.string().required('Site is required'),
  }),
})

const Reserve = () => {
  const intl = useIntl()
  const { currentUser, saveAuth, setCurrentUser } = useAuth()
  const navigate = useNavigate()
  const [skip, setSkip] = useState<boolean>(false)
  const [siteId, setSiteId] = useState<any>()
  const [showPersonModal, setShowPersonModal] = useState<boolean>(false)
  const [showCustomerModal, setShowCustomerModal] = useState<boolean>(false)
  const [assets, setAssets] = useState<any>([])
  const [dateRange, setDateRange] = useState<any[]>([null, null])
  const [startDate, setStartDate] = useState<any>(new Date())
  const [endDate, setEndDate] = useState<any>(new Date())
  const [showReserveModal, setShowReserveModal] = useState(false)
  const [duplicateReserveModal, setDuplicateReserveModal] = useState(false)
  const [pendingAssets, setPendingAssets] = useState<any>([])
  const [selectedAssets, setSelectedAssets] = useState<any>([])
  const [selectAll, setSelectAll] = useState(false)
  const [reserveFor, setReserveFor] = useState('person')
  const [emailEnabled, setEmailEnabled] = useState(false)
  const [firstEmail, setFirstEmail] = useState(false)
  const [secondEmail, setSecondEmail] = useState(false)
  const [selected, setSelected] = useState<any>([])
  const [reservedData, setReservedData] = useState<any>([])
  const [searchKeywords, setSearchKeywords] = useState<any>('')
  const [searchPersons, setSearchPersons] = useState<any>('')
  const [employeeData, setEmployeeData] = useState<any>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [siteFieldError, setSiteFieldError] = useState<boolean>(false)
  const [personFieldError, setPersonFieldError] = useState<boolean>(false)
  const [deleteDataArr, setdeleteDataArr] = useState<number[]>([])
  const [selectedPersonValue, setSelectedPersonValue] = useState('')
  const [selectedCustomerValue, setSelectedCustomerValue] = useState('')
  const [personOptinos, setPersonOptinos] = useState([])
  const [customerId, setCustomerId] = useState('')
  const [personId, setPersonId] = useState('')
  const [page, setPage] = useState<number>(1)
  const [limit, setLimit] = useState<number>(10)
  const [customerOptinos, setCustomerOptinos] = useState([])
  const [customErrors, setCustomErrors] = useState<boolean>(false)
  const { data: siteData } = useGetAllSitesQuery({ userId: currentUser?.id })
  const { data: userPersonsList } = useGetAllPersonsQuery(
    { search: selectedPersonValue },
    { skip: !selectedPersonValue }
  )
  const { data: customerData } = useGetCustomersQuery({})
  const { data: customerList } = useGetAllCustomersQuery(
    { search: selectedCustomerValue },
    { skip: !selectedCustomerValue }
  )
  const { data: locationData, error: locationError } = useGetAllLocationsBySiteQuery(
    { userId: currentUser?.id, id: siteId },
    { skip: !siteId }
  )
  const [addReserve, { isLoading: isAdding }] = useAddReserveMutation()
  const {
    data: assetsData,
    isLoading,
    error,
    isSuccess,
  } = useGetAllAssetsQuery({
    body: new URLSearchParams({}).toString(),
    page: page,
    limit: limit,
    keys: '',
  })
  const [isNewPersonAdded, setIsNewPersonAdded] = useState(false)
  const [isNewCustomerAdded, setIsNewCustomerAdded] = useState(false)

  const formik = useFormik({
    initialValues: { ...initialValues },
    validationSchema: accountDetailsSchema,
    onSubmit: async (values) => {
      try {
        handleReserveSubmit(values)
        formik.resetForm()
      } catch (error) {
        toast.error('Something went wrong')
      }
    },
  })
  const { data: personData } = useGetPersonsQuery({})

  useEffect(() => {
    if (personData) {
      const options = personData?.persons?.map((item) => ({
        value: item?.email,
        label: (
          <div className='d-flex flex-column'>
            <span className='personFullName'>{`${item?.firstName} ${item?.lastName}`}</span>
            <span className='personEmail'> {item?.email}</span>
          </div>
        ),
      }))
      setPersonOptinos(options)
    }
    if (customerData) {
      const options = customerData?.customers?.map((item) => ({
        value: item?.email,
        label: (
          <div className='d-flex flex-column'>
            <span className='personFullName'>{item?.firstName + '' + item?.lastName}</span>
            <span className='personEmail'> {item?.email}</span>
          </div>
        ),
      }))
      setCustomerOptinos(options)
    }
  }, [personData, customerData])

  useEffect(() => {
    setLoading(false)
  }, [personData])

  useEffect(() => {
    if (assetsData) {
      getAssetListing()
    }
  }, [assetsData])

  useEffect(() => {
    if (assets && pendingAssets && showReserveModal == false) {
      handlePendingList()
    }
  }, [assets, showReserveModal])

  const getAssetListing = async () => {
    setAssets(assetsData?.userAssets)
  }
  const handlePendingList = () => {
    const data = assets?.filter((asset: any) => pendingAssets.includes(asset.id))
    setSelectedAssets(data)
    setdeleteDataArr([])
  }
  const handleReserveSubmit = async (values: ReserveInterface) => {
    if (reserveFor == 'site' && !values?.siteId) {
      setSiteFieldError(true)
    } else if (reserveFor == 'person' && !userPersonsList) {
      setPersonFieldError(true)
    } else if (reserveFor == 'customer' && !customerList) {
      setCustomErrors(true)
    } else {
      const data: ReserveInterface = {
        startDate: values?.startDate,
        endDate: values?.endDate,
        reserveFor: reserveFor,
        locationId: values?.locationId,
        sendEmail: values?.sendEmail,
        reminderEmail1: values?.reminderEmail1,
        reminderEmail2: values?.reminderEmail2,
        assetId: pendingAssets,
      }
      if (emailEnabled) {
        data.sendEmail = selectedPersonValue ? selectedPersonValue : selectedCustomerValue
      }
      if (reserveFor == 'person' && selectedPersonValue) {
        data.personId = userPersonsList?.persons[0]?.id
        setPersonFieldError(false)
      } else if (reserveFor == 'person' && !selectedPersonValue) {
        setPersonFieldError(true);
        return;
      } else if (reserveFor == 'site') {
        setSiteFieldError(false)
        data.siteId = values?.siteId
      } else if (reserveFor == 'customer' && selectedCustomerValue) {
        data.customerId = customerList?.customers[0]?.id
      } else if (reserveFor == 'customer' && !selectedCustomerValue) {
        setCustomErrors(true);
        return;
      }
      try {
        const result = await addReserve(data).unwrap()
        if (result) {
          if (result.status === 409) {
            setReservedData(result?.reservedAssetIds)
            handleDuplicateReserveModal()
          } else {
            toast.dismiss()
            toast.success(result?.message ? result?.message : result?.data?.message)
            setSelectedAssets([])
            setPersonFieldError(false)
            setCustomErrors(false)
            setSiteFieldError(false)
            formik.resetForm()
          }
        }
      } catch (error: any) {
        toast.error(error?.data?.message)
      }
    }
  }

  const handleShowReserveModal = () => {
    setShowReserveModal(true)
  }
  const handleDuplicateReserveModal = () => {
    setDuplicateReserveModal(true)
  }
  const handleCloseDuplicateReserveModal = () => {
    setDuplicateReserveModal(false)
  }
  const handleCloseReserveModal = () => {
    setShowReserveModal(false)
  }
  const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = assets?.map((item: any) => item.id)
      setSelected(newSelecteds)
      setSelectAll(true)
      setdeleteDataArr(newSelecteds)
    } else {
      setSelected([])
      setSelectAll(false)
      setdeleteDataArr([])
    }
  }
  const handleCheckboxChange = (id: number) => {
    setSelected((prevSelected: any) => {
      if (prevSelected.includes(id)) {
        return prevSelected.filter((itemId: any) => itemId !== id)
      } else {
        return [...prevSelected, id]
      }
    })
    setdeleteDataArr((prevSelected: any) => {
      if (prevSelected.includes(id)) {
        return prevSelected.filter((itemId: any) => itemId !== id)
      } else {
        return [...prevSelected, id]
      }
    })
  }
  const deleteSelectedAssests = async () => {
    const data = selectedAssets?.filter((asset: any) => !deleteDataArr.includes(asset.id))
    setSelectedAssets(data)
    setdeleteDataArr([])
    setSelectAll(false)
    setSelected([])
  }
  useEffect(() => {
    setSkip(false)
  }, [])

  const handleSiteClick = ({ target: { value } }: any) => {
    if (value === '') {
      setSiteFieldError(true)
      return undefined
    }
    setLoading(true)
    setSiteId(value)
    setSkip(false)
  }
  useEffect(() => {
    if (locationData) {
      setLoading(false)
    }
  }, [locationData])
  const handleClosePersonModal = () => {
    setShowPersonModal(false)
  }

  const handleShowPersonModal = () => {
    setShowPersonModal(true)
    setIsNewPersonAdded(true)
  }
  const handleCloseCustomerModal = () => {
    setShowCustomerModal(false)
  }

  const handleShowCustomerModal = () => {
    setShowCustomerModal(true)
    setIsNewCustomerAdded(true)
  }
  const handleSelectChange = (selectedOption) => {
    setSelectedPersonValue(selectedOption?.value || '')
    if (selectedOption === null) {
      setPersonFieldError(true)
    } else {
      setPersonFieldError(false)
    }
  }
  const handleSelectChangeCustomer = (selectedOption) => {
    setSelectedCustomerValue(selectedOption?.value || "")
    if (selectedOption === null) {
      setCustomErrors(true)
    } else {
      setCustomErrors(false)
    }
  }
  const handleCancel = () => {
    setSelectedAssets([])
    setPendingAssets([])
    setShowReserveModal(false)
    setCustomErrors(false)
    setPersonFieldError(false)
    setSiteFieldError(false)
    formik.resetForm()
  }

  return (
    <>
      <SplashScreen isLoadingTemplate={isLoading} />
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'Reserve' })}</PageTitle>
      <div className='card mb-5 mb-xl-10'>
        <div className='card-header border-0'>
          <div className='card-title m-0 '>
            <h3 className='fw-bolder m-0'>
              <span className='me-2'>
                <i className='la la-calendar fs-1'></i>
              </span>
              Reserve
            </h3>
          </div>
        </div>

        <div id='kt_account_profile_details' className='collapse show'>
          <div className='form'>
            <div className='card-body border-top p-lg-9 p-md-7 p-6'>
              <div>
                {' '}
                <p className='fw-bolder m-0 mb-3'>
                  Keep track of your assets within your organization and create an even more
                  detailed history of them.
                </p>
                <div className='card-footer d-flex justify-content-start border-0 p-0'>
                  <button
                    type='button'
                    className='btn btn-light-primary me-3'
                    onClick={() => handleShowReserveModal()}
                  >
                    <i className='la la-plus fs-3' />
                    Select Assets
                  </button>
                  <ReserveModal
                    show={showReserveModal}
                    setPendingAssets={setPendingAssets}
                    selectedAssets={selectedAssets}
                    handleClose={handleCloseReserveModal}
                    handleCancel={handleCancel}
                    setPage={setPage}
                    setLimit={setLimit}
                    page={page}
                    limit={limit}
                  />
                  <DuplicateReserveModal
                    show={duplicateReserveModal}
                    reservedData={reservedData}
                    handleClose={handleCloseDuplicateReserveModal}
                  />
                </div>
                {selectedAssets && selectedAssets?.length > 0 ? (
                  <>
                    <div className='separator border-2 my-10'></div>
                    <div className='d-flex justify-content-between align-items-center'>
                      <h4 className='mt-3'>Assets Pending Reserve</h4>
                      {deleteDataArr.length > 0 && (
                        <button
                          type='button'
                          className='btn btn-danger  mb-2'
                          onClick={() => {
                            deleteSelectedAssests()
                          }}
                        >
                          Delete Selected
                        </button>
                      )}
                    </div>
                    <div className='checkout-listing-table mb-5 table-responsive pb-4'>
                      <table
                        id='kt_table_users'
                        className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
                        role='table'
                      >
                        <thead>
                          <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                            <th role='columnheader' className='w-10px pe-2'>
                              <div className='me-3'>
                                <input
                                  type='checkbox'
                                  className='form-check-input custom-form-check-input'
                                  checked={selectAll}
                                  onChange={handleSelectAll}
                                />
                              </div>
                            </th>
                            <th role='columnheader' className='min-w-125px'>
                              Asset Tag ID
                            </th>
                            <th role='columnheader' className='min-w-125px'>
                              Description
                            </th>
                            <th role='columnheader' className='min-w-125px'>
                              Status
                            </th>
                            <th role='columnheader' className='min-w-125px'>
                              Assigned to
                            </th>
                            <th role='columnheader' className='min-w-125px'>
                              Site
                            </th>
                            <th role='columnheader' className='min-w-125px'>
                              Location
                            </th>
                            <th role='columnheader' className='min-w-125px'>
                              Lease to
                            </th>
                          </tr>
                        </thead>
                        <tbody className='text-gray-600 fw-bol' role='rowgroup'>
                          {selectedAssets?.length > 0 ? (
                            selectedAssets?.map((item: any) => {
                              return (
                                <tr role='row'>
                                  <td role='cell'>
                                    <div>
                                      <input
                                        type='checkbox'
                                        className='form-check-input custom-form-check-input'
                                        checked={selected.includes(item.id)}
                                        onChange={() => handleCheckboxChange(item.id)}
                                      />
                                    </div>
                                  </td>
                                  <td role='cell'>
                                    <div className='d-flex align-items-center'>
                                      <div className=' overflow-hidden me-3'>
                                        <div>{item?.assetTagId}</div>
                                      </div>
                                    </div>
                                  </td>
                                  <td role='cell'>
                                    <div className='d-flex align-items-center'>
                                      <div className=' overflow-hidden me-3'>
                                        <div>{item?.description}</div>
                                      </div>
                                    </div>
                                  </td>
                                  <td role='cell'>
                                    <div className='d-flex align-items-center'>
                                      <div className='d-flex flex-column'>
                                        {item.statusType === 'check_out'
                                          ? 'Checked Out'
                                          : item.statusType === 'lease'
                                            ? 'Leased'
                                            : item.statusType === 'dispose'
                                              ? 'Disposed'
                                              : item.statusType === 'available'
                                                ? 'Available'
                                                : item.statusType}
                                      </div>
                                    </div>
                                  </td>
                                  <td role='cell'>
                                    <div className='d-flex align-items-center'>
                                      <div className=' overflow-hidden me-3'>
                                        {item?.assetstatus?.user ? (
                                          <div>{`${item?.assetstatus?.user?.firstName || ''} ${item?.assetstatus?.user?.lastName || ''
                                            }`}</div>
                                        ) : item?.assetstatus !== null ? (
                                          <div>{`${item?.site?.name || ''} / ${item?.location?.location || ''
                                            }`}</div>
                                        ) : (
                                          <div></div>
                                        )}
                                      </div>
                                    </div>
                                  </td>
                                  <td role='cell'>
                                    <div className='d-flex align-items-center'>
                                      <div className=' overflow-hidden me-3'>
                                        <div>{item?.site?.name}</div>
                                      </div>
                                    </div>
                                  </td>
                                  <td role='cell'>
                                    <div className='d-flex align-items-center'>
                                      <div className='d-flex flex-column'>
                                        {item?.location?.location}
                                      </div>
                                    </div>
                                  </td>
                                  <td role='cell'>
                                    <div className='d-flex align-items-center'>
                                      <div className='d-flex flex-column'>
                                        {item?.statusType === 'lease' && item?.assetslease
                                          ? `${item?.assetslease?.user?.firstName} ${item?.assetslease?.user?.lastName}`
                                          : ''}
                                      </div>
                                    </div>
                                  </td>
                                </tr>
                              )
                            })
                          ) : (
                            <tr>
                              <td colSpan={12}>
                                <div className='d-flex text-center w-100 align-items-center justify-content-center no-records fw-bold rounded'>
                                  No records found
                                </div>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                    <div className='separator border-2 my-10'></div>
                    <form onSubmit={formik.handleSubmit} noValidate className='form'>
                      <div className='row mb-3'>
                        <div className='col-xl-6'>
                          <div className='row mb-6'>
                            <label className='col-lg-3 col-form-label required fw-bold fs-6'>
                              Schedule
                            </label>
                            <div className='col-lg-9 fv-row'>
                              <div className='form-group mb-3 custom-date-picker'>
                                <ReactDatePicker
                                  selectsRange={true}
                                  startDate={startDate}
                                  endDate={endDate}
                                  onChange={(update) => {
                                    setStartDate(update[0])
                                    setEndDate(update[1])
                                    formik.setFieldValue(
                                      'startDate',
                                      moment(update[0], 'DD/MM/YYYY').toDate()
                                    )
                                    formik.setFieldValue(
                                      'endDate',
                                      moment(update[1], 'DD/MM/YYYY').toDate()
                                    )
                                  }}
                                  isClearable={true}
                                  className='form-control'
                                  placeholderText={'MM/dd/yyyy - MM/dd/yyyy'}
                                />
                              </div>
                              {formik.touched.startDate && formik.errors.startDate && (
                                <div className='fv-plugins-message-container'>
                                  <div className='fv-help-block'>{formik.errors.startDate}</div>
                                </div>
                              )}
                              {formik.touched.endDate && formik.errors.endDate && (
                                <div className='fv-plugins-message-container'>
                                  <div className='fv-help-block'>{formik.errors.endDate}</div>
                                </div>
                              )}
                            </div>
                          </div>
                          <div className='row mb-6 align-items-center'>
                            <label className='col-lg-3 col-form-label fw-bold fs-6'>
                              Reserve for
                            </label>
                            <div className='col-lg-9 fv-row'>
                              <div className='d-flex'>
                                <div className='m-2 form-check-custom'>
                                  <input
                                    className='form-check-input custom-form-check-input'
                                    {...formik.getFieldProps('reserveFor')}
                                    onClick={() => {
                                      setReserveFor('person')
                                      formik.setFieldValue('locationId', undefined)
                                      formik.setFieldValue('siteId', undefined)
                                      formik.setFieldValue('customerId', undefined)
                                      setSelectedCustomerValue('')
                                      setSiteId(undefined)
                                      setCustomErrors(false)
                                      setSiteFieldError(false)
                                      formik.resetForm()
                                    }}
                                    type='checkbox'
                                    value={'person'}
                                    checked={reserveFor === 'person' ? true : false}
                                    name={'reserveFor'}
                                    id='person'
                                  />
                                  <label className='form-check-label fs-6 fw-bold' htmlFor='person'>
                                    Person
                                  </label>
                                </div>
                                <div className='m-2 form-check-custom'>
                                  <input
                                    className='form-check-input custom-form-check-input'
                                    type='checkbox'
                                    {...formik.getFieldProps('reserveFor')}
                                    onClick={() => {
                                      setReserveFor('site')
                                      formik.setFieldValue('customerId', undefined)
                                      formik.setFieldValue('personId', undefined)
                                      setSelectedPersonValue('')
                                      setSelectedCustomerValue('')
                                      setPersonFieldError(false)
                                      setCustomErrors(false)
                                      formik.resetForm()
                                    }}
                                    value={'site'}
                                    checked={reserveFor === 'site' ? true : false}
                                    name={'reserveFor'}
                                    id='location'
                                  />
                                  <label
                                    className='form-check-label fs-6 fw-bold'
                                    htmlFor='location'
                                  >
                                    Site / Location
                                  </label>
                                </div>
                                <div className='m-2 form-check-custom'>
                                  <input
                                    className='form-check-input custom-form-check-input'
                                    type='checkbox'
                                    {...formik.getFieldProps('reserveFor')}
                                    onClick={() => {
                                      setReserveFor('customer')
                                      formik.setFieldValue('locationId', undefined)
                                      formik.setFieldValue('siteId', undefined)
                                      formik.setFieldValue('personId', undefined)
                                      setSelectedPersonValue('')
                                      setSiteId(undefined)
                                      setPersonFieldError(false)
                                      setSiteFieldError(false)
                                      formik.resetForm()
                                    }}
                                    value={'customer'}
                                    checked={reserveFor === 'customer' ? true : false}
                                    name={'reserveFor'}
                                    id='customer'
                                  />
                                  <label
                                    className='form-check-label fs-6 fw-bold'
                                    htmlFor='customer'
                                  >
                                    Customer
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>
                          {reserveFor === 'site' && (
                            <div>
                              <div className='row mb-6'>
                                <label className='col-lg-3 col-form-label fw-bold fs-6 required'>
                                  <span>Site</span>
                                </label>
                                <div className='col-lg-9 fv-row'>
                                  <select
                                    className='form-select form-control form-control-solid form-select-solid  '
                                    {...formik.getFieldProps('siteId')}
                                    onChange={(e) => {
                                      formik.handleChange(e)
                                      const value = e.target.value
                                      if (value === '') {
                                        formik.setFieldValue('siteId', undefined)
                                        setSiteId(0)
                                        setSiteFieldError(true)
                                      } else {
                                        setSiteId(value)
                                        setSiteFieldError(false)
                                      }
                                    }}
                                  >
                                    <option value=''>Select Site</option>
                                    {siteData?.sites.map((item: any, idx: number) => {
                                      return (
                                        <option key={idx} value={item.id}>
                                          {item.name}
                                        </option>
                                      )
                                    })}
                                  </select>
                                  {siteFieldError && (
                                    <span style={{ color: 'red' }}>site is required</span>
                                  )}
                                  {formik.touched.siteId && formik.errors.siteId && (
                                    <div className='fv-plugins-message-container'>
                                      <div className='fv-help-block'>{formik.errors.siteId}</div>
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className='row mb-6'>
                                <label className='col-lg-3 col-form-label fw-bold fs-6'>
                                  <span>Location</span>
                                </label>
                                <div className='col-lg-9 fv-row d-flex'>
                                  <select
                                    className='form-select form-control form-control-solid form-select-solid  '
                                    {...formik.getFieldProps('locationId')}
                                    onChange={(e) => {
                                      formik.handleChange(e)
                                      const value = e.target.value
                                      if (value === '') {
                                        formik.setFieldValue('locationId', undefined)
                                      }
                                    }}
                                  >
                                    <option value=''>Select Location</option>
                                    {siteId > 0 && locationData?.locations?.map((item: any, idx: number) => {
                                      return (
                                        <option key={idx} value={item.id}>
                                          {item.location}
                                        </option>
                                      )
                                    })}
                                  </select>
                                </div>
                              </div>
                            </div>
                          )}
                          {reserveFor === 'customer' && (
                            <>
                              <div className='row mb-6'>
                                <label className='col-lg-3 col-form-label fw-bold fs-6 required'>
                                  <span>Customer</span>
                                </label>
                                <div className='col-lg-9 fv-row'>
                                  <div className='d-flex'>
                                    <Select
                                      onChange={handleSelectChangeCustomer}
                                      options={customerOptinos}
                                      isClearable={true}
                                      isSearchable={true}
                                      placeholder='Select a Customer'
                                      classNamePrefix='custom-select'
                                      className='custom-react-select w-100'
                                    />
                                    <button
                                      type='button'
                                      className='btn btn-light-primary d-flex align-items-center ms-3'
                                      onClick={() => handleShowCustomerModal()}
                                    >
                                      <i className='la la-plus fs-3' />
                                      New
                                    </button>
                                    <CustomersModal
                                      show={showCustomerModal}
                                      handleClose={handleCloseCustomerModal}
                                      setCustomerId={setCustomerId}
                                      CustomerData={[]}
                                    />
                                  </div>
                                  {customErrors && (
                                    <span style={{ color: 'red' }}>customer is required</span>
                                  )}
                                  {formik.touched.customerId && formik.errors.customerId && (
                                    <div className='fv-plugins-message-container'>
                                      <div className='fv-help-block'>
                                        {formik.errors.customerId}
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className='row mb-6'>
                                <div className='col-lg-3 col-form-label fw-bold fs-6'>
                                  <div className='form-check form-check-custom  form-check-sm d-flex align-center me-5 mb-4'>
                                    <input
                                      className='form-check-input me-2'
                                      type='checkbox'
                                      id='flexRadioLg'
                                      checked={emailEnabled}
                                      onChange={() => setEmailEnabled(!emailEnabled)}
                                    />
                                    <label
                                      className='form-label mb-0 fw-bold fs-6'
                                      htmlFor='flexRadioLg'
                                    >
                                      Send Email
                                    </label>
                                  </div>
                                </div>
                                <div className='col-lg-9 fv-row'>
                                  <div>
                                    <input
                                      type='email'
                                      disabled={emailEnabled == true ? false : true}
                                      className='form-control form-control-solid'
                                      value={
                                        selectedPersonValue
                                          ? selectedPersonValue
                                          : selectedCustomerValue
                                      }
                                      placeholder='Email'
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className='row mb-6'>
                                <div className={emailEnabled ? 'mb-10' : 'd-none'}>
                                  <div className='d-flex align-items-center custom-emails'>
                                    <div className='form-check form-check-custom  form-check-sm d-flex align-center'>
                                      <input
                                        className='form-check-input me-2'
                                        type='checkbox'
                                        id='flexRadioLg_1'
                                        checked={firstEmail}
                                        onChange={() => {
                                          setFirstEmail(!firstEmail)
                                          if (!firstEmail) {
                                            formik.setFieldValue('reminderEmail1', undefined)
                                          }
                                        }}
                                      />
                                      <label
                                        className='form-label mb-0 fw-bold fs-6'
                                        htmlFor='flexRadioLg_1'
                                      >
                                        Reminder Email #1
                                      </label>
                                    </div>
                                    <input
                                      type='number'
                                      disabled={firstEmail == true ? false : true}
                                      {...formik.getFieldProps('reminderEmail1')}
                                      className='form-control form-control-solid mx-3'
                                      placeholder='1'
                                    />
                                    <label className='form-label mb-0 fw-bold fs-6'>
                                      Days before Event
                                    </label>
                                  </div>
                                </div>
                                <div className={emailEnabled ? 'mb-10' : 'd-none'}>
                                  <div className='d-flex align-items-center custom-emails'>
                                    <div className='form-check form-check-custom  form-check-sm d-flex align-center'>
                                      <input
                                        className='form-check-input me-2'
                                        type='checkbox'
                                        id='flexRadioLg_2'
                                        checked={secondEmail}
                                        onChange={() => {
                                          setSecondEmail(!secondEmail)
                                          if (!secondEmail) {
                                            formik.setFieldValue('reminderEmail2', undefined)
                                          }
                                        }}
                                      />
                                      <label
                                        className='form-label mb-0 fw-bold fs-6'
                                        htmlFor='flexRadioLg_2'
                                      >
                                        Reminder Email #2
                                      </label>
                                    </div>
                                    <input
                                      type='number'
                                      disabled={secondEmail == true ? false : true}
                                      {...formik.getFieldProps('reminderEmail2')}
                                      className='form-control form-control-solid mx-3'
                                      placeholder='2'
                                    />
                                    <label className='form-label mb-0 fw-bold fs-6'>
                                      Days before Event
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                          {reserveFor == 'person' && (
                            <>
                              <div className='row mb-6'>
                                <label className='col-lg-3 col-form-label fw-bold fs-6 required'>
                                  <span>Person</span>
                                </label>
                                <div className='col-lg-9 fv-row'>
                                  <div className='d-flex'>
                                    <Select
                                      onChange={handleSelectChange}
                                      options={personOptinos}
                                      isClearable={true}
                                      isSearchable={true}
                                      placeholder='Select a Person'
                                      classNamePrefix='custom-select'
                                      className='custom-react-select w-100'
                                    />
                                    <button
                                      type='button'
                                      className='btn btn-light-primary d-flex align-items-center ms-3'
                                      onClick={() => handleShowPersonModal()}
                                    >
                                      <i className='la la-plus fs-3' />
                                      New
                                    </button>
                                    <PersonsModal
                                      show={showPersonModal}
                                      handleClose={handleClosePersonModal}
                                      setPersonId={setPersonId}
                                      personData={[]}
                                    />
                                  </div>
                                  {personFieldError && (
                                    <span style={{ color: 'red' }}>person is required</span>
                                  )}
                                  {formik.touched.personId && formik.errors.personId && (
                                    <div className='fv-plugins-message-container'>
                                      <div className='fv-help-block'>{formik.errors.personId}</div>
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div className='row mb-6'>
                                <div className='col-lg-3 col-form-label fw-bold fs-6'>
                                  <div className='form-check form-check-custom  form-check-sm d-flex align-center me-5 mb-4'>
                                    <input
                                      className='form-check-input me-2'
                                      type='checkbox'
                                      id='flexRadioLg_3'
                                      checked={emailEnabled}
                                      onChange={() => setEmailEnabled(!emailEnabled)}
                                    />
                                    <label
                                      className='form-label mb-0 fw-bold fs-6'
                                      htmlFor='flexRadioLg_3'
                                    >
                                      Send Email
                                    </label>
                                  </div>
                                </div>
                                <div className='col-lg-9 fv-row'>
                                  <div>
                                    <input
                                      type='email'
                                      disabled={emailEnabled == true ? false : true}
                                      className='form-control form-control-solid'
                                      value={
                                        selectedPersonValue
                                          ? selectedPersonValue
                                          : selectedCustomerValue
                                      }
                                      placeholder='Email'
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className='row mb-6'>
                                <div className={emailEnabled ? 'mb-10' : 'd-none'}>
                                  <div className='d-flex align-items-center custom-emails'>
                                    <div className='form-check form-check-custom  form-check-sm d-flex align-center'>
                                      <input
                                        className='form-check-input me-2'
                                        type='checkbox'
                                        id='flexRadioLg_4'
                                        checked={firstEmail}
                                        onChange={() => {
                                          setFirstEmail(!firstEmail)
                                          if (!firstEmail) {
                                            formik.setFieldValue('reminderEmail1', undefined)
                                          }
                                        }}
                                      />
                                      <label
                                        className='form-label mb-0 fw-bold fs-6'
                                        htmlFor='flexRadioLg_4'
                                      >
                                        Reminder Email #1
                                      </label>
                                    </div>
                                    <input
                                      type='number'
                                      disabled={firstEmail == true ? false : true}
                                      {...formik.getFieldProps('reminderEmail1')}
                                      className='form-control form-control-solid mx-3'
                                      placeholder='1'
                                    />
                                    <label className='form-label mb-0 fw-bold fs-6'>
                                      Days before Event
                                    </label>
                                  </div>
                                </div>
                                <div className={emailEnabled ? 'mb-10' : 'd-none'}>
                                  <div className='d-flex align-items-center custom-emails'>
                                    <div className='form-check form-check-custom  form-check-sm d-flex align-center'>
                                      <input
                                        className='form-check-input me-2'
                                        type='checkbox'
                                        id='flexRadioLg_5'
                                        checked={secondEmail}
                                        onChange={() => {
                                          setSecondEmail(!secondEmail)
                                          if (!secondEmail) {
                                            formik.setFieldValue('reminderEmail2', undefined)
                                          }
                                        }}
                                      />
                                      <label
                                        className='form-label mb-0 fw-bold fs-6'
                                        htmlFor='flexRadioLg_5'
                                      >
                                        Reminder Email #2
                                      </label>
                                    </div>
                                    <input
                                      type='number'
                                      disabled={secondEmail == true ? false : true}
                                      {...formik.getFieldProps('reminderEmail2')}
                                      className='form-control form-control-solid mx-3'
                                      placeholder='2'
                                    />
                                    <label className='form-label mb-0 fw-bold fs-6'>
                                      Days before Event
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                        <div className='col-xl-6'>
                          <div className='row mb-10'>
                            <label className='col-lg-3 col-form-label fw-bold fs-6'>Notes</label>
                            <div className='col-lg-9 fv-row'>
                              <textarea
                                className='form-control'
                                {...formik.getFieldProps('notes')}
                                cols={20}
                                rows={4}
                              ></textarea>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className=' d-flex align-items-center justify-content-end'>
                        <button
                          onClick={handleCancel}
                          className='btn btn-light-primary   main-btn-style me-2'
                        >
                          Cancel
                        </button>
                        {
                          reserveFor === "person" &&
                          <button
                            type='submit'
                            className='btn btn-primary'
                            disabled={ selectedPersonValue === '' || selectedPersonValue === null || personFieldError || !formik.isValid}
                          >
                            {!isAdding && 'Reserve'}
                            {isAdding && (
                              <span className='indicator-progress'>
                                Please wait...{' '}
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                              </span>
                            )}
                          </button>
                        }
                        {
                          reserveFor === "site" &&
                          <button
                            type='submit'
                            className='btn btn-primary'
                            disabled={siteId === undefined || siteId === 0 || siteFieldError || !formik.isValid}
                          >
                            {!isAdding && 'Reserve'}
                            {isAdding && (
                              <span className='indicator-progress'>
                                Please wait...{' '}
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                              </span>
                            )}
                          </button>
                        } {
                          reserveFor === "customer" &&
                          <button
                            type='submit'
                            className='btn btn-primary'
                            disabled={selectedCustomerValue === '' || selectedCustomerValue === null || customErrors || !formik.isValid}
                          >
                            {!isAdding && 'Reserve'}
                            {isAdding && (
                              <span className='indicator-progress'>
                                Please wait...{' '}
                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                              </span>
                            )}
                          </button>
                        }

                      </div>
                    </form>
                  </>
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
        </div>
        <Loader loading={loading} />
      </div>
    </>
  )
}

export default Reserve