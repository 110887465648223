interface AssetsReportHeaderListing {
	title: String
}

const CheckoutReportsHeader = (props: AssetsReportHeaderListing) => {
	return (
		<div
			className='d-flex justify-content-between mb-3 flex-wrap'
			data-kt-user-table-toolbar='base'
		>
			<div className='d-flex align-items-center mb-xl-0 mb-4'>
				<h3 className='card-title align-items-start flex-column mb-xl-0 mb-3 me-3'>
					<span className='me-2 align-middle'>
						<i className='la la-list fs-1' aria-hidden='true'></i>
					</span>
					<span className='card-label fw-bold fs-3 mb-1'>{props.title}</span>
				</h3>
			</div>
			<div className='d-flex flex-wrap'>
				<button
					className='btn btn-light-primary main-btn-style m-1'>
					<i className='far fa-file-excel fs-5'></i> Export
					<span className='d-xl-inline d-none'> to Excel</span>
				</button>
				<button className='btn btn-light-primary main-btn-style m-1 d-none'>
					<i className='la la-print fs-3'></i> Print
				</button>
			</div>
		</div>
	)
}
export { CheckoutReportsHeader }