import React, { useEffect, useState } from 'react'
import { getAuth } from '../../../../../Auth'
import { CustomFieldData, Field, WarrantyFields } from '../../Core/_models'
import { addWarrantiesFields, getWarrantiesFields } from '../../Core/_requests'
import ConfirmationPopup from '../../../../../../../_metronic/helpers/components/ConfirmationPopup'
import { toast } from 'react-toastify'
import { Link } from 'react-router-dom'

const WarrantiesList = () => {
	const auth = getAuth()
	const [standardFields, setStandardFields] = useState<WarrantyFields>({
		hasLength: true,
		isLengthRequired: true,
		hasExpirationDate: true,
		isExpirationDateRequired: true,
		hasNotes: true,
		isNotesRequired: true,
		customFields: '',
	})
	const [editStandardFields, setEditStandardFields] = useState<WarrantyFields>({
		hasLength: true,
		isLengthRequired: true,
		hasExpirationDate: true,
		isExpirationDateRequired: true,
		hasNotes: true,
		isNotesRequired: true,
		customFields: '',
	})
	const [customFieldData, setCustomFieldData] = useState<CustomFieldData>({
		customFieldName: '',
		customFieldDataType: '',
		isRequired: false,
		listOptions: [],
	})
	const [customFieldDataArray, setCustomFieldDataArray] = useState<CustomFieldData[] | null>([])
	const [loading, setLoading] = useState(false)
	const [editIdx, setEditIdx] = useState<Number | null>()
	const [deleteIdx, setDeleteIdx] = useState<Number | null>()
	const [deleteModal, setDeleteModal] = useState<boolean>(false)
	const [flag, setFlag] = useState<Boolean>(false)
	const [fields, setFields] = useState<{ id: number; value: string }[]>([])
	const [fieldValues, setFieldValues] = useState<string[]>([])
	const [error, setError] = useState<{ fieldName: string; dataType: string; listOption: string }>({
		fieldName: '',
		dataType: '',
		listOption: '',
	})

	useEffect(() => {
		getWarrantiesFields(auth?.token).then((res) => {
			setStandardFields(res)
			setEditStandardFields(res)
			Array.isArray(res.customFields) && setCustomFieldDataArray(res?.customFields)
		})
	}, [flag])

	const selectAllCheckboxes = () => {
		standardFields.hasLength && standardFields.hasNotes
			? setStandardFields({ ...standardFields, hasLength: false, hasNotes: false })
			: setStandardFields({ ...standardFields, hasLength: true, hasNotes: true })
	}

	const handleCustomFieldData = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
		const { name, value } = e.target
		if (name === 'optional') {
			customFieldData.isRequired = false
			setCustomFieldData({ ...customFieldData })
		} else if (name === 'yes') {
			customFieldData.isRequired = true
			setCustomFieldData({ ...customFieldData })
		} else {
			setCustomFieldData({ ...customFieldData, [name]: value })
		}
	}

	const submitCustomField = () => {
		if (
			customFieldData?.customFieldName &&
			customFieldData?.customFieldDataType &&
			(customFieldData?.customFieldDataType === 'Checkbox list' ||
				customFieldData?.customFieldDataType === 'Radio button list' ||
				customFieldData?.customFieldDataType === 'Dropdown list'
				? fields[0]?.value
				: true)
		) {
			if (customFieldDataArray && typeof editIdx === 'number') {
				customFieldDataArray[editIdx] = customFieldData
			} else {
				customFieldDataArray?.push(customFieldData)
			}
			setCustomFieldDataArray([...(customFieldDataArray ?? [])])
			setEditStandardFields({
				...editStandardFields,
				customFields: JSON.stringify(customFieldDataArray),
			})
			const updatedFields = {
				...editStandardFields,
				customFields: JSON.stringify(customFieldDataArray),
			}
			addWarrantiesFields(updatedFields, auth?.token).then(() => {
				setFlag(!flag)
				toast.success('Custom field updated successfully.')
			})
			setCustomFieldData({
				customFieldName: '',
				customFieldDataType: '',
				isRequired: false,
				listOptions: [],
			})
			setError({
				fieldName: '',
				dataType: '',
				listOption: '',
			})
		} else {
			setError({
				...error,
				fieldName: !customFieldData?.customFieldName ? 'Label is required.' : '',
				dataType: !customFieldData?.customFieldDataType ? 'Data Type is required.' : '',
				listOption: !fields[0].value ? 'Option is required.' : '',
			})
		}
	}

	const confirm = () => {
		if (typeof deleteIdx === 'number') {
			customFieldDataArray?.splice(deleteIdx, 1)
		}
		setEditStandardFields({
			...editStandardFields,
			customFields: JSON.stringify(customFieldDataArray),
		})
		const updatedFields = {
			...editStandardFields,
			customFields: JSON.stringify(customFieldDataArray),
		}
		addWarrantiesFields(updatedFields, auth?.token).then(() => {
			setFlag(!flag)
			toast.success('Custom field deleted successfully.')
		})
		setDeleteModal(false)
	}

	const submitCustomersFields = (event: React.MouseEvent<HTMLButtonElement>) => {
		event?.preventDefault()
		const updatedFields = {
			...standardFields,
			customFields: JSON.stringify(standardFields.customFields),
		}
		setLoading(true)
		addWarrantiesFields(updatedFields, auth?.token)
			.then(() => {
				setFlag(!flag)
				toast.success('Database updated successfully.')
				setLoading(false)
			})
			.catch((error) => {
				setLoading(false)
			})
	}

	const addCustomField = () => {
		setCustomFieldData({
			customFieldName: '',
			customFieldDataType: '',
			isRequired: false,
			listOptions: [],
		})
		setEditIdx(null)
		setError({
			fieldName: '',
			dataType: '',
			listOption: '',
		})
		setFields([{ id: Date.now(), value: '' }])
	}

	const addNewField = () => {
		const newField: Field = { id: Date.now(), value: '' }
		setFields([...fields, newField])
	}

	const removeField = (index: number) => {
		const newFields = [...fields]
		newFields.splice(index, 1)
		setFields(newFields)
	}

	const handleChange = (index: number, value: string) => {
		const newFields = [...fields]
		newFields[index] = { ...newFields[index], value }
		setFields(newFields)
		const newFieldValues = [...fieldValues]
		newFieldValues[index] = value
		setFieldValues(newFieldValues)
		setCustomFieldData({ ...customFieldData, listOptions: newFields })
	}

	const cancel = () => {
		setDeleteModal(false)
	}

	return (
		<div className="card">
			<ConfirmationPopup
				deleteModal={deleteModal}
				cancel={cancel}
				confirm={confirm}
			/>
			<div id="database_ajax_wrapper">
				<form action="/assettiger/database" className="form-horizontal" data-ajax="true" data-ajax-method="Post" data-ajax-mode="before" data-ajax-update="#database_ajax_wrapper" id="assetDatabaseForm" method="post" role="form" ><input name="__RequestVerificationToken" type="hidden" value="bi5mAQGArgtf83vcFjyPEGK8rKsZ-9D3G-eRhsWQ-rTaCYjXK6gswp10gSiUZu3dXYXV2Zm9_cHp6yxRZbnLhFj5ZuJQOiJIAWnGosU6DIm2Zzc3Qcl40A_U_bv_VIr8fFJjrA2" />            <div className="card-body">
					<div className="form_section">
						<div className="form-title d-flex">
							<span className='me-2'>
								<svg
									version="1.1"
									xmlns="http://www.w3.org/2000/svg"
									width="24"
									height="24"
									viewBox="0 0 32 32"
									fill="#c30"
								>
									<path fill="#9b9b9b" d="M30.16 23.8l-2.667-2.667 2.667-2.667-1.227-1.133-2.667 2.667-2.667-2.667-1.187 1.173 2.667 2.667-2.667 2.667 1.173 1.173 2.667-2.667 2.667 2.667z"></path>
									<path fill="#9b9b9b" d="M29.613 6.973l-4.507 4.613-2.107-2-1.48 1.493 3.587 3.48 6-6.107z"></path>
									<path fill="#9b9b9b" d="M0.893 6.973v7.693h19.4v-7.693zM18.307 12.667h-15.427v-3.68h15.427z"></path>
									<path fill="#9b9b9b" d="M0.893 17.333v7.707h19.4v-7.707zM18.307 23.027h-15.427v-3.693h15.427z"></path>
								</svg>
							</span>
							<h4 className='mt-1'>Warranty Standard Fields</h4>
						</div>
						<div className="mb-sm-6 mb-4">
							<p>Select the fields you would like to use for the warranty table.</p>
							<div className="table-responsive">
								<table className="table table-bordered mb-0" aria-label="Assets Warranty">
									<thead className="thead-light  light-yellow-table">
										<tr>
											<th className='w-2'>
												<label className="checkbox has_nolabel m-auto" htmlFor="field_all">
													<input className='custom-form-check-input form-check-input sig_input'
														type="checkbox"
														checked={standardFields.hasLength && standardFields.hasNotes}
														onChange={selectAllCheckboxes}
														id="field_all" />
													<span></span>
													<span className="sr-only"></span>
												</label>
											</th>
											<th className='w-20'>Field name </th>
											<th className='w-20'>Data Required </th>
											<th>Description </th>
											<th className='w-32'>Example </th>
										</tr>
									</thead>
									<tbody>
										<tr>
											<td>
												<label className="checkbox has_nolabel m-auto" htmlFor="hasLengths">
													<input
														checked={standardFields?.hasLength}
														onChange={() => setStandardFields({ ...standardFields, hasLength: !standardFields.hasLength })}
														className="fieldCheck custom-form-check-input form-check-input sig_input"
														data-val="true"
														data-val-required="Length is required."
														id="hasLengths"
														name="hasLengths"
														type="checkbox"
														value="true"
													/>
													<input name="hasLengths" type="hidden" value="false" />
													<span></span>
													<span className="sr-only">Field</span>
												</label>

											</td>
											<td className="fw-bolder text-nowrap">Length</td>
											<td>
												{standardFields?.hasLength && <div className="radio-inline standard_radio_can_hide hasLengths_radio d-flex">
													<label className="radio mb-0 p_16" htmlFor="isLengthsRequired_True">
														<input
															id="isLengthsRequired_True"
															checked={standardFields.isLengthRequired}
															onChange={() => setStandardFields({ ...standardFields, isLengthRequired: true })}
															name="isLengthsRequired"
															type="checkbox"
															className='m_5 custom-form-check-input form-check-input sig_input'
															value="True"
														/>
														<span></span>
														Yes
													</label>
													<label className="radio mb-0" htmlFor="isLengthsRequired_False">
														<input
															id="isLengthsRequired_False"
															checked={!standardFields.isLengthRequired}
															onChange={() => setStandardFields({ ...standardFields, isLengthRequired: false })}
															name="isLengthsRequired"
															type="checkbox"
															value="False"
															className='m_5 custom-form-check-input form-check-input sig_input'
														/>
														<span></span>
														Optional
													</label>

												</div>}
											</td>
											<td>Length of the warranty (in months).</td>
											<td>24</td>
										</tr>
										<tr>
											<td>
												<label className="checkbox has_nolabel m-auto" htmlFor="fieldDisabledCheck">
													<input
														id="fieldDisabledCheck"
														checked={true}
														className="fieldDisabledCheck custom-form-check-input form-check-input sig_input"
														data-val="true"
														data-val-required="Expiration Date is required."
														disabled={true}
														type="checkbox"
														value="true"
													/>
													<span></span>
													<span className="sr-only">Field</span>
												</label>

											</td>
											<td className="fw-bolder text-nowrap">Expiration Date <span className="required"></span></td>
											<td>
												<div className="radio-inline">
													<label className="radio mb-0" htmlFor="fieldCheck_yes">
														<input
															type="checkbox"
															className='m_5 fieldCheck custom-form-check-input form-check-input sig_input'
															id="fieldCheck_yes"
															checked={true}
														/>
														<span></span>
														Yes
													</label>

												</div>
											</td>
											<td>Date when warranty expires.</td>
											<td>12/12/2022</td>
										</tr>
										<tr>
											<td>
												<label className="checkbox has_nolabel m-auto" htmlFor="hasNotes">
													<input
														checked={standardFields?.hasNotes}
														onChange={() => setStandardFields({ ...standardFields, hasNotes: !standardFields.hasNotes })}
														className="fieldCheck custom-form-check-input form-check-input sig_input"
														data-val="true"
														data-val-required="Notes is required."
														id="hasNotes"
														name="hasNotes"
														type="checkbox"
														value="true"
													/>
													<input name="hasNotes" type="hidden" value="false" />
													<span></span>
													<span className="sr-only">Field</span>
												</label>

											</td>
											<td className="fw-bolder text-nowrap">Notes</td>
											<td>
												{standardFields?.hasNotes && <div className="radio-inline standard_radio_can_hide hasNotes_radio d-flex">
													<label className="radio mb-0 p_16" htmlFor="isNotesRequired_True">
														<input
															id="isNotesRequired_True"
															checked={standardFields.isNotesRequired}
															onChange={() => setStandardFields({ ...standardFields, isNotesRequired: true })}
															name="isNotesRequired"
															type="checkbox"
															value="True"
															className='m_5 custom-form-check-input form-check-input sig_input'
														/>
														<span></span>
														Yes
													</label>

													<label className="radio mb-0" htmlFor="isNotesRequired_False">
														<input
															id="isNotesRequired_False"
															checked={!standardFields.isNotesRequired}
															onChange={() => setStandardFields({ ...standardFields, isNotesRequired: false })}
															name="isNotesRequired"
															type="checkbox"
															value="False"
															className='m_5 custom-form-check-input form-check-input sig_input'
														/>
														<span></span>
														Optional
													</label>

												</div>}
											</td>
											<td>Text area for notes.</td>
											<td>Renew warranty if equipment in good condition.</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>
					</div>
					<div className="form_section" id="custom_ajax_wrapper">
						<div className="form-title d-flex">
							<span className='me-2'>
								<svg
									version="1.1"
									xmlns="http://www.w3.org/2000/svg"
									width="24"
									height="24"
									viewBox="0 0 32 32"
									fill="#c30"
								>
									<path fill="#9b9b9b" d="M30.16 23.8l-2.667-2.667 2.667-2.667-1.227-1.133-2.667 2.667-2.667-2.667-1.187 1.173 2.667 2.667-2.667 2.667 1.173 1.173 2.667-2.667 2.667 2.667z"></path>
									<path fill="#9b9b9b" d="M29.613 6.973l-4.507 4.613-2.107-2-1.48 1.493 3.587 3.48 6-6.107z"></path>
									<path fill="#9b9b9b" d="M0.893 6.973v7.693h19.4v-7.693zM18.307 12.667h-15.427v-3.68h15.427z"></path>
									<path fill="#9b9b9b" d="M0.893 17.333v7.707h19.4v-7.707zM18.307 23.027h-15.427v-3.693h15.427z"></path>
								</svg>
							</span>
							<h4 className='mt-1'>Warranty Custom Fields</h4>
						</div>
						<div className="px-md-6 px-5 mb-sm-8 mb-6">
							<p>Add custom fields to join the standard fields that we provided.</p>
							<div className="form_section">
								<div className="mb-4"> <Link data-bs-toggle="modal" onClick={addCustomField} to="#addEditFieldBox" className="btn btn-light-primary"> <i className="fa fa-plus" aria-hidden="true"></i> Add Custom Field </Link> </div>
								<div className="table-responsive pb-4 custom-table">
									<table className="table table-bordered table-sm vertical_middle mb-0" id="custom_fields_table" aria-label="Asset Custom Fields">
										<thead className="thead-light">
											<tr>
												<th> Field Name </th>
												<th> Data Type </th>
												<th> Required </th>
												<th className="text-center w-80"> Edit </th>
												<th className="text-center w-95"> Delete </th>
											</tr>
										</thead>
										<tbody>
											{Array.isArray(standardFields.customFields) &&
												standardFields.customFields.map((item, idx) => (
													<tr key={idx}>
														<td className='fw-bolder'>{item?.customFieldName}</td>
														<td>{item?.customFieldDataType}</td>
														<td>{item?.isRequired == true ? 'Yes' : 'Optional'}</td>
														<td className='text-nowrap'>
															<Link
																data-bs-toggle='modal'
																onClick={() => {
																	setEditIdx(idx)
																	setCustomFieldData(
																		customFieldDataArray?.length && customFieldDataArray[idx]
																			? customFieldDataArray[idx]
																			: {
																				customFieldName: '',
																				customFieldDataType: '',
																				isRequired: false,
																				listOptions: [],
																			}
																	)
																	setFields(
																		customFieldDataArray?.length && customFieldDataArray[idx]
																			? customFieldDataArray[idx].listOptions
																			: []
																	)
																}}
																to='#addEditFieldBox'
																className='btn btn-light-primary main-btn-style btn-sm'
															>
																<i className='fa fa-pencil-alt fs-7' aria-hidden='true'></i>Edit
															</Link>
														</td>
														<td className='text-nowrap'>
															<span
																className='btn btn-light-primary main-btn-style btn-sm'
																onClick={() => { setDeleteIdx(idx); setDeleteModal(true); }}
															>
																<i className='la la-trash fs-4' aria-hidden='true'></i>Delete
															</span>
														</td>
													</tr>
												))}
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				</div>
					<div className="card-footer right notForWizard">
						<div className="row">
							<div className="col-md-12 text-end">
								<Link to="/apps/setup/database/warranty" className="btn btn-light-primary   main-btn-style me-2">Cancel</Link>
								<button type="submit" onClick={(e) => submitCustomersFields(e)} className="btn btn-primary main-btn-style">{loading ? (
									<span className='indicator-progress d-block'>
										Please wait...{' '}
										<span className='spinner-border spinner-border-sm align-middle'></span>
									</span>
								) : "Submit"}</button>
							</div>
						</div>
					</div>
					<input id="hasAssetDescription" name="hasAssetDescription" type="hidden" value="True" /></form><form action="/assettiger/database/AddField?Length=8" className="form-horizontal" data-ajax="true" data-ajax-begin="javascript: funCustomFormSubmitBegin();" data-ajax-method="Post" data-ajax-mode="replace" data-ajax-success="javascript: funCustomFormSubmited();" data-ajax-update="#custom_ajax_wrapper" id="formCustomField" method="post" role="form" ><input name="__RequestVerificationToken" type="hidden" value="P6Drb77htOawFFsvekNgHfWBWhq60oDpCoubbGMBsqUDjl2iAaD8QBR9--EiX9PwaCFSBKs_1KJeo_cU8xCFJxl-CxmIsOWHGs6UMfZP8TvVLl9DSBSzMsLIvq24QW2RmsX0tQ2" />
					<div className="modal fade" id="addEditFieldBox" tabIndex={-1} role="dialog" aria-hidden="true">
						<div className="modal-dialog modal-dialog-scrollable mw-md-600px">
							<div className="modal-content">
								<div className="modal-header">
									<h4 className="modal-title">Add Custom field</h4>
									<button type="button" className="btn-close" data-bs-dismiss="modal" aria-hidden="true" aria-label="close"></button>
								</div>
								<div className="modal-body form">
									<div className="custom-field-details">
										<div className="form-group row">
											<label className="col-sm-4 col-form-label text-sm-end required" htmlFor="AddEditCustomFieldModel_Label">Custom Field Label</label>
											<div className="col-sm-7">
												<input className="form-control input-medium" onChange={handleCustomFieldData} value={customFieldData?.customFieldName} data-val="true" data-val-length="Maximum length is 100." data-val-length-max="100" data-val-required="Label is required." id="AddEditCustomFieldModel_Label" maxLength={100} name='customFieldName' type="text" />
												<span className="field-validation-valid" data-valmsg-for="AddEditCustomFieldModel.Label" data-valmsg-replace="true"></span>
												<div className='text-danger mt-1'>{error.fieldName}</div>
											</div>
										</div>
										<div className="form-group row mt-5">
											<label className="col-sm-4 col-form-label text-sm-end" htmlFor="AddEditCustomFieldModel_FieldTypeId">Data Type<span className="required"></span></label>
											<div className="col-sm-7">
												<select className="form-select input-medium valid" value={customFieldData.customFieldDataType} onChange={handleCustomFieldData} data-val="true" data-val-number="The field Data Type must be a number." data-val-required="Data Type is required." id="AddEditCustomFieldModel_FieldTypeId" name='customFieldDataType' >
													<option>Select Data Type</option>
													<option value="Checkbox list" >Checkbox list</option>
													<option value="Currency">Currency</option>
													<option value="Date">Date</option>
													<option value="Date and Time">Date and Time</option>
													<option value="Dropdown list" >Dropdown list</option>
													<option value="Email">Email</option>
													<option value="GPS Coordinates">GPS Coordinates</option>
													<option value="Memo">Memo</option>
													<option value="Numeric">Numeric</option>
													<option value="Numeric Auto Increment">Numeric Auto Increment</option>
													<option value="Radio button list" >Radio button list</option>
													<option value="Scanner">Scanner</option>
													<option value="Text">Text</option>
													<option value="URL">URL</option>
												</select>
												<span className="field-validation-valid" data-valmsg-for="AddEditCustomFieldModel.FieldTypeId" data-valmsg-replace="true"></span>
												<div className='text-danger mt-1'>{error.dataType}</div>
											</div>
										</div>
										<div className="form-group row align-items-center mt-5" id="ValidateType">
											<label className="col-sm-4 col-form-label text-sm-end">Data Required</label>
											<div className="col-sm-7">
												<div className="radio-inline">
													<label className="radio mb-0 p_16" htmlFor="isValidate_True">
														<input
															id="isValidate_True"
															checked={customFieldData?.isRequired}
															onChange={handleCustomFieldData}
															name="yes"
															type="checkbox"
															className="form-check-input custom-form-check-input m_3"
														/>
														<span></span>
														Yes
													</label>

													<label className="radio mb-0" htmlFor="isValidate_False">
														<input
															id="isValidate_False"
															checked={!customFieldData?.isRequired}
															onChange={handleCustomFieldData}
															type="checkbox"
															name="optional"
															className="form-check-input custom-form-check-input m_3"
														/>
														<span></span>
														Optional
													</label>

												</div>
											</div>
										</div>
										<div className="form-group row DataInput d-none" id="StartingValue">
											<label className="col-sm-4 col-form-label text-sm-end" htmlFor="AddEditCustomFieldModel_Counter">Starting Value</label>
											<div className="col-sm-7">
												<input className="form-control i" data-val="true" data-val-number="The field Starting value must be a number." id="AddEditCustomFieldModel_Counter" maxLength={10} min="0" name="AddEditCustomFieldModel.Counter" type="text" />
											</div>
										</div>
										{(customFieldData?.customFieldDataType === 'Checkbox list' ||
											customFieldData?.customFieldDataType === 'Radio button list' ||
											customFieldData?.customFieldDataType === 'Dropdown list') && (
												<div className='form-group row DataInput customFieldOption'>
													<label className='col-sm-4 col-form-label text-sm-end' htmlFor='AddEditCustomFieldModel_Option' > List Options </label>
													<div className='col-sm-8 input_fields_wrap'>
														<div>
															{fields.map((field, index) => (
																<div key={field.id} className='form-inline flex-nowrap align-items-baseline mb-2'>
																	<div >
																		<input
																			className='form-control input-medium border border-black me-2'
																			type='text'
																			value={field.value}
																			onChange={(e) => handleChange(index, e.target.value)}
																		/>
																		{index == 0 && <div className='text-danger mt-1'>{error.listOption}</div>}
																	</div>
																	{index !== 0 && <div>
																		<Link to={'/dummy-link'} className='btn btn-light-primary remove_field' onClick={() => removeField(index)}>
																			Remove
																		</Link>
																	</div>}
																	{index == 0 && <div >
																		<Link
																			onClick={addNewField}
																			to='javascript:void(0)'
																			className='btn btn-light-primary add_field_button'
																		>
																			Add
																		</Link>
																	</div>}
																</div>
															))}
														</div>
														<span
															className='field-validation-valid'
															data-valmsg-for='AddEditCustomFieldModel.Options'
															data-valmsg-replace='true'
														></span>
														<span
															className='field-validation-valid'
															data-valmsg-for='AddEditCustomFieldModel.Option'
															data-valmsg-replace='true'
														></span>
													</div>
												</div>
											)}

									</div>
								</div>
								<div className="modal-footer">
									<button type="button" className="btn btn-light-primary   main-btn-style me-2" data-bs-dismiss="modal">Cancel</button>
									<button type="button"
										className="btn btn-primary"
										{...(customFieldData?.customFieldName && customFieldData?.customFieldDataType && ((customFieldData?.customFieldDataType === 'Checkbox list' || customFieldData?.customFieldDataType === 'Radio button list' || customFieldData?.customFieldDataType === 'Dropdown list') ? fields[0]?.value : true)
											? { 'data-bs-dismiss': 'modal' }
											: {})}
										value='Save'
										onClick={submitCustomField}
									>
										Save
									</button>
								</div>
							</div>
						</div>
					</div>
					<input data-val="true" data-val-length="Maximum length is 4000." data-val-length-max="4000" id="AddEditCustomFieldModel_Options" name="AddEditCustomFieldModel.Options" type="hidden" /><input data-val="true" data-val-required="Is this field visible to assets of selective 'Categories'?" id="AddEditCustomFieldModel_hasCategory" name="AddEditCustomFieldModel.hasCategory" type="hidden" value="False" /><input data-val="true" data-val-required="Is this field need to be required?" id="AddEditCustomFieldModel_isValidate" name="AddEditCustomFieldModel.isValidate" type="hidden" value="True" /><input data-val="true" data-val-number="The field CustomFieldId must be a number." data-val-required="The CustomFieldId field is required." id="AddEditCustomFieldModel_CustomFieldId" name="AddEditCustomFieldModel.CustomFieldId" type="hidden" value="0" /></form>        <div className="modal fade" id="confirmDeleteCustomField" tabIndex={-1} role="basic" aria-hidden="true">
				</div>
			</div>
		</div>
	)
}

export default WarrantiesList
