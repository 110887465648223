import React, { useState, useEffect } from 'react'
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes'
import ReactDatePicker from 'react-datepicker'
import { useGetAllSitesQuery } from '../../../../Services/Sites'
import { useAuth } from '../../../../Auth'
import { useGetAllCategoriesQuery } from '../../../../Services/Categories'
import { useGetAllLocationsQuery } from '../../../../Services/Locations'
import { useGetAllDepartmentsQuery } from '../../../../Services/Departments'
import moment from 'moment'

const searchFieldsOptions = [
  { label: 'All', value: '*' },
  { value: 'assetTagId', label: 'Asset Tag ID' },
  { value: 'brand', label: 'Brand' },
  { value: 'description', label: 'Description' },
  { value: 'model', label: 'Model' },
  { value: 'purchasedFrom', label: 'Purchased From' },
  { value: 'serialNo', label: 'Serial No' },
]

const statusTypesOptions = [
  { label: 'All', value: '*' },
  { value: 'available', label: 'Available' },
  { value: 'check_out', label: 'Checked out' },
  { value: 'dispose', label: 'Disposed' },
  { value: 'lease', label: 'Leased' },
]

const AssetsSearch = (props: any) => {
  const { currentUser } = useAuth()
  const personUser = currentUser?.userType === '2'
  const customerUser = currentUser?.userType === '4'
  const [savedCriteria, setSavedCriteria] = useState(
    JSON.parse(sessionStorage.getItem('criteria') || '{}')
  )
  const [savedSearchAssets, setSavedSearchAssets] = useState(
    JSON.parse(sessionStorage.getItem('searchAssets') || '{}')
  )

  //Search Filter
  const [savedSearchFields, setSavedSearchFields] = useState(savedCriteria?.searchField || [])
  const [savedStatusFields, setSavedStatusFields] = useState(savedCriteria?.status || [])
  const [searchFields, setSearchFields] = useState<any[]>(savedCriteria?.searchField || [])
  const [statusTypes, setStatusTypes] = useState<any[]>(savedCriteria?.status || [])

  //states
  const [keyword, setKeyword] = useState(savedCriteria?.keyword || '')
  const [siteId, setSiteId] = useState(savedSearchAssets?.siteId || '')
  const [locationId, setLocationId] = useState(savedSearchAssets?.locationId || '')
  const [categoryId, setCategoryId] = useState(savedSearchAssets?.categoryId || '')
  const [departmentId, setDepartmentId] = useState(savedSearchAssets?.departmentId || '')
  const { data: siteData } = useGetAllSitesQuery({ userId: currentUser?.id })
  const { data: categoryData } = useGetAllCategoriesQuery({ userId: currentUser?.id, page: 1 })
  const { data: locationData } = useGetAllLocationsQuery(currentUser?.id)
  const { data: departmentData } = useGetAllDepartmentsQuery({ userId: currentUser?.id })
  const [dateRange, setDateRange] = useState<any[]>([null, null])
  const [dateRangeBy, setDateRangeBy] = useState<string>(
    savedSearchAssets?.dateRangeBy === 'createdAt' ? 'dateCreated' : 'purchasedDate'
  )
  const [startDate, endDate] = dateRange
  const [quickDate, setQuickDate] = useState<any>(0)
  const [keywordProps, setKeywordProps] = useState<any>()
  const [searchAssetProps, setSearchAssetProps] = useState<any>()
  const [statusProps, setStatusProps] = useState<any>()
  const [sitesProps, setSitesProps] = useState<any>()
  const [locationProps, setLocationProps] = useState<any>()
  const [departmentProps, setDepartmentProps] = useState<any>()
  const [categoryProps, setCategoryProps] = useState<any>()

  useEffect(() => {
    const updatedCriteria = JSON.parse(sessionStorage.getItem('criteria') || '{}')
    const updatedSavedSearchAssets = JSON.parse(sessionStorage.getItem('searchAssets') || '{}')
    setSavedCriteria(updatedCriteria)
    setSavedSearchAssets(updatedSavedSearchAssets)
    setSavedSearchFields(updatedCriteria?.searchField)
    setSavedStatusFields(updatedCriteria?.status)
    setKeyword(updatedCriteria?.keyword || '')
    setSiteId(updatedSavedSearchAssets?.siteId || '')
    setLocationId(updatedSavedSearchAssets?.locationId || '')
    setCategoryId(updatedSavedSearchAssets?.categoryId || '')
    setDepartmentId(updatedSavedSearchAssets?.departmentId || '')
    setDateRangeBy(
      updatedSavedSearchAssets?.dateRangeBy === 'createdAt' ? 'dateCreated' : 'purchasedDate'
    )
  }, [props.resetAssetsSearchStates])

  const getFilteredOptions = () => {
    if (personUser || customerUser) {
      return searchFieldsOptions.filter((option) => option.value !== 'purchasedFrom')
    }
    return searchFieldsOptions
  }
  const getFilteredStatusOptions = () => {
    if (personUser) {
      return statusTypesOptions.filter(
        (option) => option.value !== 'dispose' && option.value !== 'lease'
      )
    }
    if (customerUser) {
      return statusTypesOptions.filter(
        (option) => option.value !== 'dispose' && option.value !== 'check_out'
      )
    }
    return statusTypesOptions
  }
  useEffect(() => {
    const preSelectedOptions = getFilteredOptions().filter((option) =>
      savedSearchFields?.includes(option.label)
    )
    const preSelectedStatus = getFilteredStatusOptions().filter((option) =>
      savedStatusFields?.includes(option.label)
    )
    setSearchFields(preSelectedOptions)
    setStatusTypes(preSelectedStatus)
  }, [savedSearchFields, savedStatusFields])

  function getMatchingValue(savedCriteria) {
    const dateCreated = savedCriteria?.dateCreated || savedCriteria?.purchasedDate
    if (!dateCreated) {
      return null
    }
    const optionsMapping = {
      'Current Month': 'CurrentMonth',
      'Previous Month': 'PreviousMonth',
      'Current Quarter': 'CurrentQuarter',
      'Previous Quarter': 'PreviousQuarter',
      'Current Year': '2024',
      'Year 2023': '2023',
      'Year 2022': '2022',
      'Year 2021': '2021',
      'Year 2020': '2020',
      'Year 2019': '2019',
    }
    return optionsMapping[dateCreated] || null
  }

  useEffect(() => {
    const quickDateValue = getMatchingValue(savedCriteria)
    setQuickDate(quickDateValue || 0)

    if (!savedCriteria.startDate || !savedCriteria.endDate) {
      setDateRange([null, null])
    }

    if (savedCriteria.startDate && savedCriteria.endDate) {
      setDateRange([new Date(savedCriteria.startDate), new Date(savedCriteria.endDate)])
    }
  }, [props.resetAssetsSearchStates, savedCriteria])

  const handleSearch = () => {
    if (keyword.trim().length > 0 && searchFields.length > 0) {
      props.handleSearchAssets(keywordProps)
      props.handleSearchAssets({ name: searchAssetProps.name, value: searchFields })
    } else {
      const savedCriteria = JSON.parse(sessionStorage.getItem('criteria') || '{}')
      const searchAsset = JSON.parse(sessionStorage.getItem('searchAssets') || '{}')
      const updatedCriteria = { ...savedCriteria }
      const updatedSearchAssets = { ...searchAsset }
      delete updatedCriteria['keyword']
      delete updatedSearchAssets['keyword']
      delete updatedCriteria['searchField']
      delete updatedSearchAssets['searchField']
      props.setCriteria(updatedCriteria)
      sessionStorage.setItem('criteria', JSON.stringify(updatedCriteria))
      props.setSearchAssets(updatedSearchAssets)
      sessionStorage.setItem('searchAssets', JSON.stringify(updatedSearchAssets))
    }

    if (siteId) {
      props.handleSearchAssets(sitesProps)
    }

    if (locationId) {
      props.handleSearchAssets(locationProps)
    }

    if (departmentId) {
      props.handleSearchAssets(departmentProps)
    }

    if (categoryId) {
      props.handleSearchAssets(categoryProps)
    }

    if (statusTypes.length > 0) {
      props.handleSearchAssets({ name: statusProps.name, value: statusTypes })
    }

    setKeyword('')
    setSearchFields([])
    setStatusTypes([])
  }

  const handleResetState = async () => {
    setKeyword('')
    setSearchFields([])
    setStatusTypes([])
    setQuickDate(0)
    setDateRange([null, null])
  }

  const handleKeywordChange = (e) => {
    setKeywordProps(e)
    setKeyword(e.target.value)
  }

  function onChangeSearchFields(value, event) {
    setSearchAssetProps(event)
    if (event.action === 'select-option' && event.option.value === '*') {
      setSearchFields(getFilteredOptions())
      value = getFilteredOptions().slice(1)
    } else if (event.action === 'deselect-option' && event.option.value === '*') {
      setSearchFields([])
      value = []
    } else if (event.action === 'deselect-option') {
      setSearchFields(value.filter((o) => o.value !== '*'))
    } else if (value.length === getFilteredOptions().length - 1) {
      setSearchFields(getFilteredOptions())
    } else {
      setSearchFields(value)
    }
  }

  function onChangeStatus(value, event) {
    if (event.action === 'select-option' && event.option.value === '*') {
      setStatusTypes(getFilteredStatusOptions())
      value = getFilteredStatusOptions().slice(1)
    } else if (event.action === 'deselect-option' && event.option.value === '*') {
      setStatusTypes([])
      value = []
    } else if (event.action === 'deselect-option') {
      setStatusTypes(value.filter((o) => o.value !== '*'))
    } else if (value.length === getFilteredStatusOptions().length - 1) {
      setStatusTypes(getFilteredStatusOptions())
    } else {
      setStatusTypes(value)
    }
    setStatusProps(event)
    // props.handleSearchAssets({ name: event.name, value: value })
  }

  const handleQuickDateRange = (e) => {
    let startDate = ''
    let endDate = ''
    const { value: selectedValue, name } = e.target
    setQuickDate(selectedValue)
    setDateRange([null, null])
    if (selectedValue === 'CurrentMonth') {
      const today = moment()
      startDate = today.startOf('month').format('YYYY-MM-DD')
      endDate = today.endOf('month').format('YYYY-MM-DD')
    } else if (selectedValue === 'PreviousMonth') {
      const lastMonth = moment().subtract(1, 'months')
      startDate = lastMonth.startOf('month').format('YYYY-MM-DD')
      endDate = lastMonth.endOf('month').format('YYYY-MM-DD')
    } else if (selectedValue === 'CurrentQuarter') {
      const currentQuarter = moment().quarter()
      startDate = moment().quarter(currentQuarter).startOf('quarter').format('YYYY-MM-DD')
      endDate = moment().quarter(currentQuarter).endOf('quarter').format('YYYY-MM-DD')
    } else if (selectedValue === 'PreviousQuarter') {
      const lastQuarter = moment().subtract(1, 'quarters').quarter()
      startDate = moment().quarter(lastQuarter).startOf('quarter').format('YYYY-MM-DD')
      endDate = moment().quarter(lastQuarter).endOf('quarter').format('YYYY-MM-DD')
    } else if (selectedValue === '2023' || /^\d{4}$/.test(selectedValue)) {
      const selectedYear = parseInt(selectedValue)
      startDate = moment([selectedYear, 0]).format('YYYY-MM-DD')
      endDate = moment([selectedYear, 11]).endOf('month').format('YYYY-MM-DD')
    }

    props.handleSearchAssets({
      name,
      update: [startDate, endDate],
      value: selectedValue,
      target: e.target,
      dateRangeBy,
    })
  }

  return (
    <div className={`${!props.showSearch && 'd-none'}`}>
      <div id='kt_content' className='content d-flex flex-column flex-column-fluid'>
        <div className='post d-flex flex-column-fluid' id='kt_post'>
          <div id='kt_content_container' className={`container`}>
            <div className='card card-custom gutter-b'>
              <div className='card-body'>
                <div className='form_section'>
                  <div className='form-title fw-bold fs-4 pb-3 d-flex align-items-center'>
                    <span className='fs-5 pb-1 me-2'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width='17px'
                        height='17px'
                        viewBox='0 0 16 16'
                      >
                        <path
                          fill='#080D42'
                          fill-rule='evenodd'
                          d='M5.29289,3.70711 C4.90237,3.31658 4.90237,2.68342 5.29289,2.29289 C5.68342,1.90237 6.31658,1.90237 6.70711,2.29289 L11.7071,7.29289 C12.0976,7.68342 12.0976,8.31658 11.7071,8.70711 L6.70711,13.7071 C6.31658,14.0976 5.68342,14.0976 5.29289,13.7071 C4.90237,13.3166 4.90237,12.6834 5.29289,12.2929 L9.58579,8 L5.29289,3.70711 Z'
                        />
                      </svg>
                    </span>
                    <span>Search Criteria</span>
                  </div>
                  <div className='row'>
                    <div className='col-xl-3 col-md-4'>
                      <div className='form-group mb-3'>
                        <label className='col-form-label pt-0 pb-1' htmlFor='keyword'>
                          Search
                        </label>
                        <div>
                          <input
                            className='form-control'
                            id='keyword'
                            name='keyword'
                            value={keyword}
                            placeholder='Keyword'
                            type='text'
                            onChange={handleKeywordChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className='col-xl-3 col-md-4'>
                      <div className='form-group mb-3 assetSearch_field'>
                        <label className='col-form-label pt-0 pb-1'>Search Fields</label>
                        <div className='ReactMultiSelectCheckboxes custom-checkbox-select'>
                          <ReactMultiSelectCheckboxes
                            options={getFilteredOptions()}
                            value={searchFields}
                            onChange={onChangeSearchFields}
                            name='searchField'
                            id='searchField'
                            labelledBy='Select'
                            className='form-control'
                            width='100%'
                            placeholderButtonLabel='Nothing Selected'
                          />
                        </div>
                        <span
                          className='field-validation-valid'
                          data-valmsg-for='chk_searchFields'
                          data-valmsg-replace='true'
                        ></span>
                      </div>
                    </div>
                    {!(personUser || customerUser) && (
                      <div className='col-xl-3 col-md-4'>
                        <div className='form-group mb-3'>
                          <label className='col-form-label pt-0 pb-1' htmlFor='SiteId'>
                            Site
                          </label>
                          <select
                            className='form-select'
                            data-val='true'
                            data-val-number='The field Site must be a number.'
                            id='SiteId'
                            name='siteId'
                            onChange={(e) => {
                              setSiteId(e.target.value)
                              setSitesProps(e)
                            }}
                            value={siteId}
                          >
                            <option value={0}>All Sites</option>
                            {siteData?.sites?.map((site, index) => (
                              <option key={index} value={site.id}>
                                {site.name}
                              </option>
                            ))}
                          </select>
                          <span
                            className='field-validation-valid'
                            data-valmsg-for='SiteId'
                            data-valmsg-replace='true'
                          ></span>
                        </div>
                      </div>
                    )}
                    {!(personUser || customerUser) && (
                      <div className='col-xl-3 col-md-4'>
                        <div className='form-group mb-3' id='AssetsLocationListWrapper'>
                          <label className='col-form-label pt-0 pb-1' htmlFor='LocationId'>
                            Location
                          </label>
                          <select
                            className='form-select'
                            data-val='true'
                            data-val-number='The field Location must be a number.'
                            id='LocationId'
                            name='locationId'
                            value={locationId}
                            onChange={(e) => {
                              setLocationId(e.target.value)
                              setLocationProps(e)
                            }}
                          >
                            <option value={0}>All Locations</option>
                            {locationData?.locations?.map((item: any, idx: number) => {
                              return (
                                <option key={idx} value={item.id}>
                                  {item.location}
                                </option>
                              )
                            })}
                          </select>
                          <span
                            className='field-validation-valid'
                            data-valmsg-for='LocationId'
                            data-valmsg-replace='true'
                          ></span>
                        </div>
                      </div>
                    )}
                    {!(personUser || customerUser) && (
                      <div className='col-xl-3 col-md-4'>
                        <div className='form-group mb-3'>
                          <label className='col-form-label pt-0 pb-1' htmlFor='CategoryId'>
                            Category
                          </label>
                          <select
                            className='form-select'
                            data-val='true'
                            data-val-number='The field Category must be a number.'
                            id='CategoryId'
                            name='categoryId'
                            value={categoryId}
                            onChange={(e) => {
                              setCategoryId(e.target.value)
                              setCategoryProps(e)
                            }}
                          >
                            <option value={0}>All Categories</option>
                            {categoryData?.category?.map((item: any, idx: number) => {
                              return (
                                <option key={idx} value={item.id}>
                                  {item.category}
                                </option>
                              )
                            })}
                          </select>
                          <span
                            className='field-validation-valid'
                            data-valmsg-for='CategoryId'
                            data-valmsg-replace='true'
                          ></span>
                        </div>
                      </div>
                    )}
                    {!(personUser || customerUser) && (
                      <div className='col-xl-3 col-md-4'>
                        <div className='form-group mb-3'>
                          <label className='col-form-label pt-0 pb-1' htmlFor='DepartmentId'>
                            Department
                          </label>
                          <select
                            className='form-select'
                            data-val='true'
                            data-val-number='The field Department must be a number.'
                            id='DepartmentId'
                            name='departmentId'
                            value={departmentId}
                            onChange={(e) => {
                              setDepartmentId(e.target.value)
                              setDepartmentProps(e)
                            }}
                          >
                            <option value={0}>All Departments</option>
                            {departmentData?.department?.map((item: any, idx: number) => {
                              return (
                                <option key={idx} value={item.id}>
                                  {item.department}
                                </option>
                              )
                            })}
                          </select>
                          <span
                            className='field-validation-valid'
                            data-valmsg-for='DepartmentId'
                            data-valmsg-replace='true'
                          ></span>
                        </div>
                      </div>
                    )}
                    <div className='col-xl-3 col-md-4' style={{ display: 'none' }}>
                      <div className='form-group mb-3'>
                        <label className='col-form-label pt-0 pb-1' htmlFor='person'>
                          Person
                        </label>
                        <div>
                          <input
                            className='form-control'
                            id='person'
                            placeholder='Any Person'
                            type='text'
                          />
                        </div>
                      </div>
                    </div>
                    <div className='col-xl-3 col-md-4' style={{ display: 'none' }}>
                      <div className='form-group mb-3'>
                        <label className='col-form-label pt-0 pb-1' htmlFor='customer'>
                          Customer
                        </label>
                        <div>
                          <input
                            className='form-control'
                            id='customer'
                            placeholder='Any Customer'
                            type='text'
                          />
                        </div>
                      </div>
                    </div>
                    <div className='col-xl-3 col-md-4'>
                      <div className='form-group mb-3 customSelect' id='divStatusType'>
                        <label className='col-form-label pt-0 pb-1'>Status</label>
                        <div className='ReactMultiSelectCheckboxes custom-checkbox-select'>
                          <ReactMultiSelectCheckboxes
                            options={getFilteredStatusOptions()}
                            value={statusTypes}
                            onChange={onChangeStatus}
                            labelledBy='Select'
                            name='status'
                            width='100%'
                            placeholderButtonLabel='Any Status'
                            className='form-control'
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {!(personUser || customerUser) && <div className='separator my-5'></div>}
                  <div className='row align-items-end'>
                    <div className='col-xl-3 col-md-4' style={{ display: 'none' }}>
                      <div className='mb-3 fw-bolder'>Display Results</div>
                      <div className='form-group mb-3'>
                        <label className='col-form-label pt-0 pb-1' htmlFor='recordCount'>
                          No. of Results{' '}
                        </label>
                        <select
                          name='recordCount'
                          id='recordCount'
                          className='form-select'
                          onChange={(e) => props.handleSearchAssets(e)}
                        >
                          <option value='10'>10</option>
                          <option value='25'>25</option>
                          <option value='50'>50</option>
                          <option value='100'>100</option>
                        </select>
                        <span
                          className='field-validation-valid'
                          data-valmsg-for='recordCount'
                          data-valmsg-replace='true'
                        ></span>
                      </div>
                    </div>
                    {!(personUser || customerUser) && (
                      <div className='col-xl-3 col-md-4'>
                        <div className='form-group mb-3'>
                          <label className='col-form-label pt-0 pb-1' htmlFor='DateRangeBy'>
                            Date Range By
                          </label>
                          <select
                            className='form-select'
                            data-val='true'
                            id='DateRangeBy'
                            name='dateRangeBy'
                            onChange={(e) => {
                              setDateRangeBy(e.target.value)
                            }}
                            value={dateRangeBy}
                          >
                            <optgroup label='Standard fields'>
                              <option value='purchasedDate'>Purchased Date</option>
                              <option value='dateCreated'>Date Created</option>
                            </optgroup>
                          </select>
                        </div>
                      </div>
                    )}
                    {!(personUser || customerUser) && (
                      <div className='col-xl-3 col-md-4'>
                        <div className='form-group mb-3'>
                          <label className='col-form-label pt-0 pb-1' htmlFor='QuickDateRange'>
                            Quick Date Range
                          </label>
                          <select
                            className='form-select'
                            id='QuickDateRange'
                            name='quickDate'
                            onChange={(e) => handleQuickDateRange(e)}
                            value={quickDate}
                          >
                            <option value='0'>...</option>
                            <optgroup label='Month'>
                              <option value='CurrentMonth'>Current Month</option>
                              <option value='PreviousMonth'>Previous Month</option>
                            </optgroup>
                            <optgroup label='Quarter'>
                              <option value='CurrentQuarter'>Current Quarter</option>
                              <option value='PreviousQuarter'>Previous Quarter</option>
                            </optgroup>
                            <optgroup label='Year'>
                              <option value='2024'>Current Year</option>
                              <option value='2023'>Year 2023</option>
                              <option value='2022'>Year 2022</option>
                              <option value='2021'>Year 2021</option>
                              <option value='2020'>Year 2020</option>
                              <option value='2019'>Year 2019</option>
                            </optgroup>
                          </select>
                          <span
                            className='field-validation-valid'
                            data-valmsg-for='QuickDateRange'
                            data-valmsg-replace='true'
                          ></span>
                        </div>
                      </div>
                    )}
                    <div className='col-xl-3 col-md-4 d-none'>
                      <div className='form-group mb-3 custom-date-picker'>
                        <label className='col-form-label pt-0 pb-1'>Custom Date Range</label>
                        <ReactDatePicker
                          selectsRange={true}
                          startDate={startDate}
                          endDate={endDate}
                          onChange={(update) => {
                            setDateRange(update)
                            setQuickDate(0)
                            props.handleSearchAssets({
                              name: 'customDate',
                              update: update,
                              dateRangeBy,
                            })
                          }}
                          isClearable={true}
                          className='form-control'
                          placeholderText='Custom Date'
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='card-footer text-end'>
                <div
                  className='btn btn-light-primary main-btn-style me-2'
                  onClick={() => {
                    props.setShowSearch(false)
                    props.setResetAssetsSearchStates(false)
                    handleResetState()
                  }}
                >
                  Cancel
                </div>
                <div
                  className='btn btn-primary main-btn-style'
                  onClick={() => {
                    props.setLoading(true)
                    props.setShowSearch(false)
                    props.handleSearchSubmit()
                    props.setResetAssetsSearchStates(false)
                    handleSearch()
                  }}
                >
                  Search
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AssetsSearch
