export interface CheckInInterface {
    returnDate: string,
    checkedTo: string,
    emails: string[],
    userId: number,
    notes: string,
    locationId: number | undefined,
    siteId?: number | undefined,
    departmentId: number | undefined,
    assetId: number
}
export const defaultCheckInDetail: CheckInInterface = {
    returnDate: new Date().toISOString().split('T')[0],
    checkedTo: "",
    userId: 0,
    notes: "",
    emails: [""],
    locationId: undefined,
    siteId: undefined,
    departmentId: undefined,
    assetId: 0
}
