import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { assetsApi } from './Assets'
import { getAuth } from '../Auth'


const API_URL = process.env.REACT_APP_API_URL

export const reserveApi = createApi({
	reducerPath: 'reserveApi',
	baseQuery: fetchBaseQuery({
		baseUrl: `${API_URL}/`,
		prepareHeaders: (headers) => {
			const auth = getAuth()
			if (auth && auth?.token) {
				headers.set('Authorization', `Bearer ${auth?.token}`);
			}
			return headers;
		},
	}),
	tagTypes: ['Reserve'],
	endpoints: (build) => ({
		addReserve: build.mutation<any, any>({
			query: (data) => ({
				url: `reserve`,
				method: 'POST',
				body: data,
			}),
			onQueryStarted: (arg, api) => {
				api.queryFulfilled.then(() => {
					api.dispatch(
						assetsApi.util.invalidateTags(["Assets"])
					);
				}).catch((error) => {});
			},
			invalidatesTags: () => [{ type: 'Reserve' }],
		}),
	}),
})

export const {
	useAddReserveMutation,
} = reserveApi
