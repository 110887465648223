import { useState, useEffect } from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import { useGetCompanyQuery } from 'App/Modules/Services/Company'
import getSymbolFromCurrency from 'currency-symbol-map'
import { useAuth } from 'App/Modules/Auth'
import Loader from 'App/BeatLoader'
import { useGetWarrantyQuery } from 'App/Modules/Services/Warranty'
import { toast } from 'react-toastify'
import moment from 'moment'

const ViewAssetModal = (props: any) => {
    const [data, setData] = useState<any>({})
    const [loading, setLoading] = useState<boolean>(false)
    const warrantyId = props.warrantyId
    const {
        data: warrantyData,
        error,
        isLoading,
        isSuccess,
    } = useGetWarrantyQuery({ id: warrantyId }, { skip: !warrantyId })


    useEffect(() => {
        if (isLoading) {
            setLoading(true)
        } else {
            setLoading(false)
        }

    }, [isLoading])

    useEffect(() => {
        if (isSuccess && warrantyData) {
            const { warranty } = warrantyData
            setData(warranty)
            setLoading(false)
        } else if (!isSuccess && error) {
            setLoading(false)
            toast.error("Something went wrong while fetching the details")
            props.handleClose()
        }
    }, [warrantyData, isSuccess, error])

    return (
        <>
            {
                loading ? <Loader loading={loading} /> :
                    <Modal
                        show={props.show}
                        onHide={() => {
                            props.handleClose()
                        }}
                        backdrop='static'
                        keyboard={false}
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>Asset Warranty</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <div className='assetProduct_detail mb-5'>
                                <div className='asset_detail_table table-responsive'>
                                    <table
                                        className='table table-bordered detail-table table-sm mb-0'
                                        aria-label='Quick View'
                                        role='presentation'
                                    >
                                        <tbody>
                                            <tr>
                                                <td className='label_col bg-light'>Length</td>
                                                <td className='fw-bold detail_col table-elipses'>{data?.duration}</td>
                                            </tr>
                                            <tr>
                                                <td className='label_col bg-light'>Expiration Date</td>
                                                <td className='fw-bold detail_col table-elipses'>{data?.expirationDate && moment(data?.expirationDate).format("MM/DD/YYYY")}</td>
                                            </tr>
                                            <tr>
                                                <td className='label_col bg-light'>Notes</td>
                                                <td className={`${data?.description ? "fw-bold" : "text-muted"} detail_col table-elipses`}>{data?.description || "You don't write any note"}</td>
                                            </tr>
                                            <tr>
                                                <td className='label_col bg-light'>Created by</td>
                                                <td className='fw-bold detail_col table-elipses'>{data && data.user ? `${data.user.firstName} ${data.user.lastName}` : ''}</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <div className='text-end pt-2'>
                                <div
                                    className='btn btn-light-primary   main-btn-style me-2'
                                    onClick={() => {
                                        props.handleClose()
                                    }}
                                >
                                    Close{' '}
                                </div></div>
                        </Modal.Footer>
                    </Modal>
            }
        </>
    )
}

export default ViewAssetModal
