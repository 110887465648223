import { useEffect, useState } from 'react'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { initialWarranty } from '../../Core/_models'
import {
    useAddWarrantyMutation,
    useDeleteWarrantyMutation,
    useGetWarrantyQuery,
    useUpdateWarrantyMutation,
} from 'App/Modules/Services/Warranty'
import { checkUserUnauthorized, useAuth } from 'App/Modules/Auth'
import { toast } from 'react-toastify'
import Loader from 'App/BeatLoader'
import moment from 'moment'
import DeleteConfirmationModal from './DeleteConfirmationModal'

const WarrantySchema = Yup.object().shape({
    duration: Yup.number().required('Month length is required'),
    expirationDate: Yup.string().required('Expiration date is required'),
    description: Yup.string().trim(),
})

const WarrantyModal = (props: any) => {
    const { currentUser, saveAuth, setCurrentUser } = useAuth()
    const [loading, setLoading] = useState(false)
    const warrantyId = props.warrantyId
    const {
        data: singleWarrantyData,
        error: singleWarrantyDataErr,
        isLoading,
        isSuccess,
    } = useGetWarrantyQuery({ id: warrantyId }, { skip: !warrantyId })
    const [addWarranty, { isLoading: isWarrantyAdd }] = useAddWarrantyMutation()
    const [updateWarranty, { isLoading: isUpdateWarranty }] = useUpdateWarrantyMutation()

    useEffect(() => {
        if (isWarrantyAdd || isUpdateWarranty || isLoading)
            setLoading(true)
        else
            setLoading(false)
    }, [isWarrantyAdd, isUpdateWarranty, isLoading])


    const formik = useFormik({
        initialValues: { ...initialWarranty },
        validationSchema: WarrantySchema,
        onSubmit: async (values, { resetForm }) => {
            values.assetId = Number(props.assetId)
            values.userId = props.assetUserId
            if (currentUser) {
                try {
                    const data: any =
                        warrantyId > 0 && singleWarrantyData?.warranty?.id
                            ? await updateWarranty({ body: values, id: warrantyId }).unwrap()
                            : await addWarranty(values).unwrap()
                    if (data?.success) {
                        toast.dismiss()
                        toast.success(data?.message)
                        resetForm()
                        props.handleClose()
                    } else {
                        toast.dismiss()
                        toast.error(data?.message)
                        props.handleClose()
                        resetForm()
                    }
                } catch (error: any) {
                    toast.error(error?.data?.message)
                    props.handleClose()
                    checkUserUnauthorized(error?.data, saveAuth, setCurrentUser, toast)
                }
            }
        },
    })

    useEffect(() => {
        setLoading(false)
        if (isSuccess && singleWarrantyData) {
            formik.setValues({
                duration: singleWarrantyData?.warranty?.duration,
                expirationDate: moment(singleWarrantyData?.warranty?.expirationDate).format('YYYY-MM-DD'),
                description: singleWarrantyData?.warranty?.description,
            })
        } else if (!isSuccess && singleWarrantyDataErr) {
            props.handleClose()
            toast.error("something went wrong")
        }
    }, [singleWarrantyData, isSuccess])



    return (
        <>
            {
                loading ? <Loader loading={loading} /> :
                    <Modal
                        show={props.show}
                        onHide={() => {
                            formik.resetForm()
                            props.handleClose()
                        }}
                        backdrop='static'
                        keyboard={false}
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>Asset Warranty</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <form noValidate onSubmit={formik.handleSubmit} className='form'>
                                <div className='mb-4'>
                                    <label className='fw-bold fs-6 mb-2' htmlFor='duration'>Length</label>
                                    <div className='fv-row'>
                                        <div className='input-group'>
                                            <input
                                                type='text'
                                                id='duration'
                                                className='form-control  form-control-solid'
                                                aria-label='Amount (to the nearest dollar)'
                                                value={formik.values.duration}
                                                onChange={formik.handleChange}
                                                name='duration'
                                            />
                                            <span className='input-group-text border-0'>Months</span>
                                        </div>
                                    </div>
                                    <div>
                                        {formik.errors.duration && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{formik.errors.duration}</div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className='mb-4'>
                                    <label className='required fw-bold fs-6 mb-2' htmlFor='expirationDate'>Expiration Date</label>
                                    <div className='fv-row'>
                                        <div className='input-group'>
                                            <input
                                                type='date'
                                                id='expirationDate'
                                                className='form-control  form-control-solid'
                                                aria-label='Amount (to the nearest dollar)'
                                                onChange={formik.handleChange}
                                                name='expirationDate'
                                                value={formik?.values?.expirationDate}
                                            />
                                            <span className='input-group-text border-0'>
                                                <i className='fa fa-calendar'></i>
                                            </span>
                                        </div>
                                    </div>
                                    <div>
                                        {formik.touched.expirationDate && formik.errors.expirationDate && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{formik.errors.expirationDate}</div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className='mb-4'>
                                    <label className='fw-bold fs-6 mb-2' htmlFor='Notes'>Notes</label>
                                    <div>
                                        <textarea
                                            className='form-control'
                                            data-val-length='Maximum length is 1000.'
                                            data-val-length-max='1000'
                                            cols={20}
                                            rows={4}
                                            data-val='true'
                                            id='Notes'
                                            name='description'
                                            onChange={formik.handleChange}
                                            value={formik.values.description}
                                        ></textarea>
                                    </div>
                                    <div>
                                        {formik.errors.description && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{formik.errors.description}</div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className={`${warrantyId > 0 ? "d-flex" : "d-block"} justify-content-between align-items-center"`}>
                                    {
                                        warrantyId > 0 ? <Button
                                            className='btn btn-danger main-btn-style'
                                            onClick={() => {
                                                props.setConfirmDelete(true)
                                                props.setDeletedId(warrantyId)
                                                formik.resetForm()
                                                props.handleClose()
                                            }}
                                        >
                                            <span className='indicator-label'>Delete</span>
                                        </Button> : null
                                    }
                                    <div className='text-end pt-2'>
                                        <div
                                            className='btn btn-light-primary   main-btn-style me-2'
                                            onClick={() => {
                                                formik.resetForm()
                                                props.handleClose()
                                            }}
                                        >
                                            Close{' '}
                                        </div>
                                        <Button
                                            type='submit'
                                            className='btn btn-primary main-btn-style'
                                            disabled={formik.isSubmitting}
                                        >
                                            {!isWarrantyAdd || !isUpdateWarranty || !isLoading ? (
                                                warrantyId > 0 ? (
                                                    <span className='indicator-label'>Update</span>
                                                ) : (
                                                    <span className='indicator-label'>Add</span>
                                                )
                                            ) : (
                                                <span className='indicator-progress d-block'>
                                                    Please wait...
                                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                                </span>
                                            )}
                                        </Button>
                                    </div>
                                </div>
                            </form>
                        </Modal.Body>
                    </Modal>
            }
        </>
    )
}

export default WarrantyModal
