import { PageTitle } from "_metronic/layout/core"
import WarrantiesListing from "./Components/WarrantiesListing"
import { useIntl } from "react-intl"

const Warranties = () => {
	const intl = useIntl()
	return (
		<>
			<PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'List of Warranties' })}</PageTitle>
			<WarrantiesListing />
		</>
	)
}

export default Warranties