export type Person = {
	firstName: string
	lastName: string
	employeeId?: string
	title?: string
	phone?: string
	email?: string | ""
	siteId?: number | null
	locationID?: number | null
	departmentId?: number | null
	notes: string
}

export const initialPersons: Person = {
	firstName: "",
	lastName: "",
	employeeId: "",
	title: "",
	phone: "",
	email: "",
	siteId: undefined,
	locationID: undefined,
	departmentId: undefined,
	notes: "",
}

export type PersonResponse = {
	id: number;
	firstName: string;
	lastName: string;
	employeeId?: string;
	title?: string;
	phone?: string;
	email?: string;
	siteId?: string;
	locationID?: string;
	departmentId?: string;
	notes?: string;
};
