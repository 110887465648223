import { Button, Modal } from 'react-bootstrap'
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { login } from '../../core/_requests';
import { useAuth } from '../../core/Auth'
import { toast } from 'react-toastify';
import { useEffect, useState } from 'react'
import moment from 'moment';

const otpSchema = Yup.object().shape({
    otp: Yup.string().trim().required("OTP is required.")
});

const OTPModal = ({ setRemainingTime, isTime, setIsTime, remainingTime, showOTP, handleClose, loginValues }) => {

    const { saveAuth, setCurrentUser } = useAuth()
    const [isLoading, setIsLoading] = useState(false)
    const [resendLoading, setResendLoading] = useState(false)

    useEffect(() => {
        let intervalId = null
        if (isTime) {
            intervalId = setInterval(() => {
                setRemainingTime(prevRemainingTime => {
                    const newRemainingTime = moment.duration(prevRemainingTime).subtract(1, 'seconds');
                    if (newRemainingTime.asSeconds() <= 0) {
                        clearInterval(intervalId);
                        setIsTime(false)
                        return moment.duration(0, 'seconds');
                    } else {
                        setIsTime(true)
                        return newRemainingTime;
                    }
                });
            }, 1000);

        }

        return () => clearInterval(intervalId);
    }, [isTime]);

    const resendOtp = async () => {
        setResendLoading(true)
        setIsTime(true)
        setRemainingTime(moment.duration(1, 'minutes'))
        try {
            const { data: user } = await login(loginValues?.email, loginValues?.password, loginValues?.rememberme)
            if (user?.message?.isOTPRequired) {
                setResendLoading(false)
                toast.dismiss()
                toast.success(user?.message?.message)
                return
            }
            setCurrentUser(user.data)
            saveAuth({ token: user.token })
            toast.dismiss()
            toast.success(user?.message)
        } catch (error) {
            toast.dismiss()
            setResendLoading(false)
            setCurrentUser(undefined)
            saveAuth(undefined)
            toast.error(error.response.data.message)
        }
    }

    const formik = useFormik({
        initialValues: {
            otp: ''
        },
        validationSchema: otpSchema,
        onSubmit: async (values) => {
            const data = { ...loginValues, ...values }
            try {
                setIsLoading(true)
                const { data: user } = await login(data.email, data.password, data.rememberme, data.otp)
                setIsLoading(false)
                if (user?.success) {
                    toast.dismiss()
                    toast.success(user?.message)
                    setCurrentUser(user.data)
                    saveAuth({ token: user.token })
                }
            } catch (err) {
                setIsLoading(false);
                if (err.response && err.response.data) {
                    toast.dismiss();
                    toast.error(err.response.data.message);
                }
            }
        },
    });

    const formattedTime = moment.utc(remainingTime?.asMilliseconds()).format('mm:ss');

    return (
        <>
            <Modal show={showOTP} onHide={() => {
                formik.resetForm()
                handleClose()
            }}
                backdrop='static'
                keyboard={false}>
                <form onSubmit={formik.handleSubmit}>
                    <Modal.Header closeButton>
                        <h5 className="modal-title">Two-Step Verification</h5>
                    </Modal.Header>
                    <Modal.Body>

                        <p>For added security, please enter the One Time Password (OTP) that has been sent to your register email.</p>
                        <div className="form-group row">
                            <label className="col-sm-3 col-form-label" htmlFor="OTP">Enter OTP</label>
                            <div className="col-sm-8">
                                <div>
                                    <input className="form-control" data-val="true" data-val-length="Maximum length is 50." data-val-length-max="50" data-val-required="OTP is required." id="OTP" name="otp" type="text" value={formik.values.otp} onChange={formik.handleChange} onBlur={formik.handleBlur} />
                                    {formik?.touched?.otp && formik?.errors?.otp ? (
                                        <span className={formik?.errors?.otp ? `` : `d-none`}>
                                            <label className="pl-1 text-danger">{formik?.errors?.otp}</label>
                                        </span>
                                    ) : null}
                                </div>
                                <span className="field-validation-valid material-validate" data-valmsg-for="OTP" data-valmsg-replace="true"></span>
                            </div>
                        </div>
                    </Modal.Body>
                    <Modal.Footer className="d-flex">
                        <Button className="btn btn-light-primary main-btn-style me-2" disabled={isLoading || isTime} onClick={resendOtp}>
                            {!resendLoading && isTime && `${formattedTime}`}
                            {!resendLoading && !isTime && 'Resend OTP'}
                            {resendLoading && (
                                <span className='indicator-progress d-block'>
                                    Please wait...{' '}
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                            )}
                        </Button>

                        <Button variant="primary" type="submit" disabled={isLoading}>
                            {!isLoading && 'Sign In'}
                            {isLoading && (
                                <span className='indicator-progress d-block'>
                                    Please wait...{' '}
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                            )}
                        </Button>
                    </Modal.Footer>
                </form>
            </Modal>
        </>
    );
}

export default OTPModal;