import { useState, useEffect } from "react";
import PreviewTable from "./PreviewTable";

const OrderTableColumns = ({ setColumnsData, columnsData }: any) => {
    const [allColumns, setAllColumns] = useState(false);
    const [getColumn, setGetColumn] = useState(false);
    const [organizedData, setOrganizedData] = useState({});

    const handleAllColumns = () => {
        const newAllColumns = !allColumns;
        setAllColumns(newAllColumns);
        setColumnsData((prevAssets: any) =>
            prevAssets.map((asset: any) => ({ ...asset, defaultActive: newAllColumns }))
        );
        setGetColumn(newAllColumns);
    };

    const handleCheckboxChange = (itemId: number): void => {
        setColumnsData((prevData: any) => {
            const updatedData = prevData?.map((item: any) =>
                item.id === itemId
                    ? { ...item, defaultActive: !item.defaultActive }
                    : item
            );

            const hasTrueValue = updatedData.some((item: any) => item.defaultActive);
            setGetColumn(hasTrueValue);

            return updatedData;
        });
    };

    useEffect(() => {
        const allCheck = columnsData.every((item: any) => item.defaultActive === true);
        setAllColumns(allCheck);

        const anyCheck = columnsData.some((item: any) => item.defaultActive === true);
        setGetColumn(anyCheck);

        const calculatedOrganizedData = columnsData?.reduce((acc: any, item: any) => {
            if (!acc[item.field]) {
                acc[item.field] = [];
            }
            acc[item.field].push(item);
            return acc;
        }, {});
        setOrganizedData(calculatedOrganizedData);
    }, [columnsData]);

    return (
        <>
            <div className='card-body border-top px-0 pb-2 mb-4'>
                <div className='heading'>
                    <h5>Step 2: Select and Order Table Columns</h5>
                </div>
                <p>
                    Choose which columns you want in your table by checking the appropriate boxes.
                </p>
                <label className="mb-4" htmlFor="all-columns-checkbox">
                    <div className="form-check form-check-sm form-check-custom">
                        <input
                            id="all-columns-checkbox"
                            type="checkbox"
                            className="custom-form-check-input form-check-input sig_input me-lg-3 me-2"
                            checked={allColumns}
                            onChange={() => handleAllColumns()}
                        />
                        {"All columns"}
                    </div>
                </label>

                <div className="row">
                    {Object.entries(organizedData)?.map(([heading, items]: any) => (
                        <div key={heading} className="col-md-4 col-sm-6 col-12">
                            <ul className="list-unstyled">
                                {items?.map((item: any) => (
                                    <li key={item?.id} className="mb-2">
                                        <label htmlFor={`column-checkbox-${item.id}`}>
                                            <input
                                                id={`column-checkbox-${item.id}`}
                                                type="checkbox"
                                                className="custom-form-check-input form-check-input sig_input me-lg-3 me-2"
                                                checked={item?.defaultActive}
                                                onChange={() => handleCheckboxChange(item?.id)}
                                            />
                                            {item?.header}
                                        </label>
                                    </li>
                                ))}
                            </ul>
                        </div>
                    ))}
                </div>
            </div>
            {getColumn && <PreviewTable columnsData={columnsData} />}
        </>
    );
};

export default OrderTableColumns;
