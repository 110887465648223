import { useEffect, useState, useRef } from 'react'
import { useParams } from 'react-router-dom'
import {
	useGetSingleAssetQuery,
	useGetAllAssetsQuery,
	useDeleteAssetsMutation,
} from '../../../../Services/Assets'
import { Link, useNavigate } from 'react-router-dom'
import ReactToPdf from 'react-to-pdf'
import { toAbsoluteUrlImage } from '_metronic/helpers'
import { useGetCompanyQuery } from 'App/Modules/Services/Company'
import getSymbolFromCurrency from 'currency-symbol-map'
import { checkUserUnauthorized, useAuth } from '../../../../Auth'
import { Images } from '_metronic/assets/Assets'
import { toast } from 'react-toastify'
import SplashScreen from 'App/SplashScreen'
import ReactMultiSelectCheckboxes from 'react-multiselect-checkboxes'
import { dateFormat, defaultDateFormat, setupKeys } from 'config'
import { PageTitle } from '_metronic/layout/core'
import { useIntl } from 'react-intl'
import WarrantyModal from './Modals/WarrantyModal'
import CheckedoutModal from './Modals/CheckedoutModal'
import { Prev } from 'react-bootstrap/esm/PageItem'
import {
	useDeleteWarrantyMutation,
	useGetAssetAllWarrantiesQuery,
	useGetWarrantyQuery,
} from 'App/Modules/Services/Warranty'
import ViewAssetModal from './Modals/ViewAssetModal'
import DeleteConfirmationModal from './Modals/DeleteConfirmationModal'
import ConfirmationPopup from '_metronic/helpers/components/ConfirmationPopup'
import Loader from 'App/DotsLoader'

const AssetDetails = () => {
	const intl = useIntl()
	const { id } = useParams()
	const { currentUser, saveAuth, setCurrentUser } = useAuth()
	const personUser = currentUser?.userType === '2'
	const customerUser = currentUser?.userType === '4'
	const [searchAssets, setSearchAssets] = useState<any>({
		keyword: '',
		searchField: '',
		siteId: '',
		locationId: '',
		categoryId: '',
		departmentId: '',
		person: '',
		customer: '',
		status: '',
		groupBy: '',
		recordCount: '',
		dateRangeBy: 'purchaseDate',
		quickDateRange: '',
		startDate: '',
		endDate: '',
	})
	const [page, setPage] = useState<number>(1)
	const [assetIds, setAssetIds] = useState<any>([])
	const [deleteDataArr, setDeleteDataArr] = useState<any>([])
	const [deleteModal, setDeleteModal] = useState<boolean>(false)
	const [currentAssetIndex, setCurrentAssetIndex] = useState<any>(0)
	const [limit, setLimit] = useState<number>(10)
	const [warrantyId, setWarrantyId] = useState<number>(0)
	const [checkoutId, setCheckoutId] = useState<number>(0)
	const [skip, setSkip] = useState<boolean>(false)
	const [confirmDelete, setConfirmDelete] = useState(false)
	const { data: warrantyData } = useGetAssetAllWarrantiesQuery({ id }, { skip: !id })
	const { data: assetDetail, isLoading: isLoadingViewAsset } = useGetSingleAssetQuery({ assetId: id })
	const { data: companyData, isSuccess: company } = useGetCompanyQuery()
	const { data: assetsData, isSuccess } = useGetAllAssetsQuery({
		body: new URLSearchParams(searchAssets).toString(),
		page,
		limit,
		keys: '',
	})
	const [currencyLogo, setCurrencyLogo] = useState<any>()
	const [warrantyModal, setShowWarrantyModal] = useState<boolean>(false)
	const [checkoutModal, setCheckoutModal] = useState<boolean>(false)
	const [assetViewModal, setAssetViewModal] = useState<any>("")
	const [deletedId, setDeletedId] = useState(0)
	const navigate = useNavigate()
	const [loading, setLoading] = useState<boolean>(false)
	const [showSearch, setShowSearch] = useState<boolean>(false)
	const [showView, setShowView] = useState<boolean>(false)
	const [deleteWarranty, { isLoading: isDeleted }] = useDeleteWarrantyMutation()
	const ref = useRef<HTMLDivElement>(null)
	const [deleteAssets, { isLoading: isLoadingDelete }] = useDeleteAssetsMutation()

	useEffect(() => {
		let res = getSymbolFromCurrency(companyData?.company?.currencySymbol)
		setCurrencyLogo(res)
	}, [company])

	useEffect(() => {
		if (assetsData) {
			const ids = assetsData?.userAssets?.map((asset) => asset.id)
			setAssetIds(ids)
			const initialAssetId: any = id
			const initialAssetIndex = ids?.indexOf(parseInt(initialAssetId))
			if (initialAssetIndex !== -1) {
				setCurrentAssetIndex(initialAssetIndex)
			}
		}
	}, [assetsData, id])

	const handleCloseWarrantyModal = () => {
		setShowWarrantyModal(false)
		setWarrantyId(0)
	}

	const handleCloseCheckoutModal = () => {
		setCheckoutModal(false)
		setCheckoutId(0)
	}

	const handleCloseViewWarrantyModal = () => {
		setShowView(false)
	}

	const deleteSelectedAssets = async () => {
		try {
			const deleteIds = { deleteIds: [id] }
			const res = await deleteAssets({ id: deleteIds }).unwrap()
			toast.dismiss()
			toast.success(res?.message)
			setSkip(false)
			setLoading(true)
			navigate('/assets')
		} catch (err: any) {
			toast.error(err?.data?.message)
		}
		setDeleteDataArr([])
		setDeleteModal(false)
	}
	const cancel = () => setDeleteModal(false)

	const confirm = () => deleteSelectedAssets()
	const onClickActions = (value) => {
		if (value === 'delete') {
			setDeleteDataArr([id])
			setDeleteModal(true)
		}
	}

	const handleDelete = async () => {
		try {
			const res: any = await deleteWarranty(deletedId)
			if (res?.data?.success) {
				toast.dismiss()
				toast.success(res?.data?.message)
			} else {
				toast.dismiss()
				toast.error(res?.data?.message)
			}
		} catch (error: any) {
			toast.error(error?.data?.message)
			checkUserUnauthorized(error?.data, saveAuth, setCurrentUser, toast)
		}
	}

	const handleConfirmDelete = () => {
		setConfirmDelete(false)
	}

	const goToNextAsset = () => {
		if (currentAssetIndex < assetIds.length - 1) {
			const nextAssetIndex = currentAssetIndex + 1
			setCurrentAssetIndex(nextAssetIndex)
			const nextAssetId = assetIds[nextAssetIndex]
			navigate(`/asset/view/${nextAssetId}`)
		} else {
			toast.info('No more assets found!')
		}
	}

	const goToPreviousAsset = () => {
		if (currentAssetIndex > 0) {
			const previousAssetIndex = currentAssetIndex - 1
			setCurrentAssetIndex(previousAssetIndex)
			const previousAssetId = assetIds[previousAssetIndex]
			navigate(`/asset/view/${previousAssetId}`)
		} else {
			toast.info('No more assets found!')
		}
	}

	return (
		<>
			<PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'Asset View' })}</PageTitle>
			<h3 className='fw-bolder mb-4'>
				<span className='me-2 align-middle'>
					<i className='la la-puzzle-piece fs-1' aria-hidden='true'></i>
				</span>{' '}
				Asset View
			</h3>
			<SplashScreen isLoadingTemplate={isLoadingViewAsset} />
			{!isLoadingViewAsset ? (
				<div id='kt_content' className='d-flex flex-column flex-column-fluid asset-details'>
					<div className='post d-flex flex-column-fluid' id='kt_post' ref={ref}>
						<div id='kt_content_container' className={`w-100 ${showSearch && 'd-none'}`}>
							<div className='card mb-5'>
								<ConfirmationPopup
									deleteModal={deleteModal}
									cancel={cancel}
									confirm={confirm}
									setSkip={setSkip}
									setupName={setupKeys.asset}
								/>
								<div className='card-body py-9'>
									<div className='card_title_inner'>
										<div className='card_caption mb-6 d-flex align-items-center justify-content-between'>
											<h3 className='form-title'>{assetDetail?.asset?.brand}</h3>
											<div className='d-flex'>
												{!(personUser || customerUser) && (
													<Link className='btn btn-light-primary me-2' to={`/asset/edit/${id}`}>
														<i className='fa fa-pencil-alt me-1 fs-6'></i> Edit
													</Link>
												)}
												<ReactToPdf targetRef={ref} filename='div-blue.pdf' scale={0.5}>
													{({ toPdf }) => (
														<button className='btn btn-light-primary me-2 d-none' onClick={toPdf}>
															<i className='la la-print me-1 fs-4'></i> Print
														</button>
													)}
												</ReactToPdf>
												{currentAssetIndex > 0 && (
													<button
														className='btn btn-light-primary me-2'
														onClick={goToPreviousAsset}
													>
														Previous
													</button>
												)}
												{currentAssetIndex < assetIds.length - 1 && (
													<button
														className={`btn btn-light-primary ${currentAssetIndex === 0 && 'me-2'}`}
														onClick={goToNextAsset}
													>
														Next
													</button>
												)}
											</div>
											<div className='d-none'>
												<select
													className='form-select form-select-solid'
													data-kt-select2='true'
													data-placeholder='Select option'
													data-allow-clear='true'
													defaultValue={'moreActions'}
													id='statusSelect'
													onChange={(e) => onClickActions(e.target.value)}
												>
													<option disabled hidden value='moreActions'>
														More Actions
													</option>
													<option value='delete'>Delete</option>
													<option value='dispose'>Dispose</option>
													<option value='broken'>Broken</option>
												</select>
											</div>
										</div>
										<div className='row'>
											<div className='col-xl-3 text-center mb-4 mb-md-0'>
												<div className='card card-custom overlay d-inline-block mb-xl-0 mb-8'>
													<div className='card-body p-0'>
														<div className='overlay-wrapper asset-image'>
															{assetDetail?.asset?.photo ? (
																<img
																	id='assetDefaultImg'
																	src={toAbsoluteUrlImage(assetDetail?.asset?.photo)}
																	alt='Asset'
																	title='assets-img'
																	crossOrigin='anonymous'
																	className='img-fluid center-block asset_img'
																/>
															) : (
																<img
																	src={Images.AssetsPlaceholder}
																	alt='Placeholder'
																	className='img-fluid center-block asset_img'
																/>
															)}
														</div>
													</div>
												</div>
											</div>
											<div className='col-xl-9'>
												<div className='row'>
													<div className='col-xl-7 col-lg-6'>
														<table
															className='assets-details table table-bordered table-view-detail table-sm'
															aria-label='Assets View'
															role='presentation'
														>
															<tbody>
																<tr>
																	<td className='label_col w-50'>Asset Tag ID</td>
																	<td className='detail-column fw-bold w-50'>
																		{assetDetail?.asset?.assetTagId}
																	</td>
																</tr>
																{!(personUser || customerUser) && (
																	<tr>
																		<td className='label_col w-50'>Purchase Date</td>
																		<td className='detail-column fw-bold w-50'>
																			{assetDetail?.asset?.purchasedDate
																				? dateFormat(
																					assetDetail?.asset?.purchasedDate,
																					currentUser?.dateFormat || defaultDateFormat
																				)
																				: ''}
																		</td>
																	</tr>
																)}
																{!(personUser || customerUser) && (
																	<tr>
																		<td className='label_col w-50'>Cost</td>
																		<td className='detail-column fw-bold w-50'>
																			{assetDetail?.asset?.cost}
																			{assetDetail?.asset?.cost &&
																				assetDetail?.asset?.cost >= 0 &&
																				' ' + currencyLogo}
																		</td>
																	</tr>
																)}
																<tr>
																	<td className='label_col w-50'>Brand</td>
																	<td className='detail-column fw-bold w-50'>
																		{assetDetail?.asset?.brand}
																	</td>
																</tr>
																<tr>
																	<td className='label_col w-50'>Model</td>
																	<td className='detail-column fw-bold w-50'>
																		{assetDetail?.asset?.model}
																	</td>
																</tr>
															</tbody>
														</table>
													</div>
													<div className='col-xl-5 col-lg-6'>
														<table
															className='assets-details table table-bordered table-view-detail table-sm mb-0'
															aria-label='Assets View'
															role='presentation'
														>
															<tbody>
																{!(personUser || customerUser) && (
																	<tr>
																		<td className='label_col w-50'>Site</td>
																		<td className='detail-column fw-bold w-50'>
																			{assetDetail?.asset?.site?.name}
																		</td>
																	</tr>
																)}
																{!(personUser || customerUser) && (
																	<tr>
																		<td className='label_col w-50'>Location</td>
																		<td className='detail-column fw-bold w-50'>
																			{assetDetail?.asset?.location?.location}
																		</td>
																	</tr>
																)}
																{!(personUser || customerUser) && (
																	<tr>
																		<td className='label_col w-50'>Category</td>
																		<td className='detail-column fw-bold w-50'>
																			{assetDetail?.asset?.category?.category}
																		</td>
																	</tr>
																)}
																{!(personUser || customerUser) && (
																	<tr>
																		<td className='label_col w-50'>Department</td>
																		<td className='detail-column fw-bold w-50'>
																			{assetDetail?.asset?.department?.department}
																		</td>
																	</tr>
																)}
																{!customerUser && (
																	<tr>
																		<td className='label_col w-50'>Assigned To</td>
																		<td className='detail-column fw-bold w-50'>
																			{assetDetail?.asset?.assetstatus?.user ? (
																				<div>{`${assetDetail?.asset?.assetstatus?.user?.firstName || ''} ${assetDetail?.asset?.assetstatus?.user?.lastName || ''
																					}`}</div>
																			) : assetDetail?.asset?.assetstatus !== null ? (
																				<div>{`${assetDetail?.asset?.site?.name || ''} ${assetDetail?.asset?.site?.name && assetDetail?.asset?.location?.location ? '/' : ''
																					} ${assetDetail?.asset?.location?.location || ''}`}</div>
																			) : assetDetail?.asset?.reserveassets?.length > 0 ? (
																				assetDetail?.asset?.reserveassets[0].reserveFor === 'person' ? (
																					<div>{`${assetDetail?.asset?.reserveassets[0]?.user?.firstName || ''
																						} ${assetDetail?.asset?.reserveassets[0]?.user?.lastName || ''
																						}`}</div>
																				) : assetDetail?.asset?.reserveassets[0].reserveFor === 'customer' ? (
																					<div>{`${assetDetail?.asset?.reserveassets[0]?.user?.firstName || ''
																						} ${assetDetail?.asset?.reserveassets[0]?.user?.lastName || ''
																						}`}</div>
																				) : (
																					<div>{`${assetDetail?.asset?.reserveassets[0]?.site?.name || ''} ${assetDetail?.asset?.reserveassets[0]?.location?.location || ''
																						}`}</div>
																				)
																			) : (
																				<div></div>
																			)}
																		</td>
																	</tr>
																)}
																<tr>
																	<td className='label_col w-50'>Status</td>
																	<td className='detail-column w-50'>
																		<div
																			className={`d-inline-block align-items-center px-3 py-2 rounded ${assetDetail.asset?.statusType === 'available'
																				? 'bg-light-green'
																				: assetDetail.asset?.statusType === 'lease'
																					? 'bg-light-blue'
																					: assetDetail.asset?.statusType === 'dispose'
																						? 'bg-light-pink'
																						: assetDetail.asset?.statusType === 'check_out'
																							? 'bg-light-blue'
																							: ''
																				}`}
																		>
																			{assetDetail?.asset?.statusType === 'check_out'
																				? 'Checked Out'
																				: assetDetail?.asset?.statusType === 'lease'
																					? 'Leased'
																					: assetDetail?.asset?.statusType === 'dispose'
																						? 'Disposed'
																						: assetDetail?.asset?.statusType === 'available'
																							? 'Available'
																							: assetDetail?.asset?.statusType}
																		</div>
																	</td>
																</tr>
																<tr>
																	{assetDetail?.asset?.reserveassets &&
																		assetDetail?.asset?.reserveassets?.length > 0 && (
																			<>
																				<td className='label_col'>Reservation</td>
																				<td className='detail-column fw-bold success'>
																					<div>
																						{assetDetail?.asset?.reserveassets &&
																							assetDetail?.asset?.reserveassets[0]?.startDate
																							? dateFormat(
																								assetDetail?.asset?.reserveassets[0]?.startDate,
																								currentUser?.dateFormat || defaultDateFormat
																							) + ' to '
																							: ''}

																						{assetDetail?.asset?.reserveassets[0] &&
																							assetDetail?.asset?.reserveassets[0]?.endDate
																							? dateFormat(
																								assetDetail?.asset?.reserveassets[0]?.endDate,
																								currentUser?.dateFormat || defaultDateFormat
																							)
																							: ''}
																					</div>
																					<span className='pe-2'>
																						By{' '}
																						{`${assetDetail?.asset?.user?.firstName} ${assetDetail?.asset?.user?.lastName}`}
																					</span>
																					{assetDetail?.asset?.reserveassets[0]?.customer
																						? assetDetail?.asset?.reserveassets[0]?.customer
																							?.fullName
																						: assetDetail?.asset?.reserveassets[0]?.person
																							? assetDetail?.asset?.reserveassets[0]?.person?.fullName
																							: ''}
																					{assetDetail?.asset?.reserveassets[0]?.site
																						? assetDetail?.asset?.reserveassets[0]?.site?.name
																						: ''}
																					{assetDetail?.asset?.reserveassets[0]?.location
																						? ' / ' +
																						assetDetail?.asset?.reserveassets[0]?.location
																							?.location
																						: ''}
																				</td>
																			</>
																		)}
																</tr>
															</tbody>
														</table>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className='card'>
								<div className='card-body py-9'>
									<div className='assets-tabs-wrapper mb-5'>
										<ul
											className='nav nav-tabs view-assets-tabs mb-lg-3 mb-2'
											id='myTab'
											role='tablist'
										>
											<li className='nav-item me-sm-2 m-sm-0 m-1' role='presentation'>
												<button
													className='nav-link fs-6 fw-bold col-form-label active'
													id='details-tab'
													data-bs-toggle='tab'
													data-bs-target='#details'
													type='button'
													role='tab'
													aria-controls='details'
													aria-selected='true'
												>
													Details
												</button>
											</li>
											<li className='nav-item me-sm-2 m-sm-0 m-1' role='presentation'>
												<button
													className='nav-link fs-6 fw-bold col-form-label'
													id='events-tab'
													data-bs-toggle='tab'
													data-bs-target='#events'
													type='button'
													role='tab'
													aria-controls='events'
													aria-selected='false'
												>
													Events
												</button>
											</li>
											<li className='nav-item me-sm-2 m-sm-0 m-1 d-none' role='presentation'>
												<button
													className='nav-link fs-6 fw-bold col-form-label'
													id='photos-tab'
													data-bs-toggle='tab'
													data-bs-target='#photos'
													type='button'
													role='tab'
													aria-controls='photos'
													aria-selected='false'
												>
													Photos
												</button>
											</li>
											<li className='nav-item me-sm-2 m-sm-0 m-1 d-none' role='presentation'>
												<button
													className='nav-link fs-6 fw-bold col-form-label'
													id='docs-tab'
													data-bs-toggle='tab'
													data-bs-target='#docs'
													type='button'
													role='tab'
													aria-controls='docs'
													aria-selected='false'
												>
													Docs.
												</button>
											</li>
											<li className='nav-item me-sm-2 m-sm-0 m-1' role='presentation'>
												<button
													className='nav-link fs-6 fw-bold col-form-label'
													id='warranty-tab'
													data-bs-toggle='tab'
													data-bs-target='#warranty'
													type='button'
													role='tab'
													aria-controls='warranty'
													aria-selected='false'
												>
													Warranty
												</button>
											</li>
											<li className='nav-item me-sm-2 m-sm-0 m-1 d-none' role='presentation'>
												<button
													className='nav-link fs-6 fw-bold col-form-label'
													id='linking-tab'
													data-bs-toggle='tab'
													data-bs-target='#linking'
													type='button'
													role='tab'
													aria-controls='linking'
													aria-selected='false'
												>
													Linking
												</button>
											</li>
											<li className='nav-item me-sm-2 m-sm-0 m-1' role='presentation'>
												<button
													className='nav-link fs-6 fw-bold col-form-label'
													id='maint-tab'
													data-bs-toggle='tab'
													data-bs-target='#maint'
													type='button'
													role='tab'
													aria-controls='maint'
													aria-selected='false'
												>
													Maint.
												</button>
											</li>
											<li className='nav-item me-sm-2 m-sm-0 m-1 d-none' role='presentation'>
												<button
													className='nav-link fs-6 fw-bold col-form-label'
													id='reserve-tab'
													data-bs-toggle='tab'
													data-bs-target='#reserve'
													type='button'
													role='tab'
													aria-controls='reserve'
													aria-selected='false'
												>
													Reserve
												</button>
											</li>
											<li className='nav-item me-sm-2 m-sm-0 m-1 d-none' role='presentation'>
												<button
													className='nav-link fs-6 fw-bold col-form-label'
													id='audit-tab'
													data-bs-toggle='tab'
													data-bs-target='#audit'
													type='button'
													role='tab'
													aria-controls='audit'
													aria-selected='false'
												>
													Audit
												</button>
											</li>
											<li className='nav-item me-sm-2 m-sm-0 m-1' role='presentation'>
												<button
													className='nav-link fs-6 fw-bold col-form-label'
													id='history-tab'
													data-bs-toggle='tab'
													data-bs-target='#history'
													type='button'
													role='tab'
													aria-controls='history'
													aria-selected='false'
												>
													History
												</button>
											</li>
										</ul>
									</div>
									<div className='tab-content' id='myTabContent'>
										<div
											className='tab-pane fade show active'
											id='details'
											role='tabpanel'
											aria-labelledby='details-tab'
										>
											<div className='tab-content'>
												<h4 className='mb-5'>Asset Details</h4>
												<div className='form-group row mb-3'>
													<div className='col-lg-2 d-flex align-items-center'>
														<h6 className='fw-normal'>Miscellaneous</h6>
													</div>
													<div className='col-lg-5'>
														<table
															className='table table-bordered table-view-detail table-sm mb-lg-0'
															role='presentation'
															aria-label='Asset Details'
														>
															<tbody>
																<tr>
																	<td className='label_col w-50'>Serial No</td>
																	<td className='detail-column fw-bold w-50'>
																		{assetDetail?.asset?.serialNo}
																	</td>
																</tr>
																<tr>
																	<td className='label_col w-50'>RAM</td>
																	<td className='detail-column fw-bold w-50'>
																		{assetDetail?.asset?.ram}
																	</td>
																</tr>
																<tr>
																	<td className='label_col w-50'>Storage</td>
																	<td className='detail-column fw-bold w-50'>
																		{assetDetail?.asset?.storage}
																	</td>
																</tr>
																<tr>
																	<td className='label_col w-50'>IMEI Number</td>
																	<td className='detail-column fw-bold w-50'>
																		{assetDetail?.asset?.imei}
																	</td>
																</tr>
																<tr>
																	<td className='label_col w-50'>Ports</td>
																	<td className='detail-column fw-bold w-50'>
																		{assetDetail?.asset?.ports}
																	</td>
																</tr>
																<tr>
																	<td className='label_col w-50'>Mbps</td>
																	<td className='detail-column fw-bold w-50'>
																		{assetDetail?.asset?.mbps}
																	</td>
																</tr>
															</tbody>
														</table>
													</div>
													<div className='col-lg-5'>
														<table
															className='table table-bordered table-view-detail table-sm mb-lg-0'
															role='presentation'
															aria-label='Asset Details'
														>
															<tbody>
																{
																	!(personUser || customerUser) && (
																		<tr>
																			<td className='label_col w-50'>Purchased From</td>
																			<td className='detail-column fw-bold w-50'>
																				{assetDetail?.asset?.purchasedFrom}
																			</td>
																		</tr>
																	)
																}
																<tr>
																	<td className='label_col w-50'>Stands</td>
																	<td className='detail-column fw-bold w-50'>
																		{assetDetail?.asset?.stands}
																	</td>
																</tr>
																<tr>
																	<td className='label_col w-50'>Batteries</td>
																	<td className='detail-column fw-bold w-50'>
																		{assetDetail?.asset?.batteries}
																	</td>
																</tr>
																<tr>
																	<td className='label_col w-50'>Bulbs</td>
																	<td className='detail-column fw-bold w-50'>
																		{assetDetail?.asset?.bulbs}
																	</td>
																</tr>
																<tr>
																	<td className='label_col w-50'>Lens</td>
																	<td className='detail-column fw-bold w-50'>
																		{assetDetail?.asset?.lens}
																	</td>
																</tr>
															</tbody>
														</table>
													</div>
												</div>
												{!assetDetail?.asset?.assetsdispose && assetDetail?.asset?.assetslease && (
													<div className='form-group row mb-3'>
														<div className='col-lg-2 d-flex align-items-center'>
															<h6 className='fw-normal'>Lease</h6>
														</div>
														<div className='col-lg-5'>
															<table
																className='table table-bordered table-view-detail table-sm mb-lg-0'
																role='presentation'
																aria-label='Asset Details'
															>
																<tbody>
																	<tr>
																		<td className='label_col w-50'>Leased To</td>
																		<td className='detail-column fw-bold w-50'>
																			{assetDetail?.asset?.statusType === 'lease' && assetDetail?.asset?.assetslease
																				? `${assetDetail?.asset?.assetslease?.user?.firstName} ${assetDetail?.asset?.assetslease?.user?.lastName}`
																				: ''}
																		</td>
																	</tr>
																</tbody>
															</table>
														</div>
														<div className='col-lg-5'>
															<table
																className='table table-bordered table-view-detail table-sm mb-lg-0'
																role='presentation'
																aria-label='Asset Details'
															>
																<tbody>
																	<tr>
																		<td className='label_col w-50'>Lease Begins</td>
																		<td className='detail-column fw-bold w-50'>
																			{dateFormat(
																				assetDetail?.asset?.assetslease?.leaseBegins,
																				currentUser?.dateFormat || defaultDateFormat
																			)}
																		</td>
																	</tr>
																</tbody>
															</table>
														</div>
														<div className='offset-lg-2 col-lg-5'>
															<table
																className='table table-bordered table-view-detail table-sm mb-lg-0'
																role='presentation'
																aria-label='Asset Details'
															>
																<tbody>
																	<tr>
																		<td className='label_col w-50'>Lease Notes</td>
																		<td className='detail_col fw-bold w-50'>
																			{assetDetail?.asset?.assetslease?.leaseNotes}
																		</td>
																	</tr>
																</tbody>
															</table>
														</div>
														<div className='col-lg-5'>
															<table
																className='table table-bordered table-view-detail table-sm mb-lg-0'
																role='presentation'
																aria-label='Asset Details'
															>
																<tbody>
																	<tr>
																		<td className='label_col w-50'>Lease Expires</td>
																		<td className='detail-column fw-bold w-50'>
																			{assetDetail?.asset?.assetslease?.leaseExpire !== null
																				? dateFormat(
																					assetDetail?.asset?.assetslease?.leaseExpire,
																					currentUser?.dateFormat || defaultDateFormat
																				)
																				: ''}
																		</td>
																	</tr>
																</tbody>
															</table>
														</div>
													</div>
												)}
												{!assetDetail?.asset?.assetsdispose && assetDetail?.asset?.assetstatus && (
													<div className='form-group row mb-3'>
														<div className='col-lg-2 d-flex align-items-center'>
															<h6 className='fw-normal'>Check out</h6>
														</div>
														<div className='col-lg-5'>
															<table
																className='table table-bordered table-view-detail table-sm mb-lg-0'
																role='presentation'
																aria-label='Asset Details'
															>
																<tbody>
																	<tr>
																		<td className='label_col w-50'>Assign To</td>
																		<td className='detail-column fw-bold w-50'>
																			{assetDetail?.asset?.assetstatus?.user ? (
																				<div>{`${assetDetail?.asset?.assetstatus?.user?.firstName || ''
																					} ${assetDetail?.asset?.assetstatus?.user?.lastName || ''
																					}`}</div>
																			) : assetDetail?.asset?.assetstatus !== null ? (
																				<div>{`${assetDetail?.asset?.site?.name || ''} / ${assetDetail?.asset?.location?.location || ''
																					}`}</div>
																			) : (
																				<div></div>
																			)}
																		</td>
																	</tr>
																</tbody>
															</table>
														</div>
														<div className='col-lg-5'>
															<table
																className='table table-bordered table-view-detail table-sm mb-lg-0'
																role='presentation'
																aria-label='Asset Details'
															>
																<tbody>
																	<tr>
																		<td className='label_col w-50'>Check-out Date</td>
																		<td className='detail-column fw-bold w-50'>
																			{assetDetail?.asset?.assetstatus?.checkoutDate &&
																				dateFormat(
																					assetDetail?.asset?.assetslease?.checkoutDate,
																					currentUser?.dateFormat || defaultDateFormat
																				)}
																		</td>
																	</tr>
																</tbody>
															</table>
														</div>
														<div className='offset-lg-2 col-lg-5'>
															<table
																className='table table-bordered table-view-detail table-sm mb-lg-0'
																role='presentation'
																aria-label='Asset Details'
															>
																<tbody>
																	<tr>
																		<td className='label_col w-50'>Check-out Notes</td>
																		<td className='detail_col fw-bold w-50'>
																			{assetDetail?.asset?.assetstatus?.notes}
																		</td>
																	</tr>
																</tbody>
															</table>
														</div>
														<div className='col-lg-5'>
															<table
																className='table table-bordered table-view-detail table-sm mb-lg-0'
																role='presentation'
																aria-label='Asset Details'
															>
																<tbody>
																	<tr>
																		<td className='label_col w-50'>Due Date</td>
																		<td className='detail-column fw-bold w-50'>
																			{assetDetail?.asset?.assetstatus?.dueDate &&
																				dateFormat(
																					assetDetail?.asset?.assetstatus?.dueDate,
																					currentUser?.dateFormat || defaultDateFormat
																				)}
																		</td>
																	</tr>
																</tbody>
															</table>
														</div>
													</div>
												)}
												{assetDetail?.asset?.assetsdispose && (
													<div className='form-group row mb-3'>
														<div className='col-lg-2 d-flex align-items-center'>
															<h6 className='fw-normal'>Dispose</h6>
														</div>
														<div className='col-lg-5'>
															<table
																className='table table-bordered table-view-detail table-sm mb-lg-0'
																role='presentation'
																aria-label='Asset Details'
															>
																<tbody>
																	<tr>
																		<td className='label_col w-50'>Date Disposed</td>
																		<td className='detail-column fw-bold'>
																			{dateFormat(
																				assetDetail?.asset?.assetsdispose?.dateDispose,
																				currentUser?.dateFormat || defaultDateFormat
																			)}
																		</td>
																	</tr>
																</tbody>
															</table>
														</div>
														<div className='col-lg-5'>
															<table
																className='table table-bordered table-view-detail table-sm mb-lg-0'
																role='presentation'
																aria-label='Asset Details'
															>
																<tbody>
																	<tr>
																		<td className='label_col w-50'>Notes</td>
																		<td className='detail-column fw-bold'>
																			{assetDetail?.asset?.assetsdispose?.notes}
																		</td>
																	</tr>
																</tbody>
															</table>
														</div>
														<div className='offset-lg-2 col-lg-5'>
															<table
																className='table table-bordered table-view-detail table-sm mb-lg-0'
																role='presentation'
																aria-label='Asset Details'
															>
																<tbody>
																	<tr>
																		<td className='label_col w-50'>Dispose To</td>
																		<td className='detail_col fw-bold'>
																			{assetDetail?.asset?.assetsdispose?.disposedTo}
																		</td>
																	</tr>
																</tbody>
															</table>
														</div>
													</div>
												)}
												<div className='form-group row mat-asset-detail-event'>
													<div className='col-lg-2 d-flex align-items-center'>
														<h6 className='fw-normal'>Creation</h6>
													</div>
													<div className='col-lg-5'>
														<table
															className='table table-bordered table-view-detail table-sm mb-lg-0'
															aria-label='Assets View'
															role='presentation'
														>
															{!(personUser || customerUser) && (
																<tbody>
																	<tr>
																		<td className='label_col w-50'>Date Created</td>
																		<td className='detail-column fw-bold w-50'>
																			{dateFormat(
																				assetDetail?.asset?.createdAt,
																				currentUser?.dateFormat || defaultDateFormat
																			)}
																		</td>
																	</tr>
																</tbody>
															)}
														</table>
													</div>
													<div className='col-lg-5'>
														<table
															className='table table-bordered table-view-detail table-sm mb-0'
															aria-label='Assets View'
															role='presentation'
														>
															<tbody>
																<tr>
																	<td className='label_col w-50'>Created by</td>
																	<td className='detail-column fw-bold w-50'>
																		{assetDetail?.asset?.user?.firstName +
																			' ' +
																			assetDetail?.asset?.user?.lastName}
																	</td>
																</tr>
															</tbody>
														</table>
													</div>
												</div>
											</div>
										</div>
										<div
											className='tab-pane fade'
											id='events'
											role='tabpanel'
											aria-labelledby='events-tab'
										>
											<div className='tab-content'>
												<h4 className='mb-5'>Events</h4>
												<div className='react-grid-card'>
													<div className='table-responsive pb-4'>
														<table
															id='kt_table_users'
															className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
															role='table'
														>
															<tbody role='rowgroup'>
																{assetDetail?.asset?.assetshistories?.length ? (
																	assetDetail?.asset?.assetshistories?.map(
																		(item: any, idx: any) => {
																			return (
																				<tr key={idx} role='row'>
																					{item?.changedTo === 'Available' && (
																						<>
																							<td role='cell'>
																								<span className='fw-bolder'>Return Date</span>
																								<div className='table-elipses'>
																									{item?.returnDate
																										? dateFormat(
																											item?.returnDate,
																											currentUser?.dateFormat || defaultDateFormat
																										)
																										: ''}
																								</div>
																							</td>
																							<td role='cell'>
																								<div className='table-elipses'>
																									<div
																										className={`d-inline-block align-items-center px-3 py-2 rounded ${item?.changedTo === 'Available'
																											? 'bg-light-green'
																											: ''
																											}`}
																									>
																										{item?.changedTo || ""}
																									</div>
																								</div>
																							</td>
																							<td role='cell' colSpan={3}>
																								<span className='fw-bolder'>Check-in Notes</span>
																								<div className='table-elipses'>
																									{item?.checkInNotes || ''}
																								</div>
																							</td>
																							<td role='cell' className='text-center d-none'>
																								<button
																									onClick={() => {
																										setCheckoutId(item?.id)
																										setCheckoutModal(true)
																										setAssetViewModal("checkIn")
																									}}
																									className='menu-link px-4 btn btn-light-secondary py-2 view-btn-style me-2'
																								>
																									Edit
																								</button>
																							</td>
																						</>
																					)}
																					{item?.changedTo === 'Checked out' && (
																						<>
																							<td role='cell'>
																								<div className='table-elipses'>
																									<span className='fw-bolder'>Check-out Date</span>
																									<div className='table-elipses'>
																										{item?.checkoutDate
																											? dateFormat(
																												item?.checkoutDate,
																												currentUser?.dateFormat || defaultDateFormat
																											)
																											: ''}
																									</div>
																								</div>
																							</td>
																							<td role='cell'>
																								<div className='table-elipses'>
																									<div
																										className={`d-inline-block align-items-center px-3 py-2 rounded ${item?.changedTo === 'Checked out'
																											? 'bg-light-blue'
																											: ''
																											}`}
																									>
																										{item?.changedTo}
																									</div>
																								</div>
																							</td>
																							<td>
																								<span className='fw-bolder'>Assigned To</span>
																								<div className='table-elipses'>
																									{item?.user ? (
																										<div>{`${item?.user?.firstName || ''} ${item?.user?.lastName || ''
																											}`}</div>
																									) : item !== null ? (
																										<div>{`${assetDetail?.asset?.site?.name || ''}${assetDetail?.asset?.location?.location ? ' /' + assetDetail.asset.location.location : ''}`}</div>
																									) : (
																										<div></div>
																									)}
																								</div>
																							</td>
																							<td role='cell'>
																								<span className='fw-bolder'>Due Date</span>
																								<div className='table-elipses'>
																									{item?.dueDate
																										? dateFormat(
																											item?.dueDate,
																											currentUser?.dateFormat || defaultDateFormat
																										)
																										: ''}
																								</div>
																							</td>
																							<td role='cell'>
																								<span className='fw-bolder'>Check-out Notes</span>
																								<div className='table-elipses'>
																									{item?.checkOutNotes || ''}
																								</div>
																							</td>
																							<td role='cell' className='text-center d-none'>
																								<button
																									onClick={() => {
																										setCheckoutId(item?.id)
																										setCheckoutModal(true)
																										setAssetViewModal("checkout")

																									}}
																									className='menu-link px-4 btn btn-light-secondary py-2 view-btn-style me-2'
																								>
																									Edit
																								</button>
																							</td>
																						</>
																					)}
																					{item?.changedTo === 'Leased' && (
																						<>
																							<td role='cell'>
																								<span className='fw-bolder'>Lease Begins</span>
																								<div className='table-elipses'>
																									{item?.leaseBegins
																										? dateFormat(
																											item?.leaseBegins,
																											currentUser?.dateFormat || defaultDateFormat
																										)
																										: ''}
																								</div>
																							</td>
																							<td role='cell'>
																								<div className='table-elipses'>
																									<div
																										className={`d-inline-block align-items-center px-3 py-2 rounded ${item?.changedTo === 'Leased'
																											? 'bg-light-blue'
																											: ''
																											}`}
																									>
																										{item?.changedTo}
																									</div>
																								</div>
																							</td>
																							<td>
																								<span className='fw-bolder'>Leasing Customer</span>
																								<div className='table-elipses'>
																									{item?.user ? (
																										<div>{`${item?.user?.firstName || ''} ${item?.user?.lastName || ''
																											}`}</div>
																									) : (
																										<div></div>
																									)}
																								</div>
																							</td>
																							<td role='cell'>
																								<span className='fw-bolder'>Lease Expires</span>
																								<div className='table-elipses'>
																									{item?.leaseExpire
																										? dateFormat(
																											item?.leaseExpire,
																											currentUser?.dateFormat || defaultDateFormat
																										)
																										: ''}
																								</div>
																							</td>
																							<td role='cell'>
																								<span className='fw-bolder'>Lease Notes</span>
																								<div className='table-elipses'>
																									{item?.leaseNotes || ''}
																								</div>
																							</td>
																							<td role='cell' className='text-center d-none'>
																								<button
																									onClick={() => {
																										setWarrantyId(item?.id)
																										setCheckoutModal(true)
																										setAssetViewModal("lease")
																									}}
																									className='menu-link px-4 btn btn-light-secondary py-2 view-btn-style me-2'
																								>
																									Edit
																								</button>
																							</td>
																						</>
																					)}
																					{item?.changedTo === 'Disposed' && (
																						<>
																							<td role='cell'>
																								<span className='fw-bolder'>Date Disposed</span>
																								<div className='table-elipses'>
																									{item?.dateDispose
																										? dateFormat(
																											item?.dateDispose,
																											currentUser?.dateFormat || defaultDateFormat
																										)
																										: ''}
																								</div>
																							</td>
																							<td role='cell'>
																								<div className='table-elipses'>
																									<div
																										className={`d-inline-block align-items-center px-3 py-2 rounded ${item?.changedTo === 'Disposed'
																											? 'bg-light-pink'
																											: ''
																											}`}
																									>
																										{item?.changedTo}
																									</div>
																								</div>
																							</td>
																							<td>
																								<span className='fw-bolder'>Dispose to</span>
																								<div className='table-elipses'>
																									{item?.user ? (
																										<div>{`${item?.user?.firstName || ''} ${item?.user?.lastName || ''
																											}`}</div>
																									) : (
																										<div></div>
																									)}
																								</div>
																							</td>
																							<td role='cell' colSpan={2}>
																								<span className='fw-bolder'>Notes</span>
																								<div className='table-elipses'>
																									{item?.disposeNotes || ''}
																								</div>
																							</td>
																							<td role='cell' className='text-center d-none'>
																								<button
																									onClick={() => {
																										setWarrantyId(item?.id)
																										setCheckoutModal(true)
																										setAssetViewModal("dispose")
																									}}
																									className='menu-link px-4 btn btn-light-secondary py-2 view-btn-style me-2'
																								>
																									Edit
																								</button>
																							</td>
																						</>
																					)}
																				</tr>
																			)
																		}
																	)
																) : (
																	<tr className='w-100 d-block'>
																		<td className='w-100 d-block'>
																			<div className='d-flex text-center w-100 align-items-center justify-content-center no-records fw-bold rounded'>
																				No records found
																			</div>
																		</td>
																	</tr>
																)}
															</tbody>
														</table>
													</div>
												</div>
											</div>
										</div>
										<div
											className='tab-pane fade'
											id='photos'
											role='tabpanel'
											aria-labelledby='photos-tab'
										>
											<div className='tab-content'>
												<h4 className='mb-5'>Photos</h4>
												{assetDetail?.asset?.photo ? (
													<div className='col-md-3 mb-4 mb-md-0'>
														<div className='card card-custom overlay d-inline-block'>
															<div className='card-body p-0'>
																<div className='overlay-wrapper asset-image'>
																	<img
																		id='assetDefaultImg'
																		src={
																			assetDetail?.asset?.photo && assetDetail?.asset?.photo !== ''
																				? toAbsoluteUrlImage(assetDetail?.asset?.photo)
																				: ''
																		}
																		crossOrigin='anonymous'
																		alt='Asset'
																		title='assets-img'
																		className='img-fluid center-block asset_img'
																	/>
																</div>
															</div>
														</div>
													</div>
												) : (
													<div className='d-flex text-center w-100 align-items-center justify-content-center no-records fw-bold rounded'>
														No records found
													</div>
												)}
											</div>
										</div>
										<div
											className='tab-pane fade'
											id='docs'
											role='tabpanel'
											aria-labelledby='docs-tab'
										>
											<div className='tab-content'>
												<h4 className='mb-5'>Documents</h4>
												<div className='lease-modal-table mb-3 table-responsive'>
													<table
														id='kt_table_users'
														className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
														role='table'
													>
														<thead>
															<tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
																<th role='columnheader'> Description </th>
																<th role='columnheader'> File Type </th>
																<th role='columnheader'> File Name </th>
																<th role='columnheader'> Upload Date </th>
																<th role='columnheader'> Upload By </th>
																<th role='columnheader'> Action </th>
															</tr>
														</thead>
														<tbody className='text-gray-600 fw-bold' role='rowgroup'>
															<tr role='row'>
																<td role='cell' colSpan={6} className='text-center'>
																	<div className='d-flex text-center w-100 align-items-center justify-content-center no-records fw-bold rounded'>
																		No records found
																	</div>
																</td>
															</tr>
														</tbody>
													</table>
												</div>
											</div>
										</div>
										<div
											className='tab-pane fade'
											id='warranty'
											role='tabpanel'
											aria-labelledby='warranty-tab'
										>
											<div className='tab-content'>
												{!(personUser || customerUser) && (
													<div className='d-flex justify-content-between align-items-center mb-3'>
														<h4>Warranty</h4>
														<button
															className='btn btn-light-primary m-1'
															onClick={() => {
																setWarrantyId(0)
																setShowWarrantyModal(true)
															}}
														>
															<i className='la la-plus fs-3'></i>Add{' '}
															<span className='d-sm-inline d-none'>New</span>
														</button>
													</div>
												)}
												<div className='table-responsive'>
													<table
														id='kt_table_users'
														className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
														role='table'
													>
														<thead>
															<tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
																<th role='columnheader'>Active</th>
																<th role='columnheader'>Expiration Date</th>
																<th role='columnheader'>Length</th>
																<th role='columnheader'>Note</th>
																<th role='columnheader' className='text-center'>
																	Action
																</th>
															</tr>
														</thead>
														<tbody className='text-gray-600 fw-bold' role='rowgroup'>
															{warrantyData?.warranty?.length ? (
																warrantyData?.warranty?.map((item: any, idx: any) => {
																	return (
																		<tr key={idx} role='row'>
																			<td role='cell'>
																				<div className='table-elipses'>
																					{item?.status ? (
																						<i className='fa fa-check text-success fs-4'></i>
																					) : (
																						<i className='fa fa-close text-danger fs-4'></i>
																					)}
																				</div>
																			</td>
																			<td role='cell'>
																				<div className='table-elipses'>
																					{item?.expirationDate
																						? dateFormat(
																							item?.expirationDate,
																							currentUser?.dateFormat || defaultDateFormat
																						)
																						: ''}
																				</div>
																			</td>
																			<td role='cell'>
																				<div>{item?.duration}</div>
																			</td>
																			<td role='cell'>
																				<div className='table-elipses'>{item?.description}</div>
																			</td>
																			<td role='cell' className='text-center'>
																				{!(personUser || customerUser) && (
																					<button
																						onClick={() => {
																							setWarrantyId(item?.id)
																							setShowWarrantyModal(true)
																						}}
																						className='menu-link px-4 btn btn-light-secondary py-2 view-btn-style me-2'
																					>
																						Edit
																					</button>
																				)}
																				<button
																					onClick={() => {
																						setWarrantyId(item?.id)
																						setShowView(true)
																					}}
																					className='menu-link px-4 btn btn-light-secondary py-2 view-btn-style'
																				>
																					View
																				</button>
																			</td>
																		</tr>
																	)
																})
															) : (
																<tr>
																	<td colSpan={8}>
																		<div className='d-flex text-center w-100 align-items-center justify-content-center no-records fw-bold rounded'>
																			No records found
																		</div>
																	</td>
																</tr>
															)}
														</tbody>
													</table>
												</div>
											</div>
										</div>
										<div
											className='tab-pane fade'
											id='linking'
											role='tabpanel'
											aria-labelledby='linking-tab'
										>
											<div className='tab-content'>
												<h4 className='mb-5'>Linking</h4>
												<div className='d-flex text-center w-100 align-items-center justify-content-center no-records fw-bold rounded'>
													No records found
												</div>
											</div>
										</div>
										<div
											className='tab-pane fade'
											id='maint'
											role='tabpanel'
											aria-labelledby='maint-tab'
										>
											<div className='tab-content'>
												<h4 className='mb-5'>Maintenance</h4>
												<div className='table-responsive'>
													<table
														id='kt_table_users'
														className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
														role='table'
													>
														<thead>
															<tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
																<th role='columnheader'> Schedule Date </th>
																<th role='columnheader'> Title </th>
																<th role='columnheader'> Maintenance By </th>
																<th role='columnheader'> Status </th>
																<th role='columnheader'> Completion Date </th>
																<th role='columnheader'> Maintenance Cost </th>
																<th role='columnheader'> Details </th>
															</tr>
														</thead>

														<tbody className='text-gray-600 fw-bold' role='rowgroup'>
															{assetDetail?.asset?.assetsmaintenances?.length ? (
																assetDetail?.asset?.assetsmaintenances?.map(
																	(item: any, idx: any) => {
																		return (
																			<tr key={idx} role='row'>
																				<td role='cell'>
																					<div className='table-elipses'>
																						{item?.maintenanceDueDate
																							? dateFormat(
																								item?.maintenanceDueDate,
																								currentUser?.dateFormat || defaultDateFormat
																							)
																							: ''}
																					</div>
																				</td>
																				<td role='cell'>
																					<div className='table-elipses'>
																						{item?.maintenanceTitle}
																					</div>
																				</td>
																				<td role='cell'>
																					<div className='table-elipses'>{item?.maintenanceBy}</div>
																				</td>
																				<td role='cell'>
																					<div className='table-elipses'>
																						{item?.maintenanceStatus}
																					</div>
																				</td>
																				<td role='cell'>
																					<div className='table-elipses'>
																						{item?.maintenanceDateCompleted
																							? dateFormat(
																								item?.maintenanceDateCompleted,
																								currentUser?.dateFormat || defaultDateFormat
																							)
																							: ''}
																					</div>
																				</td>
																				<td role='cell'>
																					<div className='table-elipses'>
																						{item?.maintenanceCost}
																					</div>
																				</td>
																				<td role='cell'>
																					<div className='table-elipses'>
																						{item?.maintenanceDetails}
																					</div>
																				</td>
																			</tr>
																		)
																	}
																)
															) : (
																<tr>
																	<td colSpan={8}>
																		<div className='d-flex text-center w-100 align-items-center justify-content-center no-records fw-bold rounded'>
																			No records found
																		</div>
																	</td>
																</tr>
															)}
														</tbody>
													</table>
												</div>
											</div>
										</div>
										<div
											className='tab-pane fade'
											id='reserve'
											role='tabpanel'
											aria-labelledby='reserve-tab'
										>
											<div className='tab-content'>
												<h4 className='mb-5'>Reserve</h4>
												<div className='table-responsive'>
													<table
														id='kt_table_users'
														className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
														role='table'
													>
														<thead>
															<tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
																<th role='columnheader'> Reserved For </th>
																<th role='columnheader'> Reserved By </th>
																<th role='columnheader'> Start Date </th>
																<th role='columnheader'> End Date </th>
															</tr>
														</thead>
														<tbody className='text-gray-600 fw-bold' role='rowgroup'>
															{assetDetail?.asset?.reserveassets?.length ? (
																assetDetail?.asset?.reserveassets?.map((item: any, idx: any) => {
																	return (
																		<tr key={idx} role='row'>
																			<td role='cell'>
																				<div className='table-elipses'>{item?.reserveFor}</div>
																			</td>
																			<td role='cell'>
																				<div className='table-elipses'>
																					{item?.customer
																						? item?.customer?.fullName
																						: item?.person
																							? item?.person?.fullName
																							: ''}
																					{item?.site ? item?.site?.name : ''}
																					{item?.location ? ' / ' + item?.location?.location : ''}
																				</div>
																			</td>
																			<td role='cell'>
																				<div className='table-elipses'>
																					{item?.startDate
																						? dateFormat(
																							item?.startDate,
																							currentUser?.dateFormat || defaultDateFormat
																						)
																						: ''}
																				</div>
																			</td>
																			<td role='cell'>
																				<div className='table-elipses'>
																					{item?.endDate
																						? dateFormat(
																							item?.endDate,
																							currentUser?.dateFormat || defaultDateFormat
																						)
																						: ''}
																				</div>
																			</td>
																		</tr>
																	)
																})
															) : (
																<tr>
																	<td colSpan={8}>
																		<div className='d-flex text-center w-100 align-items-center justify-content-center no-records fw-bold rounded'>
																			No records found
																		</div>
																	</td>
																</tr>
															)}
														</tbody>
													</table>
												</div>
											</div>
										</div>
										<div
											className='tab-pane fade'
											id='audit'
											role='tabpanel'
											aria-labelledby='audit-tab'
										>
											<div className='tab-content'>
												<h4 className='mb-5'>Audit</h4>
												<div className='lease-modal-table mb-3 table-responsive'>
													<table
														id='kt_table_users'
														className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
														role='table'
													>
														<thead>
															<tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
																<th role='columnheader'> Audit Name </th>
																<th role='columnheader'> Last Audit By </th>
																<th role='columnheader'> Audit Date </th>
																<th role='columnheader'> Site </th>
																<th role='columnheader'> Location </th>
																<th role='columnheader'> Notes </th>
																<th role='columnheader'> Map </th>
																<th role='columnheader'> Action </th>
															</tr>
														</thead>
														<tbody className='text-gray-600 fw-bold' role='rowgroup'>
															<tr role='row'>
																<td role='cell' colSpan={8} className='text-center'>
																	<div className='d-flex text-center w-100 align-items-center justify-content-center no-records fw-bold rounded'>
																		No records found
																	</div>
																</td>
															</tr>
														</tbody>
													</table>
												</div>
											</div>
										</div>
										<div
											className='tab-pane fade'
											id='history'
											role='tabpanel'
											aria-labelledby='history-tab'
										>
											<div className='tab-content'>
												<h4 className='mb-5'>History</h4>
												<div className='table-responsive'>
													<table
														id='kt_table_users'
														className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
														role='table'
													>
														<thead>
															<tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
																<th role='columnheader'> Date </th>
																<th role='columnheader'> Event </th>
																<th role='columnheader'> Field </th>
																<th role='columnheader'> Changed from </th>
																<th role='columnheader'> Changed to </th>
																<th role='columnheader'> Action by </th>
															</tr>
														</thead>

														<tbody className='text-gray-600 fw-bold' role='rowgroup'>
															{assetDetail?.asset?.assetshistories?.length ? (
																assetDetail?.asset?.assetshistories?.map((item: any, idx: any) => {
																	return (
																		<tr key={idx} role='row'>
																			<td role='cell'>
																				<div className='table-elipses'>
																					{item?.date
																						? dateFormat(
																							item?.date,
																							currentUser?.dateFormat || defaultDateFormat
																						)
																						: ''}
																				</div>
																			</td>
																			<td role='cell'>
																				<div className='table-elipses'>{item?.event}</div>
																			</td>
																			<td role='cell'>
																				<div className='table-elipses'>{item?.field}</div>
																			</td>
																			<td role='cell'>
																				<div className={`table-elipses d-inline-block align-items-center px-3 py-2 rounded ${item?.changedFrom === 'available' ? 'bg-light-green' :
																						item?.changedFrom === 'lease' ? 'bg-light-blue' :
																						item?.changedFrom === 'dispose' ? 'bg-light-pink' :
																						item?.changedFrom === 'check_out' ? 'bg-light-blue' : ''
																					}`}>
																					{item?.changedFrom}
																				</div>
																			</td>

																		
																			<td role='cell'>
																				<div className={`table-elipses d-inline-block align-items-center px-3 py-2 rounded ${item?.changedTo === 'Available' ? 'bg-light-green' :
																						item?.changedTo === 'Leased' ? 'bg-light-blue' :
																						item?.changedTo === 'Disposed' ? 'bg-light-pink' :
																						item?.changedTo === 'Checked out' ? 'bg-light-blue' : ''
																					}`}>
																					{item?.changedTo}
																				</div>
																			</td>


																			<td role='cell'>
																				<div className='table-elipses'>{item?.actionBy}</div>
																			</td>

																		</tr>
																	)
																})
															) : (
																<tr>
																	<td colSpan={8}>
																		<div className='d-flex text-center w-100 align-items-center justify-content-center no-records fw-bold rounded'>
																			No records found
																		</div>
																	</td>
																</tr>
															)}
														</tbody>
													</table>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<WarrantyModal
						show={warrantyModal}
						handleClose={handleCloseWarrantyModal}
						warrantyId={warrantyId}
						assetId={id}
						setWarrantyId={setWarrantyId}
						assetUserId={assetDetail?.asset?.userId}
						setDeletedId={setDeletedId}
						setConfirmDelete={setConfirmDelete}
					/>
					<CheckedoutModal
						show={checkoutModal}
						assetViewModal={assetViewModal}
						handleClose={handleCloseCheckoutModal}
						checkoutId={checkoutId}
						assetId={id}
						setCheckoutId={setCheckoutId}
						assetUserId={assetDetail?.asset?.userId}
						setDeletedId={setDeletedId}
						setConfirmDelete={setConfirmDelete}
					/>
					<ViewAssetModal
						show={showView}
						handleClose={handleCloseViewWarrantyModal}
						warrantyId={warrantyId}
					/>
					<DeleteConfirmationModal
						isDeleted={isDeleted}
						handleDelete={handleDelete}
						handleClose={handleConfirmDelete}
						show={confirmDelete}
					/>
				</div>
			) : null}
			<Loader loading={loading} />
		</>
	)
}

export default AssetDetails