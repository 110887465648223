import {
  FC,
  useState,
  useEffect,
  createContext,
  useContext,
  useRef,
  Dispatch,
  SetStateAction,
} from 'react'
import { LayoutSplashScreen } from '../../../../_metronic/layout/core'
import { AuthModel, UserModel } from './_models'
import * as authHelper from './AuthHelpers'
import { getUserByToken, logoutUser } from './_requests'
import { WithChildren } from '../../../../_metronic/helpers'
import { useLocation, useNavigate } from 'react-router-dom'
import { verifyEmail } from './_requests'
import { toast } from 'react-toastify'

type AuthContextProps = {
  auth: AuthModel | undefined
  saveAuth: (auth: AuthModel | undefined) => void
  currentUser: UserModel | undefined
  setCurrentUser: Dispatch<SetStateAction<UserModel | undefined>>
  logout: () => void
}

const initAuthContextPropsState = {
  auth: authHelper.getAuth(),
  saveAuth: () => { },
  currentUser: undefined,
  setCurrentUser: () => { },
  logout: () => { },
}

const AuthContext = createContext<AuthContextProps>(initAuthContextPropsState)

const useAuth = () => {
  return useContext(AuthContext)
}

const AuthProvider: FC<WithChildren> = ({ children }) => {
  const [auth, setAuth] = useState<AuthModel | undefined>(authHelper.getAuth())
  const [currentUser, setCurrentUser] = useState<UserModel | undefined>()

  const saveAuth = (auth: AuthModel | undefined) => {
    setAuth(auth)
    if (auth) {
      authHelper.setAuth(auth)
    } else {
      authHelper.removeAuth()
    }
  }

  const logout = async () => {
    try {
      if (auth && auth?.token) {
        const response = await logoutUser(auth?.token)
        toast.success(response?.data?.message)
      }
      saveAuth(undefined)
      setCurrentUser(undefined)
    } catch (err: any) {
      if (err?.response?.data?.status === 401) {
        saveAuth(undefined)
        setCurrentUser(undefined)
        toast.error(err?.response?.data?.message)
      }
    }
  }

  return (
    <AuthContext.Provider value={{ auth, saveAuth, currentUser, setCurrentUser, logout }}>
      {children}
    </AuthContext.Provider>
  )
}

const AuthInit: FC<WithChildren> = ({ children }) => {
  const { saveAuth, auth, logout, setCurrentUser } = useAuth()
  const didRequest = useRef(false)
  const [showSplashScreen, setShowSplashScreen] = useState(true)
  const navigate = useNavigate()
  const location = useLocation()
  const params = new URLSearchParams(location.search)
  const emailToken = params.get('token')
  const socialToken = params.get('social_token')

  useEffect(() => {
    if (emailToken) {
      emailVerification(emailToken)
      setShowSplashScreen(false)
    } else if (socialToken) {
      saveAuth({ token: socialToken })
      requestUser(socialToken)
    } else {
      if (auth && auth.token) {
        requestUser(auth.token)
      } else {
        logout()
        setShowSplashScreen(false)
      }
    }
  }, [])

  const requestUser = async (apiToken: string) => {
    try {
      if (!didRequest.current) {
        const {
          data: { user, infoNote },
        } = await getUserByToken(apiToken)
        if (user) {
          setCurrentUser(user)
          if (infoNote) {
            toast.info(infoNote)
          }
        }
      }
    } catch (error) {
      saveAuth(undefined)
      setCurrentUser(undefined)
      console.error(error)
    } finally {
      setShowSplashScreen(false)
    }

    return () => (didRequest.current = true)
  }

  const emailVerification = async (token: string | undefined): Promise<void> => {
    try {
      const response = await verifyEmail(token)
      if (response?.data?.success) {
        toast.success(response?.data?.message)
        navigate('/auth/login', { replace: true })
      }
    } catch (error: any) {
      if (!error?.response?.data?.success) {
        toast.error(error?.response?.data?.message)
      }
      navigate('/auth/login', { replace: true })
    }
  }

  return showSplashScreen ? <LayoutSplashScreen /> : <>{children}</>
}

export { AuthProvider, AuthInit, useAuth }
