import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'
import { useFormik } from 'formik'
import { useUpdateTwoFactorMutation } from 'App/Modules/Services/Profile'
import { toast } from 'react-toastify'
import Loader from 'App/BeatLoader'

const TwoFactorSchema = Yup.object().shape({
    emailOTP: Yup.boolean().required(),
    phoneOTP: Yup.boolean().required(),
})

const initialValues = {
    emailOTP: false,
    phoneOTP: false,
}

const TwoFactorAuth = ({ userData }) => {
    const [updateTwoFactor, { isLoading }] = useUpdateTwoFactorMutation()

    useEffect(() => {
        if (userData) {
            formik.setValues({
                emailOTP: userData?.user?.emailOTP,
                phoneOTP: userData?.user?.phoneOTP,
            })
        }
    }, [])

    const formik = useFormik({
        initialValues,
        validationSchema: TwoFactorSchema,
        onSubmit: async (values) => {
            try {
                const data = await updateTwoFactor(values).unwrap()
            } catch (error: any) {
                toast.dismiss()
                toast.error(error)
            }
        },
    })

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>, formik: any, bool: boolean) => {
        formik.setFieldValue(e.target.name, bool)
        formik.handleSubmit()
    }

    return (
        <>
            <div className='card mb-5 mb-xl-10 position-relative'>
                <div className='card-header border-0'>
                    <div className='card-title m-0'>
                        <h3 className='fw-bolder m-0'>
                            <span className='me-2 align-middle'>
                                <i className='las la-check-circle fs-1' aria-hidden='true'></i>
                            </span>
                            <span className='card-label fw-bold fs-3 mb-1'>Two Factor Authentication</span>
                        </h3>
                    </div>
                </div>
                <div id='kt_two-factor-authentication' className='collapse show'>
                    <form onSubmit={formik.handleSubmit}>
                        <div className='card-body border-top p-lg-9 p-md-7 p-6'>
                            <p>
                                This operation will increase your account security by adding one more level of
                                security.
                            </p>
                            <div className='row mb-1'>
                                <div className='col-lg-3'>
                                    <p className='mb-0 fw-bolder col-form-label'>Send OTP to Email:</p>
                                </div>
                                <div className='col-lg-9'>
                                    <div className='radio-inline standard_radio_can_hide hasLicense_radio col-form-label'>
                                        <label className='radio mb-0 p_16' htmlFor='emailOTPYes'>
                                            <input
                                                id='emailOTPYes'
                                                name='emailOTP'
                                                type='radio'
                                                className='m_5 custom-form-check-input form-check-input sig_input'
                                                checked={formik?.values?.emailOTP === true}
                                                onChange={(e) => handleChange(e, formik, true)}
                                            />
                                            Yes
                                        </label>
                                        <label className='radio mb-0' htmlFor='emailOTPNo'>
                                            <input
                                                id='emailOTPNo'
                                                name='emailOTP'
                                                type='radio'
                                                className='m_5 custom-form-check-input form-check-input sig_input'
                                                checked={formik?.values?.emailOTP === false}
                                                onChange={(e) => handleChange(e, formik, false)}
                                            />
                                            No
                                        </label>
                                    </div>
                                    {formik?.values?.emailOTP && (
                                        <p>You will receive an OTP at your registered email address.</p>
                                    )}
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
                <Loader loading={isLoading} />
            </div>
        </>


    )
}

export { TwoFactorAuth }
