import { PageTitle } from "_metronic/layout/core"
import AssetsListing from "./Components/AssetsListing"
import { useIntl } from "react-intl"

const Assets = () => {
	const intl = useIntl()
	return (
		<>
			<PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'List of Assets' })}</PageTitle>
			<AssetsListing />
		</>
	)
}

export default Assets