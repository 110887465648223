import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { getAuth } from '../Auth'

const API_URL = process.env.REACT_APP_API_URL

export const headersSplitApi = createApi({
    baseQuery: fetchBaseQuery({ 
		baseUrl: `${API_URL}/`,
		prepareHeaders: (headers) => {
			const auth = getAuth()
			if (auth && auth?.token) {
				headers.set('Authorization', `Bearer ${auth?.token}`);
			}
			return headers;
		},
	 }),
  endpoints: () => ({}),
})