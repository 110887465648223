import React from 'react'

function EmailSubject() {
    return (
        <div>
            <div className="row mb-6">
                <div className="col-lg-2 col-form-label fw-bold fs-6">Email Subject</div>
                <div className="col-lg-10 fv-row">
                    <p className='mb-1 text-muted'> <i className='la la-info-circle fs-5 text-muted'></i> Text that will appear in the email subject line.</p>
                    <input type="text" className="form-control form-control-solid" name="emailSubject" />
                </div>
            </div>
        </div>
    )
}

export default EmailSubject