import { useAuth } from 'App/Modules/Auth'
import { useGetSinglePersonQuery } from 'App/Modules/Services/Persons'
import { Images } from '_metronic/assets/Assets'
import { toAbsoluteUrlImage } from '_metronic/helpers'
import { dateFormat, defaultDateFormat } from 'config'
import { useState } from 'react'
interface ColumnDataProps {
  column: string
  assetsData?: any
  currencyLogo?: any
}
const date = new Date()
export const ColumnData: React.FC<ColumnDataProps> = ({ column, assetsData, currencyLogo }) => {
  const { currentUser } = useAuth()
  const renderData = () => {
    switch (column) {
      case 'Asset Photo':
        return assetsData.userAssets.map((asset: any, index: number) => (
          <div className='d-inline align-items-center asset-img-table'>
            {asset.photo ? (
              <img
                src={toAbsoluteUrlImage(asset.photo)}
                alt='asset'
                className='img-fluid'
                crossOrigin='anonymous'
              />
            ) : (
              <img src={Images.AssetsPlaceholder} alt='asset' className='img-fluid' />
            )}
          </div>
        ))
      case 'Asset Tag ID':
        return assetsData.userAssets.map((asset: any, index: number) => (
          <span key={index}>{asset.assetTagId}</span>
        ))
      case 'Description':
        return assetsData.userAssets.map((asset: any, index: number) => (
          <span key={index}>{asset.description}</span>
        ))
      case 'Brand':
        return assetsData.userAssets.map((asset: any, index: number) => (
          <span key={index}>{asset.brand}</span>
        ))
      case 'Purchased Date':
        return assetsData.userAssets.map((asset: any, index: number) => (
          <span key={index}>
            {asset?.purchasedDate
              ? dateFormat(asset?.purchasedDate, currentUser?.dateFormat || defaultDateFormat)
              : ''}
          </span>
        ))
      case 'Cost':
        return assetsData.userAssets.map((asset: any, index: number) => (
          <span key={index}>{asset?.cost ? currencyLogo + ' ' + asset?.cost : ''}</span>
        ))
      case 'Status':
        return assetsData.userAssets.map((asset: any, index: number) => (
          <span key={index}>
            <div
              className={`d-inline align-items-center px-3 py-2 rounded ${asset?.statusType === 'available'
                  ? 'bg-light-green'
                  : asset?.statusType === 'lease'
                    ? 'bg-light-blue'
                    : asset?.statusType === 'dispose'
                      ? 'bg-light-pink'
                      : asset?.statusType === 'check_out'
                        ? 'bg-light-blue'
                        : ''
                }`}
            >
              {asset?.statusType === 'check_out'
                ? 'Checked Out'
                : asset?.statusType === 'lease'
                  ? 'Leased'
                  : asset?.statusType === 'dispose'
                    ? 'Disposed'
                    : asset?.statusType === 'available'
                      ? 'Available'
                      : asset?.statusType}
            </div>
          </span>
        ))
      case 'Created By':
        return assetsData?.userAssets?.map((asset: any, index: any) => {
          const { user } = asset
          const fullName = user ? `${user.firstName} ${user.lastName}` : ''
          return <span key={index}>{fullName}</span>
        })
      case 'Date Created':
        return assetsData.userAssets.map((asset: any, index: number) => (
          <span key={index}>
            {asset?.createdAt
              ? dateFormat(asset?.createdAt, currentUser?.dateFormat || defaultDateFormat)
              : ''}
          </span>
        ))
      case 'Model':
        return assetsData.userAssets.map((asset: any, index: number) => (
          <span key={index}>{asset?.model}</span>
        ))
      case 'Purchased From':
        return assetsData.userAssets.map((asset: any, index: number) => (
          <span key={index}>{asset?.purchasedFrom}</span>
        ))
      case 'Serial No':
        return assetsData.userAssets.map((asset: any, index: number) => (
          <span key={index}>{asset?.serialNo}</span>
        ))
      case 'Reservation':
        return assetsData.userAssets.map((asset: any, index: number) => {
          const currentDate = new Date()
          return (
            <>
              {asset?.reserveassets.map((reserveAsset, index) => {
                const endDate = new Date(reserveAsset.endDate)
                if (endDate >= currentDate) {
                  return (
                    <span key={index}>
                      Res.{' '}
                      {dateFormat(
                        reserveAsset.endDate,
                        currentUser?.dateFormat || defaultDateFormat
                      )}
                    </span>
                  )
                } else {
                  return null
                }
              })}
            </>
          )
        })
      case 'Category':
        return assetsData.userAssets.map((asset: any, index: number) => (
          <span key={index}>{asset?.category?.category}</span>
        ))
      case 'Department':
        return assetsData.userAssets.map((asset: any, index: number) => (
          <span key={index}>{asset?.department?.department}</span>
        ))
      case 'Location':
        return assetsData.userAssets.map((asset: any, index: number) => (
          <span key={index}>{asset?.location?.location}</span>
        ))
      case 'Site':
        return assetsData.userAssets.map((asset: any, index: number) => (
          <span key={index}>{asset?.site?.name}</span>
        ))
      case 'Assigned To':
        return assetsData.userAssets.map((asset: any, index: number) =>
          asset?.assetstatus?.user ? (
            <span key={index}>
              {`${asset?.assetstatus?.user?.firstName || ''} ${asset?.assetstatus?.user?.lastName || ''
                }`}
            </span>
          ) : asset?.assetstatus !== null ? (
            <span key={index}>
              {`${asset?.site?.name || ''} ${asset?.site?.name && asset.location?.location ? '/' : ''
                } ${asset?.location?.location || ''}`}
            </span>
          ) : asset?.reserveassets?.length > 0 ? (
            asset.reserveassets[0].reserveFor === 'person' ? (
              <span>{`${asset?.reserveassets[0]?.user?.firstName || ''} ${asset?.reserveassets[0]?.user?.lastName || ''
                }`}</span>
            ) : asset.reserveassets[0].reserveFor === 'customer' ? (
              <span>{`${asset?.reserveassets[0]?.user?.firstName || ''} ${asset?.reserveassets[0]?.user?.lastName || ''
                }`}</span>
            ) : (
              <span>{`${asset?.reserveassets[0]?.site?.name || ''} ${asset?.reserveassets[0]?.location?.location || ''
                }`}</span>
            )
          ) : (
            <span></span>
          )
        )
      case 'Event Date':
        return assetsData.userAssets.map((asset: any, index: number) => (
          <span key={index}>
            {asset?.statusType === 'check_out'
              ? asset?.assetstatus?.checkoutDate &&
              dateFormat(
                asset?.assetstatus?.checkoutDate,
                currentUser?.dateFormat || defaultDateFormat
              )
              : asset?.statusType === 'lease'
                ? asset?.assetslease?.leaseBegins &&
                dateFormat(
                  asset?.assetslease?.leaseBegins,
                  currentUser?.dateFormat || defaultDateFormat
                )
                : asset?.statusType === 'dispose'
                  ? asset?.assetsdispose?.dateDispose &&
                  dateFormat(
                    asset?.assetsdispose?.dateDispose,
                    currentUser?.dateFormat || defaultDateFormat
                  )
                  : null}
          </span>
        ))
      case 'Event Due Date':
        return assetsData.userAssets.map((asset: any, index: number) => (
          <span key={index}>
            {asset?.statusType === 'check_out'
              ? asset?.assetstatus?.dueDate &&
              dateFormat(
                asset?.assetstatus?.dueDate,
                currentUser?.dateFormat || defaultDateFormat
              )
              : asset?.statusType === 'lease'
                ? asset?.assetslease?.leaseExpire &&
                dateFormat(
                  asset?.assetslease?.leaseExpire,
                  currentUser?.dateFormat || defaultDateFormat
                )
                : null}
          </span>
        ))
      case 'Event Notes':
        return assetsData.userAssets.map((asset: any, index: number) => (
          <span key={index}>
            {asset?.statusType === 'check_out' ? asset?.assetstatus?.notes || null : null}
          </span>
        ))
      case 'Leased To':
        return assetsData.userAssets.map((asset: any, index: number) => (
          <span key={index}>{`${asset?.assetslease?.user?.firstName || ''} ${asset?.assetslease?.user?.lastName || ''
            }`}</span>
        ))
      default:
        return ''
    }
  }

  return <>{renderData()}</>
}

export const SampleColumnData: React.FC<ColumnDataProps> = ({ column }) => {
  const { currentUser } = useAuth()
  const renderData = () => {
    switch (column) {
      case 'Asset Photo':
        return 'Sample Photo'
      case 'Asset Tag ID':
        return 'Sample TagId'
      case 'Description':
        return 'Sample Description'
      case 'Brand':
        return 'Sample Brand'
      case 'Purchased Date':
        return dateFormat(date, currentUser?.dateFormat || defaultDateFormat)
      case 'Cost':
        return 'RS 1000'
      case 'Status':
        return 'Sample Status'
      case 'Created By':
        return 'Sample userName'
      case 'Date_Created':
        return dateFormat(date, currentUser?.dateFormat || defaultDateFormat)
      case 'Model':
        return 'Sample Model'
      case 'Purchased From':
        return 'Sample Purchased Location'
      case 'Serial No':
        return 'Sr-12'
      case 'Date Created':
        return dateFormat(date, currentUser?.dateFormat || defaultDateFormat)
      case 'Reservation':
        return 'Sample Reservation'
      case 'Category':
        return 'Sample Category'
      case 'Department':
        return 'Sample Department'
      case 'Location':
        return 'Sample Location'
      case 'Site':
        return 'Sample Site'
      case 'Assigned To':
        return 'Sample Assigned'
      case 'Event Date':
        return dateFormat(date, currentUser?.dateFormat || defaultDateFormat)
      case 'Event Due Date':
        return dateFormat(date, currentUser?.dateFormat || defaultDateFormat)
      case 'Event Notes':
        return 'Sample Event Notes'
      case 'Leased To':
        return 'Sample Customer Name'
      default:
        return ''
    }
  }

  return <>{renderData()}</>
}
