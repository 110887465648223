import React, { useState } from 'react';
import AlertsSettingsData from '../AlertsSettingsData'; 
import { PageTitle } from '_metronic/layout/core';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';
import EmailAlerts from '../EmailAlerts';
import LeadTime from '../LeadTime';
import OverdueTime from '../OverdueTime';

function SetupAlerts() {
    const intl = useIntl();
    const [alertsSettings, setAlertsSettings] = useState(AlertsSettingsData);

    const handleCheckboxChange = (index) => {
        const newSettings = [...alertsSettings];
        newSettings[index].checkbox = !newSettings[index].checkbox;
        setAlertsSettings(newSettings);
    };

    return (
        <>
            <PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'Setup Alerts' })}</PageTitle>
            <div className='card mb-5 mb-xl-10'>
                <div className='card-header border-0'>
                    <div className='card-title m-0 '>
                        <h3 className='fw-bolder m-0'>
                            <span className='me-2 align-middle'>
                                <i className='la la-wrench fs-1'></i>
                            </span> Setup Alerts
                        </h3>
                    </div>
                </div>
                <div id='kt_account_profile_details' className='collapse show'>
                    <div className='form'>
                        <div className='card-body border-top'>
                            <div className='pb-5 mb-5'>
                                <p className="fw-bolder m-0 mb-4">We're on guard so you don't have to be. Check boxes next to the items you want to be notified about. Decide when you want to be alerted, and choose how many days in advance you'll receive the alert. Alerts will appear in the left navigation menu and in the calendar on the dashboard.</p>
                            </div>
                            <ul className='list-unstyled mb-5'>
                                {alertsSettings.map((item, index) => (
                                    <li key={index} className='mb-6'> 
                                        <div className='d-flex'>
                                            <input 
                                                className='form-check-input custom-form-check-input' 
                                                type='checkbox' 
                                                checked={item.checkbox}
                                                onChange={() => handleCheckboxChange(index)}
                                            />
                                            <div className='ps-5'>
                                                <strong>{item.heading}</strong>
                                                <p>{item.text}</p>
                                                {item.leadTime && 
                                                    <p>
                                                        <LeadTime 
                                                            time={item.leadTime?.defaultTime} 
                                                            text={item.leadTime?.text}
                                                        />
                                                    </p>
                                                }
                                                {item.overdueTime && 
                                                    <p>
                                                        <LeadTime 
                                                            time={item.overdueTime?.defaultTime} 
                                                            text={item.overdueTime?.text}
                                                        />
                                                    </p>
                                                }
                                                {item.checkbox ? 
                                                <EmailAlerts 
                                                    frequencyAllDays={item.FrequencyAllDays} 
                                                    stopEmailPara_1={item.stopEmails.stopEmailPara_1} 
                                                    // stopEmailPara_2={item.stopEmails.stopEmailPara_2} 
                                                    // stopEmailDays={item.stopEmails}
                                                /> 
                                                : ""}
                                            </div>
                                        </div>
                                    </li>
                                ))}
                            </ul>
                            
                            <div className="card-footer  d-flex align-items-center justify-content-end pt-6 p-0">
                                <Link to={'/'} className="btn btn-light-secondary main-btn-style">Cancel</Link>
                                <button type="button" className="btn btn-primary ms-2 main-btn-style">Save</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default SetupAlerts;
