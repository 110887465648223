import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { assetsApi } from './Assets'
import { getAuth } from '../Auth'
import { dashboardApi } from './Dashboard'
import { toast } from 'react-toastify'

const API_URL = process.env.REACT_APP_API_URL

export const personsApi = createApi({
  reducerPath: 'personsApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${API_URL}/person/`,
    prepareHeaders: (headers) => {
      const auth = getAuth()
      if (auth && auth?.token) {
        headers.set('Authorization', `Bearer ${auth?.token}`)
      }
      return headers
    },
  }),
  tagTypes: ['Persons'],

  endpoints: (build) => ({
    getAllPersons: build.query<any, any>({
      query: ({ search, searchField, limit = 10, page = 1 }) => ({
        url: 'get',
        params: { search, searchField, limit, page },
        method: 'GET',
      }),
      providesTags: ['Persons'],
    }),
    getPersons: build.query<any, any>({
      query: () => 'get-all',
      providesTags: ['Persons'],
    }),
    getAssignAssets: build.query<any, any>({
      query: () => ({
        url: `assign-assets`,
        method: 'GET',
      }),
      providesTags: ['Persons'],
    }),
    getSinglePerson: build.query<any, any>({
      query: ({ id }) => ({
        url: `get-person/${id}`,
        method: 'GET',
      }),
      providesTags: ['Persons'],
    }),
    addPerson: build.mutation<any, any>({
      query: (body) => ({
        url: `add`,
        method: 'POST',
        body,
      }),
      onQueryStarted: (arg, api) => {
        api.queryFulfilled
          .then(() => {
            api.dispatch(dashboardApi.util.invalidateTags(['Dashboard']))
          })
          .catch((err) => toast.error(err))
      },
      invalidatesTags: () => [{ type: 'Persons' }],
    }),
    updatePerson: build.mutation<any, { id: any; values: any }>({
      query: ({ id, values }) => ({
        url: `update/${id}`,
        method: 'PUT',
        body: values,
      }),
      invalidatesTags: (result, error, { id }) => [{ type: 'Persons' }],
    }),
    deletePersons: build.mutation<any, any>({
      query({ id }) {
        return {
          url: `delete`,
          method: 'DELETE',
          body: id,
        }
      },
      onQueryStarted: (arg, api) => {
        api.queryFulfilled.then(() => {
          api.dispatch(assetsApi.util.invalidateTags(['Assets']))
          api.dispatch(dashboardApi.util.invalidateTags(['Dashboard']))
        })
      },
      invalidatesTags: (result, error, userId) => [{ type: 'Persons', userId }],
    }),
  }),
})

export const {
  useGetAllPersonsQuery,
  useGetPersonsQuery,
  useGetAssignAssetsQuery,
  useGetSinglePersonQuery,
  useAddPersonMutation,
  useUpdatePersonMutation,
  useDeletePersonsMutation,
} = personsApi
