import { useEffect, useRef, useState } from 'react'
import { Editor } from '@tinymce/tinymce-react'
import {
	useUpdateEmailTemplateMutation,
	useGetEmailDefaultTemplateQuery,
	useGetUserEmailTemplateQuery
} from 'App/Modules/Services/EmailTemplate'
import { toast } from 'react-toastify'
import TableColumns from './TableColumns'

export default function App(props) {

	const { data: defaultData = {}, error = {} } = useGetEmailDefaultTemplateQuery(props?.templateId)
	const { data: userEmailData, isLoading: isLoadingTemplate } = useGetUserEmailTemplateQuery(defaultData?.template?.userEmailTypeId)
	const [emailData, setEmailData] = useState({})
	const [inputFields, setInputFields] = useState([]);
	const [columns, setColumns] = useState([])
	const [showColumns, setShowColumns] = useState(false)
	const [insertFieldsItems, setInsertFieldsItems] = useState([]);
	const [updateEmailTemplate, { isLoading }] = useUpdateEmailTemplateMutation()
	const [editableData, setEditableData] = useState([]);
	const [markup, setMarkup] = useState(null)
	const [activeColumns, setActiveColumns] = useState(null)

	useEffect(() => {
		const splashScreen = document.getElementById('splash-screen')
		if (splashScreen) {
			if (isLoadingTemplate) {
				splashScreen.style.setProperty('display', 'flex')
			} else {
				splashScreen.style.setProperty('display', 'none')
			}
		}
	}, [isLoadingTemplate])

	const handleEmailSubjectChange = (event) => {
		setEmailData({ ...emailData, emailSubject: event.target.value });
	};
	const editorRef = useRef(null);

	const resetContent = () => { };

	useEffect(() => {
		if (Object.keys(error).length) {
			setEmailData()
			setInputFields()
			setColumns([])
			toast.dismiss()
			toast.error(error?.data?.message)
		}
	}, [error])

	useEffect(() => {
		setAllStates()
	}, [defaultData, userEmailData])

	const setAllStates = () => {
		setEmailData(userEmailData?.template ? userEmailData?.template : defaultData?.template);
		setInputFields(defaultData?.template?.user_email_types_fields)
		setColumns(defaultData?.template?.user_email_template_columns)
		setEditableData(defaultData?.template?.user_email_template_columns)
		setMarkup(userEmailData?.template?.markup ? userEmailData?.template?.markup : defaultData?.template?.markup)
		setActiveColumns(userEmailData?.template?.tableColumns)
	}

	useEffect(() => {
		if (activeColumns) {
			setEditableData(prevData =>
				prevData.map(item =>
				({
					...item,
					defaultActive: activeColumns.includes(item?.id)
				})
				)
			);
		}
	}, [activeColumns])

	useEffect(() => {
		if (inputFields?.length) {
			const items = [];
			let lastCategory = '';
			inputFields?.map((element) => {
				if (element?.category !== lastCategory) {
					items.push({
						type: 'menuitem',
						text: element.category,
					});
				}
				items.push({
					type: 'menuitem',
					text: element?.fieldName,
					onAction: () => handleCustomMenuItemClick(element?.fieldName),
				});
				lastCategory = element?.category;
			});
			setInsertFieldsItems(items);
		}
	}, [inputFields])

	const submitEmailTemplate = async () => {
		try {
			const activeIdArray = editableData.filter(item => item.defaultActive).map(item => item.id);
			let obj = {
				emailSubject: emailData?.emailSubject,
				markup: markup,
				tableColumns: activeIdArray,
				userEmailTypeId: emailData?.id
			}
			const result = await updateEmailTemplate(obj).unwrap()
			toast.dismiss()
			toast.success(result?.message)
		}
		catch (error) {
			if (!error?.data?.success) {
				toast.error(error?.data?.message)
			}
		}
	}

	const handleCustomMenuItemClick = (element) => {
		addTextToLastInsertedPlace(element);
	};

	const addTextToLastInsertedPlace = (textToAdd) => {
		if (editorRef.current) {
			editorRef.current.execCommand('mceInsertContent', false, `«${textToAdd}»`);
		}
	};

	const editorChange = (content) => {
		setMarkup(content)
	}

	const getTableData = (newtTable) => {
		if (newtTable) {
			const markup = emailData?.markup
			const regex = /<table[^>]*>[\s\S]*<\/table>/;
			const updatedMarkup = markup.replace(regex, newtTable);

			setEmailData((eData) => {
				return { ...eData, markup: updatedMarkup }
			})
		}
	}

	return (
		<>
			<div className='email-container'>
				{showColumns ?
					<div className='email-columns'>
						{columns ?
							<>
								<TableColumns editableData={editableData} setEditableData={setEditableData}
									setShowColumns={setShowColumns} showColumns={showColumns} setEmailData={setEmailData}
									setTableData={getTableData}
								/>
							</>
							: null}
					</div> :
					<div className='email-content'>
						<div className='mb-7'>
							<p className='fs-6 fw-bold mb-1'>Email Subject</p>
							<p className='text-muted'><span className='me-1 fs-5'><i className='la la-info-circle fw-bolder' /></span>Text that will appear in the email subject line.</p>
							<div>
								<input className="form-control" data-val="true" data-val-length="Maximum length is 1000." data-val-length-max="1000" data-val-required="Email Subject is required." id="emailSubject" name="emailSubject" type="text" value={emailData?.emailSubject} onChange={handleEmailSubjectChange} />
							</div>
						</div>
						<div className='mb-7'>
							{inputFields?.length > 0 && <Editor
								onInit={(evt, editor) => editorRef.current = editor}
								initialValue={emailData?.markup}
								apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
								onEditorChange={(content) => editorChange(content)}
								init={{
									height: 500,
									menubar: false,
									toolbar: 'undo redo | formatselect | bold italic underline | alignleft aligncenter alignright alignjustify | customDropdown | customButton',
									setup: (editor) => {
										editor.ui.registry.addMenuButton('customDropdown', {
											icon: 'more',
											text: 'Input Field',
											fetch: (callback) => {
												callback(insertFieldsItems);
											},
										});
										editor.ui.registry.addButton('customButton', {
											text: 'Edit Table',
											onAction: () => { setShowColumns(!showColumns) },
										});
									}
								}}
							/>
							}
						</div>
						<div className=' d-flex align-items-center justify-content-between'>
							<button className='btn btn-light-primary   main-btn-style me-2' onClick={resetContent}>Reset</button>
							<div className='d-flex align-items-center'>
								<button type='submit' className='btn btn-primary' onClick={submitEmailTemplate} disabled={isLoading}>
									{!isLoading && 'Save'}
									{isLoading && (
										<span className='indicator-progress d-block'>
											Please wait...{' '}
											<span className='spinner-border spinner-border-sm align-middle ms-2'></span>
										</span>
									)}
								</button>
							</div>
						</div>
					</div>
				}
			</div>
		</>
	);
}
