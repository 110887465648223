import React, { useState, useEffect } from 'react'
import { useGetAllSitesQuery } from '../../Modules/Services/Sites'
import { useGetAllLocationsBySiteQuery } from '../../Modules/Services/Locations'
import { useGetAllDepartmentsQuery } from '../../Modules/Services/Departments'
import { useAddCheckinMutation } from '../../Modules/Services/Checkout'
import { useGetAllAssetsQuery } from 'App/Modules/Services/Assets'
import { CheckInInterface, defaultCheckInDetail as initialValues } from './Core/SetupModel'
import CheckinModal from './Modal/CheckinModal'
import { useAuth } from '../../Modules/Auth'
import { PageTitle } from '../../../_metronic/layout/core'
import { useIntl } from 'react-intl'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useNavigate } from 'react-router-dom'
import { checkUserUnauthorized } from '../../Modules/Auth'
import SplashScreen from 'App/SplashScreen'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import Loader from 'App/BeatLoader'

const accountDetailsSchema = Yup.object().shape({
	returnDate: Yup.date()
		.min(new Date(new Date().setHours(0, 0, 0, 0)), 'Return date cannot be a past date')
		.required('Return date is required'),
})

const Checkin = () => {
	const intl = useIntl()
	const { currentUser, saveAuth, setCurrentUser } = useAuth()
	const navigate = useNavigate()
	const [assets, setAssets] = useState<any>([])
	const [loading, setLoading] = useState<boolean>(false)
	const [dataLoading, setDataLoading] = useState<boolean>(false)
	const [showCheckInModal, setShowCheckInModal] = useState(false)
	const [pendingAssets, setPendingAssets] = useState<any>([])
	const [selectedAssets, setSelectedAssets] = useState<any>([])
	const [selectAll, setSelectAll] = useState(false)
	const [personCheckout, setPersonCheckout] = useState(false)
	const [emailEnabled, setEmailEnabled] = useState(false)
	const [selected, setSelected] = useState<any>([])
	const [siteId, setSiteId] = useState<any>()
	const [skip, setSkip] = useState<boolean>(false)
	const [assignTo, setAssignTo] = useState<string>('')
	const [page, setPage] = useState<number>(1)
	const [limit, setLimit] = useState<number>(10)
	const [customErrors, setCustomErrors] = useState({ siteField: '', returnDate: '' })
	const [deleteDataArr, setDeleteDataArr] = useState<number[]>([])
	const { data: userSitesList } = useGetAllSitesQuery(currentUser?.id)
	const { data: userLocationList, error: locationError } = useGetAllLocationsBySiteQuery(
		{ userId: currentUser?.id, id: siteId },
		{ skip: !siteId }
	)
	const { data: userDepartmentList } = useGetAllDepartmentsQuery(currentUser?.id)
	const [addCheckin, { isLoading: isAdding }] = useAddCheckinMutation()
	const {
		data: assetsData,
		isLoading,
		error,
		isSuccess,
	} = useGetAllAssetsQuery(
		{
			body: new URLSearchParams({ status: 'check_out' }).toString(),
			page: page,
			limit: limit,
			keys: '',
		},
		{ skip }
	)

	const extractEmail = (asset) => {
		if (asset?.assetstatus?.user?.email) {
			return asset?.assetstatus?.user?.email
		} else {
			return ''
		}
	}

	let emails = selectedAssets.map((asset) => extractEmail(asset))
	let personEmail = ''

	if (emails.length === 1) {
		personEmail = emails[0]
	}

	const formik = useFormik({
		initialValues: { ...initialValues },
		validationSchema: accountDetailsSchema,

		onSubmit: async (values) => {
			setLoading(true)
			try {
				if (values.checkedTo !== 'site') {
					values.checkedTo = 'person'
				}
				if (personCheckout == false && values?.siteId === undefined) {
					setCustomErrors({ siteField: 'Site is required', returnDate: '' })
					setLoading(false)
					return undefined
				} else if (!values?.returnDate) {
					setCustomErrors({ returnDate: 'ReturnDate is required', siteField: '' })
					setLoading(false)
					return undefined
				} else {
					setCustomErrors({ siteField: '', returnDate: '' })
					handleCheckinSubmit(values)
				}
			} catch (error: any) {
				toast.error(error?.data?.message)
			}
		},
	})

	useEffect(() => {
		if (assetsData) {
			getAssetListing()
		}
	}, [assetsData])

	useEffect(() => {
		if (assets && pendingAssets && showCheckInModal == false) {
			handlePendingList()
		}
	}, [assets, showCheckInModal])

	const getAssetListing = async () => {
		setAssets(assetsData?.userAssets)
	}

	const handlePendingList = () => {
		const data = assets?.filter((asset: any) => pendingAssets.includes(asset.id))
		setSelectedAssets(data)
		setDeleteDataArr([])
	}

	const handleCheckinSubmit = async (values: CheckInInterface) => {
		let pasredSiteId
		if (values?.checkedTo?.[0] === 'siteId' || values?.checkedTo?.[1] === 'siteId') {
			const siteId: any = values.siteId
			pasredSiteId = siteId === '' ? undefined : parseInt(siteId)
		}
		const data = {
			returnDate: values?.returnDate,
			checkedTo: values?.checkedTo,
			notes: values?.notes,
			locationId: values?.locationId,
			siteId: pasredSiteId,
			departmentId: values?.departmentId,
			assetId: pendingAssets,
			emails: emailEnabled ? emails : [''],
		}
		try {
			const result = await addCheckin(data).unwrap()
			if (result) {
				toast.dismiss()
				toast.success(result?.message ? result?.message : result?.data?.message)
				setSelectedAssets([])
				setPersonCheckout(false)
				setSiteId(undefined)
				setCustomErrors({ siteField: '', returnDate: '' })
				navigate('/checkin')
				formik.resetForm()
			}
		} catch (error: any) {
			checkUserUnauthorized(error?.data, saveAuth, setCurrentUser, toast)
		} finally {
			setLoading(false)
		}
	}

	const handleshowCheckInModal = () => {
		setShowCheckInModal(true)
	}

	const handleCloseCheckinModal = () => {
		setShowCheckInModal(false)
	}

	useEffect(() => {
		setDataLoading(false)
	}, [userLocationList])

	const handleSiteClick = ({ target: { value } }: any) => {
		if (value === '') {
			setCustomErrors({ siteField: 'Site is required', returnDate: '' })
			setSiteId(0)
		} else {
			setDataLoading(true)
			setSiteId(value)
			setSkip(false)
			setCustomErrors({ siteField: '', returnDate: '' })
			setDataLoading(false)
		}
	}

	const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.checked) {
			const newSelecteds = assets?.map((item: any) => item.id)
			setSelected(newSelecteds)
			setSelectAll(true)
			setDeleteDataArr(newSelecteds)
		} else {
			setSelected([])
			setSelectAll(false)
			setDeleteDataArr([])
		}
	}

	const handleCheckboxChange = (id: number) => {
		setSelected((prevSelected) => {
			const updatedSelected = prevSelected.includes(id)
				? prevSelected.filter((itemId) => itemId !== id)
				: [...prevSelected, id]
			const isAllSelected = updatedSelected.length === assets?.length
			setSelectAll(isAllSelected)
			return updatedSelected
		})
		setDeleteDataArr((prevSelected) => {
			if (prevSelected.includes(id)) {
				return prevSelected.filter((itemId) => itemId !== id)
			} else {
				return [...prevSelected, id]
			}
		})
	}

	useEffect(() => {
		if (isLoading) {
			setDataLoading(true)
		}
		setDataLoading(false)
	}, [isLoading])

	const deleteSelectedAssests = async () => {
		const data = selectedAssets?.filter((asset: any) => !deleteDataArr.includes(asset.id))
		setSelectedAssets(data)
		setDeleteDataArr([])
		setSelectAll(false)
		setSelected([])
	}

	const handleReturnDate = ({ target: { value } }: any) => {
		setDataLoading(true)
		setSkip(false)
		if (value) {
			setCustomErrors({ siteField: '', returnDate: '' })
		}
		setDataLoading(false)
	}

	const handleCancel = () => {
		setPersonCheckout(false)
		setSelectedAssets([])
		setPendingAssets([])
		setShowCheckInModal(false)
		setSiteId(undefined)
		setCustomErrors({ siteField: '', returnDate: '' })
		formik.resetForm()
	}
	const onChangePerson = () => {
		setPersonCheckout(true)
		setEmailEnabled(false)
		setSiteId(undefined)
		formik.setFieldValue('siteId', undefined)
		formik.setFieldValue('locationId', undefined)
		if (emails.length === 0 || emails.every((email) => email.trim() === '')) {
			toast.info('This asset is not assigned to any person')
		}
	}

	return (
		<>
			<SplashScreen isLoadingTemplate={isLoading} />
			<PageTitle breadcrumbs={[]}>{intl.formatMessage({ id: 'Checkin' })}</PageTitle>
			<div className='card mb-5 mb-xl-10'>
				<div className='card-header border-0'>
					<div className='card-title m-0'>
						<h3 className='fw-bolder m-0'>
							<span className='me-2'>
								<i className='la la-user-times fs-1'></i>
							</span>{' '}
							Check-In
						</h3>
					</div>
				</div>
				<div id='kt_account_profile_details' className='collapse show'>
					<div className='form'>
						<div className='card-body border-top p-lg-9 p-md-7 p-6'>
							<div>
								{' '}
								<p className='fw-bolder m-0 mb-3'>
									Track the journey of each asset as it moves through your organization.
								</p>
								<div className='card-footer d-flex justify-content-start border-0 p-0'>
									<button
										type='button'
										className='btn btn-light-primary me-3'
										onClick={() => handleshowCheckInModal()}
									>
										<i className='la la-plus fs-3' />
										Select Assets
									</button>
									<CheckinModal
										setPendingAssets={setPendingAssets}
										selectedAssets={selectedAssets}
										show={showCheckInModal}
										handleClose={handleCloseCheckinModal}
										handleCancel={handleCancel}
										setPage={setPage}
										setLimit={setLimit}
										page={page}
										limit={limit}
										setAssignTo={setAssignTo}
									/>
								</div>
								{selectedAssets && selectedAssets?.length > 0 ? (
									<>
										<div className='separator border-2 my-10'></div>
										<div className='d-flex justify-content-between align-items-center'>
											<h4 className='mt-3'>Assets Pending Check-In</h4>
											{deleteDataArr.length > 0 && (
												<button
													type='button'
													className='btn btn-danger mb-2'
													onClick={() => {
														deleteSelectedAssests()
													}}
												>
													Delete Selected
												</button>
											)}
										</div>
										<div className='checkout-listing-table mb-5 table-responsive pb-4'>
											<table
												id='kt_table_users'
												className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
												role='table'
											>
												<thead>
													<tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
														<th role='columnheader' className='w-10px pe-2'>
															<div className='me-3'>
																<input
																	type='checkbox'
																	className='form-check-input custom-form-check-input'
																	checked={selectAll}
																	onChange={handleSelectAll}
																/>
															</div>
														</th>
														<th role='columnheader' className='min-w-125px'>
															Asset Tag ID
														</th>
														<th role='columnheader' className='min-w-125px'>
															Description
														</th>
														<th role='columnheader' className='min-w-125px'>
															Status
														</th>
														<th role='columnheader' className='min-w-125px'>
															Assigned to
														</th>
														<th role='columnheader' className='min-w-125px'>
															Site
														</th>
														<th role='columnheader' className='min-w-125px'>
															Location
														</th>
														<th role='columnheader' className='min-w-125px'>
															Lease to
														</th>
													</tr>
												</thead>
												<tbody className='text-gray-600 fw-bol' role='rowgroup'>
													{selectedAssets?.length > 0 ? (
														selectedAssets?.map((item: any) => {
															return (
																<tr role='row' key={item.id}>
																	<td role='cell'>
																		<div>
																			<input
																				type='checkbox'
																				className='form-check-input custom-form-check-input'
																				checked={selected.includes(item.id)}
																				onChange={() => handleCheckboxChange(item.id)}
																			/>
																		</div>
																	</td>
																	<td role='cell'>
																		<div className='d-flex align-items-center'>
																			<div className=' overflow-hidden me-3'>
																				<Link to='/dummy-link'>{item?.assetTagId}</Link>
																			</div>
																		</div>
																	</td>
																	<td role='cell'>
																		<div className='d-flex align-items-center'>
																			<div className=' overflow-hidden me-3'>
																				<Link to='/dummy-link'>{item?.description}</Link>
																			</div>
																		</div>
																	</td>
																	<td role='cell'>
																		<div className='d-flex align-items-center'>
																			<div className='d-flex flex-column'>
																				{item.statusType === 'check_out'
																					? 'Checked Out'
																					: item.statusType === 'lease'
																						? 'Leased'
																						: item.statusType === 'dispose'
																							? 'Disposed'
																							: item.statusType === 'available'
																								? 'Available'
																								: item.statusType}
																			</div>
																		</div>
																	</td>
																	<td role='cell'>
																		<div className='d-flex align-items-center'>
																			<div className=' overflow-hidden me-3'>
																				{item?.assetstatus?.user ? (
																					<div>{`${item?.assetstatus?.user?.firstName || ''} ${item?.assetstatus?.user?.lastName || ''}`}</div>
																				) : item?.assetstatus !== null ? (
																					<div>{`${item?.site?.name || ''} ${item?.site?.name && item.location?.location ? "/" : ""} ${item?.location?.location || ''}`}</div>
																				) : item?.reserveassets?.length > 0 ? (
																					item.reserveassets[0].reserveFor === 'person' ? (
																						<div>{`${item?.reserveassets[0]?.user?.firstName || ''} ${item?.reserveassets[0]?.user?.lastName || ''}`}</div>
																					) : item.reserveassets[0].reserveFor === 'customer' ? (
																						<div>{`${item?.reserveassets[0]?.user?.firstName || ''} ${item?.reserveassets[0]?.user?.lastName || ''}`}</div>
																					) : (
																						<div>{`${item?.reserveassets[0]?.site?.name || ''} ${item?.reserveassets[0]?.location?.location || ''}`}</div>
																					)
																				) : (
																					<div></div>
																				)}
																			</div>
																		</div>
																	</td>
																	<td role='cell'>
																		<div className='d-flex align-items-center'>
																			<div className=' overflow-hidden me-3'>
																				<Link to='/dummy-link'>{item?.site?.name}</Link>
																			</div>
																		</div>
																	</td>
																	<td role='cell'>
																		<div className='d-flex align-items-center'>
																			<div className='d-flex flex-column'>
																				{item?.location?.location}
																			</div>
																		</div>
																	</td>
																	<td role='cell'>
																		<div className='d-flex align-items-center'>
																			<div className='d-flex flex-column'>
																				{item?.statusType === 'lease' && item?.assetslease
																					? `${item?.assetslease?.user?.firstName} ${item?.assetslease?.user?.lastName}`
																					: ''}
																			</div>
																		</div>
																	</td>
																</tr>
															)
														})
													) : (
														<tr>
															<td colSpan={12}>
																<div className='d-flex text-center w-100 align-items-center justify-content-center no-records fw-bold rounded'>
																	No records found
																</div>
															</td>
														</tr>
													)}
												</tbody>
											</table>
										</div>
										<div className='separator border-2 my-10'></div>
										<form onSubmit={formik.handleSubmit} noValidate className='form'>
											<div className='row mb-3'>
												<div className='col-xl-6'>
													<div className='row mb-6 align-items-center'>
														<label className='col-lg-3 col-form-label fw-bold fs-6'>
															Check-in from
														</label>
														<div className='col-lg-9 fv-row'>
															<div className='d-flex'>
																<div className='m-2 form-check-custom'>
																	<div>
																		<input
																			className='form-check-input custom-form-check-input'
																			{...formik.getFieldProps('checkedTo')}
																			onClick={onChangePerson}
																			type='checkbox'
																			value={'person'}
																			checked={personCheckout == false ? false : true}
																			name={'checkedTo'}
																			id='person'
																		/>
																	</div>
																	<label className='form-check-label fw-bold fs-6' htmlFor='person'>
																		Person
																	</label>
																</div>
																<div className='m-2 form-check-custom'>
																	<div>
																		<input
																			className='form-check-input custom-form-check-input'
																			type='checkbox'
																			{...formik.getFieldProps('checkedTo')}
																			onClick={() => {
																				setCustomErrors({
																					siteField: '',
																					returnDate: '',
																				})
																				setPersonCheckout(false)
																				setEmailEnabled(false)
																				setSiteId(undefined)
																				formik.setFieldValue('assignTo', undefined)
																			}}
																			value={'site'}
																			checked={personCheckout == false ? true : false}
																			name={'checkedTo'}
																			id='location'
																		/>
																	</div>
																	<label
																		className='form-check-label fw-bold fs-6'
																		htmlFor='location'
																	>
																		Site / Location
																	</label>
																</div>
															</div>
														</div>
													</div>
													<div className='row mb-6'>
														<label className={'col-lg-3 col-form-label fw-bold fs-6 required'}>
															Return Date
														</label>
														<div className='col-lg-9 fv-row'>
															<div>
																<input
																	className='form-control form-control-solid'
																	{...formik.getFieldProps('returnDate')}
																	type='date'
																	placeholder='Pick date rage'
																	id='kt_daterangepicker_3'
																	onChange={(e) => {
																		formik.handleChange(e)
																		handleReturnDate(e)
																	}}
																/>
															</div>
															{formik.touched.returnDate && formik.errors.returnDate && (
																<div className='fv-plugins-message-container'>
																	<div className='fv-help-block'>{formik.errors.returnDate}</div>
																</div>
															)}
															{customErrors?.returnDate !== '' &&
																personCheckout == (false || true) && (
																	<div className='fv-plugins-message-container'>
																		<div className='fv-help-block'>{customErrors?.returnDate}</div>
																	</div>
																)}
														</div>
													</div>
													<div className='row mb-6'>
														<label
															className={
																personCheckout == true
																	? 'col-lg-3 col-form-label fw-bold fs-6'
																	: 'col-lg-3 col-form-label fw-bold fs-6 required'
															}
														>
															Site
														</label>
														<div className='col-lg-9 fv-row'>
															<select
																className='form-select form-select-solid mb-3'
																{...formik.getFieldProps('siteId')}
																aria-label='Select example'
																onChange={(e) => {
																	handleSiteClick(e)
																	formik.handleChange(e)
																}}
															>
																<option value=''>Select Site</option>
																{userSitesList?.sites?.map((item: any, idx: string) => {
																	return <option value={item?.id}>{item?.name}</option>
																})}
															</select>
															{personCheckout == false &&
																formik.touched.siteId &&
																formik.errors.siteId && (
																	<div className='fv-plugins-message-container'>
																		<div className='fv-help-block'>{formik.errors.siteId}</div>
																	</div>
																)}
															{customErrors?.siteField !== '' && personCheckout == false && (
																<div className='fv-plugins-message-container'>
																	<div className='fv-help-block'>{customErrors?.siteField}</div>
																</div>
															)}
														</div>
													</div>
													<div className='row mb-6'>
														<label className='col-lg-3 col-form-label fw-bold fs-6'>Location</label>
														<div className='col-lg-9 fv-row'>
															<select
																className='form-select form-select-solid mb-3'
																{...formik.getFieldProps('locationId')}
																aria-label='Select example'
																onChange={(e) => {
																	formik.handleChange(e)
																	const value = e.target.value
																	if (value === '') {
																		formik.setFieldValue('locationId', undefined)
																	}
																}}
															>
																<option value=''>Select Location</option>
																{siteId > 0 &&
																	userLocationList?.locations?.map((item: any, idx: number) => {
																		return <option value={item?.id}>{item?.location}</option>
																	})}
															</select>
														</div>
													</div>
												</div>
												<div className='col-xl-6'>
													<div className='row mb-6'>
														<label
															className='col-lg-3 col-form-label fw-bold fs-6'
															htmlFor='department'
														>
															Department
														</label>
														<div className='col-lg-9 fv-row'>
															<select
																className='form-select form-select-solid mb-3'
																{...formik.getFieldProps('departmentId')}
																onChange={(e) => {
																	formik.handleChange(e)
																	const value = e.target.value
																	if (value === '') {
																		formik.setFieldValue('departmentId', undefined)
																	}
																}}
																id='department'
																aria-label='Select example'
															>
																<option value=''>Select Department</option>
																{userDepartmentList?.department?.map((item: any, idx: number) => {
																	return <option value={item?.id}>{item?.department}</option>
																})}
															</select>
														</div>
													</div>
													<div className='row mb-10'>
														<label
															className='col-lg-3 col-form-label fw-bold fs-6'
															htmlFor='checkinNotes'
														>
															Check-in Notes
														</label>
														<div className='col-lg-9 fv-row'>
															<textarea
																className='form-control'
																{...formik.getFieldProps('notes')}
																id='checkinNotes'
																cols={20}
																rows={4}
															></textarea>
														</div>
													</div>
													<div
														className={
															personCheckout == true ? 'mb-10 row align-items-center' : 'd-none'
														}
													>
														<div className='col-lg-3 col-form-label fw-bold fs-6'>
															<div className='form-check form-check-custom  form-check-sm d-flex align-center mb-lg-0 mb-4'>
																<div>
																	<input
																		className='form-check-input me-2'
																		type='checkbox'
																		id='flexRadioLg'
																		checked={emailEnabled}
																		onChange={() => setEmailEnabled(!emailEnabled)}
																	/>
																</div>
																<label
																	className='form-label mb-0 fw-bold fs-6'
																	htmlFor='flexRadioLg'
																>
																	Send Email
																</label>
															</div>
														</div>
														<div className='col-lg-9 fv-row'>
															<div>
																<input
																	type='email'
																	{...formik.getFieldProps('emails')}
																	className='form-control form-control-solid'
																	placeholder='Email'
																	value={personEmail}
																/>
															</div>
														</div>
													</div>
												</div>
											</div>
											<div className=' d-flex align-items-center justify-content-end'>
												<button
													onClick={handleCancel}
													className='btn btn-light-primary   main-btn-style me-2'
												>
													Cancel
												</button>
												{
													personCheckout ? (
														<button
															type='submit'
															className='btn btn-primary main-btn-style'
															disabled={
																customErrors.returnDate !== '' || !formik.isValid
															}
														>
															{!loading && 'Check-In'}
															{loading && (
																<span className='indicator-progress d-block'>
																	Please wait...{' '}
																	<span className='spinner-border spinner-border-sm align-middle ms-2'></span>
																</span>
															)}
														</button>

													) : (
														<button
															type='submit'
															className='btn btn-primary main-btn-style'
															disabled={
																siteId === undefined || siteId === 0 || customErrors.siteField !== '' || !formik.isValid
															}
														>
															{!loading && 'Check-In'}
															{loading && (
																<span className='indicator-progress d-block'>
																	Please wait...{' '}
																	<span className='spinner-border spinner-border-sm align-middle ms-2'></span>
																</span>
															)}
														</button>
													)
												}
											</div>
										</form>
									</>
								) : (
									''
								)}
							</div>
						</div>
					</div>
				</div>
				<Loader loading={dataLoading} />
			</div>
		</>
	)
}

export default Checkin
