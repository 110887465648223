import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { assetsApi } from './Assets'
import { getAuth } from '../Auth'
import { toast } from 'react-toastify'

const API_URL = process.env.REACT_APP_API_URL
export const warrantyApi = createApi({
    reducerPath: 'warrantyApi',
    baseQuery: fetchBaseQuery({
        baseUrl: `${API_URL}/asset-warranty`,
        prepareHeaders: (headers) => {
            const auth = getAuth()
            if (auth && auth?.token) {
                headers.set('Authorization', `Bearer ${auth?.token}`)
            }
            return headers
        },
    }),
    tagTypes: ['Warranty'],
    endpoints: (build) => ({
        addWarranty: build.mutation<void, any>({
            query: (data) => ({
                url: '/create',
                method: 'POST',
                body: data,
            }),
            onQueryStarted: (arg, api) => {
                api.queryFulfilled.then(() => {
                    api.dispatch(assetsApi.util.invalidateTags(['Assets']))
                }).catch(err => toast.error(err))
            },
            invalidatesTags: () => [{ type: 'Warranty' }],
        }),
        getAllWarranties: build.query<any, { body: any; page: any; limit: any; keys:any }>({
            query: ({ body, page, limit, keys }) => `list/?${body}&page=${page}&limit=${limit}&keys=${keys}`,
            providesTags: ['Warranty'],
        }),
        getWarranty: build.query<any, any>({
            query: ({id }) => `/get/${id}`,
            providesTags: ['Warranty'],
        }),
        getAssetAllWarranties: build.query<any, any>({
            query: ({id}) => `/get-asset-warranties/${id}`,
            providesTags: ['Warranty'],
        }),
        updateWarranty: build.mutation<any, any>({
            query: ({ body, id }) => ({
                url: `/update/${id}`,
                method: 'PUT',
                body,
            }),
            invalidatesTags: () => [{ type: 'Warranty' }],
        }),
        deleteWarranty: build.mutation<any, any>({
            query(id) {
                return {
                    url: `/delete/${id}`,
                    method: 'DELETE',
                    body: id,
                }
            },
            invalidatesTags: () => [{ type: 'Warranty' }],
        }),
    }),
})

export const {
    useAddWarrantyMutation,
    useGetAllWarrantiesQuery,
    useGetWarrantyQuery,
    useDeleteWarrantyMutation,
    useUpdateWarrantyMutation,
    useGetAssetAllWarrantiesQuery
} = warrantyApi
