import { assetsApi } from 'App/Modules/Services/Assets'
import { AuthModel } from './_models'
import { assetsReportsApi } from 'App/Modules/Services/AssetsReports'
import { categoriesApi } from 'App/Modules/Services/Categories'
import { checkoutApi } from 'App/Modules/Services/Checkout'
import { companyApi } from 'App/Modules/Services/Company'
import { customersApi } from 'App/Modules/Services/Customers'
import { dashboardApi } from 'App/Modules/Services/Dashboard'
import { departmentsApi } from 'App/Modules/Services/Departments'
import { toast } from 'react-toastify'
import { disposeApi } from 'App/Modules/Services/Dispose'
import { emailTemplateApi } from 'App/Modules/Services/EmailTemplate'
import { eventsApi } from 'App/Modules/Services/Events'
import { exportsApi } from 'App/Modules/Services/Exports'
import { groupsApi } from 'App/Modules/Services/groups'
import { headersSplitApi } from 'App/Modules/Services/HeadersConfig'
import { importsApi } from 'App/Modules/Services/Imports'
import { leaseApi } from 'App/Modules/Services/Lease'
import { leaseReturnApi } from 'App/Modules/Services/LeaseReturn'
import { locationsApi } from 'App/Modules/Services/Locations'
import { maintenanceApi } from 'App/Modules/Services/Maintenance'
import { moveApi } from 'App/Modules/Services/Move'
import { optionApi } from 'App/Modules/Services/Option'
import { personsApi } from 'App/Modules/Services/Persons'
import { profileApi } from 'App/Modules/Services/Profile'
import { reserveApi } from 'App/Modules/Services/Reserve'
import { setupApi } from 'App/Modules/Services/setupColumn'
import { sitesApi } from 'App/Modules/Services/Sites'
import { tableOptionApi } from 'App/Modules/Services/TableOption'
import { warrantyApi } from 'App/Modules/Services/Warranty'
import { subUserApi } from 'App/Modules/Services/SubUser'

const AUTH_LOCAL_STORAGE_KEY = 'kt-auth-react-v'

const getAuth = (): AuthModel | undefined => {
  if (!localStorage) {
    return
  }

  const lsValue: string | null = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
  if (!lsValue) {
    return
  }

  try {
    const auth: AuthModel = JSON.parse(lsValue) as AuthModel
    if (auth) {
      return auth
    }
  } catch (error) {
    console.error('AUTH LOCAL STORAGE PARSE ERROR', error)
  }
}

const setAuth = (auth: AuthModel) => {
  if (!localStorage) {
    return
  }

  try {
    const lsValue = JSON.stringify(auth)
    localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, lsValue)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE SAVE ERROR', error)
  }
}

const removeAuth = () => {
  if (!localStorage) {
    return
  }

  try {
    localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE REMOVE ERROR', error)
  }
}

const checkUserUnauthorized = (response: any, saveAuth?: any, setCurrentUser?: any, toast?: any) => {
  try {
    if (response?.status === 401) {
      saveAuth(undefined)
      setCurrentUser(undefined)
      toast.dismiss()
      toast.error(response?.message)
    }
  } catch (err) {
    toast.error(err)
  }
}

export function setupAxios(axios: any) {
  axios.defaults.headers.Accept = 'application/json'
  axios.interceptors.request.use(
    (config: { headers: { Authorization: string } }) => {
      const auth = getAuth()
      if (auth && auth.token) {
        config.headers.Authorization = `Bearer ${auth.token}`
      }

      return config
    },
    (err: any) => Promise.reject(err)
  )
}

export const invalidateCache = async (dispatch) => {
  try {
    await dispatch(assetsApi.util.resetApiState())
    await dispatch(assetsReportsApi.util.resetApiState())
    await dispatch(categoriesApi.util.resetApiState())
    await dispatch(checkoutApi.util.resetApiState())
    await dispatch(companyApi.util.resetApiState())
    await dispatch(customersApi.util.resetApiState())
    await dispatch(dashboardApi.util.resetApiState())
    await dispatch(departmentsApi.util.resetApiState())
    await dispatch(disposeApi.util.resetApiState())
    await dispatch(emailTemplateApi.util.resetApiState())
    await dispatch(eventsApi.util.resetApiState())
    await dispatch(exportsApi.util.resetApiState())
    await dispatch(groupsApi.util.resetApiState())
    await dispatch(headersSplitApi.util.resetApiState())
    await dispatch(importsApi.util.resetApiState())
    await dispatch(leaseApi.util.resetApiState())
    await dispatch(leaseReturnApi.util.resetApiState())
    await dispatch(locationsApi.util.resetApiState())
    await dispatch(maintenanceApi.util.resetApiState())
    await dispatch(moveApi.util.resetApiState())
    await dispatch(optionApi.util.resetApiState())
    await dispatch(personsApi.util.resetApiState())
    await dispatch(profileApi.util.resetApiState())
    await dispatch(reserveApi.util.resetApiState())
    await dispatch(setupApi.util.resetApiState())
    await dispatch(sitesApi.util.resetApiState())
    await dispatch(subUserApi.util.resetApiState())
    await dispatch(tableOptionApi.util.resetApiState())
    await dispatch(warrantyApi.util.resetApiState())
  } catch (error: any) {
    toast.error(error?.message)
  }
}

export { getAuth, setAuth, removeAuth, checkUserUnauthorized, AUTH_LOCAL_STORAGE_KEY }
