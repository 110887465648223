import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { getAuth } from '../Auth'

export interface Company {
	userId: string
	name: string
}

type CompaniesResponse = Company[]
const API_URL = process.env.REACT_APP_API_URL

export const companyApi = createApi({
	reducerPath: 'companyApi',
	baseQuery: fetchBaseQuery({
		baseUrl: `${API_URL}/company/`,
		prepareHeaders: (headers) => {
			const auth = getAuth()
			if (auth && auth?.token) {
				headers.set('Authorization', `Bearer ${auth?.token}`);
			}
			return headers;
		},
	}),
	tagTypes: ['Company'],
	endpoints: (build) => ({
		getCompany: build.query<any, void>({
			query: () => 'list',
			providesTags: ['Company'],
		}),
		updateCompany: build.mutation<any, { formData?: FormData }>({
			query: ({ formData }) => ({
				url: `update`,
				method: 'PUT',
				body: formData,
			}),
			invalidatesTags: (result, error) => [{ type: 'Company' }],
		}),
		deleteCompany: build.mutation<{ success: boolean; }, number>({
			query(id) {
				return {
					url: `delete/${id}`,
					method: 'DELETE',
				}
			},
			invalidatesTags: (result, error) => [{ type: 'Company' }],
		}),
		deleteImage: build.mutation<any, any>({
			query: (id) => ({
				url: `delete-image/${id}`,
				method: 'POST'
			}),
			invalidatesTags: (result, error) => [{ type: 'Company' }],
		})
	}),
})

export const {
	useGetCompanyQuery,
	useUpdateCompanyMutation,
	useDeleteCompanyMutation,
	useDeleteImageMutation
} = companyApi
