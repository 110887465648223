import { Suspense, useEffect } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { I18nProvider } from '../_metronic/i18n/i18nProvider'
import { LayoutProvider, LayoutSplashScreen } from '../_metronic/layout/core'
import { MasterInit } from '../_metronic/layout/MasterInit'
import { AuthInit } from './Modules/Auth'
import "./App.css"

const App = () => {
	const location = useLocation();
	const { pathname } = location;

	useEffect(() => {
		localStorage.setItem("path", pathname)
	}, [pathname])

	return (
		<Suspense fallback={<LayoutSplashScreen />}>
			<I18nProvider>
				<LayoutProvider>
					<AuthInit>
						<Outlet />
						<MasterInit />
					</AuthInit>
				</LayoutProvider>
			</I18nProvider>
		</Suspense>
	)
}

export { App }	
