import { FC, Suspense, useEffect } from 'react'
import { Route, Routes, Navigate, useLocation } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import { DashboardWrapper } from '../Pages/Dashboard/DashboardWrapper'
import { MenuTestPage } from '../Pages/MenuTestPage'
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils'
import { WithChildren } from '../../_metronic/helpers'
import Checkout from '../Pages/Checkout/Checkout'
import Reserve from 'App/Pages/Reserve/Reserve'
import Checkin from '../Pages/Checkin/Checkin'
import AddAssets from 'App/Modules/Apps/CompanySetup/Asset/Components/AssetsInfo'
import Lease from '../Modules/Lease/Lease'
import LeaseReturn from '../Modules/LeaseReturn/LeaseReturn'
import Move from '../Modules/Move/Move'
import Maintenance from '../Modules/Maintenance/Maintenance'
import Maintenances from 'App/Modules/Maintenance/Maintenances'
import Warranties from 'App/Modules/Warranties/Warranties'
import Dispose from '../Modules/Dispose/Dispose'
import Import from '../Modules/Import/Import'
import Export from 'App/Modules/Export/Export'
import Customers from '../Modules/Apps/CompanySetup/Customers/Customers'
import Persons from '../Modules/Apps/CompanySetup/Persons/Persons'
import Users from '../Modules/Apps/CompanySetup/Users/Users'
import SecurityGroups from '../Modules/Apps/CompanySetup/SecurityGroups/SecurityGroups'
import UserAssets from '../Modules/Apps/CompanySetup/Asset/Assets'
import AssetDetails from '../Modules/Apps/CompanySetup/Asset/Components/AssetDetail'
import ProfilePage from '../Modules/Profile/ProfilePage'
import WizardsPage from '../Modules/Wizards/WizardsPage'
import AccountPage from '../Modules/Accounts/AccountPage'
import AccountDetailsPage from '../Modules/AccountDetails/AccountDetailsPage'
import ChatPage from '../Modules/Apps/Chat/ChatPage'
import UsersPage from '../Modules/Apps/UserManagement/UsersPage'
import SetupPage from '../Modules/Apps/CompanySetup/SetupPage'
import AssetPage from '../Modules/Apps/CompanySetup/AssetPage'
import AddUsers from 'App/Modules/Apps/CompanySetup/Users/Components/AddUsers'
import CreateGroup from 'App/Modules/Apps/CompanySetup/SecurityGroups/Components/CreateGroup'
import EditUser from 'App/Modules/Apps/CompanySetup/Users/Components/EditUser'
import ReportByAssetTags from 'App/Modules/Apps/CompanySetup/Reports/AssetsReports/ReportByAssetTags/ReportByAssetTags'
import ReportByAssetPicture from 'App/Modules/Apps/CompanySetup/Reports/AssetsReports/ReportByAssetPicture/ReportByAssetPicture'
import ReportByCategories from 'App/Modules/Apps/CompanySetup/Reports/AssetsReports/ReportByCategories/ReportByCategories'
import ReportByDepartment from 'App/Modules/Apps/CompanySetup/Reports/AssetsReports/ReportByDepartment/ReportByDepartment'
import ReportByWarrantyInfo from 'App/Modules/Apps/CompanySetup/Reports/AssetsReports/ReportByWarrantyInfo/ReportByWarrantyInfo'
import ReportsBySites from 'App/Modules/Apps/CompanySetup/Reports/AssetsReports/ReportBySites/ReportBySites'
import ReportsByLocations from 'App/Modules/Apps/CompanySetup/Reports/AssetsReports/ReportByLocations/ReportByLocations'
import ReportsByPersonEmployee from 'App/Modules/Apps/CompanySetup/Reports/CheckoutReports/ReportsByPersonEmployee/ReportsByPersonEmployee'

import { useAuth } from 'App/Modules/Auth'
import SetupAlerts from 'App/Modules/Alerts/SetupAlerts/SetupAlerts'

const PrivateRoutes = () => {
  const { currentUser } = useAuth()
  const path = localStorage.getItem('path')
  const personUser = currentUser?.userType === '2'
  const customerUser = currentUser?.userType === '4'
  const setup = currentUser?.isSetupCompleted
  
  const intialRoute = setup && !(personUser || customerUser)
    ? '/dashboard'
    : !setup && !(personUser || customerUser)
      ? '/apps/setup/wizard'
      : !setup && (personUser || customerUser)
        ? '/assets' : '/'
  return (
    <Routes>
      <Route element={<MasterLayout />}>
        <Route
          path=''
          element={
            <Navigate
              to={intialRoute}
            />
          }
        />
        <Route
          path='auth/*'
          element={
            <Navigate
              to={intialRoute}
            />
          }
        />
        <Route path='assets' element={<UserAssets />} />
        <Route path='asset/view/:id' element={<AssetDetails />} />
        {!(personUser || customerUser) && (
          <>
            <Route path='dashboard' element={<DashboardWrapper />} />
            <Route path='checkout' element={<Checkout />} />
            <Route path='checkin' element={<Checkin />} />
            <Route path='asset/add' element={<AddAssets />} />
            <Route path='asset/edit/:id' element={<AddAssets />} />
            <Route path='lease' element={<Lease />} />
            <Route path='lease-return' element={<LeaseReturn />} />
            <Route path='maintenance' element={<Maintenance />} />
            <Route path='maintenances' element={<Maintenances />} />
            <Route path='alerts/maintenances/due' element={<Maintenances />} />
            <Route path='alerts/maintenances/overdue' element={<Maintenances />} />
            <Route path='alerts/v2' element={<SetupAlerts />} />
            <Route path='warranties' element={<Warranties />} />
            <Route path='warranties/expire' element={<Warranties />} />
            <Route path='dispose' element={<Dispose />} />
            <Route path='move' element={<Move />} />
            <Route path='assets' element={<UserAssets />} />
            <Route path='list-of-assets' element={<UserAssets />} />
            <Route path='import' element={<Import />} />
            <Route path='export' element={<Export />} />
            <Route path='persons' element={<Persons />} />
            <Route path='customers' element={<Customers />} />
            <Route path='users' element={<Users />} />
            <Route path='users/add' element={<AddUsers />} />
            <Route path='users/edit/:id' element={<EditUser />} />
            <Route path='group-manager' element={<SecurityGroups />} />
            <Route path='group-manager/creategroup' element={<CreateGroup />} />
            <Route path='group-manager/editGroup/:id' element={<CreateGroup />} />
            <Route path='reserve' element={<Reserve />} />
            <Route path='menu-test' element={<MenuTestPage />} />
            <Route path='apps/reports/assetbyassettag' element={<ReportByAssetTags />} />
            <Route path='apps/reports/assetsbytagwithpictures' element={<ReportByAssetPicture />} />
            <Route path='apps/reports/assetbycategory' element={<ReportByCategories />} />
            <Route path='apps/reports/assetbysite' element={<ReportsBySites />} />
            <Route path='apps/reports/assetbylocation' element={<ReportsByLocations />} />
            <Route path='apps/reports/assetbydepartment' element={<ReportByDepartment />} />
            <Route path='apps/reports/assetbywarrantyinfo' element={<ReportByWarrantyInfo />} />
            <Route path='apps/reports/by-person-employee' element={<ReportsByPersonEmployee />} />
          </>
        )}

        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />
        <Route
          path='crafted/pages/account-details/*'
          element={
            <SuspensedView>
              <AccountDetailsPage />
            </SuspensedView>
          }
        />
        {!(personUser || customerUser) && (
          <Route
            path='crafted/pages/wizards/*'
            element={
              <SuspensedView>
                <WizardsPage />
              </SuspensedView>
            }
          />
        )}
        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />
        <Route
          path='apps/user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        {!(personUser || customerUser) && (
          <Route
            path='apps/setup/*'
            element={
              <SuspensedView>
                <SetupPage />
              </SuspensedView>
            }
          />
        )}
        <Route
          path='apps/asset/*'
          element={
            <SuspensedView>
              <AssetPage />
            </SuspensedView>
          }
        />
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}
const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export { PrivateRoutes }
