import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { SiteResponse } from '../Core/_models'
import { SitesHeader } from './SitesHeader'
import SitesModal from './SitesModal'
import ConfirmationPopup from '../../../../../../_metronic/helpers/components/ConfirmationPopup'
import ActionIcon from '../../../../../../_metronic/assets/icons/ActionIcon'
import { useDeleteSitesMutation, useGetAllSitesQuery } from '../../../../Services/Sites'
import { useNavigate } from 'react-router-dom'
import Pagination from 'rc-pagination'
import 'rc-pagination/assets/index.css'
import localeInfo from 'rc-pagination/lib/locale/en_US'
import SplashScreen from 'App/SplashScreen'
import { useAuth } from 'App/Modules/Auth'
import { checkUserUnauthorized } from 'App/Modules/Auth'
import { setupKeys } from 'config'
import { Link } from 'react-router-dom'
import Loader from 'App/BeatLoader'

const SitesListing = () => {
	const navigate = useNavigate()
	const { currentUser, saveAuth, setCurrentUser } = useAuth()
	const [check, setCheck] = useState<number[]>([])
	const [show, setShow] = useState<boolean>(false)
	const [data, setData] = useState({})
	const [selectAll, setSelectAll] = useState(false)
	const [selected, setSelected] = useState<number[]>([])
	const [deleteDataArr, setDeleteDataArr] = useState<number[]>([])
	const [deleteModal, setDeleteModal] = useState<boolean>(false)
	const [deleteSites, { isLoading: isLoadingDeleteSite }] = useDeleteSitesMutation()
	const [isSitesExist, setIsSitesExist] = useState<any>(false)
	const [deleteCascade, setDeleteCascade] = useState(false)
	const [pagination, setPagination] = useState<any>({})
	const [loading, setLoading] = useState<boolean>(false)
	const [page, setPage] = useState<number>(1)
	const [limit, setLimit] = useState<number>(10)
	const { data: userSitesList, isLoading, isError, error } = useGetAllSitesQuery({ page, limit })

	useEffect(() => {
		if (isError) {
			const errorData = error as { data: any }
			checkUserUnauthorized(errorData?.data, saveAuth, setCurrentUser, toast)
		} else if (!userSitesList?.sites?.length && page > 1) {
			setPage(page - 1)
		}
	}, [isError, userSitesList])

	const handleClose = () => {
		setShow(false)
	}

	const handlePageChange = (currentPage: any) => {
		setLoading(true)
		setPage(currentPage)
	}

	const handleShow = (item: SiteResponse) => {
		setData(item)
		setShow(true)
	}

	const handleCheckboxChange = (id: number) => {
		setSelected((prevSelected) => {
			const updatedSelected = prevSelected.includes(id)
				? prevSelected.filter((itemId) => itemId !== id)
				: [...prevSelected, id]
			const isAllSelected = updatedSelected.length === userSitesList?.sites?.length
			setSelectAll(isAllSelected)
			return updatedSelected
		})
		setDeleteDataArr((prevSelected) => {
			if (prevSelected.includes(id)) {
				return prevSelected.filter((itemId) => itemId !== id)
			} else {
				return [...prevSelected, id]
			}
		})
	}

	const checkVistingPage = (dataList: any) => {
		if (isSitesExist === false) {
			if (dataList && dataList?.length > 0) {
				setIsSitesExist(dataList?.length)
			} else {
				setIsSitesExist(0)
			}
		}
	}

	const handleSelectAll = (event: React.ChangeEvent<HTMLInputElement>) => {
		if (event.target.checked) {
			const newSelecteds = userSitesList?.sites?.map((item: any) => item.id)
			setSelected(newSelecteds)
			setSelectAll(true)
			setDeleteDataArr(newSelecteds)
		} else {
			setSelected([])
			setSelectAll(false)
			setDeleteDataArr([])
		}
	}

	const deleteSelectedSites = async (id: number[]) => {
		try {
			const siteIds = { siteIds: id }
			const res = await deleteSites({ cascade: deleteCascade ? 1 : 0, siteIds }).unwrap()
			toast.success(res.message)
			setCheck([])
			setDeleteDataArr([])
			setSelected([])
			setSelectAll(false)
			setDeleteModal(false)
			setDeleteCascade(false)
			setLoading(true)
		} catch (error: any) {
			setCheck([])
			setDeleteDataArr([])
			setSelectAll(false)
			setDeleteModal(false)
			setDeleteCascade(false)
			setSelected([])
			if (!error?.data?.success) {
				toast.error(error?.data?.message)
			}
			checkUserUnauthorized(error?.data, saveAuth, setCurrentUser, toast)
		}
	}
	const cancel = () => {
		setDeleteModal(false)
		setDeleteCascade(false)
	}
	const confirm = () => deleteSelectedSites(check)

	useEffect(() => {
		if (currentUser && userSitesList) {
			checkVistingPage(userSitesList?.sites)
			setPagination(userSitesList?.pagination)
			setLoading(false)
		}
	}, [userSitesList])

	const handleLimitChange = (newLimit: number) => {
		setLoading(true)
		setLimit(Number(newLimit))
		setPage(1)
	}

	const currentPage = userSitesList?.pagination?.page || 0
	const totalRecords = userSitesList?.pagination?.total || 0
	const pageSize = limit || 10
	const startRecord = (currentPage - 1) * pageSize + 1
	const endRecord = Math.min(currentPage * pageSize, totalRecords)

	return (
		<>
			<SplashScreen isLoadingTemplate={isLoading || isLoadingDeleteSite} />
			{!(isLoading || isLoadingDeleteSite) ? (
				<div id='kt_content' className='d-flex flex-column flex-column-fluid'>
					<div className='post d-flex flex-column-fluid' id='kt_post'>
						<div id='kt_content_container' className='container'>
							<div className='card'>
								<ConfirmationPopup
									deleteModal={deleteModal}
									cancel={cancel}
									confirm={confirm}
									deleteCascade={deleteCascade}
									setupName={setupKeys.site}
								/>
								<SitesHeader
									deleteDataArr={deleteDataArr}
									setDeleteModal={setDeleteModal}
									setCheck={setCheck}
									handleShow={handleShow}
								/>
								<SitesModal
									show={show}
									handleClose={handleClose}
									SiteData={data}
									setLoading={setLoading}
								/>

								<div className='card-body py-4'>
									<p className={'text-dark'}>
										<strong>Asset Rack</strong> allows you to enter multiple <strong>Sites</strong>.
										For example, the <strong>Site</strong> may be a building or address. This means
										that you can better track each asset that is assigned to a given{' '}
										<strong>Site</strong>. A detailed <strong>Site</strong> makes it easy to find
										and count each asset.
									</p>
									<div className='d-flex limit-options align-items-center mb-3'>
										<span className='text-muted'>Showing</span>
										<select
											className='form-select form-select-sm fw-bold mx-3 border-1'
											onChange={(e) => handleLimitChange(Number(e.target.value))}
											value={limit}
										>
											<option value='10'>10</option>
											<option value='15'>15</option>
											<option value='20'>20</option>
											<option value='25'>25</option>
											<option value='50'>50</option>
										</select>
										<span className='text-muted'>entries</span>
									</div>

									<div className='table-responsive pb-4 custom-table'>
										<table
											id='kt_table_users'
											className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'
											role='table'
										>
											<thead>
												<tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
													<th role='columnheader' className='w-10px pe-2'>
														<div className='header-checkbox me-3'>
															<input
																type='checkbox'
																className='form-check-input custom-form-check-input'
																checked={selectAll}
																onChange={handleSelectAll}
															/>
														</div>
													</th>
													<th role='columnheader' className='w-10%'>
														{' '}
														Site{' '}
													</th>
													<th role='columnheader' >
														{' '}
														Description{' '}
													</th>
													<th role='columnheader' >
														{' '}
														Address{' '}
													</th>
													<th role='columnheader' >
														{' '}
														Apt/Suite{' '}
													</th>
													<th role='columnheader' >
														{' '}
														City{' '}
													</th>
													<th role='columnheader' >
														{' '}
														State{' '}
													</th>
													<th role='columnheader' >
														{' '}
														Zip{' '}
													</th>
													<th role='columnheader' >
														{' '}
														country{' '}
													</th>
													<th role='columnheader' className='text-center'>
														Actions
													</th>
												</tr>
											</thead>
											<tbody className='text-gray-600 fw-bol' role='rowgroup'>
												{userSitesList?.sites?.length ? (
													userSitesList?.sites?.map((item: SiteResponse, idx: number) => {
														return (
															<tr key={idx} role='row'>
																<td role='cell'>
																	<div className='form-check form-check-sm form-check-custom '>
																		<input
																			type='checkbox'
																			className='form-check-input'
																			checked={selected.includes(item.id)}
																			onChange={() => handleCheckboxChange(item.id)}
																		/>{' '}
																	</div>
																</td>
																<td role='cell'>
																	<div className='table-elipses'>{item.name}</div>
																</td>
																<td role='cell'>
																	<div className='table-elipses'>{item.description}</div>
																</td>
																<td role='cell'>
																	<div className='table-elipses'>{item.address}</div>
																</td>
																<td role='cell'>
																	<div className='table-elipses'>{item.aptSuite}</div>
																</td>
																<td role='cell'>
																	<div className='table-elipses'>{item.city}</div>
																</td>
																<td role='cell'>
																	<div className='table-elipses'>{item.state}</div>
																</td>
																<td role='cell'>
																	<div className='table-elipses'>{item.postalCode}</div>
																</td>
																<td role='cell'>
																	<div className='table-elipses'>{item.country}</div>
																</td>
																<td role='cell' className='text-center'>
																	<button
																		className='dropdown-toggle'
																		type='button'
																		id='dropdownMenuButton1'
																		data-bs-toggle='dropdown'
																		aria-expanded='false'
																	>
																		Action
																		<ActionIcon />
																	</button>
																	<ul
																		className='dropdown-menu actions-dropdown'
																		aria-labelledby='dropdownMenuButton1'
																	>
																		<li onClick={() => handleShow(item)}>
																			<button className='dropdown-item'>Edit</button>
																		</li>
																		<li
																			onClick={() => {
																				setDeleteModal(true)
																				setCheck([item.id])
																			}}
																		>
																			<button className='dropdown-item'>Delete</button>
																		</li>
																	</ul>
																</td>
															</tr>
														)
													})
												) : (
													<tr>
														<td colSpan={12}>
															<div className='d-flex text-center w-100 align-items-center justify-content-center no-records fw-bold rounded'>
																No records found
															</div>
														</td>
													</tr>
												)}
											</tbody>
										</table>
									</div>
									{pagination && userSitesList?.sites?.length > 0 && (
										<div className='d-sm-flex align-items-center justify-content-between flex-wrap pt-3'>
											<div className='m-1'>
												Showing {startRecord} to {endRecord} of {totalRecords} records
											</div>
											<Pagination
												className='m-1 custom-pagination'
												defaultCurrent={1}
												pageSize={limit}
												current={pagination?.page}
												total={userSitesList?.pagination?.total}
												onChange={handlePageChange}
												locale={localeInfo}
											/>
										</div>
									)}
								</div>
							</div>
						</div>
					</div>
					<Loader loading={loading} />
				</div>
			) : null}
		</>
	)
}

export default SitesListing
