import React, { useEffect, useRef, useState } from 'react';
import Footer from 'App/Modules/Home/Footer/Footer';
import Header from 'App/Modules/Home/Header/Header';
import './PrivacyPolicy.css';

interface Section {
    id: string;
    title: string;
}
const sections: Section[] = [
    { id: 'section-intro', title: 'Introduction' },
    { id: 'section-values', title: 'Information We Collect' },
    { id: 'section-info', title: 'Sharing Your Information' },
    { id: 'section-cookies-and-tracking', title: 'Cookies and Tracking Technologies' },
    { id: 'section-log-information', title: 'Log Information' },
    { id: 'section-email-track', title: 'Email Tracking' },
    { id: 'section-use-your-info', title: 'How We Use Your Information' },
    { id: 'section-control-your-info', title: 'Control of Your Information' },
    { id: 'section-your-rights', title: 'Your Rights' },
    { id: 'section-security', title: 'Security' },
    { id: 'section-privacy-of-minors', title: 'Privacy of Minors' },
    { id: 'section-delete-data', title: 'Deleted Data' },
    { id: 'section-data-retention', title: 'Data Retention' },
    { id: 'section-contact-info', title: 'Contact Information' },
    { id: 'section-changes-policy', title: 'Changes to This Policy' },
];

const PrivacyPolicy: React.FC = () => {
    const tocRef = useRef<HTMLDivElement | null>(null);
    const [activeSection, setActiveSection] = useState<string>(sections[0].id);

    useEffect(() => {
        window.scrollTo(0, 0);
        const handleScroll = () => {
            const offsetTop = 120;
            if (tocRef.current) {
                if (window.innerWidth >= 768 && window.scrollY > offsetTop) {
                    tocRef.current.classList.add('sticky');
                } else {
                    tocRef.current.classList.remove('sticky');
                }
            }
        };

        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        setActiveSection(entry.target.id);
                    }
                });
            },
            { rootMargin: '0px 0px -80% 0px' }
        );

        sections.forEach((section) => {
            const element = document.getElementById(section.id);
            if (element) {
                observer.observe(element);
            }
        });

        window.addEventListener('scroll', handleScroll);
        window.addEventListener('resize', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
            window.removeEventListener('resize', handleScroll);
            observer.disconnect();
        };
    }, []);

    const scrollToSection = (id: string) => {
        const element = document.getElementById(id);
        if (element) {
            const yOffset = -100;
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({ top: y, behavior: 'smooth' });
        }
    };

    return (
        <>
            <Header />
            <div className="privacy-policy-container">
                <div className="container">
                    <div className="row privacy-policy-content">
                        <div className='col-md-4 col-lg-4'>
                            <nav className="privacy-policy-toc" ref={tocRef}>
                                <ul className="list-unstyled">
                                    {sections.map((section) => (
                                        <li key={section.id}>
                                            <a
                                                href={`#${section.id}`}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    scrollToSection(section.id);
                                                }}
                                                className={activeSection === section.id ? 'active-table-of-content' : ''}
                                            >
                                                {section.title}
                                            </a>
                                        </li>
                                    ))}
                                </ul>
                            </nav>
                        </div>
                        <div className="col-md-8 col-lg-8 privacy-policy-details">
                            <div className='privacy-policy-header'>
                                <h1>Privacy Policy - Asset Rack</h1>
                            </div>
                            <section id="section-intro">
                                <h2>Introduction</h2>
                                <p>At Asset Rack, we understand the importance of your privacy. This policy outlines how we collect, use, and protect your personal information. By using our services, you agree to the practices described in this Privacy Policy, which is also subject to our Terms of Service.</p>
                            </section>
                            <section id="section-values">
                                <h2>Information We Collect</h2>
                                <p>We only collect personal information when you voluntarily provide it. This may occur when you complete a form, send us an email, or engage in a transaction on our site. The information we collect may include your name, company name, business address, email address, phone number, fax number, job title, industry sector, and payment details.</p>
                                <p>To ensure the security of your credit card information, we use a certified payment processor in compliance with PCI DSS regulations. The processor returns a token to us, so we never see, process, or store your raw credit card information. Our compliance is validated through annual SAQ-D and quarterly vulnerability scans conducted by an external agency.</p>
                            </section>
                            <section id="section-info">
                                <h2>Sharing Your Information</h2>
                                <p>There are occasions when we share your voluntarily provided information with third parties to fulfill your requests. These parties are contractually obligated to use your information solely for the purposes specified and are prohibited from using it for other purposes.</p>
                                <p>We may engage third-party service providers to perform functions on our behalf, such as mailing, email services, credit card processing, customer satisfaction surveys, and order fulfillment. These providers have access to the necessary information to perform their duties but are restricted from using it for other purposes. You have the option to opt out of this information sharing, although it may impact our ability to provide certain services to you.</p>
                            </section>
                            <section id="section-cookies-and-tracking">
                                <h2>Cookies and Tracking Technologies</h2>
                                <p>Our website uses cookies, which are small data files stored on your computer. Cookies help us recognize you as a past customer and improve your experience on our site. We also use third-party cookies, like Google Analytics, for website analytics. You can opt out of advertising cookies by following the instructions provided in your browser settings.</p>
                                <p>We do not respond to "do not track" signals and do not collect data on your online activities across third-party websites.</p>
                            </section>
                            <section id="section-log-information">
                                <h2>Log Information</h2>
                                <p>Our servers automatically log certain information, such as your browser type, IP address, referral source, and navigation patterns on our site. This information is not personally identifiable and helps us detect unauthorized access to your account and improve our services.</p>
                                <p>We also track your usage history on our site, including search history and accessed content, to facilitate easier navigation.</p>
                            </section>
                            <section id="section-email-track">
                                <h2>Email Tracking</h2>
                                <p>If you receive emails from us, we may use web beacon technology to track whether you have clicked on individual email advertisements.</p>
                            </section>
                            <section id="section-use-your-info">
                                <h2>How We Use Your Information</h2>
                                <p>We use your personal information to personalize your experience, maintain and improve Asset Rack, and communicate with you. We share your information with third parties only under specific conditions:</p>
                                <ul className='privacy-ul'>
                                    <li>To provide products or services you have requested, such as order confirmations, invoices, and customer service communications.</li>
                                    <li>For marketing purposes, like sending catalogs or marketing emails. We may share your name and physical address with third-party affiliates for this purpose.</li>
                                    <li>When required by law, such as in response to a court order.</li>
                                    <li>To investigate suspected fraud, illegal activities, or violations of our Terms of Service.</li>
                                    <li>When we hire contractors to perform services for us, including payment processing and order fulfillment.</li>
                                    <li>If you donate to our charity partners at checkout, we will share your contact information with the respective charity partner.</li>
                                    <li>If Asset Rack is acquired or merged with another company, we will notify you of any changes to this policy.</li>
                                </ul>
                            </section>
                            <section id="section-control-your-info">
                                <h2>Control of Your Information</h2>
                                <p>You can opt out of sharing your personal information with our partners, and receiving catalogs, or marketing emails by emailing us at Info@assetrack.com. Even if you opt-out, we may still use your personal information to communicate with you regarding your account, fulfill your orders, or comply with legal requirements.</p>
                                <p>To deactivate your account, email Info@assetrack.com or call us at +1 (888) 290-7750.</p>
                            </section>
                            <section id="section-your-rights">
                                <h2>Your Rights</h2>
                                <p>You have the right to:</p>
                                <ul className='privacy-ul'>
                                    <li>Request the rectification or erasure of your personal data.</li>
                                    <li>Restrict the processing of your personal data.</li>
                                    <li>Object to the use of your personal data for direct marketing purposes.</li>
                                    <p>To exercise these rights, contact us via:</p>
                                    <li className='d-flex align-items-start justify-content-start flex-column'>
                                        Phone:
                                        <a className='contact-info' href="tel:+18882907750"><span className='website-url'>+1 (888) 290-7750</span></a>
                                        or <a className='contact-info' href="tel:+17187971900"><span className='website-url'>+1 (718) 797-1900 </span></a>
                                    </li>
                                    <li>Fax: <a className='contact-info' href="fax:+17185044448"><span className='website-url'>+1 (718) 504-4448</span></a></li>
                                    <li>Email: <a className='contact-info' href="mailto:Info@assetrack.com"><span className='website-url'>Info@assetrack.com</span></a></li>
                                    <li className='d-flex align-items-start justify-content-start flex-column'>
                                        Adress:
                                        <a
                                            className='contact-info'
                                            href="https://www.google.com/maps?q=300+Cadman+Plaza+West,+Suite+1303,+Brooklyn,+NY+11201,+USA"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <span className='website-url'>
                                                300 Cadman Plaza West, Suite 1303, Brooklyn, NY 11201, USA
                                            </span>
                                        </a>
                                    </li>
                                    <li className='d-flex align-items-start justify-content-start flex-column'>Website: <a className='contact-info ' href="https://www.assetrack.com/contact-us">via the "Contact Us" <span className='website-url'>https://www.assetrack.com/contact-us</span> </a></li>
                                </ul>
                            </section>
                            <section id="section-security">
                                <h2>Security</h2>
                                <p>We store your personal information on secure, access-controlled servers, and our team is committed to maintaining the confidentiality and security of your data. All data is stored in PCI-compliant data centers. In the event of a security breach, we will promptly inform you.</p>
                            </section>
                            <section id="section-privacy-of-minors">
                                <h2>Privacy of Minors</h2>
                                <p>We do not knowingly collect information from minors under 18. If we discover that we have collected such information, we will cancel the account and delete the data.</p>
                            </section>
                            <section id="section-delete-data">
                                <h2>Deleted Data</h2>
                                <p>Your data will be automatically deleted within 30 days of account cancellation.</p>
                            </section>
                            <section id="section-data-retention">
                                <h2>Data Retention</h2>
                                <p>We store and process your personal data as long as necessary to fulfill the purposes for which it was collected, comply with our contractual obligations, and adhere to record retention laws.</p>
                            </section>
                            <section id="section-contact-info">
                                <h2>Contact Information</h2>
                                <p>For questions or concerns about our privacy policy, contact us at legal@smartsign.com or by any of the means listed in the "Your Rights" section.</p>
                            </section>
                            <section id="section-changes-policy">
                                <h2>Changes to This Policy</h2>
                                <p>We will notify you of any changes to this policy by updating this page. If you have any questions or comments, please email us at legal@smartsign.com.</p>
                            </section>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default PrivacyPolicy;
